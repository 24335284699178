import { FunctionalComponent, h, JSX } from 'preact';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useSound } from "../hooks/useSound"
import { Img } from "./img"

interface IProps {
  lastTest: string
  lastTestStatus: boolean
  testImagerCount: number
}

export const SatelliteGIF: FunctionalComponent<IProps> = ({ lastTest, lastTestStatus, testImagerCount}) => {
  return lastTest !== 'testimager' ? (
    <Img src={`satellite_gifs/${lastTest}-${lastTestStatus?"success":"fail"}.gif`} />
  ) : (
    <Img src={`satellite_gifs/${lastTest}-${testImagerCount < 3 ?"N"+testImagerCount:"Y"}.gif`} />
  )
}

