export type ComponentCategory = "Structure" | "Command" | "PowerMgmt" | "EarthObserv" | "NavAttitude" | "RadioTelemetry"

export interface IComponent {
  name: string
  category: string
  description: string
  group: string
  battery?: boolean
  valid: boolean
  stats: any
  filename: string
  svg: string
}

export interface IChosenComponentsByCategory { key: ComponentCategory, picks: IComponent[] }
export interface IComponentsCategory { name: string, key: ComponentCategory, items: IComponent[][] }

export type IAnyComponentsCategory = IChosenComponentsByCategory | IComponentsCategory

export function findCategoryByKey<Type extends IAnyComponentsCategory> (key: ComponentCategory, cats: Array<Type>) {
  return cats.find(category => category.key === key)
} 

const components = [
  ["Structure", "3U Structure", "Structure-101", "Lightweight and durable. This 3U structure has room for communications and command components, power equipment, a medium size payload, and enough surface area to maximize solar absorption.", "0/12", "2/3", "0/5", "3/3", "2/5", "Bus", "Y", "Structure-Back, Structure-Front"],
  ["Processing Command and Control","Onboard Computer (OBC)","Command-101","Processes commands and performs calculations necessary to carry out tasks.","9/12","3/3","3/5","2/3","2/5","OBC","Y","OBC"],
  ["Processing Command and Control","Onboard Computer with Integrated Attitude Controls","Command-102","Processes commands and performs calculations necessary to carry out tasks, as well as minimal navigation and attitude capabilities.","11/12","3/3","5/5","2/3","4/5","OBC","N","OBCAlt2"],
  ["Processing Command and Control","Onboard Computer with Integrated Gyroscope ","Command-103","Processes commands and performs calculations necessary to carry out tasks, as well as minimal navigation and stabilization capabilities.","6/12","3/3","4/5","3/3","4/5","OBC","N","OBCAlt1"],
  ["Processing Command and Control","Motherboard (configuration A)","Command-201","Standard motherboard with configuration A for optimal positioning in avionics stack.","3/12","2/3","2/5","2/3","2/5","Motherboard","Y","Motherboard"],
  ["Processing Command and Control","Motherboard (configuration B)","Command-202","Standard motherboard with configuration B for optimal positioning in avionics stack.","3/12","2/3","2/5","2/3","2/5","Motherboard","Y","MotherboardAlt1"],
  ["Processing Command and Control","Motherboard with Integrated Magnetorquer","Command-203","Standard motherboard with additional attitude control component.","5/12","1/3","3/5","3/3","4/5","Motherboard","N","MotherboardAlt2"],
  ["Radio and Telemetry","UHF/VHF Duplex Radio","RadioTelemetry-101","For standard UHF/VHF command signals.","7/12","2/2","3/5","3/3","1/3","Radio","N","RadioAlt2"],
  ["Radio and Telemetry","High-Data S-Band Radio Module","RadioTelemetry-102","For high-data S-band telemetry and command signals.","9/12","2/2","5/5","2/3","5/5","Radio","N","RadioAlt1"],
  ["Radio and Telemetry","S-Band Radio Module","RadioTelemetry-103","For standard S-band telemetry and command signals.","5/12","2/2","3/5","2/3","3/5","Radio","Y","Radio"],
  ["Radio and Telemetry","SDR Transceiver Module","RadioTelemetry-104","Full-range radio emitter software-defined parameters. Good for any communications needs.","12/12","2/2","5/5","3/3","4/5","Radio","N","RadioAlt3"],
  ["Radio and Telemetry","SDR Radio Antenna","RadioTelemetry-201","For use only with SDR transceiver module.","12/12","2/3","0/5","2/3","3/5","Antenna1","N","SBandPatch"],
  ["Radio and Telemetry","S-Band Radio Antenna with UHF","RadioTelemetry-202","Standard antenna for transmission and reception of radio and command signals, S-Band and UHF.","1/12","2/3","2/5","3/3","1/5","Antenna1","Y","SBandPatchAlt1"],
  ["Radio and Telemetry","S-Band Radio Antenna","RadioTelemetry-203","Suitable for transmission and reception of radio and command signals.","2/12","2/3","2/5","3/3","1/5","Antenna1","Y","SBandPatchAlt2"],
  ["Radio and Telemetry","GPS Radio Antenna A","RadioTelemetry-301","Standard GPS telemetry antenna, wide mount.","5/12","2/3","2/5","3/3","1/5","Antenna2","Y","GPSPatchAlt1"],
  ["Radio and Telemetry","GPS Radio Antenna B","RadioTelemetry-302","Standard GPS telemetry antenna. Narrow mount, not compatible with S-Band antenna.","2/12","1/3","1/5","3/3","1/5","Antenna2","N","GPSPatchAlt2"],
  ["Radio and Telemetry","GPS Radio Antenna C","RadioTelemetry-303","Standard GPS telemetry antenna.","5/12","1/3","1/5","3/3","1/5","Antenna2","Y","GPSpatch"],
  ["Navigation and Attitude Control","Base ADCS Module","NavAttitude-101","Attitude and Directional Control system.","2/12","1/3","1/5","1/3","1/5","ADCS","N","ADCSAlt1"],
  ["Navigation and Attitude Control","Medium-capacity ADCS Module","NavAttitude-102","Attitude and Directional Control system with integrated magnetorquer and temperature control system.","6/12","2/3","2/5","1/3","3/5","ADCS","Y","ADCSAlt3"],
  ["Navigation and Attitude Control","High-capacity ADCS Module","NavAttitude-103","Attitude and Directional Control system with integrated magnetorquer.","8/12","2/3","2/5","2/3","3/5","ADCS","Y","ADCS"],
  ["Navigation and Attitude Control","ADCS Module with Backup Control System","NavAttitude-104","Attitude and Directional Control system with integrated magnetorquer and redundant systems.","12/12","2/3","4/5","2/3","5/5","ADCS","N","ADCSAlt2"],
  ["Navigation and Attitude Control","Reaction Sphere","NavAttitude-201","Integrated ADCS with reaction sphere unit.","8/12","3/3","4/5","2/3","5/5","Positioning","N","ReactionSphere"],
  ["Navigation and Attitude Control","Reaction Wheel Kit, Large","NavAttitude-202","Set of 3 XYZ large reaction wheel units for attitude control. Recommended for 6U satellite structures and larger.","2/12","1/3","3/5","3/3","1/5","Positioning","N","ReactionWheelsAlt1"],
  ["Navigation and Attitude Control","Reaction Wheel Kit, Medium","NavAttitude-203","Set of 3 XYZ medium reaction wheel units for attitude control. Recommended for 3-6U satellite structures.","2/12","1/3","3/5","3/3","1/5","Positioning","Y","ReactionWheels"],
  ["Navigation and Attitude Control","Reaction Wheel Kit, Small","NavAttitude-204","Set of 3 XYZ small reaction wheel units for attitude control. Recommended for 1-2U satellite structures.","2/12","1/3","3/5","3/3","1/5","Positioning","N","ReactionWheelsAlt2"],
  ["Navigation and Attitude Control","Motor Controller Module","NavAttitude-301","Directs power to reaction wheel units. Compatible with all size RW kits.","6/12","1/3","2/5","3/3","2/5","Motor","Y","MotorController"],
  ["Navigation and Attitude Control","Gyro Unit","NavAttitude-401","Senses satellite position to maintain proper attitude. ","5/12","1/3","1/5","3/3","2/5","Gyro","Y","Gyro"],
  ["Navigation and Attitude Control","Standard Sensor Array Kit","NavAttitude-501","Full array of sensors including star sensors, Earth Horizon sensor unit and sun sensor unit.","6/12","2/3","2/5","2/3","3/5","Sensors","Y","SunSensor, StarSensor-Lg, StarSensor-Sm"],
  ["Navigation and Attitude Control","Enduro Sensor Array Kit","NavAttitude-502","Full array of sensors including star sensors, Earth Horizon sensor unit and sun sensor unit.","7/12","2/3","2/5","1/3","3/5","Sensors","Y","SunSensor-Alt, StarSensor-LgAlt, StarSensor-Sm"],
  ["Earth & Space Observation","Imaging Unit with Telescope","EarthObserv-101","Deep-space imaging unit.","12/12","3/3","4/5","1/3","5/5","Imager","N","ImagerAlt2"],
  ["Earth & Space Observation","Imaging Unit","EarthObserv-102","Standard camera imaging unit. ","4/12","1/3","2/5","3/3","2/5","Imager","Y","Imager"],
  ["Earth & Space Observation","Imaging Unit with IR sensors","EarthObserv-103","Standard camera imaging unit with additional IR functionality for infrared imaging.","5/12","2/3","1/5","2/3","3/5","Imager","Y","ImagerAlt1"],
  ["Earth & Space Observation","Imaging Unit with wide-spectrum array and IR sensors","EarthObserv-104","Standard camera imaging unit with wide-spectrum and  IR functionality for a full range of imaging.","9/12","2/3","4/5","2/3","4/5","Imager","N","ImagerAlt3"],
  ["Power Management","Electrical Power System (legacy)","PowerMgmt-101","Manages the power intake and temperature regulation of the satellite.","9/12","2/3","3/5","1/3","1/5","EPS","N","EPSAlt1"],
  ["Power Management","Electrical Power System","PowerMgmt-102","Manages the power intake and temperature regulation of the satellite. Updated from the legacy model. ","9/12","1/3","2/5","2/5","2/5","EPS","Y","EPS"],
  ["Power Management","Electrical Power System with Integrated Battery","PowerMgmt-103","Manages the power intake and temperature regulation of the satellite, as well as storing power with the integrated battery array.","5/12","3/3","3/5","2/5","3/5","EPS","Y","EPSAlt2"],
  ["Power Management","Solar Panel Array, deployable","PowerMgmt-201","Wing-style solar array. Sits alongside structure when not deployed. ","9/12","2/3","0/5","2/3","2/5","Solar Array","N","SolarArray-Near-Alt, SolarArray-Back-Alt"],
  ["Power Management","Bifold Solar Panel Array, deployable","PowerMgmt-202","Wing-style solar array. Sits alongside structure when not deployed.","12/12","3/3","0/5","2/3","3/5","Solar Array","Y","SolarArray-Near, SolarArray-Back"],
  ["Power Management",".5U Solar Panel, Individual","PowerMgmt-301","Standard solar panel for structural surface mounting. Covers approx. .5U surface area.","1/12","1/3","0/5","3/3","1/5","Solar","N", "Solar-Near-AltSingle"],
  ["Power Management",".5U Solar Panel, 5-pack","PowerMgmt-302","Group of standard solar panel for structural surface mounting. Covers approx. 2.5U surface area.","2/12","1/3","0/5","3/3","2/5","Solar","N","Solar-Near-Alt5up"],
  ["Power Management",".5U Solar Panel, 10-pack","PowerMgmt-303","Group of standard solar panel for structural surface mounting. Covers approx. 5U surface area.","5/12","1/3","0/5","3/3","3/5","Solar","Y","Solar-Near"],
  ["Power Management",".5U Solar Panel, 15-pack","PowerMgmt-304","Group of standard solar panel for structural surface mounting. Covers approx. 7.5U surface area.","9/12","1/3","0/5","3/3","4/5","Solar","Y","Solar-Near"],
  ["Power Management","Secure Battery Unit","PowerMgmt-401","Self-contained power unit with integrated battery.","5/12","1/3","1/5","3/5","4/5","Battery","N","BatteriesAlt2"],
  ["Power Management","Secure Battery Unit with Additional Battery Array","PowerMgmt-402","Self-contained power unit with integrated battery, plus additional high-power battery array for more operation time.","10/12","3/3","1/5","2/5","5/5","Battery","N","BatteriesAlt1"],
  ["Power Management","High-Density Battery Array","PowerMgmt-403","One battery array for moderate operation while minimizing size.","5/12","1/3","1/5","1/3","1/5","Battery","Y","Batteries"],
  ["Power Management","High-Density Battery Matrix","PowerMgmt-404","A full 1U stack of battery arrays for maximum power storage.","12/12","3/3","1/5","1/3","3/5","Battery","N","BatteriesAlt3"]
]

const componentCategoriesKeys = Array.from(new Set(components.map(c => c[2].split('-')[0]))) // PowerMgmt, etc
const componentCategoriesNames = Array.from(new Set(components.map(c => c[0]))) // Power Management, etc.
const groupsByCategory = {}; // { "PowerMgmt": [ "Battery", etc ]"}
componentCategoriesKeys.forEach(ck => {
  groupsByCategory[ck] = Array.from(new Set(components.filter(c => c[2].includes(ck)).map(c => c[9])))
})

const makeComponent: (cs: any[]) => IComponent[] = (cs) => {
  return cs.map(c => {
    const [ category, name, filename, description, power, size, energy, durability, cost, group, valid, svg ] = c;
    return {
      name,
      category,
      description,
      group,
      valid: valid === 'Y',
      svg,
      stats: {
        power: power.split("/"),
        size: size.split("/"),
        energy: energy.split("/"),
        durability: durability.split("/"),
        cost: cost.split("/"),
      },
      filename
    }
  })
}

const categories: IComponentsCategory[] = componentCategoriesKeys.map((ck, i) => {
  return {
    name: componentCategoriesNames[i],
    key: ck as ComponentCategory,
    items: groupsByCategory[ck].map((gc: string) => makeComponent(components.filter(c => c[9] === gc))) // for each group makes a list of components
  }
})

export const useSatComponents = () => {
  return {
    categories,
    components
  }
}
