import { FunctionalComponent, h, JSX } from 'preact';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { Img } from "./img"
import { Section } from './section';
import useFade from "../hooks/useFade";
import { config } from "../config"
import useSound from '../hooks/useSound';
import { SectionName } from "../section-context"
import {usePagination} from '../hooks/usePagination';

const images = [
  { 
    src: "3dmodel-1.png",
    title: "You've made first contact with a 3D printed alien!",
    download: "/assets/m2/obj/alien.stl",
  },
  { 
    src:"3dmodel-2.png" ,
    title: "Just your everyday badass alien samurai.",
    download: "/assets/m2/obj/alien-samurai.stl",
  },
  { 
    src:"3dmodel-3.png" ,
    title: "In space, no one can hear you 3D print.",
    download: "/assets/m2/obj/space-helmet.stl",
  },
  { 
    src:"3dmodel-4.png" ,
    title: "A NASA communication satellite!",
    download: "/assets/m2/obj/satellite.zip",
  }
]

interface IProps {
  close: (...params: any) => void,
}

const galleryTotal = 4;

export const TDPrinterSection: FunctionalComponent<IProps> = ({ close }) => {
  const [ playButtonSound ] = useSound(`${config.m2SoundFolder}click_to_the_next_3d_model.ogg`, { volume: 0.5 })
  const nothing = useCallback((evt: JSX.TargetedMouseEvent<HTMLElement>) => {
    evt.stopPropagation();
  }, [])
  const { idx: galleryIdx, back, next } = usePagination(galleryTotal)
  const img = useMemo(() => images[galleryIdx], [galleryIdx])
  const moveWithSound = (cb) => (e) => {
    playButtonSound();
    cb(e)
  }
  return (
    <Section name={SectionName.tdprinter} close={close} title={img.title} subtitle="" transition="fade-in">
      <div class="specs-gallery" onClick={nothing}>
        <Img src={img.src} close={close} download={img.download} class={`tdprinter-image tdprinter-image--${galleryIdx}`} />
        <div class="tdgallery__nav">
          <button onClick={moveWithSound(back)} class="btn-prev"></button>
          <button onClick={moveWithSound(next)} class="btn-next"></button>
        </div>
      </div>
    </Section>
  )
}
