import { h } from 'preact';
import {useCallback, useContext, useMemo} from 'preact/hooks';
import useFade from '../hooks/useFade';
import { SectionName, SectionContext } from '../section-context';

interface IProps {
  close?: (...params: any) => void,
  children: any,
  name: SectionName,
  transition?: "fade-in" | "slide",
  class?: string,
  title?: string,
  subtitle?: string,
  nopos?: boolean,
}
export const Section = ({
  title = "", subtitle = "", children, close, name, nopos, class: className = "", transition = "slide", ...props
}: IProps) => {

  const { activeSection, scrollLeft } = useContext(SectionContext);
  const [ display, setDisplay, displayProps ] = useFade(false);

  const getSectionClasses = useCallback(() => {
    const classes = `experience__section--transition-${transition} ${className}`;
    if (activeSection === name) {
      if (transition === "fade-in") {
        setDisplay(true);
      }
      return classes +" experience__section--expanded";
    }
    return classes;
  }, [activeSection, className, transition, name])

  const style = { 
    left: !nopos ? scrollLeft : 0,
    ... transition === "fade-in" ? displayProps.style : {}
  }

  return (
    transition !== "fade-in" || activeSection === name ? (
      <div id={`section--${name}`} class={`experience__section--${name} experience__section ${getSectionClasses()}`} onClick={close} {...props} {... transition === "fade-in" ? displayProps: {}} style={style} >
        {title && 
          <div class="experience__section__title">
            {title}
          </div>
        }
        {subtitle &&
          <div class="experience__section__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
        }
        {children}
      </div>
    ) : null
  )
}
