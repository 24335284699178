import { FunctionalComponent, h, JSX } from 'preact';
import { useCallback, useContext, useEffect, useRef, useState } from 'preact/hooks';
import { useSound } from "../hooks/useSound"
import { config } from "../config"
import { Close } from "./close"
import { SectionContext, SectionName } from "../section-context"
import { Section } from './section';

interface IProps {
  close: (...params: any) => void,
}

const trackTotal = 3;

const tracks = [{
    title: "Chill Space",
    url: `${config.m2SoundFolder}track-1.ogg`
  },
  {
    title: "Intense",
    url: `${config.m2SoundFolder}track-2.ogg`
  },
  {
    title: "Synthwave",
    url: `${config.m2SoundFolder}track-3.ogg`
  }
];

export const BluetoothRadioSection: FunctionalComponent<IProps> = ({ close }) => {
  const { audioPaused, contentHeight } = useContext(SectionContext);
  const [ volume, setVolume ] = useState<number>(50);
  const trackAudioRef = useRef(typeof window !== "undefined" ? new Audio(tracks[0].url) : null);
  const bgAudioRef  = useRef(typeof window !== "undefined" ? new Audio(`${config.m2SoundFolder}ambient-music.ogg`) : null);
  const [ playButtonSound ] = useSound(`${config.m2SoundFolder}click_next_song.ogg`, { volume: 0.5 })

  if (trackAudioRef) {
    trackAudioRef.current.autoplay = true
    bgAudioRef.current.autoplay = true
  }

  useEffect(() => {
    return () => {
      trackAudioRef.current.pause();
      bgAudioRef.current.pause();
    }
  }, [])

  useEffect(() => {
    bgAudioRef.current.volume = volume / 100 / 4;
    trackAudioRef.current.volume = volume / 100;
  }, [volume]) 

  const getPos = useCallback((num: number | "auto") => {
    if (num !== "auto") {
      return `${num / 100 * contentHeight}px`;
    }

    return num
  }, [contentHeight])

  const [ trackIdx, setTrackIdx ] = useState<number>(0)
  const playNextTrack = useCallback(() => {
    playButtonSound();
    setTrackIdx((trackIdx + 1) % trackTotal);
  }, [trackIdx, playButtonSound]);

  const playPrevTrack = useCallback(() => {
    playButtonSound();
    setTrackIdx((trackIdx || trackTotal) - 1);
  }, [trackIdx, playButtonSound]);

  useEffect(() => {
    trackAudioRef.current.src = tracks[trackIdx].url;
    if (!audioPaused) {
      trackAudioRef.current.play();
    }
  }, [trackIdx, audioPaused])

  useEffect(() => {
    if (!audioPaused) {
      trackAudioRef.current.play()
      bgAudioRef.current.loop = true
      bgAudioRef.current.play()
    } else {
      trackAudioRef.current.pause()
      bgAudioRef.current.pause()
    }
  }, [audioPaused])

  return (
    <Section name={SectionName.bluetooth} close={close} title="" subtitle="" transition="fade-in">
      <div style={`position:relative; height: 60vh; width: 60vh; margin: auto;`}>
        <div class="radio__sound" style={{ top: getPos(12), left: getPos(31), width: getPos(47)}}>
        <input class="radio__volume" type="range" min="0" max="100" value={volume} onChange={(e: any) => setVolume(e.target.value)}/>
      </div>
        { /* TODO: buttons should have size according to the screen */}
        <button class="radio__prev" style={{left: getPos(13), top: getPos(8.3) }} onClick={playPrevTrack} />
      <button class="radio__next" style={{left: getPos(24), top: getPos(8.6)}} onClick={playNextTrack}/>
        <button class="radio__prev__hover" style={{left: getPos(13.5), top: getPos(10.6), backgroundUrl: "radio-back-hover.svg"}} onClick={playPrevTrack}/>
      <button class="radio__next__hover" style={{left: getPos(24.5), top: getPos(10.6), backgroundUrl: "radio-next-hover.svg"}} onClick={playNextTrack}/>
    </div>
      <Close close={close} />
    </Section>
  )
}
