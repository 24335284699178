import { useCallback, useState } from 'preact/hooks';
import useSound from './useSound';

export const usePagination = (total: number, start: number = 0, soundfile?: string) => {
  const [ playPageFlip ] = soundfile ? useSound(soundfile, { volume: 0.5 }) : [ null ];
  const [ idx, setIdx ] = useState(start)
  const next = useCallback(() => {
    if (!soundfile || idx < total - 1) {
      setIdx((idx + 1) % total);
      if (soundfile) {
        playPageFlip()
      }
    }
  }, [idx, playPageFlip]);
  const back = useCallback(() => {
    if (!soundfile || idx > 0) {
      setIdx(((idx || total) - 1) % total);
      if (soundfile) {
        playPageFlip()
      }
    }
  }, [idx]);

  return {
    idx,
    next,
    back
  }
}

