import { h } from 'preact';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import {SectionContext, SectionName} from '../section-context';
import { config } from "../config"
import { Video } from "./video"

interface IProps {
  close: (...params: any) => void,
  playVideo: (...params: any) => void,
  stopVideo: (...params: any) => void,
}

  export const LaptopVideoSection = ({close, playVideo: _playVideo, stopVideo: _stopVideo}: IProps) => {
  const { activeSection } = useContext(SectionContext)
  const [ videoPlaying, setVideoPlaying ] = useState<boolean>(false)

  useEffect(() => {
    if (activeSection !== SectionName.login) {
      setVideoPlaying(false)
    }
  }, [activeSection])

  const playVideo = useCallback(() => {
    _playVideo()
    setVideoPlaying(true)
  }, [_playVideo])

  const stopVideo = useCallback(() => {
    _stopVideo()
    setVideoPlaying(false)
  }, [_stopVideo])

  return (
    <div id="login-section" class="browser">
      <div class="browser__title">
        <button class="browser__close" onClick={close}></button>
        <button class="browser__max"></button>
        <button class="browser__min" onClick={close}></button>
      </div>

      <div class="browser__content browser__content--video">
        <Video 
          src={`${config.m1VideoFolder}laptop-video.mp4`}
          playVideo={playVideo}
          stopVideo={stopVideo}
          controls
          playing={videoPlaying}
          />
      </div>
    </div>
  )
}

