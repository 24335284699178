import { FunctionalComponent, h, JSX } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { Img } from "./img"
import useFade from "../hooks/useFade";
import { config } from "../config"
import useSound from '../hooks/useSound';
import {usePagination} from '../hooks/usePagination';

const svgs = {
}

interface IProps {
  close: (...params: any) => void,
  completedFaseOne:  (...params: any) => void,
}

const galleryTotal = 4;
const rightSpec = 2;

export const PrintSection: FunctionalComponent<IProps> = ({ close, completedFaseOne }) => {
  const [ isChosen, setIsChosen, chosenProps ] = useFade(false, "0.5s");
  const [ defaultView, setDefaultView, defaultViewChoosenProps ] = useFade(true, "0.5s");
  const [ winSlide, setWinSlide ] = useState<number>(0)
  const [ playRightFile ] = useSound(`${config.m1SoundFolder}right-file.ogg`, { volume: 0.5 });
  const [ playWrongFile ] = useSound(`${config.m1SoundFolder}wrong-password.ogg`, { volume: 0.5 });
  const { idx: galleryIdx, back, next } = usePagination(galleryTotal)

  const chooseDesign = useCallback(() => {
    setDefaultView(false);
    setIsChosen(true);
  }, [])

  const clear = useCallback((evt: any) => {
    setIsChosen(false);
    evt.preventDefault();
    return false;
  }, []);

  useEffect(() => {
    setDefaultView(!isChosen);
  }, [isChosen])

  const completeFaseOne = useCallback(() => {
    setWinSlide(2)
    completedFaseOne()
  }, [])

  useEffect(() => {
    if (isChosen) {
      if (galleryIdx === rightSpec) {
        playRightFile();
      } else {
        playWrongFile();
      }
    }
  }, [galleryIdx, playWrongFile, playRightFile, isChosen])

  const nothing = useCallback((evt: JSX.TargetedMouseEvent<HTMLElement>) => {
    evt.stopPropagation();
  }, [])

  if (!isChosen) {
    return defaultView && (
      <>
        <div class="experience__section__title">
        </div>
        <div class="experience__section__subtitle">
        </div>
        <div class="specs-gallery" {...defaultViewChoosenProps} onClick={nothing}>
          { galleryIdx === 0 && <Img src="specsheet-1.jpg" close={close} />}
          { galleryIdx === 1 && <Img src="specsheet-2.jpg" close={close} />}
          { galleryIdx === 2 && <Img src="specsheet-3.jpg" close={close} />}
          { galleryIdx === 3 && <Img src="specsheet-4.jpg" close={close} />}
          <div class="specs-gallery__nav">
            <button onClick={back} class="btn-prev"></button>
            <button onClick={next} class="btn-next"></button>
          </div>
          <button class="btn-white" onClick={chooseDesign}>Choose this design</button>
        </div>
      </>
    )
  } else if (isChosen && winSlide === 0) {
    return (
      <>
        <div class="experience__section__title" {...chosenProps}>
          { galleryIdx === rightSpec? "Great choice!" : "Not quite!" }
        </div>
        <div class="experience__section__subtitle" {...chosenProps}>
          { galleryIdx === rightSpec ? "These design parameters should result in a satellite build that can perform the mission. Your project is off to a promising start." : "These design parameters don’t result in a satellite build that can perform the mission. Select a different spec sheet." }
        </div>
        <div class="specs-gallery specs-gallery--final-choice" {...chosenProps} onClick={nothing}>
          <Img src={galleryIdx === rightSpec ? `correct-${galleryIdx+1}.jpg` : `incorrect-${galleryIdx+1}.jpg`}/>
          <button class="btn-white" onClick={galleryIdx === rightSpec ? () => setWinSlide(1) : clear}>
            { galleryIdx === rightSpec ? "What's next?" : "Try again" }
          </button>
        </div>
      </>
    )
  } else if (winSlide === 1) {
    return (
      <>
        <div class="experience__section__title">
          Now what?
        </div>
        <div class="experience__section__subtitle">
          For starters, you get this trophy.<br/>Congratulations on completing Mission 1 of Build-a-Sat!
        </div>
        <Img src="trophy.png" key="img-123" style={{  margin: "30px 0 30px", height: "30vh" }}/>
        <button class="btn-white" onClick={completeFaseOne}>
          Thanks, but... there’s more, right?
        </button>
      </>
    )
  } else if (winSlide === 2) {
    return (
      <>
        <div class="experience__section__title">
          Of course there is!
        </div>
        <div class="experience__section__subtitle">
          To be notified when the next mission is released, fill out the form below with your email.<br />When the time comes, we’ll send you everything you need to keep going.
        </div>
        <div class="browser login-window">
          <div class="browser__title">
            <button class="browser__close" onClick={close}></button>
            <button class="browser__max"></button>
            <button class="browser__min" onClick={close}></button>
          </div>
          <div class="browser__content wpforms-container-full trophy-form">
            <div class="wpforms-form">
              <h1>JOIN THE ALLIANCE</h1>
              <form onSubmit={() => setWinSlide(3)}>
                <div class="wpforms-field wpforms-field-password">
                  <iframe
                    id="JotFormIFrame-221355109819053"
                    title="HackASat Headers Cap Form"
                    allowTransparency
                    allowFullScreen
                    allow="geolocation; microphone; camera"
                    src="https://hipaa.jotform.com/221355109819053"
                    frameBorder="0"
                    scrolling="no"
                    style="height:320px"
                  >
                  </iframe>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  } else if (winSlide === 3) {
    return (
      <div class="flex-center-content" onClick={close}>
        <div class="experience__section__title">
          Welcome to the Alliance!
        </div>
        <div class="experience__section__subtitle">
          Welcome to the Alliance! You will be notified when the next mission is released.<br/> And check your inbox for some bonus material! Continue exploring, or exit the experience and see what else hackasat.com has to offer.
        </div>
        <Img src="wr-signature.svg" style={{width: "100px", margin: "20px"}}/>
      </div>
    )
  }
}

