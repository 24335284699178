import { FunctionalComponent, h, JSX } from 'preact';
import { Section } from './section';
import { SectionContext, SectionName } from '../section-context';
import { Img } from "./img"
import {useCallback, useContext, useMemo, useState} from 'preact/hooks';
import { Clickable } from './clickable';
import { Close } from "./close"
import { ShoppingIcon } from "./shoppingIcon"
import { SatModel } from "./satModel"
import { useSatComponents, ComponentCategory, IChosenComponentsByCategory, findCategoryByKey, IComponent } from "../hooks/useSatComponents"
import useSound from '../hooks/useSound';
import { config } from "../config"
import ReactGA from "react-ga4"

const trackId = "654256973217";

let iframeSrc = ""
if (typeof location !== "undefined") {
  iframeSrc = !location.href.includes("dev--") ? "https://hipaa.jotform.com/221635862291155" : "https://hipaa.jotform.com/221675657755166" 
}

const statCharsMap = {
  power: 'I',
  size: 'ж',
  energy: 'ж',
  durability: 'ж',
  cost: '$',
}

const makeStats = (stat: string, amount: number[]) => {
  const [ active, total ] = amount;
  return (
    <>
      <span class="stat-value stat-value--active">{statCharsMap[stat].repeat(active)}</span>
      <span class="stat-value">{statCharsMap[stat].repeat(total-active)}</span>
    </>
  )
}

interface IProps {
  close: (...params: any) => void,
  complete: (...params: any) => void
}

export const ShoppingSatSection: FunctionalComponent<IProps> = ({ close, complete }) => {
  const { completedFaseTwo } = useContext(SectionContext);
  const [ playComponentChosen ] = useSound(`${config.m2SoundFolder}select_part.ogg`, { volume: 0.5 })
  const [ playWrongParts ] = useSound(`${config.m2SoundFolder}shopping_cart_fail.ogg`, { volume: 0.5 })
  const [ playRightParts ] = useSound(`${config.m2SoundFolder}shopping_cart_success.ogg`, { volume: 0.5 })
  const { categories } = useSatComponents();

  const [ activeCategory, setActiveCategory ] = useState<ComponentCategory | null>(null)
  const [ inputTrack, setInputTrack ] = useState<string>("")
  const [ trackingSuccess, setTrackingSuccess ] = useState<boolean>(false)
  const [ trackingError, setTrackingError ] = useState<boolean>(false)
  const [ didOrder, setDidOrder ] = useState<boolean>(false)
  const [ orderError, setOrderError ] = useState<boolean>(false)
  const [ orderIncomplete, setOrderIncomplete ] = useState<boolean>(false)
  const [ finished, setFinished ] = useState<boolean>(false)
  const [ trophyView, setTrophyView ] = useState<boolean>(false)
  const [ cartView, setCartView ] = useState<boolean>(false)
  const [ dropdownView, setDropdownView ] = useState<boolean>(false)
  const [ specsheetVisible, setSpecsheetVisible ] = useState<boolean>(false)
  const showSpecsheet = useCallback(() => {
    setSpecsheetVisible(true);
    setCartView(false);
  }, [])
  const [ chosenComponents, setChosenComponents ] = useState<IChosenComponentsByCategory[]>(categories.map((category) => ({
    key: category.key,
    picks: category.items.map(() => null)
  })))

  const getCategoryState = useCallback((categoryKey: ComponentCategory) => {
    const picks = findCategoryByKey(categoryKey, chosenComponents).picks
    if (!didOrder) {
      if (picks.some((pick: IComponent | null) => pick === null)) {
        return "incomplete"
      } else {
        return "complete"
      }
    } else {
      if (picks.some((pick: IComponent | null) => !pick?.valid)) {
        return "error"
      } else {
        return "done"
      }
    }
  }, [chosenComponents, didOrder])

  const isChosen = useCallback((categoryKey: ComponentCategory, component: IComponent) => {
    const picks: IComponent[] = findCategoryByKey(categoryKey, chosenComponents).picks
    return picks.includes(component);
  }, [chosenComponents])

  const dropdownText = useMemo(() => {
     return activeCategory !== null ? categories.find(cat => cat.key === activeCategory).name : "Click Here" 
  }, [activeCategory])

  const selectComponent = useCallback((component: IComponent, group: number, category: ComponentCategory) => {
    const picks = findCategoryByKey(category, chosenComponents).picks.slice();
    if (picks[group] && picks[group] === component) {
      ReactGA.event("m2_shopping_section__unpick_component");
      ReactGA.event({
        category: "m2_shopping_section",
        action: "unpick_component",
        label: component.name
      });
      delete picks[group]
    } else {
      ReactGA.event("m2_shopping_section__pick_component");
      ReactGA.event({
        category: "m2_shopping_section",
        action: "pick_component",
        label: component.name
      });
      playComponentChosen();
      picks[group] = component;
    }
    setChosenComponents([
      ...chosenComponents.filter(i => i.key !== category),
      {
        key: category,
        picks: picks
      }
    ]);
  }, [chosenComponents])

  const completed = useMemo(() => (chosenComponents.every(c => c.picks.every(p => p !== null))), [chosenComponents])

  const showTrophy = useCallback((e) => {
    e.preventDefault()
    ReactGA.event("m2_shopping_section__claim_prize");
    ReactGA.event({
      category: "m2_shopping_section",
      action: "claim_prize"
    });
    setCartView(false)
    setSpecsheetVisible(false)
    setTrophyView(true)
    complete()
  }, [])

  const doOrder = useCallback(() => {
    setDidOrder(true)
    setActiveCategory(null)
    if (completed) {
      if (chosenComponents.every(c => c.picks.every(p => p?.valid))) {
        ReactGA.event("m2_shopping_section__right_order");
        ReactGA.event({
          category: "m2_shopping_section",
          action: "right_order"
        });
        playRightParts()
        setFinished(true)
      } else {
        ReactGA.event("m2_shopping_section__wrong_order__wrong_components");
        ReactGA.event({
          category: "m2_shopping_section",
          action: "wrong_order",
          label: "wrong_components"
        });
        playWrongParts()
        setOrderError(true)
      }
    } else {
      ReactGA.event("m2_shopping_section__wrong_order__incomplete");
      ReactGA.event({
        category: "m2_shopping_section",
        action: "wrong_order",
        label: "incomplete"
      });
      playWrongParts()
      setOrderIncomplete(true)
    }
  }, [chosenComponents, playWrongParts])

  const category = useMemo(() => categories.find(c => c.key === activeCategory), [activeCategory])
  const setCategory = useCallback((category) => {
    setActiveCategory(category.key);
    setDropdownView(false)
    setOrderError(false)
    setOrderIncomplete(false)
  }, [])

  const closeCartIfOutside = useCallback((e: any) => {
    if (e.target.id === 'section--shopping-sat' || e.target.id === 'Sat_Mockup-_All_Assets_are_in_Sublayers') {
      setCartView(false)
      setSpecsheetVisible(false)
    }
  }, [])

  /*
  useEffect(() => {
    const completeMission = (event) => {
      if (location.hash === "#callback=shopping-cart") {
        showTrophy()
      }
    }
    window.addEventListener('hashchange', completeMission)

    return () => {
      window.removeEventListener("hashchange", completeMission)
    }
  }, [showTrophy])
   */

  const doTrack = useCallback((e) => {
    e.preventDefault()
    if (trackId === inputTrack) {
      ReactGA.event("m2_shopping_section__track_delivery__ok");
      ReactGA.event({
        category: "m2_shopping_section",
        action: "track_delivery",
        label: "ok"
      });
      setTrackingError(false)
      setTrackingSuccess(true)
    } else {
      ReactGA.event("m2_shopping_section__track_delivery__wrong");
      ReactGA.event({
        category: "m2_shopping_section",
        action: "track_delivery",
        label: "wrong"
      });
      setTrackingSuccess(false)
      setTrackingError(true)
    }
  }, [inputTrack])

  return (
    <Section name={SectionName.shoppingSat} title="" subtitle="" close={closeCartIfOutside} transition="fade-in">
      <Clickable x={107} y={5} width={71} action={showSpecsheet} sound="click_on_the_spec_sheet">
        <Img src="shopping-sat-specsheet-hover.svg" />
      </Clickable>
      <SatModel chosenComponents={chosenComponents} />
      <Close close={close} />
      { specsheetVisible && <Img src="specsheet.jpg" style={{ zIndex: 0 }} close={() => setSpecsheetVisible(false)}/> }

      <div class={`experience__section__shopping-sidebar ${cartView?"experience__section__shopping-sidebar--visible":""}`}>
       { !completedFaseTwo&& (!didOrder || !finished) ? (
          <>
            <div class="experience__section__category-dropdown-header">
              <Img src="sidebar-collapse-icon.svg"  class="icon" onClick={() => setCartView(false)}/>
              <button onClick={doOrder}> ORDER PARTS </button>
              <div class="icon" />
            </div>
            <h2>Category</h2>
            <div class="experience__section__category-dropdown-wrapper">
              <span onClick={() => setDropdownView(!dropdownView)} class="experience__section__category-dropdown-btn">
                {dropdownText}<Img src="dropdown-icon.svg" class="icon" />
              </span>
              <div class={`experience__section__category-dropdown ${dropdownView?'experience__section__category-dropdown--visible':''}`}>
                {categories.map((category) => (
                  <div key={category} class="experience__section__category-dropdown__item" onClick={() => setCategory(category)} >
                    <span class={`experience__section__category-dropdown__state-box experience__section__category-dropdown__state-box--${getCategoryState(category.key)}`}></span>
                    {category.name}
                  </div>
                ))}
              </div>
            </div>
            <div class="experience__section__components-list">
              {category && (
                <div key={category} class={`experience__section__components-list__category ${activeCategory === category.key?"experience__section__components-list__category--active":""}`}>
                  {
                  category.items.map((componentList, i) => componentList.map((component, j) => (
                    <SatComponent key={i + '' + j} data={component} category={category.key} group={i} chosen={isChosen(category.key, component)} action={selectComponent}/>
                  )))
                  }
                </div>
              )}
              {orderError && (
                <div style="margin-top: 32px">
                  <h2>Warning</h2>
                  <p>Some of the parts you have selected may not work for your required specifications.</p>
                  <p>Please review and update your cart before ordering.</p>
                </div>

              )}
              {orderIncomplete && (
                <div style="margin-top: 32px">
                  <h2>Error</h2>
                  <p>Your satellite is missing a component or some components. Please add all parts to your cart before placing your order.</p>
                </div>

              )}
            </div>
          </>
        ) : (
          <div>
            <div class="experience__section__category-dropdown-header">
              <Img src="sidebar-collapse-icon.svg"  class="icon" onClick={() => setCartView(false)}/>
              <div class="icon" />
            </div>
            {!completedFaseTwo && (
              <>
                <h2>Success</h2>
                <p>Your parts are on their way to White Rabbit's workshop! Your tracking number is {trackId}</p>
                <p>Enter your email address to get delivery notifications.</p>
                <iframe
                  id="JotFormIFrame-221635862291155"
                  title="White Rabbit Shopping Cart"
                  allowTransparency
                  allowFullScreen
                  allow="geolocation; microphone; camera"
                  src={iframeSrc}
                  frameBorder={0}
                  scrolling="no"
                  style={{ width: "100%", height: "130px" }}
                >
                </iframe>
                <div style="height: 60px" />
              </>
            )}
            <h2>Track it!</h2>
            <p>Find out when you can start working on mission 3.</p>
            <div class="dark-form">
              <form onSubmit={doTrack} class="experience__section__shopping-sidebar__form wpforms-form">
                <input type="text" onInput={(e: any) => setInputTrack(e.target.value)}/>
                <button>
                  track
                </button>
              </form>
            </div>
            {trackingSuccess && (
              <TrackingChart />
            )}
            {trackingError && (
              <>
              We can't find your package please enter a different tracking number.
              </>
            )}
            {!completedFaseTwo && (
            <div class="dark-form">
              <form onSubmit={showTrophy} class="experience__section__shopping-sidebar__form wpforms-form">
                <button>
                  Claim your prize!
                </button>
              </form>
            </div>
            )}
          </div>
        )}
      </div>

      { trophyView && (
        <div class="experience__section experience__section--expanded experience__section--transition-fade-in">
          <Img src="trophy.png" key="1234" style={{ zIndex: 2 }}/>
          <a href="https://hackasat.com/assets" target="linkref" style="margin: auto">
            <Img src="mission-complete.png" style={{ zIndex: 2, width: "25vw" }}/>
          </a>
          <Close close={() => setTrophyView(false)} />
        </div>
      )}

      <ShoppingIcon action={(e) => { setCartView(true); setSpecsheetVisible(false); e.preventDefault; e.stopPropagation()}} />
    </Section>
  )
}

interface ISatComponentProps { data: IComponent, category: ComponentCategory, group: number, chosen: boolean, action: (c: IComponent, g: number, cc: ComponentCategory) => void }

const SatComponent: FunctionalComponent<ISatComponentProps> =({
  data,
  category,
  group,
  chosen,
  action
}) => {
  const click = useCallback((evt: JSX.TargetedMouseEvent<HTMLElement>) => {
    evt.preventDefault();
    action(data, group, category)
  }, [action, data, group, category])
  return (
    <div class={`experience__section__component ${chosen?'experience__section__component--chosen':''}`} onClick={click}>
      <div class="experience__section__component__image-wrapper">
        <Img src={`${category}/${data.filename}.svg`} class="experience__section__component__image" />
      </div>
      <div class="experience__section__component__content col">
        <h3>{data.name}</h3>
        <p>{data.description}</p>
        <div class="experience__section__component__content__stats">
          {Object.keys(data.stats).map((statName: string) => (
            <div class="col">
              <h3>{statName}</h3>
              <div>{makeStats(statName, data.stats[statName])}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const TrackingChart = () => (
  <div class="experience__section__shopping-sidebar__tracking-chart">
    <Img src="trackingchart.svg" />
    <div class="row">
      <div>Order Received</div>
      <div>Order Shipped</div>
      <div>Arriving July 24</div>
    </div>
  </div>
)
