import { FunctionalComponent, h } from 'preact';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { SectionContext, SectionName } from "../section-context"
import { Section } from './section';
import { Img } from "./img"
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import useSound from '../hooks/useSound';
import {config} from '../config';
import {ITestComponent} from '../hooks/useTestComponents';

enum Animations {
  LOAD= 0,
  TEST_FAIL= 1,
  TEST_STATIC= 2,
  TEST_HARMONIC= 3,
  TEST_SHOCK= 4
}

const grades = {
  "UPGRADED SATELLITE BUS": {
    title: "GRADE: C",
    text: `A bit of a bumpy ride, but your satellite will probably function fine in orbit. <br />
<em>Probably...</em>`
  },
  "MOOG SOFTRIDE SYSTEM": {
    title: "GRADE: B",
    text: `Testing shows that you made a smart choice to give your spacecraft a smooth ride.<br/>Nice work!`
  },
  "SPECIALIZED ROCKET CAPSULE": {
    title: "GRADE: A",
    text: `You spent the big bucks, and it shows!<br />
Your satellite will be riding in first-class comfort all the way
into orbit.`
  },
}

const animations = [
  {
    table: "LoadSatelliteintoDeployer"
  },{
    table: "level3test-table",
    screen: "level3test-screen"
  },{
    table: "staticloadtest-table",
    screen: "staticloadtest-screen"
  },{
    table: "harmonicloadtest-table",
    screen: "harmonicloadtest-screen"
  },{
    table: "shockloadtest-table",
    screen: "shockloadtest-screen"
  }
]
const screens = {
  vibration: {
    title: "VIBRATION TABLE TESTING",
    content: `<p>
          The vibration table simulates the forces experienced by a
            satellite during launch.
        </p>
        <p>
            During this process the satellite components will be analyzed
            for their overall durability as well as for any negative reaction
            to the acoustic frequencies produced by vibrations.
        </p>
        <p>
            Good vibes only!
        </p>`

  },
  stage1: {
    title: "STAGE 1- STATIC LOAD",
    content: `<p>This stage simulates the vertical pressure sustained by a
    satellite as it accelerates upward on launch.</p>`
  },
  stage2: {
    title: "STAGE 2- HARMONIC LOAD",
    content: `<p>This stage simulates the vibrational acoustic frequencies
experienced throughout the launch, flight and deployment
processes.</p>`
  },
  stage3: {
    title: "STAGE 3- SHOCK LOAD",
    content: `<p>This stage simulates the omnidirectional shocks and
vibrations experienced during liftoff, motor activity,
separation activity, staging and more.</p>`
  }
}
const steps = {
  'STEP 1': {
    screen: screens.vibration,
    content: `Click or tap the satellite to load it into the deployer
casing. This casing is used to insulate the satellite during
launch and subsequently to eject the satellite into orbit.`,
    next: 'STEP 2',
  },
  'STEP 2': {
    screen: screens.vibration,
    content: `Choose your vibration table setting to correspond
      with your launch vehicle choice`,
    animation: Animations.TEST_STATIC,
    options: [{
      name: 'test1',
      nextAnimationIdx: Animations.TEST_STATIC,
      next: 'RUN VIBRATION TEST'
    }, {
      name: 'test2',
      nextAnimationIdx: Animations.TEST_STATIC,
      next: 'RUN VIBRATION TEST'
    }, {
      name: 'test3',
      nextAnimationIdx: Animations.TEST_FAIL,
      next: 'RUN VIBRATION TEST'
    }]
  },
  'RUN VIBRATION TEST': {
    screen: screens.stage1,
  },
  'WHOA!': {
    screen: screens.vibration,
    content: 'Try setting the table to Level 1 or 2.',
    animation: Animations.TEST_FAIL,
    options: [{
      name: 'test1',
      next: 'RUN VIBRATION TEST',
      nextAnimationIdx: Animations.TEST_STATIC
    },{
      name: 'test2',
      next: 'RUN VIBRATION TEST',
      nextAnimationIdx: Animations.TEST_STATIC
    }]
  },
  'STAGE 1 - STATIC LOAD': {
    screen: screens.stage1,
    content: `This stage simulates the vertical pressure sustained by a
    satellite as it accelerates upward on launch.`,
    animation: Animations.TEST_STATIC,
    next: 'STAGE 2 - HARMONIC LOAD'
  },
  'STAGE 2 - HARMONIC LOAD': {
    screen: screens.stage2,
    content: `This stage simulates the vibrational acoustic frequencies
experienced throughout the launch, flight and deployment
processes.`,
    animation: Animations.TEST_HARMONIC,
    next: 'STAGE 3 - SHOCK LOAD'
  },
  'STAGE 3 - SHOCK LOAD': {
    screen: screens.stage3,
    content: `This stage simulates the omnidirectional shocks and
vibrations experienced during liftoff, motor activity,
separation activity, staging and more.`,
    animation: Animations.TEST_SHOCK,
    next: 'TESTING COMPLETE'
  },
  'TESTING COMPLETE': {
  }
}

interface IProps {
  testingComponents: ITestComponent[],
  close: (...params: any) => void,
  finish: (...params: any) => void,
  review: (...params: any) => void,
}

export const StationTwo: FunctionalComponent<IProps> = ({ close, testingComponents, review, finish }) => {
  const [ isPlayingAnimation, setIsPlayingAnimation ] = useState(false)
  const tableLottieRefs = useRef([])
  const screenLottieRef = useRef(null)
  const TPlayer = (Player as any);
  const [ currentAnimation, setCurrentAnimation ] = useState(0)
  const [ currentStep, setCurrentStep ] = useState('STEP 1')
  const [ testingCompleteSound ] = useSound(config.m4SoundFolder + 'station-2/testing complete.ogg', { volume: 0.5 })
  const [ goSound ] = useSound(config.m4SoundFolder + 'station-2/go.ogg', { volume: 0.5 })
  const [ continueSound ] = useSound(config.m4SoundFolder + 'station-2/continue.ogg', { volume: 0.5 })
  const [ reviseSound ] = useSound(config.m4SoundFolder + 'station-2/revise.ogg', { volume: 0.5 })
  const { activeSection } = useContext(SectionContext)

  const goBack = useCallback(() => {
    close()
  }, [])

  const playAnimation = useCallback((override = currentAnimation) => {
    const a = new Audio(config.m4SoundFolder + "station-2/" + animations[currentAnimation].table.replace("-table", "") + ".ogg")
    setIsPlayingAnimation(true)
    setTimeout(() => {
      a.play();
      tableLottieRefs.current[override].play() 
      screenLottieRef.current?.play()
      setIsPlayingAnimation(true)
    }, 10)
  }, [currentAnimation])

  const onCompleteAnimation = useCallback((event: PlayerEvent) => { 
    if (event === "complete") {
      let step: string;
      switch (currentAnimation){
        case  Animations.LOAD:
          step = "STEP 2"
          break;
        case  Animations.TEST_FAIL:
          step = "WHOA!"
          break;
        case  Animations.TEST_STATIC:
          step = "STAGE 2 - HARMONIC LOAD"
          break;
        case  Animations.TEST_HARMONIC:
          step = "STAGE 3 - SHOCK LOAD"
          break;
        default:
          testingCompleteSound()
          step = "TESTING COMPLETE"
          break;
      }
      setCurrentStep(step)
      if (steps[step].animation) {
        setCurrentAnimation(steps[step].animation)
      }
      if (currentAnimation === Animations.TEST_STATIC || currentAnimation === Animations.TEST_HARMONIC) {
        playAnimation(steps[step].animation)
      }
      setIsPlayingAnimation(false)
    }
  }, [currentStep, currentAnimation, playAnimation])

  const chooseOption = useCallback(((opt) => {
    setCurrentStep(opt.next);
    setCurrentAnimation(opt.nextAnimationIdx)
  }), [])

  const getGrade = useCallback(() => {
    // TODO: this should not have a null state
    return testingComponents[0] ? grades[testingComponents[0].name] : { title: "", text: ""}
  }, [testingComponents])

  useEffect(() => {
    setCurrentAnimation(0)
    setCurrentStep('STEP 1')
  }, [activeSection])

  return (
    <Section name={SectionName.station2} transition="fade-in">
      <Img src="station-2/cranebase.png" style={{ width: "100%", top: 0, maxWidth: "100%" }} />
      <div class="station-two__infobox">
        { currentStep === 'TESTING COMPLETE' ? (
          <>
            <h1>{getGrade().title}</h1>
            <p dangerouslySetInnerHTML={{ __html: getGrade().text }}></p>
          </>
        ) : (
          <>
            <h1>{steps[currentStep].screen.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: steps[currentStep].screen.content }}></div>
          </>
        )}
      </div>
      <div class="station-two__animations">
        <Img src="station-2/table_base.png" />
        { animations.map((anim, idx) => (
          <TPlayer key={anim.table} src={`/assets/m4/obj/station-2/${anim.table}.json`}
            ref={(ref) => tableLottieRefs.current[idx] = ref}
            loop={false}
            autoplay={false}
            keepLastFrame={idx === Animations.TEST_FAIL}
            style={{ height: "90%", visibility: idx === currentAnimation ? "" : "hidden" }}
            onEvent={onCompleteAnimation}/>
        ))}
        { currentAnimation === Animations.LOAD && (
          <div onClick={() => playAnimation()} style={{ width: "80%", position: "absolute", left: "10%", height: "80%", top: "20%", zIndex: 2 }} />
        )}
      </div>
      <div class="station-two__console">
        <div class={`station-two__console__text ${isPlayingAnimation && animations[currentAnimation].screen ? '' : 'hide'}`}>
          <TPlayer 
            src={`/assets/m4/obj/station-2/${animations[currentAnimation].screen}.json`}
            loop={false}
            autoplay={true}
            keepLastFrame
            ref={screenLottieRef}
            style={{ height: "90%" }}>
          </TPlayer>
        </div>
        { !currentStep.includes("STAGE ") && (
          <div class={`station-two__console__text ${isPlayingAnimation && animations[currentAnimation].screen ? 'hide' : ''}`}>
            <h1>{currentStep}</h1>
            { currentStep === 'RUN VIBRATION TEST' && (
              <button onClick={() => { goSound(); playAnimation()}}><Img src="station-2/runtest-btn.svg" /></button>
            )}
            { currentStep === 'TESTING COMPLETE' && (
              <>
                <button onClick={() => {reviseSound(); review()}}><Img src="station-2/revise-btn.svg "/></button>
                <button onClick={() => {continueSound(); finish()}}><Img src="station-2/continue-btn.svg "/></button>
              </>
            )}
            { currentStep !== 'TESTING COMPLETE' && currentStep !== 'RUN VIBRATION TEST' && (
              <>
                <p>
                  {steps[currentStep].content}
                </p>
                { steps[currentStep].options &&  steps[currentStep].options.map((opt) => (
                  <button class="option-btn" key={opt.text} onClick={() => chooseOption(opt)}>
                    <Img src={`station-2/${opt.name}-btn.svg`} />
                  </button>
                ))}
              </>
            )}
          </div>
        )}
      </div>
      <Img src="backbutton.svg" onClick={goBack} class="mission-4__btn-back" />
    </Section>
  )
}


