import { h, JSX } from 'preact';
import { config } from "../config"
import { forwardRef, useContext } from "preact/compat";
import { Close } from "./close"
import {SectionContext} from '../section-context';

interface IProps {
  src: string,
  download?: string,
  mission?: 0 | 1 | 2 | 3 | 4,
  onLoad?: (...params: any) => void,
  class?: string,
  draggable?: boolean,
  style?: any,
  close?: (...args: any) => void,
  onClick?: JSX.MouseEventHandler<HTMLImageElement>
}

export const Img = forwardRef<HTMLImageElement, IProps>(({ src, close, onLoad = () => {}, mission = null, download, ...attrs }, ref) => {
  const { mission: overrideMission, contentWidth } = useContext(SectionContext)
  const finalSrc = contentWidth > 1200 ? src : src.replace(/\.(png|jpg)/, "_sm.$1");
  const activeMission = mission !== null ? mission : overrideMission;
  const mfolder = config.getMissionImgFolder(activeMission)
  const href = `${mfolder}${finalSrc}`
  const img = <img src={href} {...attrs} ref={ref} onLoad={onLoad} />
  return !close ? img : (
    <div class="img__linkable" href={href}>
      {img}
      <div class="img__icons">
        { mission === 1 && (
          <a class="img__linkable___clue" href="https://hackasat.com/white-rabbit-notes/" target="_blank">
            <img src={`${mfolder}clue-icon.svg`} />
          </a>
        )}
        { mission === 2 && (
          <div class="img__linkable___cart" onClick={close}>
            <img src={`${mfolder}cart-icon.svg`} />
          </div>
        )}
        <a class="img__linkable__save" href={download || href} onClick={e => e.stopPropagation()} download>
          <img src={`${mfolder}save-icon.svg`} />
        </a>
      </div>
      <Close close={close}/>
    </div>
  );
})
