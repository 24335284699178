export type ComponentCategory = "Structure" | "Command" | "PowerMgmt" | "EarthObserv" | "NavAttitude" | "RadioTelemetry"

export type ITestComponentCategory = "component" | "test"

export interface ITestComponent {
  name: string
  description: string
  line1: string
  line2?: string
  cost: number
  hours: number
  category: ITestComponentCategory
  thumb: string
  image: string
}

const testComponents: ITestComponent[] = [
  {
    name: "UPGRADED SATELLITE BUS",
    description: `Simple and effective. A stronger, more vibration-resistant body for your
cubesat might mean more work up front but it will pay off on launch day.
Your launch vehicle is the SpaceX Dragon II, a standard for cubesats and
many private and government projects.`,
    line1: "SATELLITE BODY",
    line2: "SPACEX DRAGON II CAPSULE",
    cost: 5,
    hours: 20,
    category: "component",
    thumb: "upgradedbus",
    image: "station1-sys1diagram"
  },
  {
    name: "MOOG SOFTRIDE SYSTEM",
    description: `Off-the-shelf and quick to install, these high-end vibration-dampening
components are a great choice for a variety of launch situations. Your launch
vehicle is the SpaceX Dragon II, a standard for cubesats and many private
and government projects.`,
    line1: "DEPLOYER CASING",
    line2: "SPACEX DRAGON II CAPSULE",
    cost: 11,
    hours: 25,
    category: "component",
    thumb: "moogsoftride",
    image: "station1-sys2diagram"
  },
  {
    name: "SPECIALIZED ROCKET CAPSULE",
    description: `The Ariane S rocket is a highly-specialized, single-use solution used for
major deployments such as the James Webb Telescope. A spendy choice,
but with its custom-engineered vibration reduction systems you can be sure
your spacecraft will have a cushy ride.`,
    line1: "NONE",
    line2: "ARIANE 5 ROCKET",
    cost: 14,
    hours: 0,
    category: "component",
    thumb: "specalizedcapsule",
    image: "station1-sys3diagram"
  },
  {
    name: "CORE SYSTEM CHECK",
    description: `This verification level confirms that your core system components and your
imager will function in a vacuum, under cold and hot temperatures, and
without deteriorating too quickly. Just the basics, but it’s cheap and best of
all, you can do it yourself.`,
    line1: "CAMERA, POWER AND COMPUTER COMPONENTS",
    cost: 1,
    hours: 45,
    category: "test",
    thumb: "",
    image: "station1-verify1-satdiagram"
  },
  {
    name: "FULL SYSTEM CHECK",
    description: `This verification level confirms that all of your computer and electrical
system components as well as your imager will function in a vacuum, under
cold and hot temperatures, and without deteriorating too quickly. This will
also confirm that your battery and solar components are built correctly.
Covers all the necessities, though surprises can still pop up down the road.`,
    line1: "CAMERA, POWER, COMPUTER, BATTERY AND SOLAR COMPONENTS",
    cost: 4,
    hours: 25,
    category: "test",
    thumb: "",
    image: "station1-verify2-satdiagram"
  },
  {
    name: "EXHAUSTIVE SYSTEM CHECK",
    description: `This verification level confirms that every nut, bolt, wire and loose end in
your satellite has been carefully checked and verified to perform well in
space. It might be more than you need, but at least you’ll have peace of
mind come launch day.`,
    line1: "CAMERA, POWER, COMPUTER, BATTERY AND SOLAR COMPONENTS, BUS, SEALANTS",
    cost: 10,
    hours: 30,
    category: "test",
    thumb: "",
    image: "station1-verify3-satdiagram"
  },
]

export const useTestComponents = () => {
  return {
    testComponents
  }
}

