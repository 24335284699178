import { FunctionalComponent, h } from 'preact';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { config } from "../config"
import useSound from '../hooks/useSound';
import {SectionContext} from '../section-context';
import { Close } from "./close"

interface IProps {
  paused: boolean
}

const trackTotal = 3;

const tracks = [{
    title: "Chill Space",
    url: `${config.m1SoundFolder}track-1.ogg`
  },
  {
    title: "Intense",
    url: `${config.m1SoundFolder}track-2.ogg`
  },
  {
    title: "Synthwave",
    url: `${config.m1SoundFolder}track-3.ogg`
  }
];

export const RadioSection: FunctionalComponent<IProps> = ({ paused }) => {
  const { contentHeight } = useContext(SectionContext);
  const trackAudioRef = useRef(typeof window !== "undefined" ? new Audio(tracks[0].url) : null);
  const bgAudioRef  = useRef(typeof window !== "undefined" ? new Audio(`${config.m1SoundFolder}ambient-music.ogg`) : null);
  const [ volume, setVolume ] = useState<number>(50);
  const [ playButtonSound ] = useSound(`${config.m1SoundFolder}click-enter.ogg`, { volume: 0.5 })

  if (trackAudioRef) {
    trackAudioRef.current.autoplay = true
    bgAudioRef.current.autoplay = true
  }

  useEffect(() => {
    return () => {
      trackAudioRef.current.pause();
      bgAudioRef.current.pause();
    }
  }, [])


  useEffect(() => {
    bgAudioRef.current.volume = volume / 100 / 2;
    trackAudioRef.current.volume = volume / 100;
  }, [volume]) 


  const [ trackIdx, setTrackIdx ] = useState<number>(0)
  const playNextTrack = useCallback(() => {
    playButtonSound();
    setTrackIdx((trackIdx + 1) % trackTotal);
  }, [trackIdx, playButtonSound]);

  const playPrevTrack = useCallback(() => {
    playButtonSound();
    setTrackIdx((trackIdx || trackTotal) - 1);
  }, [trackIdx, playButtonSound]);

  useEffect(() => {
    trackAudioRef.current.src = tracks[trackIdx].url
    if (!paused) {
      try {
        trackAudioRef.current.play()
      } catch (e) {
        console.log(e)
      }
    }
  }, [trackIdx, paused])

  useEffect(() => {
    try {
      if (!paused) {
        trackAudioRef.current.play();
        bgAudioRef.current.play();
      } else {
        trackAudioRef.current.pause();
        bgAudioRef.current.pause();
      }
    } catch (e) {
      console.log(e)
    }
  }, [paused])

  const getPos = useCallback((num: number | "auto") => {
    if (num !== "auto") {
      return `${num / 100 * contentHeight}px`;
    }

    return num
  }, [contentHeight])

  /*
  const radioWidth = useMemo(() => {
    if (contentWidth/contentHeight < 16/9) {
      return contentWidth/contentHeight * 1000 / (16 / 9)
    } else {
      return 1000;
    }
  }, [contentHeight, contentWidth])
   */

  return (
    <div style={`background-image: url('${config.m1ImgFolder}radio-background.jpg');`} class="experience__section--back-background">
      <div style={`position:relative; height: 60vh; width: 60vh; margin: auto;`}>
      <div class="radio__sound" style={{ top: getPos(57), left: getPos(18)}}>
        <input class="radio__volume" type="range" min="0" max="100" value={volume} onChange={(e: any) => setVolume(e.target.value)}/>
      </div>
      <div class="radio__text" style={{left: getPos(7.2), top: getPos(50.25)}}>
        <p>Track {trackIdx + 1} of {trackTotal}: <strong>{tracks[trackIdx].title}</strong></p>
        <p>Playlist: W. Rabbit's Chill Mix</p>
      </div>
      <button class="radio__prev" style={{left: getPos(8), top: getPos(55.3)}} onClick={playPrevTrack}/>
      <button class="radio__next" style={{left: getPos(18.5), top: getPos(55.2)}} onClick={playNextTrack}/>
      <button class="radio__prev__hover" style={{left: getPos(7.9), top: getPos(55.6)}} onClick={playPrevTrack}/>
      <button class="radio__next__hover" style={{left: getPos(18.4), top: getPos(55.6)}} onClick={playNextTrack}/>
    </div>
      <Close close={close} />
    </div>
  )
}
