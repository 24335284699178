import { FunctionalComponent, h } from 'preact';
import {useEffect, useRef} from 'preact/hooks';
import { IChosenComponentsByCategory, useSatComponents } from "../hooks/useSatComponents"

interface IProps {
  chosenComponents: IChosenComponentsByCategory[],
}
export const SatModel: FunctionalComponent<IProps> =({
  chosenComponents,
}) => {
  const imgRef = useRef<any>(null);
  const { components } = useSatComponents()

  useEffect(() => {
    components.map((c) => {
      const ids = c[11].split(",")
      ids.forEach(id => {
        imgRef.current.querySelector(`#${id.trim()}`).style.visibility = "hidden"
      })
    })
  }, [components])

  useEffect(() => {
    chosenComponents.map(({ key, picks }) => {
      picks.filter(p => p).forEach((c) => {
        const ids = c.svg.split(",")
        ids.forEach(id => {
          imgRef.current.querySelector(`#${id.trim()}`).style.visibility = "visible"
          const wire = imgRef.current.querySelector(`#${id.trim()}-Wire`)
          if (wire) {
            wire.style.visibility = "hidden"
          }
        })
      })
    })
    return () => {
      chosenComponents.map(({ key, picks }) => {
        picks.filter(p => p).forEach((c) => {
          const ids = c.svg.split(",")
          ids.forEach(id => {
            imgRef.current.querySelector(`#${id.trim()}`).style.visibility = "hidden"
            const wire = imgRef.current.querySelector(`#${id.trim()}-Wire`)
            if (wire) {
              wire.style.visibility = "visible"
            }
          })
        })
      })
    }
  }, [chosenComponents])

  return (
    <svg version="1.1" id="Sat_Mockup-_All_Assets_are_in_Sublayers"
      xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1171.5 727.2"
      ref={imgRef}
      style="enable-background:new 0 0 1171.5 727.2;">
      <g id="Solar-Back-Wire_00000065071655831438179620000009953810111577845889_">
        <g id="Solar-Back-Wire">
          <g>
            <polyline class="st0" points="144.4,470.2 144.4,477 222.1,509.1 			"/>
            <polyline class="st0" points="241.7,395 144.4,459.2 144.4,470.2 222.5,502.5 			"/>
            <polyline class="st0" points="241.4,431.5 216.8,420.6 165.3,454.6 223.2,479.4 			"/>
            <polyline class="st0" points="241.7,404.5 224.7,415.7 242.7,423.4 			"/>
            <path class="st0" d="M241.4,432.5"/>
          </g>
          <g>
            <polyline class="st0" points="0.6,404.4 0.6,413.3 131.6,467.4 242.3,394.4 			"/>
            <polyline class="st0" points="543.9,128.2 459.9,92.3 0.6,395.5 0.6,406.5 131.6,460.6 241.4,388.2 			"/>
            <polygon class="st0" points="180.9,403 73,356.9 21.5,390.9 136.3,440 180.9,410.6 			"/>
            <polygon class="st0" points="240.3,364.1 132.4,318 80.9,351.9 195.7,401.1 240.3,371.7 			"/>
            <polyline class="st0" points="264.9,309.3 191.8,279 140.3,313 241.7,356.4 			"/>
            <polyline class="st0" points="326.8,271.4 251.2,240.1 199.7,274.1 271.7,304.7 			"/>
            <polyline class="st0" points="410.6,215.8 335,184.5 283.5,218.5 355.4,249.1 			"/>
            <polyline class="st0" points="470,176.8 394.4,145.6 342.9,179.6 414.9,210.1 			"/>
            <polyline class="st0" points="529.4,137.9 453.8,106.7 402.3,140.7 474.3,171.2 			"/>
          </g>
        </g>
      </g>
      <g id="SolarArray-Back-Alt">
        <g>
          <g>
            <g>
              <polygon class="st1" points="275.5,531.2 734.7,228.1 734.7,218.9 603.7,162.9 144.4,466 144.4,477 				"/>
              <polygon class="st2" points="275.5,524.4 734.7,221.3 734.7,212.1 603.7,156.1 144.4,459.2 144.4,470.2 				"/>
            </g>
            <polygon class="st3" points="324.7,466.8 216.8,420.6 165.3,454.6 280.1,503.7 324.7,474.3 			"/>
            <polygon class="st3" points="384.1,427.9 276.2,381.7 224.7,415.7 339.6,464.8 384.1,435.4 			"/>
            <polygon class="st3" points="443.5,389 335.6,342.8 284.1,376.8 399,425.9 443.5,396.5 			"/>
            <polygon class="st3" points="502.9,350 395,303.9 343.5,337.9 458.4,387 502.9,357.6 			"/>
            <polygon class="st3" points="586.7,294.4 478.8,248.3 427.3,282.3 542.2,331.4 586.7,302 			"/>
            <polygon class="st3" points="646.1,255.5 538.2,209.4 486.7,243.3 601.6,292.5 646.1,263.1 			"/>
            <polygon class="st3" points="705.5,216.6 597.6,170.4 546.1,204.4 661,253.5 705.5,224.1 			"/>
            <g>
              <line class="st4" x1="263.1" y1="503.7" x2="277.6" y2="494.3"/>
              <line class="st4" x1="167.1" y1="462.2" x2="181.6" y2="452.7"/>
            </g>
            <g>
              <line class="st4" x1="324.9" y1="463.2" x2="339.4" y2="453.7"/>
              <line class="st4" x1="228.9" y1="421.6" x2="243.4" y2="412.2"/>
            </g>
            <g>
              <line class="st4" x1="385.9" y1="423.3" x2="400.4" y2="413.8"/>
              <line class="st4" x1="289.9" y1="381.7" x2="304.4" y2="372.3"/>
            </g>
            <g>
              <line class="st4" x1="442.8" y1="384.4" x2="457.3" y2="374.9"/>
              <line class="st4" x1="346.8" y1="342.8" x2="361.3" y2="333.3"/>
            </g>
            <g>
              <line class="st4" x1="527.5" y1="329.4" x2="542" y2="319.9"/>
              <line class="st4" x1="431.5" y1="287.8" x2="446" y2="278.4"/>
            </g>
            <g>
              <line class="st4" x1="586.9" y1="289.9" x2="601.4" y2="280.5"/>
              <line class="st4" x1="490.9" y1="248.4" x2="505.4" y2="238.9"/>
            </g>
            <g>
              <line class="st4" x1="648.4" y1="251.1" x2="662.9" y2="241.7"/>
              <line class="st4" x1="552.4" y1="209.6" x2="566.9" y2="200.1"/>
            </g>
          </g>
        </g>
      </g>
      <g id="SolarArray-Back">
        <g>
          <g>
            <g>
              <polygon class="st1" points="132.8,466.9 592.1,163.8 592.1,154.7 461.1,98.6 1.8,401.8 1.8,412.8 				"/>
              <polygon class="st2" points="132.8,460.2 592.1,157 592.1,147.9 461.1,91.8 1.8,395 1.8,406 				"/>
            </g>
            <polygon class="st3" points="182,402.5 74.1,356.4 22.6,390.4 137.5,439.5 182,410.1 			"/>
            <polygon class="st3" points="241.4,363.6 133.5,317.5 82,351.5 196.9,400.6 241.4,371.2 			"/>
            <polygon class="st3" points="300.8,324.7 192.9,278.6 141.4,312.5 256.3,361.6 300.8,332.2 			"/>
            <polygon class="st3" points="360.2,285.8 252.3,239.6 200.8,273.6 315.7,322.7 360.2,293.3 			"/>
            <polygon class="st3" points="444,230.2 336.1,184 284.6,218 399.5,267.1 444,237.7 			"/>
            <polygon class="st3" points="503.5,191.3 395.5,145.1 344.1,179.1 458.9,228.2 503.5,198.8 			"/>
            <polygon class="st3" points="562.9,152.3 454.9,106.2 403.5,140.2 518.3,189.3 562.9,159.9 			"/>
            <g>
              <line class="st4" x1="120.4" y1="439.5" x2="134.9" y2="430"/>
              <line class="st4" x1="24.4" y1="397.9" x2="38.9" y2="388.5"/>
            </g>
            <g>
              <line class="st4" x1="182.2" y1="398.9" x2="196.7" y2="389.5"/>
              <line class="st4" x1="86.2" y1="357.4" x2="100.7" y2="347.9"/>
            </g>
            <g>
              <line class="st4" x1="243.3" y1="359" x2="257.8" y2="349.6"/>
              <line class="st4" x1="147.3" y1="317.5" x2="161.8" y2="308"/>
            </g>
            <g>
              <line class="st4" x1="300.1" y1="320.1" x2="314.6" y2="310.6"/>
              <line class="st4" x1="204.1" y1="278.6" x2="218.6" y2="269.1"/>
            </g>
            <g>
              <line class="st4" x1="384.8" y1="265.1" x2="399.3" y2="255.7"/>
              <line class="st4" x1="288.8" y1="223.6" x2="303.3" y2="214.1"/>
            </g>
            <g>
              <line class="st4" x1="444.2" y1="225.7" x2="458.7" y2="216.2"/>
              <line class="st4" x1="348.2" y1="184.1" x2="362.7" y2="174.6"/>
            </g>
            <g>
              <line class="st4" x1="505.7" y1="186.9" x2="520.2" y2="177.4"/>
              <line class="st4" x1="409.7" y1="145.3" x2="424.2" y2="135.8"/>
            </g>
          </g>
          <g>
            <g>
              <polygon class="st1" points="275.5,531.2 734.7,228.1 734.7,218.9 603.7,162.9 144.4,466 144.4,477 				"/>
              <polygon class="st2" points="275.5,524.4 734.7,221.3 734.7,212.1 603.7,156.1 144.4,459.2 144.4,470.2 				"/>
            </g>
            <polygon class="st3" points="324.7,466.8 216.8,420.6 165.3,454.6 280.1,503.7 324.7,474.3 			"/>
            <polygon class="st3" points="384.1,427.9 276.2,381.7 224.7,415.7 339.6,464.8 384.1,435.4 			"/>
            <polygon class="st3" points="443.5,389 335.6,342.8 284.1,376.8 399,425.9 443.5,396.5 			"/>
            <polygon class="st3" points="502.9,350 395,303.9 343.5,337.9 458.4,387 502.9,357.6 			"/>
            <polygon class="st3" points="586.7,294.4 478.8,248.3 427.3,282.3 542.2,331.4 586.7,302 			"/>
            <polygon class="st3" points="646.1,255.5 538.2,209.4 486.7,243.3 601.6,292.5 646.1,263.1 			"/>
            <polygon class="st3" points="705.5,216.6 597.6,170.4 546.1,204.4 661,253.5 705.5,224.1 			"/>
            <g>
              <line class="st4" x1="263.1" y1="503.7" x2="277.6" y2="494.3"/>
              <line class="st4" x1="167.1" y1="462.2" x2="181.6" y2="452.7"/>
            </g>
            <g>
              <line class="st4" x1="324.9" y1="463.2" x2="339.4" y2="453.7"/>
              <line class="st4" x1="228.9" y1="421.6" x2="243.4" y2="412.2"/>
            </g>
            <g>
              <line class="st4" x1="385.9" y1="423.3" x2="400.4" y2="413.8"/>
              <line class="st4" x1="289.9" y1="381.7" x2="304.4" y2="372.3"/>
            </g>
            <g>
              <line class="st4" x1="442.8" y1="384.4" x2="457.3" y2="374.9"/>
              <line class="st4" x1="346.8" y1="342.8" x2="361.3" y2="333.3"/>
            </g>
            <g>
              <line class="st4" x1="527.5" y1="329.4" x2="542" y2="319.9"/>
              <line class="st4" x1="431.5" y1="287.8" x2="446" y2="278.4"/>
            </g>
            <g>
              <line class="st4" x1="586.9" y1="289.9" x2="601.4" y2="280.5"/>
              <line class="st4" x1="490.9" y1="248.4" x2="505.4" y2="238.9"/>
            </g>
            <g>
              <line class="st4" x1="648.4" y1="251.1" x2="662.9" y2="241.7"/>
              <line class="st4" x1="552.4" y1="209.6" x2="566.9" y2="200.1"/>
            </g>
          </g>
        </g>
      </g>
      <g id="Structure-Back-Wire">
        <g id="Structure-BackWire">
          <g>
            <g>
              <polyline class="st5" points="746.7,144.6 746.7,125.6 772.7,108.5 771,50.3 295.6,363.5 296.3,521.6 733.1,237.7 				"/>
            </g>
            <polygon class="st5" points="569.2,304 543.4,321.2 543.4,215.5 569.2,198.3 			"/>
            <polygon class="st5" points="378.4,491.3 362.5,502 362.5,363.7 378.4,353.1 			"/>
            <g>
              <polygon class="st5" points="362.5,410.4 310.7,445.2 310.7,464.2 362.5,429.3 				"/>
              <polygon class="st5" points="378.4,399.7 378.4,418.6 412.6,395.6 412.6,376.8 				"/>
            </g>
            <polygon class="st5" points="354.8,482.8 338.8,493.5 362.5,502 362.5,485.6 			"/>
          </g>
          <polyline class="st5" points="282.8,526.7 272.2,519.6 272.2,511.8 277.5,508.5 277.5,501 282.8,497 		"/>
          <polyline class="st5" points="431.5,588.6 282.8,521 412.6,430.9 		"/>
          <polyline class="st5" points="385.7,481.3 397.6,472.4 398.1,456.1 367.6,475.4 494.1,528.3 		"/>
          <polyline class="st5" points="431.5,602.1 282.8,534.6 282.8,492.4 412.6,406.4 		"/>
          <g>
            <polygon class="st5" points="758.1,8.8 758.1,34.7 257.5,359.2 257.4,333.3 			"/>
          </g>
          <g>
            <polygon class="st5" points="257.5,359.2 242.8,351.3 242.7,325.4 257.4,333.3 			"/>
          </g>
          <polygon class="st5" points="742.2,1.6 242.7,325.4 257.4,333.3 758.1,8.8 		"/>
        </g>
      </g>
      <g id="Structure-Back_00000005952099543626007870000007409959196443577220_">
        <g id="Structure-Back">
          <polygon class="st6" points="767.1,213.6 748.3,225.9 890.1,286.6 755.8,376 747.8,372.6 612.9,315.1 598.4,324.6 740.5,385.4 
            606.2,474.9 598.2,471.5 462.9,413.8 402.5,453.6 448,424.1 590.3,485 455.9,574.5 448,571.1 312,513.1 281.8,533 432.6,601.6 
            917.5,273.9 		"/>
          <polygon class="st7" points="767.1,200.4 748.3,212.7 890.1,273.4 755.8,362.9 747.8,359.5 612.9,301.9 598.4,311.5 740.5,372.3 
            606.2,461.7 598.2,458.3 462.9,400.6 402.5,440.4 448,411 590.3,471.9 455.9,561.3 448,557.9 312,500 281.8,519.9 432.6,588.4 
            917.5,260.7 		"/>
          <g>
            <g>
              <polygon class="st8" points="783.9,178.8 783.9,204.7 284.7,528.6 284.6,502.7 				"/>
            </g>
            <polygon class="st7" points="768,171.7 269.8,494.9 284.6,502.7 783.9,178.8 			"/>
            <g>
              <polygon class="st6" points="284.7,528.6 269.9,520.7 269.8,494.9 284.6,502.7 				"/>
            </g>
          </g>
          <g>
            <path class="st6" d="M286.2,477.5l451.1-293.7v-21.2l-20,17.7l-6.4-3.6l-1.3-105.8c0,0,27.2-23.1,27.8-19.5
              C738,55,740.6,63,740.6,63l-19.1,14.5l2.7,85.1l27.6-5.3l-7.6,33.4l-458,304.4V477.5z"/>
            <path class="st8" d="M755.1,58.7V36.9L278.6,350.1v156.4L755,191v-48.8l-26,17.2V75.8L755.1,58.7z M744.2,163.7v23.5L291,484.9
              V352.4L733.5,63v4.3l-16.3,10.3v102.6L744.2,163.7z"/>
            <polyline class="st6" points="278.6,350.1 755.1,36.9 775.1,47.5 295.4,363.1 			"/>
          </g>
          <g>
            <g>
              <polygon class="st8" points="756.4,7.8 756.5,33.6 257.2,357.6 257.2,331.7 				"/>
            </g>
            <polygon class="st7" points="740.6,0.6 242.4,323.8 257.2,331.7 756.4,7.8 			"/>
            <g>
              <polygon class="st6" points="257.2,357.6 242.5,349.7 242.4,323.8 257.2,331.7 				"/>
            </g>
          </g>
        </g>
      </g>
      <g id="SBandPatch-Wire_00000080167025685331107580000007552803666994643078_">
        <g id="SBandPatch-Wire">
          <path class="st9" d="M752.2,170.6V85.7c0-5.3,5.5-8.9,10.4-6.7l79.5,35.6c2.6,1.2,4.3,3.8,4.3,6.7v84.9c0,5.3-5.5,8.9-10.4,6.7
            l-79.5-35.6C753.9,176.1,752.2,173.5,752.2,170.6z"/>
        </g>
      </g>
      <g id="SBandPatchAlt2">
        <g>
          <polygon class="st10" points="752,175.4 752,74.5 846,116.6 846,217.5 		"/>
          <path class="st11" d="M781,99.8c11.1,4.9,22.2,9.9,33.3,14.8c6.2,9.4,10.9,16.6,17,26c0.1,13.2,0.2,26.3,0.3,39.5
            c-8.1,5.3-10.6,6.9-18.8,12.2c-11.5-5.2-23.1-10.4-34.6-15.6c-6.1-9.5-10.8-16.7-16.9-26.2l0-38C769.8,107.1,772.5,105.3,781,99.8
            z"/>
          <circle class="st6" cx="762" cy="90.5" r="3.7"/>
          <circle class="st6" cx="835.5" cy="123.4" r="3.7"/>
          <circle class="st6" cx="837" cy="202.8" r="3.7"/>
          <circle class="st6" cx="762.5" cy="170.8" r="3.7"/>
          <path class="st12" d="M782.6,152.8v-14.5c0-5.1,5.2-8.5,9.9-6.4l13.8,6.2v14.5c0,5.1-5.2,8.5-9.9,6.4L782.6,152.8z"/>
        </g>
      </g>
      <g id="SBandPatchAlt1">
        <g>
          <polygon class="st10" points="752,175.4 752,74.5 846,116.6 846,217.5 		"/>
          <path class="st1" d="M772,96.3c16.9,7.5,33.9,15.1,50.8,22.6c0,5.6,3.3,10.6,8.4,12.8c0.2,19.3,0.3,38.5,0.5,57.8
            c-4.3-1.9-9.2,1.2-9.1,6c-17.5-7.9-34.9-15.7-52.4-23.6c0-4.9-2.9-9.3-7.3-11.3v-58.3C767.1,104.1,772,101,772,96.3z"/>
          <circle class="st6" cx="834.5" cy="121.8" r="3.7"/>
          <circle class="st6" cx="834.7" cy="201.8" r="3.7"/>
          <circle class="st6" cx="763.2" cy="90.9" r="3.7"/>
          <circle class="st6" cx="761.4" cy="170.2" r="3.7"/>
          <g>
            <path class="st13" d="M790.7,152.8c-0.1-5.2-0.1-10.4-0.2-15.5c3.9,1.9,7.8,3.9,11.7,5.8c-0.1,4.9-0.2,9.8-0.3,14.8
              C798.2,156.2,794.4,154.5,790.7,152.8z"/>
          </g>
          <g>
            <path class="st13" d="M802.1,174.2c-0.1-5.2-0.1-10.4-0.2-15.5c3.9,1.9,7.8,3.9,11.7,5.8c-0.1,4.9-0.2,9.8-0.3,14.8
              C809.5,177.6,805.8,175.9,802.1,174.2z"/>
          </g>
          <g>
            <path class="st13" d="M778.5,162.9c-0.1-5.2-0.1-10.4-0.2-15.5c3.9,1.9,7.8,3.9,11.7,5.8c-0.1,4.9-0.2,9.8-0.3,14.8
              C785.9,166.3,782.2,164.6,778.5,162.9z"/>
          </g>
          <g>
            <path class="st13" d="M802.9,143.5c-0.1-5.2-0.1-10.4-0.2-15.5c3.9,1.9,7.8,3.9,11.7,5.8c-0.1,4.9-0.2,9.8-0.3,14.8
              C810.4,146.9,806.6,145.2,802.9,143.5z"/>
          </g>
          <g>
            <path class="st13" d="M778.9,131.7c-0.1-5.2-0.1-10.4-0.2-15.5c3.9,1.9,7.8,3.9,11.7,5.8c-0.1,4.9-0.2,9.8-0.3,14.8
              C786.4,135.1,782.7,133.4,778.9,131.7z"/>
          </g>
        </g>
      </g>
      <g id="SBandPatch_00000034082228080251010670000008684430684585974668_">
        <g id="SBandPatch">
          <polygon class="st10" points="752.2,175.3 752.2,74.3 846.4,116.5 846.4,217.5 		"/>
          <path class="st1" d="M769,153.2v-25.6c0-13,13.4-21.7,25.3-16.4l24.6,11c6.5,2.9,10.6,9.3,10.6,16.4v25.6
            c0,13-13.4,21.7-25.3,16.4l-24.6-11C773.2,166.7,769,160.3,769,153.2z"/>
          <path class="st12" d="M786.6,153.9v-15.7c0-5.5,5.7-9.2,10.7-6.9l14.9,6.7v15.7c0,5.5-5.7,9.2-10.7,6.9L786.6,153.9z"/>
        </g>
      </g>
      <g id="GPSpatch-Wire_00000008830437722454269480000003001064636605150362_">
        <g id="GPSpatch-Wire">
          <path class="st9" d="M765.2,164.5v-57.1c0-5.3,5.5-8.9,10.4-6.7l53.5,24c2.6,1.2,4.3,3.8,4.3,6.7v57.1c0,5.3-5.5,8.9-10.4,6.7
            l-53.5-24C766.9,170.1,765.2,167.4,765.2,164.5z"/>
        </g>
      </g>
      <g id="GPSPatchAlt2">
        <g>
          <polygon class="st1" points="773.2,171.8 773.2,99 824,123.7 824,196.5 		"/>
          <polygon class="st14" points="802.1,146.6 802.1,127.9 819.6,135.7 819.6,154.5 		"/>
          <polygon class="st14" points="777.3,161.1 777.3,142.3 794.8,150.1 794.8,168.9 		"/>
          <path class="st15" d="M792.3,168c0.1-15.1,0.2-30.3,0.3-45.4c3.3,1.7,6.5,3.5,9.8,5.2c-0.1,15-0.2,30.1-0.3,45.1
            C798.8,171.3,795.5,169.7,792.3,168z"/>
        </g>
      </g>
      <g id="GPSPatchAlt1">
        <g>
          <polygon class="st1" points="764.7,169 764.7,96.2 832.5,126.5 832.5,199.3 		"/>
          <polygon class="st14" points="807.9,148.6 807.9,129.8 825.4,137.6 825.4,156.4 		"/>
          <polygon class="st14" points="806.9,176.6 806.9,157.9 824.4,165.7 824.4,184.5 		"/>
          <polygon class="st14" points="787.1,166.2 787.1,147.4 804.6,155.2 804.6,174 		"/>
          <polygon class="st14" points="788,138.9 788,120.1 805.5,127.9 805.5,146.7 		"/>
          <path class="st15" d="M774.3,159.3c0.1-15.1,0.2-30.3,0.3-45.4c3.3,1.7,6.5,3.5,9.8,5.2c-0.1,15-0.2,30.1-0.3,45.1
            C780.8,162.5,777.6,160.9,774.3,159.3z"/>
        </g>
      </g>
      <g id="GPSpatch_00000127013706263663052390000014911711640439383743_">
        <g id="GPSpatch">
          <polygon class="st1" points="765.2,169.3 765.2,96.1 833.4,126.6 833.4,199.9 		"/>
          <polygon class="st14" points="779.2,135.8 779.2,116.9 796.8,124.8 796.8,143.6 		"/>
          <polygon class="st14" points="801.9,146.7 801.9,127.8 819.5,135.7 819.5,154.5 		"/>
          <polygon class="st14" points="779.2,162.5 779.2,143.6 796.8,151.5 796.8,170.4 		"/>
          <polygon class="st14" points="801.9,173.4 801.9,154.5 819.5,162.4 819.5,181.3 		"/>
        </g>
      </g>
      <g id="MotorController-Wire_00000126313923129180107620000012256321049001632699_">
        <g id="MotorController-Wire">
          <g>
            <path class="st16" d="M760.6,342.2l1,0.5c2.8,1.3,6,1,8.5-0.8l80-57.3c2-1.5,3.3-3.8,3.3-6.3v-38"/>
          </g>
          <g>
            <path class="st16" d="M727.7,282.4l33.1,15.4c2.7,1.3,5.9,1,8.3-0.7l84.3-56.8L757,191.5c-2.2-1.1-4.8-1-6.9,0.3l-73,46.1"/>
          </g>
        </g>
      </g>
      <g id="MotorController_00000182517486261066746220000004363969407478887073_">
        <g id="MotorController">
          <g>
            <g>
              <path class="st1" d="M854.1,236.3l-185.4,14v36c0,5.9,3.3,11.3,8.5,14l79,40.9c5.3,2.7,11.6,2.3,16.5-1.2l74.8-53.5
                c4.2-3,6.6-7.8,6.6-12.9L854.1,236.3L854.1,236.3z"/>
            </g>
            <g>
              <path class="st17" d="M756.2,187.4l-83.3,54c-6,3.9-5.4,12.8,1.1,15.8l86.4,40.7c2.8,1.3,6.2,1.1,8.8-0.6l80.7-52.5
                c5.9-3.8,5.4-12.5-0.9-15.7l-83.8-42.3C762.3,185.5,758.9,185.7,756.2,187.4z"/>
            </g>
          </g>
          <ellipse class="st1" cx="762.7" cy="281.4" rx="14.2" ry="6.7"/>
          <ellipse class="st1" cx="761.3" cy="204.3" rx="14.2" ry="6.7"/>
        </g>
      </g>
      <g id="ReactionSphere">
        <g>
          <g>
            <g>
              <g>
                <path class="st1" d="M854,235.6l-185.3,14v36c0,5.9,3.3,11.3,8.5,14l79,40.9c5.3,2.7,11.6,2.3,16.5-1.2l74.8-53.4
                  c4.2-3,6.6-7.8,6.6-12.9V235.6z"/>
              </g>
              <g>
                <path class="st15" d="M756.1,186.8l-83.3,54c-6,3.9-5.4,12.8,1.1,15.8l86.3,40.7c2.8,1.3,6.2,1.1,8.8-0.6l80.7-52.4
                  c5.9-3.8,5.4-12.5-0.9-15.7l-83.7-42.2C762.2,184.8,758.8,185,756.1,186.8z"/>
              </g>
            </g>
            <ellipse class="st1" cx="762.6" cy="280.7" rx="14.2" ry="6.7"/>
            <ellipse class="st1" cx="695.6" cy="249.6" rx="14.2" ry="6.7"/>
            <ellipse class="st1" cx="828" cy="235.6" rx="14.2" ry="6.7"/>
            <ellipse class="st1" cx="761.3" cy="203.6" rx="14.2" ry="6.7"/>
          </g>
          <path class="st18" d="M762.6,260.8c17.9,0,36.5-5.9,47.2-19.2c-1.5-24.8-22-44.4-47.2-44.4c-25.1,0-45.7,19.6-47.2,44.4
            C723.4,254.8,744.7,260.8,762.6,260.8z"/>
        </g>
      </g>
      <g id="StarSensor-Sm-Wire_00000161617245131710290720000001376583864956053152_">
        <g id="StarSensor-Sm-Wire">
          <polyline class="st16" points="866.1,213.4 866.1,174.3 900.8,189.6 900.8,260.9 		"/>
          <polyline class="st16" points="859.3,267.8 859.3,210.4 894,225.8 894,265.3 		"/>
        </g>
      </g>
      <g id="StarSensor-Sm">
        <g id="StarSensor-sm">
          <path class="st19" d="M866.3,214.2V175l34.7,15.4V269l-6.8,17.1c0,0-25-41-25-41.8S866.3,214.2,866.3,214.2"/>
          <polygon class="st17" points="859.5,267.8 859.5,211.2 894.2,226.5 894.2,286.1 		"/>
          <polygon class="st17" points="863,262.4 863,255.7 890.7,267.9 890.7,276.9 		"/>
          <polygon class="st1" points="866.3,175 859.5,211.2 894.2,226.5 901,190.4 		"/>
          
            <ellipse transform="matrix(0.8827 -0.4699 0.4699 0.8827 -10.1668 440.2615)" class="st1" cx="876.9" cy="240.5" rx="11.7" ry="16.9"/>
        </g>
      </g>
      <g id="ReactionWheels-Wire_00000158029359741771056210000008444012603837203108_">
        <g id="ReactionWheels-Wire">
          <g>
            <polyline class="st16" points="797.5,112.5 784.7,120.1 784.6,89.7 828.4,63.8 828.5,92.4 			"/>
          </g>
          <g>
            <polygon class="st16" points="784.7,120.1 769.9,112.2 769.8,81.9 784.6,89.7 			"/>
          </g>
          <polygon class="st16" points="809.7,58.4 769.8,81.9 784.6,89.7 828.4,63.8 		"/>
          <path class="st16" d="M838.1,97.3c-1.3-1.3-2.9-2.4-4.6-3.1c0.1,0,0.2,0.1,0.3,0.1l-12.7-5.5c-0.2-0.1-0.4-0.2-0.5-0.2
            c-0.5-0.2-1-0.3-1.5-0.5c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6-0.1-0.8-0.1c-0.1,0-0.2,0-0.3,0
            c-0.7-0.1-1.3-0.1-2,0c-0.1,0-0.3,0-0.4,0c-0.7,0.1-1.4,0.1-2.1,0.3c-0.7,0.2-1.5,0.4-2.2,0.7c-0.2,0.1-0.4,0.1-0.5,0.2
            c-0.6,0.3-1.3,0.6-1.9,0.9c0,0-0.1,0-0.1,0.1l0,0c-0.8,0.5-1.6,1-2.3,1.6c-0.1,0.1-0.1,0.1-0.2,0.1l-0.1,0.1
            c-0.6,0.5-1.1,1-1.7,1.5l-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.4,0.4-0.7,0.8-1.1,1.3c-0.1,0.1-0.2,0.2-0.2,0.3
            c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0.5-0.7,1-1,1.6c0,0.1-0.1,0.1-0.1,0.2l0,0c-0.4,0.7-0.7,1.3-1,2c0,0.1-0.1,0.1-0.1,0.2
            s-0.1,0.2-0.1,0.3c-0.4,0.8-0.7,1.7-0.9,2.6l0,0c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.4-0.1,0.7-0.2,1.1
            c0,0.2-0.1,0.5-0.1,0.7c0,0.4-0.1,0.8-0.1,1.2c0,0.2,0,0.4,0,0.6c0,0.6,0,1.2,0.1,1.8c0.4,4.6,2.2,8.4,5.1,11l4.4,2.7"/>
          <path class="st16" d="M809.8,115.6c0-0.2,0-0.4,0-0.5c-0.8-9.9,6-19.6,15.1-21.6c5.2-1.1,10,0.4,13.3,3.8"/>
          <path class="st16" d="M796.3,65.6c0.2,0.1-23.9-10.7-24.2-10.8c-0.7-0.3-1.5-0.5-2.3-0.7c-0.1,0-0.3-0.1-0.5-0.1
            c-0.5-0.1-1-0.2-1.5-0.3c-0.4-0.1-0.9-0.1-1.3-0.2c-0.2,0-0.4,0-0.5-0.1c-1-0.1-2.1-0.1-3.1,0c-0.2,0-0.5,0-0.7,0.1
            c-1.1,0.1-2.2,0.2-3.3,0.5c-1.2,0.3-2.3,0.6-3.4,1c-0.3,0.1-0.6,0.2-0.9,0.3c-1,0.4-2,0.9-2.9,1.4c-0.1,0-0.2,0.1-0.2,0.1l0,0
            c-1.3,0.7-2.5,1.6-3.6,2.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.9,0.7-1.8,1.5-2.6,2.4c0,0.1-0.1,0.1-0.2,0.1
            c-0.1,0.1-0.2,0.3-0.4,0.4c-0.6,0.6-1.2,1.3-1.7,2c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.6,0.8-1.1,1.6-1.6,2.4
            c-0.1,0.1-0.1,0.2-0.2,0.3v0.1c-0.6,1-1.1,2.1-1.6,3.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.3-1.1,2.7-1.4,4
            c0,0,0,0,0,0.1c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.6-0.2,1.1-0.2,1.7c-0.1,0.4-0.1,0.7-0.2,1.1
            c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.3-0.1,0.6-0.1,1c0,1,0,1.9,0.1,2.9c0.6,7.5,3.8,13.6,8.5,17.7c1.6,1.3,45.4,23.8,45.3,23.7"/>
          <g>
            <path class="st16" d="M784.7,80.9c-1-0.6-2.5-0.6-3.5,0s-1,1.5,0,2c1,0.6,2.5,0.6,3.5,0S785.7,81.4,784.7,80.9"/>
          </g>
          <g>
            <path class="st16" d="M815.3,62.8c-1-0.6-2.5-0.6-3.5,0s-1,1.5,0,2c1,0.6,2.5,0.6,3.5,0C816.3,64.3,816.3,63.4,815.3,62.8"/>
          </g>
          <g>
            <path class="st16" d="M758.5,78.7c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.6-0.3-1.2-0.4-1.8c-0.1-0.4-0.2-0.7-0.4-1.1
              c-0.2-0.5-0.3-1.1-0.5-1.6c-0.1-0.4-0.3-0.7-0.5-1.1c-0.2-0.5-0.4-0.9-0.6-1.4c0,0,0,0,0-0.1c-0.6-1.3-1.4-2.5-2.1-3.7
              c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.7-1-1.4-1.9-2.2-2.8c0,0,0,0-0.1-0.1s-0.2-0.2-0.3-0.3
              c-0.6-0.7-1.3-1.4-2-2.1c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.2-0.4-0.4c-0.7-0.6-1.3-1.1-2-1.7c-0.2-0.1-0.3-0.3-0.5-0.4
              c-0.1,0-0.1-0.1-0.2-0.1c-1-0.7-1.9-1.3-3-1.9c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-1.3-0.7-2.6-1.3-4-1.8
              c0,0,0,0-0.1,0s-0.2,0-0.2-0.1c-1-0.4-2.1-0.6-3.1-0.9c-0.3-0.1-0.6-0.1-0.9-0.2c-1.2-0.2-2.3-0.4-3.5-0.4s-2.3,0-3.4,0.1
              c-0.2,0-0.5,0-0.7,0.1c-1.1,0.1-2.1,0.3-3.1,0.6c-0.2,0-0.3,0.1-0.5,0.2c-0.4,0.1-0.8,0.2-1.2,0.4c-0.5,0.2-0.9,0.4-1.4,0.6
              c-0.1,0.1-0.3,0.1-0.4,0.2c-0.7,0.3-1.4,0.7-2.1,1.1c-0.2,0.1-11.2,7.6-17.5,12"/>
            <g>
              <polygon class="st16" points="716.4,123.3 716.3,92.9 731.9,83.3 731.9,113.1 				"/>
            </g>
            <g>
              <polygon class="st16" points="716.4,123.3 666.8,98.3 666.7,67.9 716.3,92.9 				"/>
            </g>
            <polygon class="st16" points="680.7,58.8 666.7,67.9 716.3,92.9 731.9,83.3 			"/>
            <path class="st16" d="M706,118.2c0.5-1.9,0.7-4,0.6-6.2c0-0.6-0.1-1.2-0.2-1.8c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.2-0.8-0.3-1.2
              c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.2-0.7-0.3-1c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.3-0.2-0.6-0.4-0.9l0,0
              c-0.4-0.8-0.8-1.6-1.3-2.4c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.6-0.9-1.3-1.3-1.9l0,0
              c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4-0.5-0.8-0.9-1.3-1.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.2
              c-0.4-0.4-0.8-0.8-1.3-1.1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1-0.1c-0.6-0.5-1.2-0.9-1.9-1.3c-0.1,0-0.1-0.1-0.1-0.1
              c-0.1,0-0.2-0.1-0.2-0.1c-0.8-0.5-1.7-0.9-2.6-1.2l0,0c-0.1,0-0.1,0-0.2,0c-0.7-0.2-1.3-0.4-2-0.6c-0.2,0-0.4-0.1-0.6-0.1
              c-0.7-0.2-1.5-0.3-2.3-0.3c-0.7,0-1.5,0-2.2,0c-0.1,0-0.3,0-0.4,0c-0.7,0.1-1.3,0.2-2,0.3c-0.1,0-0.2,0.1-0.3,0.1
              c-0.3,0.1-0.5,0.1-0.8,0.2s-0.6,0.2-0.9,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-0.9,0.4-1.4,0.7c-0.2,0.1-0.3,0.2-0.5,0.3l-8.5,5.3
              "/>
            <g>
              <path class="st16" d="M719.4,83.9c-1-0.6-2.5-0.6-3.5,0s-1,1.5,0,2c1,0.6,2.5,0.6,3.5,0C720.4,85.4,720.4,84.4,719.4,83.9"/>
            </g>
            <g>
              <path class="st16" d="M682.5,65.4c-1-0.6-2.5-0.6-3.5,0s-1,1.5,0,2c1,0.6,2.5,0.6,3.5,0C683.5,66.9,683.5,66,682.5,65.4"/>
            </g>
            <line class="st16" x1="723.4" y1="127" x2="737.4" y2="117.8"/>
          </g>
          <path class="st16" d="M797.6,204.2v40.7c0,8.9,14.7,16.2,32.7,16.2s32.7-7.2,32.7-16.2v-35.3"/>
          <path class="st16" d="M848.2,184.2c0,5.6-9.2,10.2-20.7,10.2c-11.4,0-20.7-4.6-20.7-10.2"/>
          <path class="st16" d="M806.9,158.5v25.7c0,5.6,9.2,10.2,20.7,10.2c11.4,0,20.7-4.6,20.7-10.2v-25.7"/>
          <polygon class="st16" points="875.6,201.5 833.8,228.5 833.8,207.3 875.6,180.3 		"/>
          <polyline class="st16" points="806.9,193.8 848.7,166.8 875.6,180.3 		"/>
          <polygon class="st16" points="806.9,215 833.8,228.5 833.8,207.3 806.9,193.8 		"/>
          <path class="st16" d="M806.9,214.8c-5.7-2.7-9.3-6.5-9.3-10.6c0-4,3.4-7.7,9-10.5"/>
          <ellipse class="st16" cx="827.6" cy="158.5" rx="20.6" ry="9.7"/>
          <path class="st16" d="M848.7,181.3c0,5.6-9.2,10.2-20.7,10.2c-11.4,0-20.7-4.6-20.7-10.2"/>
        </g>
      </g>
      <g id="ReactionWheelsAlt2">
        <g id="ReactionWheels_00000035527795757850688310000003430771015303898009_">
          <g>
            <path class="st20" d="M688.5,86.7l35.1,48.5l34.7-20c3.8-4.6,5.9-11.3,5.4-19.2c-0.1-1-0.2-2-0.3-3c-0.1-0.3-0.1-0.7-0.2-1
              c-0.1-0.7-0.2-1.3-0.4-2c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-0.6-0.3-1.2-0.5-1.8c-0.1-0.4-0.3-0.8-0.4-1.2c-0.2-0.5-0.3-1-0.5-1.5
              c0,0,0,0,0-0.1c-0.6-1.4-1.2-2.8-1.9-4.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.6-1.1-1.3-2.2-2-3.3v-0.1
              c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6-0.9-1.2-1.7-1.9-2.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.3-0.4-0.4c-0.6-0.7-1.2-1.4-1.9-2
              c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.1c-0.9-0.8-1.8-1.6-2.7-2.4c-0.1-0.1-0.1-0.1-0.2-0.2
              c-0.1-0.1-0.2-0.1-0.3-0.2c-1.2-0.9-2.5-1.7-3.7-2.4l0,0c-0.1,0-0.2-0.1-0.2-0.1c-1-0.5-1.9-0.9-2.9-1.3
              c-0.3-0.1-0.6-0.2-0.8-0.3c-1.1-0.4-2.2-0.7-3.3-0.9s-2.1-0.3-3.2-0.3c-0.2,0-0.4,0-0.6,0c-1,0-2,0.1-2.9,0.3
              c-0.2,0-0.3,0.1-0.5,0.1c-0.4,0.1-0.8,0.1-1.2,0.3c-0.4,0.1-0.9,0.3-1.3,0.4c-0.1,0-0.3,0.1-0.4,0.1c-0.7,0.3-1.4,0.6-2,0.9
              L688.5,86.7z"/>
            
              <ellipse transform="matrix(0.8152 -0.5792 0.5792 0.8152 67.3374 430.2353)" class="st21" cx="707.8" cy="109.6" rx="21" ry="30.1"/>
            <path class="st20" d="M679.4,102.9c1-1.6,0,0,1.5-1c-0.1,0.1-0.2,0.1-0.3,0.2l11.2-7.9c0.2-0.1,0.3-0.2,0.5-0.3
              c0.4-0.3,0.9-0.5,1.3-0.7c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.5-0.2,0.8-0.2c0.1,0,0.2-0.1,0.3-0.1
              c0.6-0.2,1.3-0.3,1.9-0.3c0.1,0,0.3,0,0.4,0c0.7,0,1.4-0.1,2.1,0c0.7,0.1,1.5,0.2,2.2,0.4c0.2,0,0.4,0.1,0.6,0.2
              c0.7,0.2,1.3,0.4,1.9,0.7c0,0,0.1,0,0.1,0.1l0,0c0.9,0.4,1.7,0.9,2.5,1.4c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0.6,0.4,1.2,0.9,1.8,1.4
              l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.4,0.8,0.8,1.2,1.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.2
              c0.4,0.5,0.9,1,1.2,1.6c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0.1c0.5,0.7,0.9,1.4,1.3,2.1c0,0.1,0.1,0.1,0.1,0.2
              c0.1,0.1,0.1,0.2,0.1,0.3c0.5,0.9,0.9,1.8,1.3,2.7l0,0c0.1,0.3,0.2,0.7,0.4,1c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.4,0.2,0.8,0.3,1.2
              c0.1,0.3,0.2,0.5,0.2,0.8c0.1,0.4,0.2,0.9,0.3,1.3c0,0.2,0.1,0.4,0.1,0.7c0.1,0.7,0.2,1.3,0.2,2c0.4,5-0.8,9.5-3,12.8l-10.8,7.3
              L679.4,102.9z"/>
            
              <ellipse transform="matrix(0.8827 -0.4699 0.4699 0.8827 25.8824 339.0899)" class="st22" cx="692.2" cy="117.7" rx="14" ry="20.2"/>
          </g>
          <g id="RW2_00000013901074163962085220000017879901158487925914_">
            <path class="st20" d="M829.4,80.3L802.5,132l-7.8-3.8c0.1,0-29.4-12.1-31-13.4c-4.8-4-8-10.2-8.5-17.7c-0.1-1-0.1-1.9-0.1-2.9
              c0-0.3,0-0.6,0.1-1c0-0.6,0-1.2,0.1-1.9c0-0.4,0.1-0.7,0.2-1.1c0.1-0.6,0.1-1.1,0.3-1.7c0.1-0.4,0.2-0.8,0.3-1.2
              c0.1-0.5,0.2-1,0.3-1.5c0,0,0,0,0-0.1c0.4-1.4,0.9-2.7,1.4-4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3
              c0.5-1.1,1-2.2,1.6-3.2v-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.5-0.8,1.1-1.7,1.6-2.4c0.1-0.1,0.2-0.3,0.3-0.4s0.2-0.3,0.4-0.4
              c0.5-0.7,1.1-1.4,1.7-2c0.1-0.1,0.2-0.3,0.4-0.4c0-0.1,0.1-0.1,0.2-0.1c0.8-0.9,1.7-1.6,2.6-2.4c0.1-0.1,0.1-0.1,0.2-0.2
              c0.1-0.1,0.2-0.1,0.3-0.2c1.2-0.9,2.4-1.8,3.6-2.5l0,0c0.1,0,0.2-0.1,0.2-0.1c0.9-0.5,1.9-1,2.9-1.4c0.3-0.1,0.6-0.2,0.9-0.3
              c1.1-0.4,2.2-0.8,3.4-1c1.1-0.3,2.2-0.4,3.3-0.5c0.2,0,0.5,0,0.7-0.1c1.1-0.1,2.1,0,3.1,0c0.2,0,0.4,0,0.5,0.1
              c0.4,0,0.9,0.1,1.3,0.2c0.5,0.1,1,0.2,1.5,0.3c0.2,0,0.3,0.1,0.5,0.1c0.8,0.2,1.5,0.5,2.3,0.7L829.4,80.3z"/>
            
              <ellipse transform="matrix(0.4897 -0.8719 0.8719 0.4897 323.7563 764.6931)" class="st21" cx="815.1" cy="105.8" rx="29.2" ry="22"/>
            <path class="st20" d="M842.6,98.3c-1.3-1.3,0,0-1.7-0.7c0.1,0,0.2,0.1,0.3,0.1l-12.7-5.5c-0.2-0.1-0.4-0.2-0.5-0.2
              c-0.5-0.2-1-0.3-1.5-0.5c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6-0.1-0.8-0.1c-0.1,0-0.2,0-0.3,0
              c-0.7-0.1-1.3-0.1-2,0c-0.1,0-0.3,0-0.4,0c-0.7,0.1-1.4,0.1-2.1,0.3c-0.7,0.2-1.5,0.4-2.2,0.7c-0.2,0.1-0.4,0.1-0.5,0.2
              c-0.6,0.3-1.3,0.6-1.9,0.9c0,0-0.1,0-0.1,0.1l0,0c-0.8,0.5-1.6,1-2.3,1.6c-0.1,0.1-0.1,0.1-0.2,0.1l-0.1,0.1
              c-0.6,0.5-1.1,1-1.7,1.5l-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.4,0.4-0.7,0.8-1.1,1.3c-0.1,0.1-0.2,0.2-0.2,0.3
              c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0.5-0.7,1-1,1.6c0,0.1-0.1,0.1-0.1,0.2l0,0c-0.4,0.7-0.7,1.3-1,2c0,0.1-0.1,0.1-0.1,0.2
              s-0.1,0.2-0.1,0.3c-0.4,0.8-0.7,1.7-0.9,2.6l0,0c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.4-0.1,0.7-0.2,1.1
              c0,0.2-0.1,0.5-0.1,0.7c0,0.4-0.1,0.8-0.1,1.2c0,0.2,0,0.4,0,0.6c0,0.6,0,1.2,0.1,1.8c0.4,4.6,2.2,8.4,5.1,11l12.5,5.6
              L842.6,98.3"/>
            
              <ellipse transform="matrix(0.4897 -0.8719 0.8719 0.4897 325.7729 782.9422)" class="st21" cx="831.7" cy="113.2" rx="18.7" ry="14.1"/>
          </g>
          <g>
            <path class="st20" d="M803,192.6v40.7c0,8.9,14.7,16.2,32.7,16.2s32.7-7.2,32.7-16.2v-41.6L803,192.6z"/>
            <ellipse class="st17" cx="835.9" cy="191.8" rx="32.6" ry="15.3"/>
          </g>
          <g>
            <path class="st20" d="M815,160.9v25.7c0,5.6,9.2,10.2,20.7,10.2c11.4,0,20.7-4.6,20.7-10.2v-25.7H815z"/>
            <ellipse class="st17" cx="835.7" cy="160.9" rx="20.6" ry="9.7"/>
          </g>
        </g>
      </g>
      <g id="ReactionWheelsAlt1">
        <g>
          <path class="st23" d="M694.6,167.7c-0.1,0.1,50.4-35.6,52.1-37.7c5.1-6.2,8-15.3,7.3-25.9c-0.1-1.3-0.3-2.7-0.4-4.1
            c-0.1-0.4-0.1-0.9-0.3-1.3c-0.1-0.9-0.3-1.8-0.5-2.7c-0.1-0.5-0.3-1.1-0.4-1.6c-0.3-0.8-0.4-1.6-0.7-2.4c-0.1-0.5-0.4-1.1-0.5-1.6
            c-0.3-0.7-0.4-1.3-0.7-2c0,0,0,0,0-0.1c-0.8-1.9-1.6-3.8-2.6-5.7c-0.1-0.1-0.1-0.4-0.3-0.5c-0.1-0.1-0.1-0.3-0.3-0.4
            c-0.8-1.5-1.8-3-2.7-4.5V77c-0.1-0.1-0.3-0.3-0.3-0.4c-0.8-1.2-1.6-2.3-2.6-3.4c-0.1-0.1-0.3-0.4-0.4-0.5
            c-0.1-0.1-0.4-0.4-0.5-0.5c-0.8-0.9-1.6-1.9-2.6-2.7c-0.1-0.1-0.4-0.4-0.5-0.5c-0.1-0.1-0.1-0.1-0.3-0.1c-1.2-1.1-2.4-2.2-3.6-3.2
            c-0.1-0.1-0.1-0.1-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.3c-1.6-1.2-3.4-2.3-5-3.2l0,0c-0.1,0-0.3-0.1-0.3-0.1c-1.4-0.7-2.6-1.2-3.9-1.8
            c-0.4-0.1-0.8-0.3-1.1-0.4c-1.5-0.5-3-0.9-4.5-1.2s-2.8-0.4-4.3-0.4c-0.3,0-0.5,0-0.8,0c-1.3,0-2.7,0.1-3.9,0.4
            c-0.3,0-0.4,0.1-0.7,0.1c-0.5,0.1-1.1,0.1-1.6,0.4c-0.5,0.1-1.2,0.4-1.8,0.5c-0.1,0-0.4,0.1-0.5,0.1c-0.9,0.4-1.9,0.8-2.7,1.2
            c-0.3,0.1-28.4,17.7-28.2,17.5l-35,27.7l47.2,67.9L694.6,167.7z"/>
          
            <ellipse transform="matrix(0.8152 -0.5792 0.5792 0.8152 43.0476 407.7079)" class="st21" cx="660.4" cy="136.4" rx="28.4" ry="40.6"/>
          <g>
            <polygon class="st23" points="675.5,145.8 695,154.6 695.1,109.5 628.8,80 628.7,122.4 			"/>
          </g>
          <g>
            <polygon class="st1" points="695,154.6 717.2,139.9 717.4,94.9 695.1,109.5 			"/>
          </g>
          <polygon class="st17" points="652.8,66.2 717.4,94.9 695.1,109.5 628.8,80 		"/>
          <path class="st23" d="M622.1,127.3c1.4-2.2,0,0,2-1.3c-0.1,0.1-0.3,0.1-0.4,0.3l15.1-10.7c0.3-0.1,0.4-0.3,0.7-0.4
            c0.5-0.4,1.2-0.7,1.8-0.9c0.1,0,0.3-0.1,0.4-0.1c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.3,1.1-0.3c0.1,0,0.3-0.1,0.4-0.1
            c0.8-0.3,1.8-0.4,2.6-0.4c0.1,0,0.4,0,0.5,0c0.9,0,1.9-0.1,2.8,0c0.9,0.1,2,0.3,3,0.5c0.3,0,0.5,0.1,0.8,0.3
            c0.9,0.3,1.8,0.5,2.6,0.9c0,0,0.1,0,0.1,0.1l0,0c1.2,0.5,2.3,1.2,3.4,1.9c0.1,0,0.1,0.1,0.3,0.1l0.1,0.1c0.8,0.5,1.6,1.2,2.4,1.9
            l0.1,0.1c0.1,0.1,0.3,0.3,0.4,0.4c0.5,0.5,1.1,1.1,1.6,1.8c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.1,0.3,0.3,0.3
            c0.5,0.7,1.2,1.3,1.6,2.2c0.1,0.1,0.1,0.1,0.3,0.3c0,0,0,0,0,0.1c0.7,0.9,1.2,1.9,1.8,2.8c0,0.1,0.1,0.1,0.1,0.3
            c0.1,0.1,0.1,0.3,0.1,0.4c0.7,1.2,1.2,2.4,1.8,3.6l0,0c0.1,0.4,0.3,0.9,0.5,1.3c0.1,0.4,0.3,0.7,0.4,1.1c0.1,0.5,0.3,1.1,0.4,1.6
            c0.1,0.4,0.3,0.7,0.3,1.1c0.1,0.5,0.3,1.2,0.4,1.8c0,0.3,0.1,0.5,0.1,0.9c0.1,0.9,0.3,1.8,0.3,2.7c0.5,6.8-1.1,12.8-4.1,17.3
            L653.5,173l-35-41"/>
          <g>
            <path class="st1" d="M694.8,96.4c1.5-1.1,3.8-1.4,5.3-0.7c1.5,0.7,1.5,2,0,3c-1.5,1.1-3.8,1.3-5.3,0.7
              C693.3,98.7,693.3,97.4,694.8,96.4"/>
          </g>
          <g>
            <path class="st1" d="M648.7,75.9c1.5-1.1,3.8-1.3,5.3-0.7c1.5,0.7,1.5,2,0,3c-1.5,1.1-3.8,1.3-5.3,0.7S647.2,76.9,648.7,75.9"/>
          </g>
          
            <ellipse transform="matrix(0.8827 -0.4699 0.4699 0.8827 5.7526 317.7024)" class="st22" cx="639.3" cy="147.3" rx="18.9" ry="27.3"/>
        </g>
        <g id="RW2_00000030451696458193354680000003078742031506629006_">
          <path class="st23" d="M782.7,165c0.1,0-59-30.2-61.2-32c-6.5-5.4-10.8-13.8-11.5-23.9c-0.1-1.4-0.1-2.6-0.1-3.9
            c0-0.4,0-0.8,0.1-1.3c0-0.8,0-1.6,0.1-2.6c0-0.5,0.1-0.9,0.3-1.5c0.1-0.8,0.1-1.5,0.4-2.3c0.1-0.5,0.3-1.1,0.4-1.6
            c0.1-0.7,0.3-1.3,0.4-2c0,0,0,0,0-0.1c0.5-1.9,1.2-3.6,1.9-5.4c0.1-0.1,0.1-0.4,0.3-0.5c0.1-0.1,0.1-0.3,0.3-0.4
            c0.7-1.5,1.3-3,2.2-4.3v-0.1c0.1-0.1,0.1-0.3,0.3-0.4c0.7-1.1,1.5-2.3,2.2-3.2c0.1-0.1,0.3-0.4,0.4-0.5c0.1-0.1,0.3-0.4,0.5-0.5
            c0.7-0.9,1.5-1.9,2.3-2.7c0.1-0.1,0.3-0.4,0.5-0.5c0-0.1,0.1-0.1,0.3-0.1c1.1-1.2,2.3-2.2,3.5-3.2c0.1-0.1,0.1-0.1,0.3-0.3
            c0.1-0.1,0.3-0.1,0.4-0.3c1.6-1.2,3.2-2.4,4.9-3.4l0,0c0.1,0,0.3-0.1,0.3-0.1c1.2-0.7,2.6-1.3,3.9-1.9c0.4-0.1,0.8-0.3,1.2-0.4
            c1.5-0.5,3-1.1,4.6-1.4c1.5-0.4,3-0.5,4.5-0.7c0.3,0,0.7,0,0.9-0.1c1.5-0.1,2.8,0,4.2,0c0.3,0,0.5,0,0.7,0.1
            c0.5,0,1.2,0.1,1.8,0.3c0.7,0.1,1.3,0.3,2,0.4c0.3,0,0.4,0.1,0.7,0.1c1.1,0.3,2,0.7,3.1,0.9c0.4,0.1,32.8,14.7,32.7,14.6
            l41.4,23.8l-40.5,66.8L782.7,165z"/>
          
            <ellipse transform="matrix(0.4897 -0.8719 0.8719 0.4897 296.057 775.1941)" class="st21" cx="810.3" cy="134.7" rx="39.4" ry="29.7"/>
          <g>
            <polygon class="st23" points="793.8,142.7 776.5,153 776.4,112 835.5,77 835.6,115.6 			"/>
          </g>
          <g>
            <polygon class="st1" points="776.5,153 756.5,142.3 756.4,101.4 776.4,112 			"/>
          </g>
          <polygon class="st17" points="814.2,67.4 756.4,101.4 776.4,112 835.5,77 		"/>
          <path class="st23" d="M844.7,119c-1.8-1.8,0,0-2.3-0.9c0.1,0,0.3,0.1,0.4,0.1l-17.1-7.4c-0.3-0.1-0.5-0.3-0.7-0.3
            c-0.7-0.3-1.4-0.4-2-0.7c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.8-0.3-1.2-0.3s-0.8-0.1-1.1-0.1c-0.1,0-0.3,0-0.4,0
            c-0.9-0.1-1.8-0.1-2.7,0c-0.1,0-0.4,0-0.5,0c-0.9,0.1-1.9,0.1-2.8,0.4c-0.9,0.3-2,0.5-3,0.9c-0.3,0.1-0.5,0.1-0.7,0.3
            c-0.8,0.4-1.8,0.8-2.6,1.2c0,0-0.1,0-0.1,0.1l0,0c-1.1,0.7-2.2,1.3-3.1,2.2c-0.1,0.1-0.1,0.1-0.3,0.1l-0.1,0.1
            c-0.8,0.7-1.5,1.3-2.3,2l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4c-0.5,0.5-0.9,1.1-1.5,1.8c-0.1,0.1-0.3,0.3-0.3,0.4
            c-0.1,0.1-0.1,0.3-0.3,0.3c-0.5,0.7-0.9,1.3-1.4,2.2c0,0.1-0.1,0.1-0.1,0.3l0,0c-0.5,0.9-0.9,1.8-1.3,2.7c0,0.1-0.1,0.1-0.1,0.3
            c0,0.1-0.1,0.3-0.1,0.4c-0.5,1.1-0.9,2.3-1.2,3.5l0,0c-0.1,0.4-0.3,0.8-0.3,1.4c-0.1,0.4-0.1,0.7-0.3,1.1
            c-0.1,0.5-0.1,0.9-0.3,1.5c0,0.3-0.1,0.7-0.1,0.9c0,0.5-0.1,1.1-0.1,1.6c0,0.3,0,0.5,0,0.8c0,0.8,0,1.6,0.1,2.4
            c0.5,6.2,3,11.3,6.9,14.9l18.8,10l29.7-41.6"/>
          <g>
            <path class="st1" d="M776.5,100.1c-1.3-0.8-3.4-0.8-4.7,0c-1.4,0.8-1.4,2,0,2.7c1.3,0.8,3.4,0.8,4.7,0S777.9,100.8,776.5,100.1"
              />
          </g>
          <g>
            <path class="st1" d="M817.8,75.6c-1.3-0.8-3.4-0.8-4.7,0s-1.3,2,0,2.7c1.3,0.8,3.4,0.8,4.7,0C819.2,77.7,819.2,76.5,817.8,75.6"
              />
          </g>
          
            <ellipse transform="matrix(0.4897 -0.8719 0.8719 0.4897 302.3077 794.6876)" class="st21" cx="830" cy="139.1" rx="25.2" ry="19"/>
        </g>
        <g id="RW1_00000062915134357091231140000002003131048051164602_">
          <path class="st23" d="M774.4,209V264c0,12,19.8,21.9,44.1,21.9c24.3,0,44.1-9.7,44.1-21.9v-47.7L774.4,209z"/>
          <ellipse class="st17" cx="818.8" cy="207.8" rx="44" ry="20.7"/>
          <polygon class="st23" points="879.7,205.4 823.3,241.8 823.3,213.2 879.7,176.8 		"/>
          <polyline class="st17" points="787,195 843.4,158.6 879.7,176.8 823.3,213.2 787,195 		"/>
          <polygon class="st1" points="787,223.6 823.3,241.8 823.3,213.2 787,195 		"/>
          <path class="st23" d="M787,147.3V182c0,7.6,12.4,13.8,27.9,13.8c15.4,0,27.9-6.2,27.9-13.8v-34.7H787z"/>
          <ellipse class="st17" cx="814.9" cy="147.3" rx="27.8" ry="13.1"/>
        </g>
      </g>
      <g id="ReactionWheels_00000038375292415657683350000013424167124541120653_">
        <g id="ReactionWheels">
          <g>
            <path class="st19" d="M713.2,135.1c-0.1,0.1,37.3-26.4,38.6-27.9c3.8-4.6,5.9-11.3,5.4-19.2c-0.1-1-0.2-2-0.3-3
              c-0.1-0.3-0.1-0.7-0.2-1c-0.1-0.7-0.2-1.3-0.4-2c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-0.6-0.3-1.2-0.5-1.8c-0.1-0.4-0.3-0.8-0.4-1.2
              c-0.2-0.5-0.3-1-0.5-1.5c0,0,0,0,0-0.1c-0.6-1.4-1.2-2.8-1.9-4.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
              c-0.6-1.1-1.3-2.2-2-3.3v-0.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6-0.9-1.2-1.7-1.9-2.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.3-0.4-0.4
              c-0.6-0.7-1.2-1.4-1.9-2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.1c-0.9-0.8-1.8-1.6-2.7-2.4c-0.1-0.1-0.1-0.1-0.2-0.2
              c-0.1-0.1-0.2-0.1-0.3-0.2c-1.2-0.9-2.5-1.7-3.7-2.4l0,0c-0.1,0-0.2-0.1-0.2-0.1c-1-0.5-1.9-0.9-2.9-1.3
              c-0.3-0.1-0.6-0.2-0.8-0.3c-1.1-0.4-2.2-0.7-3.3-0.9s-2.1-0.3-3.2-0.3c-0.2,0-0.4,0-0.6,0c-1,0-2,0.1-2.9,0.3
              c-0.2,0-0.3,0.1-0.5,0.1c-0.4,0.1-0.8,0.1-1.2,0.3c-0.4,0.1-0.9,0.3-1.3,0.4c-0.1,0-0.3,0.1-0.4,0.1c-0.7,0.3-1.4,0.6-2,0.9
              c-0.2,0.1-21,13.1-20.9,13L672,89.3l35,50.3L713.2,135.1z"/>
            
              <ellipse transform="matrix(0.8152 -0.5792 0.5792 0.8152 62.3174 419.1079)" class="st21" cx="687.9" cy="111.9" rx="21" ry="30.1"/>
            <g>
              <polygon class="st19" points="699.1,118.9 713.5,125.4 713.6,92 664.5,70.1 664.4,101.5 				"/>
            </g>
            <g>
              <polygon class="st1" points="713.5,125.4 730,114.5 730.1,81.2 713.6,92 				"/>
            </g>
            <polygon class="st17" points="682.3,59.9 730.1,81.2 713.6,92 664.5,70.1 			"/>
            <path class="st19" d="M659.5,105.2c1-1.6,0,0,1.5-1c-0.1,0.1-0.2,0.1-0.3,0.2l11.2-7.9c0.2-0.1,0.3-0.2,0.5-0.3
              c0.4-0.3,0.9-0.5,1.3-0.7c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.5-0.2,0.8-0.2c0.1,0,0.2-0.1,0.3-0.1
              c0.6-0.2,1.3-0.3,1.9-0.3c0.1,0,0.3,0,0.4,0c0.7,0,1.4-0.1,2.1,0c0.7,0.1,1.5,0.2,2.2,0.4c0.2,0,0.4,0.1,0.6,0.2
              c0.7,0.2,1.3,0.4,1.9,0.7c0,0,0.1,0,0.1,0.1l0,0c0.9,0.4,1.7,0.9,2.5,1.4c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0.6,0.4,1.2,0.9,1.8,1.4
              l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.4,0.8,0.8,1.2,1.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.2
              c0.4,0.5,0.9,1,1.2,1.6c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0.1c0.5,0.7,0.9,1.4,1.3,2.1c0,0.1,0.1,0.1,0.1,0.2
              c0.1,0.1,0.1,0.2,0.1,0.3c0.5,0.9,0.9,1.8,1.3,2.7l0,0c0.1,0.3,0.2,0.7,0.4,1c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.4,0.2,0.8,0.3,1.2
              c0.1,0.3,0.2,0.5,0.2,0.8c0.1,0.4,0.2,0.9,0.3,1.3c0,0.2,0.1,0.4,0.1,0.7c0.1,0.7,0.2,1.3,0.2,2c0.4,5-0.8,9.5-3,12.8L682.8,139
              l-25.9-30.4"/>
            <g>
              <path class="st1" d="M713.4,82.3c1.1-0.8,2.8-1,3.9-0.5c1.1,0.5,1.1,1.5,0,2.2c-1.1,0.8-2.8,1-3.9,0.5S712.3,83,713.4,82.3"/>
            </g>
            <g>
              <path class="st1" d="M679.2,67.1c1.1-0.8,2.8-1,3.9-0.5c1.1,0.5,1.1,1.5,0,2.2c-1.1,0.8-2.8,1-3.9,0.5
                C678.1,68.8,678.1,67.8,679.2,67.1"/>
            </g>
            
              <ellipse transform="matrix(0.8827 -0.4699 0.4699 0.8827 22.461 329.9869)" class="st22" cx="672.3" cy="120" rx="14" ry="20.2"/>
          </g>
          <g id="RW2">
            <path class="st19" d="M788.8,129.2c0.1,0-43.7-22.4-45.3-23.7c-4.8-4-8-10.2-8.5-17.7c-0.1-1-0.1-1.9-0.1-2.9c0-0.3,0-0.6,0.1-1
              c0-0.6,0-1.2,0.1-1.9c0-0.4,0.1-0.7,0.2-1.1c0.1-0.6,0.1-1.1,0.3-1.7c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.5,0.2-1,0.3-1.5
              c0,0,0,0,0-0.1c0.4-1.4,0.9-2.7,1.4-4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.5-1.1,1-2.2,1.6-3.2v-0.1
              c0.1-0.1,0.1-0.2,0.2-0.3c0.5-0.8,1.1-1.7,1.6-2.4c0.1-0.1,0.2-0.3,0.3-0.4s0.2-0.3,0.4-0.4c0.5-0.7,1.1-1.4,1.7-2
              c0.1-0.1,0.2-0.3,0.4-0.4c0-0.1,0.1-0.1,0.2-0.1c0.8-0.9,1.7-1.6,2.6-2.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2
              c1.2-0.9,2.4-1.8,3.6-2.5l0,0c0.1,0,0.2-0.1,0.2-0.1c0.9-0.5,1.9-1,2.9-1.4c0.3-0.1,0.6-0.2,0.9-0.3c1.1-0.4,2.2-0.8,3.4-1
              c1.1-0.3,2.2-0.4,3.3-0.5c0.2,0,0.5,0,0.7-0.1c1.1-0.1,2.1,0,3.1,0c0.2,0,0.4,0,0.5,0.1c0.4,0,0.9,0.1,1.3,0.2
              c0.5,0.1,1,0.2,1.5,0.3c0.2,0,0.3,0.1,0.5,0.1c0.8,0.2,1.5,0.5,2.3,0.7C772,55.2,796,66,795.9,65.9l30.7,17.6l-30,49.5
              L788.8,129.2z"/>
            
              <ellipse transform="matrix(0.4897 -0.8719 0.8719 0.4897 319.8836 760.0056)" class="st21" cx="809.2" cy="106.7" rx="29.2" ry="22"/>
            <g>
              <polygon class="st19" points="797,112.7 784.2,120.3 784.1,89.9 827.9,64 828,92.6 				"/>
            </g>
            <g>
              <polygon class="st1" points="784.2,120.3 769.4,112.4 769.3,82.1 784.1,89.9 				"/>
            </g>
            <polygon class="st17" points="812.1,56.9 769.3,82.1 784.1,89.9 827.9,64 			"/>
            <path class="st19" d="M834.7,95.1c-1.3-1.3,0,0-1.7-0.7c0.1,0,0.2,0.1,0.3,0.1L820.6,89c-0.2-0.1-0.4-0.2-0.5-0.2
              c-0.5-0.2-1-0.3-1.5-0.5c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6-0.1-0.8-0.1c-0.1,0-0.2,0-0.3,0
              c-0.7-0.1-1.3-0.1-2,0c-0.1,0-0.3,0-0.4,0c-0.7,0.1-1.4,0.1-2.1,0.3c-0.7,0.2-1.5,0.4-2.2,0.7c-0.2,0.1-0.4,0.1-0.5,0.2
              c-0.6,0.3-1.3,0.6-1.9,0.9c0,0-0.1,0-0.1,0.1l0,0c-0.8,0.5-1.6,1-2.3,1.6c-0.1,0.1-0.1,0.1-0.2,0.1l-0.1,0.1
              c-0.6,0.5-1.1,1-1.7,1.5l-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.4,0.4-0.7,0.8-1.1,1.3c-0.1,0.1-0.2,0.2-0.2,0.3
              c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0.5-0.7,1-1,1.6c0,0.1-0.1,0.1-0.1,0.2l0,0c-0.4,0.7-0.7,1.3-1,2c0,0.1-0.1,0.1-0.1,0.2
              s-0.1,0.2-0.1,0.3c-0.4,0.8-0.7,1.7-0.9,2.6l0,0c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.4-0.1,0.7-0.2,1.1
              c0,0.2-0.1,0.5-0.1,0.7c0,0.4-0.1,0.8-0.1,1.2c0,0.2,0,0.4,0,0.6c0,0.6,0,1.2,0.1,1.8c0.4,4.6,2.2,8.4,5.1,11l13.9,7.4l22-30.8"
              />
            <g>
              <path class="st1" d="M784.2,81.1c-1-0.6-2.5-0.6-3.5,0s-1,1.5,0,2c1,0.6,2.5,0.6,3.5,0S785.2,81.6,784.2,81.1"/>
            </g>
            <g>
              <path class="st1" d="M814.8,63c-1-0.6-2.5-0.6-3.5,0s-1,1.5,0,2c1,0.6,2.5,0.6,3.5,0C815.8,64.5,815.8,63.6,814.8,63"/>
            </g>
            
              <ellipse transform="matrix(0.4897 -0.8719 0.8719 0.4897 324.5137 774.4451)" class="st21" cx="823.9" cy="110" rx="18.7" ry="14.1"/>
          </g>
          <g id="RW1">
            <path class="st19" d="M797.6,204.2v40.7c0,8.9,14.7,16.2,32.7,16.2s32.7-7.2,32.7-16.2v-35.3L797.6,204.2z"/>
            <ellipse class="st17" cx="830.5" cy="203.3" rx="32.6" ry="15.3"/>
            <polygon class="st19" points="875.6,201.5 833.8,228.5 833.8,207.3 875.6,180.3 			"/>
            <polyline class="st17" points="806.9,193.8 848.7,166.8 875.6,180.3 833.8,207.3 806.9,193.8 			"/>
            <polygon class="st1" points="806.9,215 833.8,228.5 833.8,207.3 806.9,193.8 			"/>
            <path class="st19" d="M806.9,158.5v25.7c0,5.6,9.2,10.2,20.7,10.2c11.4,0,20.7-4.6,20.7-10.2v-25.7H806.9z"/>
            <ellipse class="st17" cx="827.6" cy="158.5" rx="20.6" ry="9.7"/>
          </g>
        </g>
      </g>
      <g id="StarSensor-Lg-Wire_00000109010529371406500230000016067008991443810992_">
        <g id="StarSensor-Lg-Wire">
          <g>
            <path class="st16" d="M764.4,162.6c-19,11-34.4,37.6-34.3,59.4c0.1,21.8,15.5,30.6,34.5,19.7c19-11,34.4-37.6,34.3-59.4
              C798.8,160.4,783.4,151.6,764.4,162.6"/>
          </g>
          <path class="st16" d="M741.9,244.9L671.5,218c-6.2-3.6-10.1-11.2-10.2-22.1c-0.1-21.8,15.3-48.4,34.3-59.4c8.3-4.8,16-5.8,22-3.6
            l70,26.6"/>
          <line class="st16" x1="730.2" y1="225" x2="670.3" y2="202.1"/>
          <line class="st16" x1="730.2" y1="218.4" x2="670.3" y2="195.5"/>
          <line class="st16" x1="734.7" y1="195.5" x2="674.8" y2="172.6"/>
          <line class="st16" x1="737.3" y1="190.4" x2="677.3" y2="167.5"/>
          <line class="st16" x1="749.6" y1="174.1" x2="689.6" y2="151.2"/>
          <line class="st16" x1="754.4" y1="169" x2="694.4" y2="146.1"/>
        </g>
      </g>
      <g id="StarSensor-Lg">
        <g id="StarSensor-lg">
          <path class="st1" d="M741.9,243.9L671.4,217c-6.2-3.6-10.1-11.2-10.2-22.1c-0.1-21.8,15.3-48.4,34.3-59.4c8.3-4.8,16-5.8,22-3.6
            l70,26.6L741.9,243.9z"/>
          <g>
            <path class="st17" d="M764.4,161.6c-19,11-34.4,37.6-34.3,59.4c0.1,21.8,15.5,30.6,34.5,19.7c19-11,34.4-37.6,34.3-59.4
              C798.8,159.4,783.4,150.6,764.4,161.6"/>
          </g>
          <g>
            <path class="st24" d="M764.4,174.8c-12.7,7.3-22.9,25-22.8,39.6c0,14.5,10.3,20.4,23,13.1c12.7-7.3,22.9-25,22.8-39.6
              C787.4,173.3,777.1,167.5,764.4,174.8"/>
          </g>
          <path class="st1" d="M778.5,172.2c1.3,2.6,2.1,5.8,2.1,9.7c0,14.5-10.2,32.3-22.8,39.6c-5.3,3.1-10.2,3.8-14.1,2.6
            c3.6,7,11.6,8.8,20.9,3.4c12.7-7.3,22.9-25,22.8-39.6C787.4,179.4,783.9,173.9,778.5,172.2z"/>
          <line class="st25" x1="730.2" y1="224" x2="670.3" y2="201.1"/>
          <line class="st25" x1="728.9" y1="216.3" x2="668.9" y2="193.4"/>
          <line class="st25" x1="734.7" y1="194.5" x2="674.8" y2="171.6"/>
          <line class="st25" x1="737.3" y1="189.4" x2="677.3" y2="166.5"/>
          <line class="st25" x1="749.5" y1="173.1" x2="689.6" y2="150.2"/>
          <line class="st25" x1="754.4" y1="168" x2="694.4" y2="145.1"/>
        </g>
      </g>
      <g id="StarSensor-LgAlt">
        <g id="StarSensor-lg_00000049910825330882718020000012783408008366487944_">
          <path class="st1" d="M741.9,243.9L671.4,217c-6.2-3.6-10.1-11.2-10.2-22.1c-0.1-21.8,15.3-48.4,34.3-59.4c8.3-4.8,16-5.8,22-3.6
            l70,26.6L741.9,243.9z"/>
          <g>
            <path class="st17" d="M764.4,161.6c-19,11-34.4,37.6-34.3,59.4c0.1,21.8,15.5,30.6,34.5,19.7c19-11,34.4-37.6,34.3-59.4
              C798.8,159.4,783.4,150.6,764.4,161.6"/>
          </g>
          <g>
            <path class="st26" d="M764.4,174.8c-12.7,7.3-22.9,25-22.8,39.6c0,14.5,10.3,20.4,23,13.1c12.7-7.3,22.9-25,22.8-39.6
              C787.4,173.3,777.1,167.5,764.4,174.8"/>
          </g>
          <path class="st1" d="M778.5,172.2c1.3,2.6,2.1,5.8,2.1,9.7c0,14.5-10.2,32.3-22.8,39.6c-5.3,3.1-10.2,3.8-14.1,2.6
            c3.6,7,11.6,8.8,20.9,3.4c12.7-7.3,22.9-25,22.8-39.6C787.4,179.4,783.9,173.9,778.5,172.2z"/>
          <line class="st25" x1="730.2" y1="224" x2="670.3" y2="201.1"/>
          <line class="st25" x1="728.9" y1="216.3" x2="668.9" y2="193.4"/>
          <line class="st25" x1="734.7" y1="194.5" x2="674.8" y2="171.6"/>
          <line class="st25" x1="737.3" y1="189.4" x2="677.3" y2="166.5"/>
          <line class="st25" x1="749.5" y1="173.1" x2="689.6" y2="150.2"/>
          <line class="st25" x1="754.4" y1="168" x2="694.4" y2="145.1"/>
        </g>
      </g>
      <g id="OBCWire_00000099662882584364498040000000344065956543034783_">
        <g id="OBCWire">
          <line class="st9" x1="646" y1="238.3" x2="646" y2="247.9"/>
          <path class="st9" d="M633,301.2c3.9,2.5,7,8.5,7,13.5c0,4.9-3.2,6.9-7.1,4.4c-3.9-2.5-7-8.5-7-13.4
            C626,300.7,629.1,298.7,633,301.2z"/>
          <g>
            <polyline class="st9" points="649.3,279.3 686.5,296.8 731.7,267.4 731.7,225.8 			"/>
          </g>
          <line class="st9" x1="686.5" y1="296.8" x2="686.5" y2="256"/>
          <path class="st9" d="M656.2,282.6L628,300.7l0,0c-1.3,0.8-2.1,2.5-2.1,5c0,4.9,3.1,11,7,13.4c2,1.3,3.9,1.4,5.1,0.5l-0.1,0.1
            l41-26.4"/>
          <g>
            <polyline class="st9" points="647.8,325.3 735.6,268.1 661.7,230.7 604.2,268 			"/>
          </g>
          <g>
            <polygon class="st9" points="685.3,256 649.8,279.1 673.3,290 707.9,267.5 			"/>
          </g>
          <g>
            <polygon class="st9" points="654.6,239 619,262.1 642.6,273 677.2,250.5 			"/>
          </g>
          <path class="st9" d="M710.3,286.2v11.6c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-21.1"/>
          <g>
            <g>
              <polyline class="st9" points="659.1,388.5 776,311.3 734.6,291.4 				"/>
            </g>
            <g>
              <polyline class="st9" points="659.1,407 776,330.9 760,322.8 				"/>
            </g>
            <g>
              <polygon class="st9" points="739.8,310.8 664.9,359 675.6,364 750.1,316 				"/>
            </g>
          </g>
        </g>
      </g>
      <g id="OBCAlt2">
        <g>
          <g>
            <path class="st27" d="M625.2,271.4L775,342.3c3.3,1.6,3.6,6.2,0.5,8.2l-149,96.7c-1.4,0.9-3.1,1-4.5,0.3l-149.7-70.9
              c-3.3-1.6-3.6-6.2-0.5-8.2l149-96.7C622.1,270.8,623.8,270.7,625.2,271.4z"/>
            <path class="st11" d="M625.2,268.5L775,339.4c3.3,1.6,3.6,6.2,0.5,8.2l-149,96.7c-1.4,0.9-3.1,1-4.5,0.3l-149.7-70.9
              c-3.3-1.6-3.6-6.2-0.5-8.2l149-96.7C622.1,267.9,623.8,267.8,625.2,268.5z"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M487.2,359.5c25.5,1.8,50.9,3.7,76.4,5.5c0.2,3.3,0.5,6.6,0.7,9.9c-10.1,6.9-20.3,13.7-30.4,20.5
                  l-46.6-22.4C487.3,368.5,487.3,364,487.2,359.5z"/>
              </g>
              <g>
                <polygon class="st10" points="518.9,342.8 566.1,365.6 534.3,382.3 487.8,359.9 					"/>
              </g>
            </g>
            <path class="st28" d="M533.6,381.1c0.1,4.8,0.2,9.6,0.3,14.3"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M559.7,393.3c25.5,1.8,50.9,3.7,76.4,5.5c0.2,3.3,0.5,6.6,0.7,9.9c-10.1,6.9-20.3,13.7-30.4,20.5
                  l-46.6-22.4C559.8,402.3,559.8,397.8,559.7,393.3z"/>
              </g>
              <g>
                <polygon class="st10" points="591.4,376.5 638.6,399.3 606.8,416.1 560.3,393.7 					"/>
              </g>
            </g>
            <path class="st28" d="M606.1,414.8c0.1,4.8,0.2,9.6,0.3,14.3"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M769.9,327.9c-30.1,5-60.2,10-90.3,15l0.6,15.2l37,19.2c17.6-11.2,35.2-22.5,52.7-33.7
                  C769.9,338.3,769.9,333.1,769.9,327.9z"/>
              </g>
              <g>
                <path class="st10" d="M734.1,316.1l-54.5,26.8l37,19.2c17.1-10.8,34.3-21.5,51.4-32.3C756.7,325.2,745.4,320.6,734.1,316.1z"/>
              </g>
            </g>
            <line class="st28" x1="716.6" y1="362" x2="717.2" y2="377.2"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M691.3,378.1c-30.1,5-60.2,10-90.3,15l0.6,15.2l37,19.2c17.6-11.2,35.2-22.5,52.7-33.7
                  C691.3,388.6,691.3,383.3,691.3,378.1z"/>
              </g>
              <g>
                <path class="st10" d="M655.5,366.3L601,393.1l37,19.2c17.1-10.8,34.3-21.5,51.4-32.3C678.1,375.5,666.8,370.9,655.5,366.3z"/>
              </g>
            </g>
            <line class="st28" x1="638" y1="412.3" x2="638.5" y2="427.5"/>
          </g>
          <g>
            <path class="st27" d="M625.3,251.1l150,71c3.3,1.6,3.6,6.2,0.5,8.2l-149.3,96.9c-1.4,0.9-3.1,1-4.6,0.3l-150-71
              c-3.3-1.6-3.6-6.2-0.5-8.2l149.3-96.9C622.1,250.5,623.8,250.4,625.3,251.1z"/>
            <path class="st11" d="M625.3,248.2l150,71c3.3,1.6,3.6,6.2,0.5,8.2l-149.3,96.9c-1.4,0.9-3.1,1-4.6,0.3l-150-71
              c-3.3-1.6-3.6-6.2-0.5-8.2l149.3-96.9C622.1,247.6,623.8,247.5,625.3,248.2z"/>
          </g>
          <g>
            <path class="st29" d="M675.5,263.9v35.6c0,2.9,3.9,5.2,8.8,5.2c4.9,0,8.8-2.3,8.8-5.2v-36.7"/>
            <ellipse class="st30" cx="684.3" cy="263.9" rx="8.8" ry="4.4"/>
          </g>
          <g>
            <path class="st29" d="M561.9,335.7v35.6c0,2.9,3.9,5.2,8.8,5.2c4.9,0,8.8-2.3,8.8-5.2v-36.7"/>
            <ellipse class="st30" cx="570.7" cy="335.7" rx="8.8" ry="4.4"/>
          </g>
          <g>
            <path class="st29" d="M491.7,308.4V344c0,2.9,3.9,5.2,8.8,5.2s8.8-2.3,8.8-5.2v-36.7"/>
            <ellipse class="st30" cx="500.5" cy="308.4" rx="8.8" ry="4.4"/>
          </g>
          <g>
            <path class="st27" d="M617.1,225.1l82.1,40.5c3.1,1.5,3.4,5.8,0.5,7.7l-126.9,79.9c-1.3,0.8-2.9,0.9-4.3,0.3l-82.8-36.9
              c-3.1-1.5-3.4-5.8-0.5-7.7l127.7-83.5C614.1,224.5,615.7,224.4,617.1,225.1z"/>
            <path class="st15" d="M617.1,222.3l82.1,40.5c3.1,1.5,3.4,5.8,0.5,7.7l-126.9,79.9c-1.3,0.8-2.9,0.9-4.3,0.3l-82.8-36.9
              c-3.1-1.5-3.4-5.8-0.5-7.7l127.7-83.5C614.1,221.8,615.7,221.7,617.1,222.3z"/>
          </g>
          <g>
            <g>
              <polygon class="st11" points="615.6,232.3 643,244.1 596.6,272 569.8,260.6 				"/>
            </g>
            <g>
              <polygon class="st11" points="652.9,252.1 680.3,263.9 633.9,291.8 607.1,280.4 				"/>
            </g>
          </g>
          <g>
            <path class="st15" d="M615.5,237.4c5,2.5,10,4.9,15,7.4c-4.2,2.8-8.3,5.5-12.5,8.3c-5.1-2.4-10.2-4.8-15.2-7.3
              C607,243,611.3,240.2,615.5,237.4z"/>
          </g>
          <g>
            <path class="st15" d="M653.8,257c5,2.5,10,4.9,15,7.4c-4.2,2.8-8.3,5.5-12.5,8.3c-5.1-2.4-10.2-4.8-15.2-7.3
              C645.3,262.7,649.6,259.9,653.8,257z"/>
          </g>
          <g>
            
              <ellipse transform="matrix(0.9939 -0.1104 0.1104 0.9939 -29.1121 79.0882)" class="st31" cx="699.8" cy="302.5" rx="8.7" ry="11.5"/>
            
              <ellipse transform="matrix(0.9939 -0.1104 0.1104 0.9939 -29.3107 78.8283)" class="st17" cx="697.4" cy="304.2" rx="8.7" ry="11.5"/>
            <path class="st32" d="M586.2,382.8l114.1-70.6c2.3-3.3,2.7-6.5,2.4-9.4c-0.7-5.2-6.6-7.9-11.1-5.2l-107.5,66.5
              C584.1,364.1,589.7,371.6,586.2,382.8z"/>
            
              <ellipse transform="matrix(0.9939 -0.1104 0.1104 0.9939 -37.6352 66.5988)" class="st17" cx="582.7" cy="373.2" rx="8.7" ry="11.5"/>
            
              <ellipse transform="matrix(0.9939 -0.1104 0.1104 0.9939 -37.8508 66.2383)" class="st31" cx="579.4" cy="375" rx="8.7" ry="11.5"/>
          </g>
        </g>
      </g>
      <g id="OBCAlt1">
        <g>
          <g>
            <path class="st27" d="M619.9,249.7l156.7,74.2c3.5,1.6,3.8,6.4,0.6,8.5L621.3,433.6c-1.4,0.9-3.2,1-4.8,0.3l-156.7-74.2
              c-3.5-1.6-3.8-6.4-0.6-8.5L615.1,250C616.6,249.1,618.4,249,619.9,249.7z"/>
            <path class="st11" d="M619.9,246.7l156.7,74.2c3.5,1.6,3.8,6.4,0.6,8.5L621.3,430.6c-1.4,0.9-3.2,1-4.8,0.3l-156.7-74.2
              c-3.5-1.6-3.8-6.4-0.6-8.5L615.1,247C616.6,246.1,618.4,245.9,619.9,246.7z"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M511.8,353.7c38.4,2.8,76.7,5.5,115.1,8.3c0.4,5,0.7,9.9,1.1,14.9c-15.3,10.3-30.6,20.6-45.8,31
                  L512,374.1C511.9,367.3,511.9,360.5,511.8,353.7z"/>
              </g>
              <g>
                <polygon class="st10" points="559.6,328.4 630.7,362.8 582.8,388 512.6,354.3 					"/>
              </g>
            </g>
            <path class="st28" d="M581.7,386.2c0.1,7.2,0.3,14.4,0.4,21.6"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M727.8,334.9c-39.1,6.5-78.2,13-117.4,19.5l0.8,19.8l48.1,24.9c22.8-14.6,45.7-29.2,68.5-43.8
                  C727.8,348.5,727.8,341.7,727.8,334.9z"/>
              </g>
              <g>
                <path class="st10" d="M681.3,319.6l-70.9,34.8l48.1,24.9c22.3-14,44.5-28,66.8-41.9C710.6,331.5,695.9,325.5,681.3,319.6z"/>
              </g>
            </g>
            <line class="st28" x1="658.5" y1="379.3" x2="659.2" y2="399.1"/>
          </g>
          <g>
            <path class="st27" d="M619.8,237.6l150,71c3.3,1.6,3.6,6.2,0.5,8.2l-149.3,96.9c-1.4,0.9-3.1,1-4.6,0.3l-150-71
              c-3.3-1.6-3.6-6.2-0.5-8.2l149.3-96.9C616.6,237,618.4,236.9,619.8,237.6z"/>
            <path class="st11" d="M619.8,234.7l150,71c3.3,1.6,3.6,6.2,0.5,8.2l-149.3,96.9c-1.4,0.9-3.1,1-4.6,0.3l-150-71
              c-3.3-1.6-3.6-6.2-0.5-8.2L615.3,235C616.6,234.1,618.4,234,619.8,234.7z"/>
          </g>
          <g>
            <path class="st15" d="M518.6,257.4c44.5,3,89.1,6,133.6,9c0.1,15.2,0.2,30.4,0.2,45.6c-15.9,10.5-31.8,21.1-47.6,31.6
              c-28.1-12.7-56.3-25.4-84.4-38.1C519.8,289.5,519.2,273.4,518.6,257.4z"/>
          </g>
          <g>
            <path class="st11" d="M566.4,224.2c28.6,13.8,57.2,27.7,85.8,41.5c-15.7,10.5-31.5,20.9-47.2,31.4c-28.8-13.3-57.6-26.7-86.4-40
              C534.6,246.1,550.5,235.2,566.4,224.2z"/>
          </g>
          <g>
            <path class="st13" d="M573.2,259.4c5,2.5,10,4.9,15,7.4c-4.2,2.8-8.3,5.5-12.5,8.3c-5.1-2.4-10.2-4.8-15.2-7.3
              C564.7,265,569,262.2,573.2,259.4z"/>
          </g>
          <line class="st28" x1="604.5" y1="296.2" x2="604.8" y2="342.8"/>
          <g>
            <path class="st15" d="M552.8,240.8c6.2-3.7,16.3-3.7,22.5-0.2c6.2,3.5,6.2,9.4,0,13c-6.2,3.7-16.3,3.7-22.5,0.2
              C546.6,250.3,546.6,244.4,552.8,240.8"/>
          </g>
          <path class="st1" d="M552.8,246.3c6.2-3.7,16.3-3.7,22.5-0.2c1.9,1.1,3.2,2.3,3.9,3.7c-0.7,1.4-2,2.7-3.9,3.8
            c-6.2,3.7-16.3,3.7-22.5,0.2c-1.9-1.1-3.2-2.3-3.9-3.7C549.6,248.7,550.9,247.4,552.8,246.3z"/>
          <g>
            <path class="st15" d="M598.5,263.9c6.2-3.7,16.3-3.7,22.5-0.2c6.2,3.5,6.2,9.4,0,13c-6.2,3.7-16.3,3.7-22.5,0.2
              C592.3,273.4,592.2,267.5,598.5,263.9"/>
          </g>
          <path class="st1" d="M598.5,269.4c6.2-3.7,16.3-3.7,22.5-0.2c1.9,1.1,3.2,2.3,3.9,3.7c-0.7,1.4-2,2.7-3.9,3.8
            c-6.2,3.7-16.3,3.7-22.5,0.2c-1.9-1.1-3.2-2.3-3.9-3.7C595.3,271.8,596.6,270.5,598.5,269.4z"/>
          <path class="st1" d="M647.1,315.2c-1.3-3.6-2.5-7.2-3.8-10.9c-8.6,5.8-17.2,11.7-25.9,17.5c-0.9,4.7-1.7,9.5-2.6,14.2"/>
        </g>
      </g>
      <g id="OBC_00000007395523604489247880000016249661108788384147_">
        <g id="OBC">
          <g>
            <g>
              <g>
                <path class="st27" d="M639.4,268.5L770,330.3c2.9,1.4,3.2,5.4,0.5,7.1l-130,84.4c-1.2,0.8-2.7,0.9-4,0.3l-130.6-61.8
                  c-2.9-1.4-3.2-5.4-0.5-7.1l130-84.4C636.6,267.9,638.1,267.8,639.4,268.5z"/>
                <path class="st11" d="M639.4,265.9L770,327.7c2.9,1.4,3.2,5.4,0.5,7.1l-130,84.4c-1.2,0.8-2.7,0.9-4,0.3l-130.6-61.8
                  c-2.9-1.4-3.2-5.4-0.5-7.1l130-84.4C636.6,265.4,638.1,265.3,639.4,265.9z"/>
              </g>
              <polygon class="st17" points="741.1,329 754.3,335.8 673.9,388 660.7,381.2 				"/>
            </g>
            <g>
              <g>
                <path class="st27" d="M639.4,248.5L770,310.3c2.9,1.4,3.2,5.4,0.5,7.1l-130,84.4c-1.2,0.8-2.7,0.9-4,0.3l-130.6-61.8
                  c-2.9-1.4-3.2-5.4-0.5-7.1l130-84.4C636.6,248,638.1,247.9,639.4,248.5z"/>
                <path class="st11" d="M639.4,246L770,307.8c2.9,1.4,3.2,5.4,0.5,7.1l-130,84.4c-1.2,0.8-2.7,0.9-4,0.3l-130.6-61.8
                  c-2.9-1.4-3.2-5.4-0.5-7.1l130-84.4C636.6,245.5,638.1,245.4,639.4,246z"/>
              </g>
              <polygon class="st17" points="741.1,309 754.3,315.9 673.9,368.1 660.7,361.3 				"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st27" d="M659.5,260.2l71.4,35.3c2.7,1.3,3,5.1,0.5,6.7l-110.5,69.5c-1.1,0.7-2.5,0.8-3.8,0.3L545,339.8
                c-2.7-1.3-3-5.1-0.5-6.7l111.2-72.7C656.8,259.7,658.2,259.6,659.5,260.2z"/>
              <path class="st15" d="M659.5,257.8l71.4,35.3c2.7,1.3,3,5.1,0.5,6.7l-110.5,69.5c-1.1,0.7-2.5,0.8-3.8,0.3L545,337.4
                c-2.7-1.3-3-5.1-0.5-6.7L655.7,258C656.8,257.3,658.2,257.2,659.5,257.8z"/>
            </g>
            <g>
              <path class="st29" d="M710.3,265.5v31c0,2.5,3.4,4.5,7.6,4.5s7.6-2,7.6-4.5v-32"/>
              <ellipse class="st30" cx="717.9" cy="265.5" rx="7.6" ry="3.8"/>
            </g>
            <g>
              <path class="st29" d="M611.4,328v31c0,2.5,3.4,4.5,7.6,4.5s7.6-2,7.6-4.5v-32"/>
              <ellipse class="st30" cx="619" cy="328" rx="7.6" ry="3.8"/>
            </g>
            <g>
              <path class="st29" d="M550.2,304.2v31c0,2.5,3.4,4.5,7.6,4.5s7.6-2,7.6-4.5v-32"/>
              <ellipse class="st30" cx="557.9" cy="304.2" rx="7.6" ry="3.8"/>
            </g>
            <g>
              <path class="st27" d="M659.5,231.7l71.4,35.3c2.7,1.3,3,5.1,0.5,6.7l-110.5,69.5c-1.1,0.7-2.5,0.8-3.8,0.3L545,311.3
                c-2.7-1.3-3-5.1-0.5-6.7l111.2-72.7C656.8,231.2,658.2,231.1,659.5,231.7z"/>
              <path class="st15" d="M659.5,229.3l71.4,35.3c2.7,1.3,3,5.1,0.5,6.7l-110.5,69.5c-1.1,0.7-2.5,0.8-3.8,0.3L545,308.9
                c-2.7-1.3-3-5.1-0.5-6.7l111.2-72.7C656.8,228.8,658.2,228.7,659.5,229.3z"/>
            </g>
            <g>
              <g>
                <polygon class="st11" points="658.1,237.9 682,248.2 641.6,272.5 618.2,262.6 					"/>
              </g>
              <g>
                <polygon class="st11" points="690.6,255.2 714.5,265.5 674,289.8 650.7,279.9 					"/>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Motherboard-Wire">
        <g id="MotherboardWire">
          <g>
            <path class="st9" d="M412.2,290.1l-10.4,6.7c-3.3,2.2-3,7.2,0.6,8.8l132.9,60.7c3.2,1.4,6.8,1.2,9.8-0.7l131-85.2
              c3.3-2.1,3-7-0.5-8.7l-1.6-0.8"/>
          </g>
          <g>
            <path class="st9" d="M473.4,237.8l-66.1,42.9c-3.3,2.2-3,7.1,0.6,8.8l131.8,62.2c3.2,1.5,6.9,1.2,9.9-0.7l122.8-79.8
              c3.3-2.1,3-7-0.5-8.7l-11.3-5.7"/>
            <polyline class="st9" points="577.7,214.9 538.9,195.3 517.5,209.2 			"/>
          </g>
          <g>
            <path class="st9" d="M584.9,210.2L465,288.1c-3.3,2.1-3,7.1,0.6,8.8l75.3,35c1.6,0.7,3.4,0.6,4.9-0.3l117.1-76.2
              c3.2-2.1,3-6.9-0.5-8.7L589.9,210C588.3,209.1,586.4,209.2,584.9,210.2z"/>
          </g>
          <g>
            <polygon class="st9" points="507.7,204.2 473.8,226.1 524.5,249.7 556.5,228.9 			"/>
          </g>
          <g>
            <polyline class="st9" points="506.4,261.2 473.8,244.7 473.8,226.1 524.5,249.7 			"/>
          </g>
        </g>
      </g>
      <g id="MotherboardAlt2">
        <g>
          <g>
            <path class="st27" d="M540.5,216.9l137.7,65.2c3,1.4,3.3,5.7,0.5,7.5l-137,89c-1.3,0.8-2.8,0.9-4.2,0.3l-137.7-65.2
              c-3-1.4-3.3-5.7-0.5-7.5l137-89C537.6,216.4,539.2,216.3,540.5,216.9z"/>
            <path class="st11" d="M540.5,214.2l137.7,65.2c3,1.4,3.3,5.7,0.5,7.5l-137,89c-1.3,0.8-2.8,0.9-4.2,0.3L399.8,311
              c-3-1.4-3.3-5.7-0.5-7.5l137-89C537.6,213.7,539.2,213.6,540.5,214.2z"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M413.5,297.9c23.4,1.7,46.9,3.4,70.3,5c0.2,3,0.4,6.1,0.7,9.1c-9.3,6.3-18.7,12.6-28,18.9l-42.8-20.6
                  C413.6,306.2,413.6,302.1,413.5,297.9z"/>
              </g>
              <g>
                <polygon class="st10" points="442.7,282.5 486.1,303.5 456.9,318.9 414,298.3 					"/>
              </g>
            </g>
            <path class="st28" d="M456.2,317.8c0.1,4.4,0.2,8.8,0.2,13.2"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M480.2,329c23.4,1.7,46.9,3.4,70.3,5c0.2,3,0.4,6.1,0.7,9.1c-9.3,6.3-18.7,12.6-28,18.9l-42.8-20.6
                  C480.3,337.3,480.3,333.1,480.2,329z"/>
              </g>
              <g>
                <polygon class="st10" points="509.4,313.6 552.8,334.5 523.6,349.9 480.7,329.4 					"/>
              </g>
            </g>
            <path class="st28" d="M522.9,348.8c0.1,4.4,0.2,8.8,0.2,13.2"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M673.6,268.8c-27.7,4.6-55.4,9.2-83.1,13.8l0.5,14l34,17.6c16.2-10.3,32.3-20.7,48.5-31
                  C673.6,278.4,673.6,273.6,673.6,268.8z"/>
              </g>
              <g>
                <path class="st10" d="M640.7,258l-50.2,24.6l34,17.6c15.8-9.9,31.5-19.8,47.3-29.7C661.4,266.4,651.1,262.2,640.7,258z"/>
              </g>
            </g>
            <line class="st28" x1="624.5" y1="300.2" x2="625.1" y2="314.2"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M601.3,315c-27.7,4.6-55.4,9.2-83.1,13.8l0.5,14l34,17.6c16.2-10.3,32.3-20.7,48.5-31
                  C601.3,324.7,601.3,319.8,601.3,315z"/>
              </g>
              <g>
                <path class="st10" d="M568.3,304.2l-50.2,24.6l34,17.6c15.8-9.9,31.5-19.8,47.3-29.7C589.1,312.6,578.7,308.4,568.3,304.2z"/>
              </g>
            </g>
            <line class="st28" x1="552.2" y1="346.5" x2="552.7" y2="360.5"/>
          </g>
          <g>
            <path class="st27" d="M540.5,198.2l138,65.3c3.1,1.4,3.3,5.7,0.5,7.5l-137.3,89.2c-1.3,0.8-2.8,0.9-4.2,0.3l-138-65.3
              c-3.1-1.4-3.3-5.7-0.5-7.5l137.3-89.2C537.6,197.6,539.2,197.5,540.5,198.2z"/>
            <path class="st11" d="M540.5,195.5l138,65.3c3.1,1.4,3.3,5.7,0.5,7.5l-137.3,89.2c-1.3,0.8-2.8,0.9-4.2,0.3l-138-65.3
              c-3.1-1.4-3.3-5.7-0.5-7.5l137.3-89.2C537.6,194.9,539.2,194.8,540.5,195.5z"/>
          </g>
          <g>
            <path class="st13" d="M540.2,213.2c4.6,2.3,9.2,4.5,13.8,6.8c-3.8,2.5-7.6,5.1-11.5,7.6c-4.7-2.2-9.3-4.5-14-6.7
              C532.5,218.4,536.3,215.8,540.2,213.2z"/>
          </g>
          <g>
            <path class="st13" d="M639.3,256.9c4.6,2.3,9.2,4.5,13.8,6.8c-3.8,2.5-7.6,5.1-11.5,7.6c-4.7-2.2-9.3-4.5-14-6.7
              C631.5,262,635.4,259.4,639.3,256.9z"/>
          </g>
          <g>
            
              <ellipse transform="matrix(5.769663e-03 -1 1 5.769663e-03 95.5097 713.0919)" class="st31" cx="406.4" cy="308.5" rx="10.6" ry="8"/>
            
              <ellipse transform="matrix(5.769663e-03 -1 1 5.769663e-03 96.6068 716.7703)" class="st17" cx="408.8" cy="309.8" rx="10.6" ry="8"/>
            <path class="st32" d="M518,371l-111.2-53.6c-2.4-2.8-3.1-5.7-3.1-8.3c0.1-4.9,5.3-7.9,9.7-5.8l104.7,50.5
              C518.1,353.7,513.7,361.1,518,371z"/>
            
              <ellipse transform="matrix(5.769663e-03 -1 1 5.769663e-03 155.3183 880.1466)" class="st17" cx="520.3" cy="362" rx="10.6" ry="8"/>
            
              <ellipse transform="matrix(5.769663e-03 -1 1 5.769663e-03 157.2546 884.6789)" class="st31" cx="523.5" cy="363.3" rx="10.6" ry="8"/>
          </g>
        </g>
      </g>
      <g id="MotherboardAlt1">
        <g>
          <g>
            <path class="st27" d="M542.1,217.1l137.7,65.2c3,1.4,3.3,5.7,0.5,7.5l-137,89c-1.3,0.8-2.8,0.9-4.2,0.3l-137.7-65.2
              c-3-1.4-3.3-5.7-0.5-7.5l137-89C539.1,216.6,540.7,216.5,542.1,217.1z"/>
            <path class="st11" d="M542.1,214.5l137.7,65.2c3,1.4,3.3,5.7,0.5,7.5l-137,89c-1.3,0.8-2.8,0.9-4.2,0.3l-137.7-65.2
              c-3-1.4-3.3-5.7-0.5-7.5l137-89C539.1,213.9,540.7,213.8,542.1,214.5z"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M415.1,298.2c23.4,1.7,46.9,3.4,70.3,5c0.2,3,0.4,6.1,0.7,9.1c-9.3,6.3-18.7,12.6-28,18.9l-42.8-20.6
                  C415.2,306.5,415.1,302.3,415.1,298.2z"/>
              </g>
              <g>
                <polygon class="st10" points="444.2,282.8 487.7,303.7 458.4,319.1 415.6,298.6 					"/>
              </g>
            </g>
            <path class="st28" d="M457.8,318c0.1,4.4,0.2,8.8,0.2,13.2"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M481.8,329.2c23.4,1.7,46.9,3.4,70.3,5c0.2,3,0.4,6.1,0.7,9.1c-9.3,6.3-18.7,12.6-28,18.9l-42.8-20.6
                  C481.9,337.5,481.8,333.4,481.8,329.2z"/>
              </g>
              <g>
                <polygon class="st10" points="510.9,313.8 554.4,334.8 525.1,350.2 482.3,329.6 					"/>
              </g>
            </g>
            <path class="st28" d="M524.5,349.1c0.1,4.4,0.2,8.8,0.2,13.2"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M675.1,269.1c-27.7,4.6-55.4,9.2-83.1,13.8l0.5,14l34,17.6c16.2-10.3,32.3-20.7,48.5-31
                  C675.1,278.7,675.1,273.9,675.1,269.1z"/>
              </g>
              <g>
                <path class="st10" d="M642.2,258.2l-50.2,24.6l34,17.6c15.8-9.9,31.5-19.8,47.3-29.7C663,266.6,652.6,262.4,642.2,258.2z"/>
              </g>
            </g>
            <line class="st28" x1="626.1" y1="300.5" x2="626.6" y2="314.5"/>
          </g>
          <g>
            <g>
              <g>
                <path class="st17" d="M602.8,315.3c-27.7,4.6-55.4,9.2-83.1,13.8l0.5,14l34,17.6c16.2-10.3,32.3-20.7,48.5-31
                  C602.8,324.9,602.8,320.1,602.8,315.3z"/>
              </g>
              <g>
                <path class="st10" d="M569.9,304.4l-50.2,24.6l34,17.6c15.8-9.9,31.5-19.8,47.3-29.7C590.7,312.8,580.3,308.6,569.9,304.4z"/>
              </g>
            </g>
            <line class="st28" x1="553.8" y1="346.7" x2="554.3" y2="360.7"/>
          </g>
          <g>
            <path class="st27" d="M542.1,198.4l138,65.3c3.1,1.4,3.3,5.7,0.5,7.5l-137.3,89.2c-1.3,0.8-2.8,0.9-4.2,0.3l-138-65.3
              c-3.1-1.4-3.3-5.7-0.5-7.5l137.3-89.2C539.1,197.9,540.7,197.8,542.1,198.4z"/>
            <path class="st11" d="M542.1,195.7l138,65.3c3.1,1.4,3.3,5.7,0.5,7.5l-137.3,89.2c-1.3,0.8-2.8,0.9-4.2,0.3l-138-65.3
              c-3.1-1.4-3.3-5.7-0.5-7.5L537.9,196C539.1,195.2,540.7,195.1,542.1,195.7z"/>
          </g>
          <g>
            <path class="st27" d="M539.9,207.4l75.5,37.3c2.9,1.4,3.2,5.4,0.5,7.1l-116.7,73.5c-1.2,0.8-2.7,0.9-4,0.3L419,291.5
              c-2.9-1.4-3.2-5.4-0.5-7.1L536,207.6C537.2,206.8,538.7,206.7,539.9,207.4z"/>
            <path class="st15" d="M539.9,204.8l75.5,37.3c2.9,1.4,3.2,5.4,0.5,7.1l-116.7,73.5c-1.2,0.8-2.7,0.9-4,0.3L419,289
              c-2.9-1.4-3.2-5.4-0.5-7.1L536,205.1C537.2,204.3,538.7,204.2,539.9,204.8z"/>
          </g>
          <g>
            <path class="st13" d="M537.9,328c4.6,2.3,9.2,4.5,13.8,6.8c-3.8,2.5-7.6,5.1-11.5,7.6c-4.7-2.2-9.3-4.5-14-6.7
              C530.1,333.2,534,330.6,537.9,328z"/>
          </g>
          <g>
            <path class="st13" d="M640.8,257.1c4.6,2.3,9.2,4.5,13.8,6.8c-3.8,2.5-7.6,5.1-11.5,7.6c-4.7-2.2-9.3-4.5-14-6.7
              C633.1,262.3,637,259.7,640.8,257.1z"/>
          </g>
        </g>
      </g>
      <g id="Motherboard_00000090976875564639251620000007471935598643906212_">
        <g id="Motherboard">
          <g>
            <path class="st27" d="M541.1,209.5L677.6,274c3,1.4,3.3,5.6,0.5,7.4l-135.7,88.1c-1.2,0.8-2.8,0.9-4.1,0.3l-136.4-64.6
              c-3-1.4-3.3-5.6-0.5-7.4L537,209.7C538.2,208.9,539.8,208.8,541.1,209.5z"/>
            <path class="st11" d="M541.1,206.8l136.4,64.6c3,1.4,3.3,5.6,0.5,7.4l-135.7,88.1c-1.2,0.8-2.8,0.9-4.1,0.3l-136.4-64.6
              c-3-1.4-3.3-5.6-0.5-7.4L537,207.1C538.2,206.3,539.8,206.2,541.1,206.8z"/>
          </g>
          <g>
            <path class="st27" d="M541.1,198.9l130.6,61.8c2.9,1.4,3.2,5.4,0.5,7.1l-130,84.4c-1.2,0.8-2.7,0.9-4,0.3l-130.6-61.8
              c-2.9-1.4-3.2-5.4-0.5-7.1l130-84.4C538.3,198.4,539.8,198.3,541.1,198.9z"/>
            <path class="st11" d="M541.1,196.3l130.6,61.8c2.9,1.4,3.2,5.4,0.5,7.1l-130,84.4c-1.2,0.8-2.7,0.9-4,0.3l-130.6-61.8
              c-2.9-1.4-3.2-5.4-0.5-7.1l130-84.4C538.3,195.8,539.8,195.7,541.1,196.3z"/>
          </g>
          <g>
            <g>
              <g>
                <polygon class="st17" points="473,227.6 576,231.7 576,248.9 535,272.2 473,244.8 					"/>
              </g>
              <g>
                <polygon class="st10" points="513.2,203.8 576,231.7 535,255 473,227.6 					"/>
              </g>
            </g>
            <line class="st28" x1="535" y1="255" x2="535" y2="272.2"/>
          </g>
          <g>
            <path class="st27" d="M587,210.1l75.6,37.3c2.9,1.4,3.2,5.4,0.5,7.1l-117,73.6c-1.2,0.8-2.7,0.9-4,0.3l-76.3-34
              c-2.9-1.4-3.2-5.4-0.5-7.1l117.7-77C584.2,209.6,585.7,209.5,587,210.1z"/>
            <path class="st15" d="M587,207.5l75.6,37.3c2.9,1.4,3.2,5.4,0.5,7.1l-117,73.6c-1.2,0.8-2.7,0.9-4,0.3l-76.3-34
              c-2.9-1.4-3.2-5.4-0.5-7.1L583,207.8C584.2,207,585.7,206.9,587,207.5z"/>
          </g>
        </g>
      </g>
      <g id="Radio-Wire_00000183930325681260892490000002678646493707323826_">
        <g id="Radio-Wire">
          <g>
            <polyline class="st9" points="507.7,204.1 649.6,271 781.4,185.4 781.4,175.8 			"/>
          </g>
          <g>
            <polygon class="st9" points="643.9,106.4 507.7,194.6 649.6,261.5 781.4,175.8 			"/>
          </g>
          <g>
            <polyline class="st9" points="511.4,205.9 511.4,239.3 642.7,301.2 642.7,267.9 			"/>
          </g>
          <polyline class="st9" points="642.7,301.2 775.7,215.2 775.7,189.7 		"/>
          <polyline class="st9" points="682.6,281.5 682.6,300.3 770.9,242.5 770.9,223.4 		"/>
        </g>
      </g>
      <g id="RadioAlt3">
        <g>
          <path class="st33" d="M573.9,266.8c1.7-0.8,3.3,1.1,4,2.7c3.9,9.2,0.7,21.2,7.3,28.7c3.2,3.6,7.9,5.2,12.5,6.4
            c5.5,1.4,11.7,2.4,16.4-0.8c2.7-1.9,4.5-4.9,6-7.8c3.7-7.3,6.7-15.8,4.1-23.5"/>
          <path class="st33" d="M520.3,240.2c-2.6,1.1-3.5,4.6-2.6,7.3c0.9,2.7,3.2,4.7,5.4,6.4s4.6,3.5,6,5.9c2.5,4.4,1.3,9.9,2.1,15
            c0.7,4.6,3.2,9,6.9,11.9c2.2,1.7,5,3,7.7,2.6c3.8-0.5,6.5-4,7.6-7.6s0.7-7.5,0.7-11.3c-0.1-7.3,0.6-14.5,2.3-21.6"/>
          <g>
            <g>
              <polygon class="st17" points="770.9,242.4 682.1,300.2 586.5,255.1 586.5,207.4 770.9,194.7 				"/>
            </g>
          </g>
          <polygon class="st1" points="682.1,300.2 683.9,234.3 636.1,279.8 		"/>
          <g>
            <polygon class="st17" points="511.7,206.5 511.7,239.9 642.9,301.8 776,215.8 776,190.3 			"/>
          </g>
          <g>
            <g>
              <polyline class="st17" points="781.7,176.4 781.7,185.9 649.9,271.6 508,204.7 508,195.2 781.7,176.4 				"/>
            </g>
            <g>
              <polygon class="st10" points="644.1,107 508,195.2 649.9,262.1 781.7,176.4 				"/>
            </g>
          </g>
          <line class="st28" x1="642.9" y1="301.8" x2="642.9" y2="268.7"/>
          <path class="st14" d="M648.7,135.4c26.7,13.3,53.4,26.6,80,39.9c1.8,0.9,2,3.4,0.3,4.5l-81.6,53c-0.7,0.5-1.7,0.5-2.5,0.2
            l-82-38.8c-1.8-0.9-2-3.4-0.3-4.5c27.5-17.9,54.9-35.8,82.4-53.6c0.3-0.2,0.9-0.6,1.7-0.8C647.5,135,648.3,135.2,648.7,135.4z"/>
          <line class="st34" x1="584.5" y1="188.3" x2="645.4" y2="148.9"/>
          <line class="st34" x1="595.6" y1="193.5" x2="655.7" y2="154.5"/>
          <line class="st34" x1="607.4" y1="198.6" x2="665.4" y2="159.5"/>
          <line class="st34" x1="617" y1="203.8" x2="676.1" y2="164.2"/>
          <line class="st34" x1="628.1" y1="209" x2="686.9" y2="170.3"/>
          <line class="st34" x1="637.5" y1="213.6" x2="697.2" y2="175.1"/>
          <line class="st34" x1="647" y1="219" x2="707.4" y2="179.8"/>
          <ellipse class="st1" cx="650" cy="244.9" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="648.1" cy="124.9" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="536.6" cy="194.7" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="756.4" cy="179.9" rx="10" ry="4.6"/>
        </g>
      </g>
      <g id="RadioAlt2_00000011753101821416272980000016257131708127651468_">
        <g id="RadioAlt2">
          <g>
            <g>
              <g>
                <polygon class="st17" points="531.3,146.4 634.3,150.5 634.3,167.7 593.3,191 531.3,163.6 					"/>
              </g>
              <g>
                <polygon class="st10" points="571.4,122.6 634.3,150.5 593.3,173.8 531.3,146.4 					"/>
              </g>
            </g>
            <line class="st28" x1="593.3" y1="173.8" x2="593.3" y2="191"/>
          </g>
          <path class="st33" d="M573.9,266.8c1.7-0.8,3.3,1.1,4,2.7c3.9,9.2,0.7,21.2,7.3,28.7c3.2,3.6,7.9,5.2,12.5,6.4
            c5.5,1.4,11.7,2.4,16.4-0.8c2.7-1.9,4.5-4.9,6-7.8c3.7-7.3,6.7-15.8,4.1-23.5"/>
          <path class="st33" d="M520.3,240.2c-2.6,1.1-3.5,4.6-2.6,7.3c0.9,2.7,3.2,4.7,5.4,6.4s4.6,3.5,6,5.9c2.5,4.4,1.3,9.9,2.1,15
            c0.7,4.6,3.2,9,6.9,11.9c2.2,1.7,5,3,7.7,2.6c3.8-0.5,6.5-4,7.6-7.6s0.7-7.5,0.7-11.3c-0.1-7.3,0.6-14.5,2.3-21.6"/>
          <g>
            <g>
              <polygon class="st17" points="770.9,242.4 682.1,300.2 586.5,255.1 586.5,207.4 770.9,194.7 				"/>
            </g>
          </g>
          <polygon class="st1" points="682.1,300.2 683.9,234.3 636.1,279.8 		"/>
          <g>
            <polygon class="st17" points="511.7,206.5 511.7,239.9 642.9,301.8 776,215.8 776,190.3 			"/>
          </g>
          <g>
            <g>
              <polyline class="st15" points="781.7,176.4 781.7,185.9 649.9,271.6 508,204.7 508,195.2 781.7,176.4 				"/>
            </g>
            <g>
              <polygon class="st15" points="644.1,107 508,195.2 649.9,262.1 781.7,176.4 				"/>
            </g>
          </g>
          <line class="st28" x1="642.9" y1="301.8" x2="642.9" y2="268.7"/>
          <ellipse class="st1" cx="650" cy="244.9" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="648.1" cy="124.9" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="536.6" cy="194.7" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="756.4" cy="179.9" rx="10" ry="4.6"/>
        </g>
      </g>
      <g id="RadioAlt1_00000152968336717730871750000003278210489027646643_">
        <g id="RadioAlt1">
          <path class="st33" d="M573.9,266.8c1.7-0.8,3.3,1.1,4,2.7c3.9,9.2,0.7,21.2,7.3,28.7c3.2,3.6,7.9,5.2,12.5,6.4
            c5.5,1.4,11.7,2.4,16.4-0.8c2.7-1.9,4.5-4.9,6-7.8c3.7-7.3,6.7-15.8,4.1-23.5"/>
          <path class="st33" d="M520.3,240.2c-2.6,1.1-3.5,4.6-2.6,7.3c0.9,2.7,3.2,4.7,5.4,6.4s4.6,3.5,6,5.9c2.5,4.4,1.3,9.9,2.1,15
            c0.7,4.6,3.2,9,6.9,11.9c2.2,1.7,5,3,7.7,2.6c3.8-0.5,6.5-4,7.6-7.6s0.7-7.5,0.7-11.3c-0.1-7.3,0.6-14.5,2.3-21.6"/>
          <g>
            <g>
              <polygon class="st17" points="770.9,242.4 682.1,300.2 586.5,255.1 586.5,207.4 770.9,194.7 				"/>
            </g>
          </g>
          <polygon class="st1" points="682.1,300.2 683.9,234.3 636.1,279.8 		"/>
          <g>
            <polygon class="st17" points="511.7,206.5 511.7,239.9 642.9,301.8 776,215.8 776,190.3 			"/>
          </g>
          <g>
            <g>
              <polyline class="st17" points="781.7,176.4 781.7,185.9 649.9,271.6 508,204.7 508,195.2 781.7,176.4 				"/>
            </g>
            <g>
              <polygon class="st10" points="644.1,107 508,195.2 649.9,262.1 781.7,176.4 				"/>
            </g>
          </g>
          <g>
            <path class="st27" d="M651.7,136.1l55.2,27.3c2.1,1,2.3,3.9,0.3,5.2l-85.4,53.7c-0.9,0.6-2,0.6-2.9,0.2l-55.7-24.8
              c-2.1-1-2.3-3.9-0.3-5.2l85.9-56.2C649.7,135.7,650.8,135.6,651.7,136.1z"/>
            <path class="st15" d="M651.7,134.2l55.2,27.3c2.1,1,2.3,3.9,0.3,5.2l-85.4,53.7c-0.9,0.6-2,0.6-2.9,0.2l-55.7-24.8
              c-2.1-1-2.3-3.9-0.3-5.2l85.9-56.2C649.7,133.8,650.8,133.8,651.7,134.2z"/>
          </g>
          <line class="st28" x1="642.9" y1="301.8" x2="642.9" y2="268.7"/>
          <ellipse class="st1" cx="650" cy="244.9" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="648.1" cy="124.9" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="536.6" cy="194.7" rx="10" ry="4.6"/>
          <ellipse class="st1" cx="756.4" cy="179.9" rx="10" ry="4.6"/>
          <g>
            <g>
              <g>
                <polygon class="st17" points="580.8,185.3 639.6,187.6 639.6,197.4 616.2,210.7 580.8,195.1 					"/>
              </g>
              <g>
                <polygon class="st10" points="603.8,171.7 639.6,187.6 616.2,200.9 580.8,185.3 					"/>
              </g>
            </g>
            <line class="st28" x1="616.2" y1="200.9" x2="616.2" y2="210.7"/>
          </g>
          <g>
            <path class="st14" d="M518.5,214.4c40.1,19.1,80.1,38.1,120.2,57.2c0.5,1.7,1,3.3,1.5,5c-0.1,4.4-0.2,8.8-0.4,13.2
              c-1.2,0.4-2.5,0.8-3.7,1.1c-39-17.9-78-35.9-117-53.8c-1-1.8-2.1-3.6-3.1-5.4c0.1-4.5,0.3-9,0.4-13.6
              C517.1,216.9,517.8,215.7,518.5,214.4z"/>
            <line class="st34" x1="633" y1="284.5" x2="633.1" y2="274.6"/>
            <line class="st34" x1="624.1" y1="280.6" x2="624.2" y2="270.7"/>
            <line class="st34" x1="615.2" y1="276.2" x2="615.3" y2="266.3"/>
            <line class="st34" x1="606.3" y1="272.6" x2="606.4" y2="262.7"/>
            <line class="st34" x1="598.1" y1="268.5" x2="598.2" y2="258.6"/>
            <line class="st34" x1="589.2" y1="264.2" x2="589.4" y2="254.3"/>
            <line class="st34" x1="580.4" y1="260.6" x2="580.5" y2="250.7"/>
            <line class="st34" x1="571.5" y1="256.5" x2="571.6" y2="246.6"/>
            <line class="st34" x1="563.1" y1="252.5" x2="563.2" y2="242.6"/>
            <line class="st34" x1="554.7" y1="248.2" x2="554.8" y2="238.3"/>
            <line class="st34" x1="546.1" y1="243.9" x2="546.2" y2="234"/>
            <line class="st34" x1="537.7" y1="240.1" x2="537.8" y2="230.2"/>
            <line class="st34" x1="529.5" y1="236.5" x2="529.6" y2="226.6"/>
            <line class="st34" x1="521.5" y1="232.5" x2="521.6" y2="222.6"/>
          </g>
        </g>
      </g>
      <g id="Radio">
        <g>
          <g>
            <polygon class="st17" points="770.9,242.5 682.1,300.3 586.4,255.2 586.4,207.5 770.9,194.8 			"/>
          </g>
        </g>
        <polygon class="st1" points="682.1,300.3 683.8,234.4 636.1,279.9 	"/>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <polygon id="SVGID_1_" points="678.4,272.9 653.8,290.1 664.9,320.1 689.1,312.4 							"/>
                  </defs>
                  <clipPath id="SVGID_00000121254056141554998610000012294769647750248883_">
                    <use  style="overflow:visible;"/>
                  </clipPath>
                  <g style="clip-path:url(#SVGID_00000121254056141554998610000012294769647750248883_);">
                    <g>
                      <path class="st27" d="M541.1,237.9l136.4,64.6c3,1.4,3.3,5.6,0.5,7.4L542.3,398c-1.2,0.8-2.8,0.9-4.1,0.3l-136.4-64.6
                        c-3-1.4-3.3-5.6-0.5-7.4L537,238.1C538.2,237.3,539.8,237.2,541.1,237.9z"/>
                      <path class="st11" d="M541.1,235.2l136.4,64.6c3,1.4,3.3,5.6,0.5,7.4l-135.7,88.1c-1.2,0.8-2.8,0.9-4.1,0.3L401.8,331
                        c-3-1.4-3.3-5.6-0.5-7.4L537,235.5C538.2,234.7,539.8,234.6,541.1,235.2z"/>
                    </g>
                    <g>
                      <path class="st27" d="M541.1,227.3l130.6,61.8c2.9,1.4,3.2,5.4,0.5,7.1l-130,84.4c-1.2,0.8-2.7,0.9-4,0.3l-130.6-61.8
                        c-2.9-1.4-3.2-5.4-0.5-7.1l130-84.4C538.3,226.8,539.8,226.7,541.1,227.3z"/>
                      <path class="st11" d="M541.1,224.7l130.6,61.8c2.9,1.4,3.2,5.4,0.5,7.1l-130,84.4c-1.2,0.8-2.7,0.9-4,0.3l-130.6-61.8
                        c-2.9-1.4-3.2-5.4-0.5-7.1l130-84.4C538.3,224.2,539.8,224.1,541.1,224.7z"/>
                    </g>
                    <g>
                      <g>
                        <g>
                          <polygon class="st17" points="473,256 576,260.1 576,277.4 535,300.6 473,273.2 										"/>
                        </g>
                        <g>
                          <polygon class="st10" points="513.2,232.2 576,260.1 535,283.4 473,256 										"/>
                        </g>
                      </g>
                      <line class="st28" x1="535" y1="283.4" x2="535" y2="300.6"/>
                    </g>
                    <g>
                      <path class="st27" d="M587,238.5l75.6,37.3c2.9,1.4,3.2,5.4,0.5,7.1l-117,73.6c-1.2,0.8-2.7,0.9-4,0.3l-76.3-34
                        c-2.9-1.4-3.2-5.4-0.5-7.1L583,238.8C584.2,238,585.7,237.9,587,238.5z"/>
                      <path class="st15" d="M587,236l75.6,37.3c2.9,1.4,3.2,5.4,0.5,7.1l-117,73.6c-1.2,0.8-2.7,0.9-4,0.3l-76.3-34
                        c-2.9-1.4-3.2-5.4-0.5-7.1l117.7-77C584.2,235.4,585.7,235.3,587,236z"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <path class="st33" d="M573.8,266.9c1.7-0.8,3.3,1.1,4,2.7c3.9,9.2,0.7,21.2,7.3,28.7c3.2,3.6,7.9,5.2,12.5,6.4
          c5.5,1.4,11.7,2.4,16.4-0.8c2.7-1.9,4.5-4.9,6-7.8c3.7-7.3,6.7-15.8,4.1-23.5"/>
        <path class="st33" d="M520.3,240.3c-2.6,1.1-3.5,4.6-2.6,7.3s3.2,4.7,5.4,6.4c2.2,1.8,4.6,3.5,6,5.9c2.5,4.4,1.3,9.9,2.1,15
          c0.7,4.6,3.2,9,6.9,11.9c2.2,1.7,5,3,7.7,2.6c3.8-0.5,6.5-4,7.6-7.6c1-3.6,0.7-7.5,0.7-11.3c-0.1-7.3,0.6-14.5,2.3-21.6"/>
        <g>
          <polygon class="st17" points="511.7,206.6 511.7,240 642.9,301.9 775.9,215.9 775.9,190.4 		"/>
        </g>
        <g>
          <g>
            <polyline class="st17" points="781.7,176.5 781.7,186 649.8,271.7 508,204.8 508,195.3 781.7,176.5 			"/>
          </g>
          <g>
            <polygon class="st10" points="644.1,107.1 508,195.3 649.8,262.2 781.7,176.5 			"/>
          </g>
        </g>
        <line class="st28" x1="642.9" y1="301.9" x2="642.9" y2="268.8"/>
        <ellipse class="st1" cx="650" cy="245" rx="10" ry="4.6"/>
        <ellipse class="st1" cx="648.1" cy="125" rx="10" ry="4.6"/>
        <ellipse class="st1" cx="536.6" cy="194.8" rx="10" ry="4.6"/>
        <ellipse class="st1" cx="756.4" cy="180" rx="10" ry="4.6"/>
      </g>
      <g id="ADCS-Wire_00000071549273988820103460000002730746473914448529_">
        <g id="ADCS-Wire">
          <g>
            <line class="st16" x1="523.7" y1="494.2" x2="523.7" y2="476"/>
            <line class="st16" x1="509.3" y1="475.5" x2="509.3" y2="503.6"/>
            <line class="st16" x1="634.5" y1="422.3" x2="634.5" y2="403.4"/>
            <line class="st16" x1="620.1" y1="412.8" x2="620.1" y2="431.8"/>
            <line class="st16" x1="410.2" y1="432.9" x2="410.2" y2="429.4"/>
            <path class="st16" d="M410.2,448.5"/>
            <path class="st16" d="M410.2,464.6"/>
            <path class="st16" d="M395.7,451.4"/>
            <line class="st16" x1="395.7" y1="422.4" x2="395.7" y2="426"/>
          </g>
          <g>
            <g>
              <path class="st16" d="M395.7,410.7l-9.8,6.4l131.8,62.4L643,397.6c1.8-1.2,1.7-3.9-0.3-4.8l-8.2-4.1"/>
            </g>
            <path class="st16" d="M509.3,456.5v11.4c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-11"/>
            <path class="st16" d="M620.1,393.8v2.3c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-11.7"/>
            <path class="st16" d="M395.7,403.4v11.7c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-4.8"/>
          </g>
          <path class="st16" d="M502.5,492.6l-111.6-52.8c-6.7-7.7,0-16.4,0-16.4l111.6,52.8C502.5,476.3,498.4,483.3,502.5,492.6z"/>
          <path class="st16" d="M492.1,505.3l-96.7-45.8c-3.3-1.6-3.6-6.1-0.6-8.1l0.9-0.6"/>
        </g>
      </g>
      <g id="ADCSAlt3">
        <g>
          <g>
            <g>
              <g>
                <path class="st27" d="M521.6,377.4l122.5,58c2.7,1.3,3,5,0.4,6.7l-121.9,79.1c-1.1,0.7-2.5,0.8-3.7,0.2l-122.5-58
                  c-2.7-1.3-3-5-0.4-6.7l121.9-79.1C519,376.9,520.4,376.8,521.6,377.4z"/>
                <path class="st15" d="M521.6,375l122.5,58c2.7,1.3,3,5,0.4,6.7l-121.9,79.1c-1.1,0.7-2.5,0.8-3.7,0.2l-122.5-58
                  c-2.7-1.3-3-5-0.4-6.7l121.9-79.1C519,374.5,520.4,374.4,521.6,375z"/>
                <g>
                  <path class="st29" d="M622.3,389.2v48c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-48.9"/>
                  <ellipse class="st30" cx="629.4" cy="389.2" rx="7.2" ry="3.6"/>
                  <path class="st29" d="M512.5,460.4v48c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-48.9"/>
                  <ellipse class="st30" cx="519.6" cy="460.4" rx="7.2" ry="3.6"/>
                  <path class="st29" d="M512.5,336.8v48c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-48.9"/>
                  <ellipse class="st30" cx="519.6" cy="336.8" rx="7.2" ry="3.6"/>
                  <path class="st29" d="M399.9,408v48c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-48.9"/>
                  <ellipse class="st30" cx="407.1" cy="408" rx="7.2" ry="3.6"/>
                </g>
              </g>
              <g>
                <path class="st27" d="M521.6,334l122.5,58c2.7,1.3,3,5,0.4,6.7l-121.9,79.1c-1.1,0.7-2.5,0.8-3.7,0.2l-122.5-58
                  c-2.7-1.3-3-5-0.4-6.7l121.9-79.1C519,333.5,520.4,333.4,521.6,334z"/>
                <path class="st15" d="M521.6,331.6l122.5,58c2.7,1.3,3,5,0.4,6.7l-121.9,79.1c-1.1,0.7-2.5,0.8-3.7,0.2l-122.5-58
                  c-2.7-1.3-3-5-0.4-6.7l121.9-79.1C519,331.2,520.4,331.1,521.6,331.6z"/>
              </g>
            </g>
            <g>
              <ellipse class="st31" cx="398.9" cy="436.8" rx="7.5" ry="9.9"/>
              <ellipse class="st17" cx="401.1" cy="438" rx="7.5" ry="9.9"/>
              <path class="st32" d="M503.9,494.8l-104.6-49.7c-2.3-2.6-3-5.3-2.9-7.8c0.1-4.6,4.9-7.4,9.1-5.5l98.5,46.8
                C503.9,478.6,499.8,485.6,503.9,494.8z"/>
              <ellipse class="st17" cx="506" cy="486.3" rx="7.5" ry="9.9"/>
              <ellipse class="st31" cx="509" cy="487.5" rx="7.5" ry="9.9"/>
            </g>
          </g>
          <path class="st14" d="M521.7,326.6l122.5,58c2.7,1.3,3,5,0.4,6.7l-121.9,79.1c-1.1,0.7-2.5,0.8-3.7,0.2l-122.5-58
            c-2.7-1.3-3-5-0.4-6.7L518,326.8C519.1,326.1,520.5,326,521.7,326.6z"/>
          <line class="st34" x1="432.8" y1="401.2" x2="522.9" y2="342.7"/>
          <line class="st34" x1="447.1" y1="409.2" x2="537.2" y2="350.7"/>
          <line class="st34" x1="462.4" y1="417.3" x2="552.5" y2="358.8"/>
          <line class="st34" x1="476.7" y1="425.4" x2="566.8" y2="366.9"/>
          <line class="st34" x1="492.7" y1="433.7" x2="582.8" y2="375.2"/>
          <line class="st34" x1="508" y1="441.8" x2="598.1" y2="383.3"/>
          <line class="st34" x1="522.3" y1="449.8" x2="612.4" y2="391.3"/>
        </g>
      </g>
      <g id="ADCSAlt2">
        <g>
          <g>
            <g>
              <path class="st27" d="M519.5,374.2l123.7,55.3c2.7,1.2,3.1,5,0.6,6.7l-120.2,81.8c-1.1,0.7-2.5,0.9-3.7,0.3l-123.7-55.3
                c-2.7-1.2-3.1-5-0.6-6.7l120.2-81.8C516.9,373.8,518.3,373.6,519.5,374.2z"/>
              <path class="st36" d="M519.5,371.8l123.7,55.3c2.7,1.2,3.1,5,0.6,6.7l-120.2,81.8c-1.1,0.7-2.5,0.9-3.7,0.3l-123.7-55.3
                c-2.7-1.2-3.1-5-0.6-6.7l120.2-81.8C516.9,371.4,518.3,371.2,519.5,371.8z"/>
              <g>
                <path class="st29" d="M620.5,383.8l1,48c0.1,2.4,3.3,4.2,7.3,4.1c4-0.1,7.1-2.1,7.1-4.4l-1.1-48.9"/>
                
                  <ellipse transform="matrix(0.9998 -2.152502e-02 2.152502e-02 0.9998 -8.1128 13.5986)" class="st30" cx="627.6" cy="383.7" rx="7.2" ry="3.6"/>
                <path class="st29" d="M512.2,457.4l1,48c0.1,2.4,3.3,4.2,7.3,4.1c4-0.1,7.1-2.1,7.1-4.4l-1.1-48.9"/>
                
                  <ellipse transform="matrix(0.9998 -2.152502e-02 2.152502e-02 0.9998 -9.7216 11.2861)" class="st30" cx="519.4" cy="457.2" rx="7.2" ry="3.6"/>
                <path class="st29" d="M509.6,333.8l1,48c0.1,2.4,3.3,4.2,7.3,4.1c4-0.1,7.1-2.1,7.1-4.4l-1.1-48.9"/>
                
                  <ellipse transform="matrix(0.9998 -2.152502e-02 2.152502e-02 0.9998 -7.0611 11.2002)" class="st30" cx="516.7" cy="333.6" rx="7.2" ry="3.6"/>
                <path class="st29" d="M398.6,407.5l1,48c0.1,2.4,3.3,4.2,7.3,4.1c4-0.1,7.1-2.1,7.1-4.4l-1.1-48.9"/>
                
                  <ellipse transform="matrix(0.9998 -2.152502e-02 2.152502e-02 0.9998 -8.6733 8.8282)" class="st30" cx="405.8" cy="407.3" rx="7.2" ry="3.6"/>
              </g>
            </g>
            <g>
              <path class="st27" d="M518.6,330.8l123.7,55.3c2.7,1.2,3.1,5,0.6,6.7l-120.2,81.8c-1.1,0.7-2.5,0.9-3.7,0.3l-123.7-55.3
                c-2.7-1.2-3.1-5-0.6-6.7l120.2-81.8C516,330.4,517.4,330.3,518.6,330.8z"/>
              <path class="st36" d="M518.6,328.4l123.7,55.3c2.7,1.2,3.1,5,0.6,6.7l-120.2,81.8c-1.1,0.7-2.5,0.9-3.7,0.3l-123.7-55.3
                c-2.7-1.2-3.1-5-0.6-6.7l120.2-81.8C515.9,328,517.4,327.9,518.6,328.4z"/>
            </g>
          </g>
          <g>
            
              <ellipse transform="matrix(0.9998 -2.152502e-02 2.152502e-02 0.9998 -9.298 8.6711)" class="st31" cx="398.1" cy="436.2" rx="7.5" ry="9.9"/>
            
              <ellipse transform="matrix(0.9998 -2.152502e-02 2.152502e-02 0.9998 -9.3221 8.7205)" class="st17" cx="400.4" cy="437.4" rx="7.5" ry="9.9"/>
            <path class="st37" d="M504.4,492l-105.7-47.4c-2.3-2.6-3.1-5.3-3.1-7.7c0-4.6,4.8-7.5,8.9-5.7l99.5,44.7
              C504.1,475.8,500.2,482.8,504.4,492z"/>
            
              <ellipse transform="matrix(0.9998 -2.152502e-02 2.152502e-02 0.9998 -10.2889 11.0104)" class="st17" cx="506.3" cy="483.4" rx="7.5" ry="9.9"/>
            
              <ellipse transform="matrix(0.9998 -2.152502e-02 2.152502e-02 0.9998 -10.3124 11.0769)" class="st31" cx="509.4" cy="484.6" rx="7.5" ry="9.9"/>
          </g>
          <g>
            
              <ellipse transform="matrix(0.9908 -0.1356 0.1356 0.9908 -45.8897 88.4529)" class="st31" cx="626.2" cy="381" rx="7.5" ry="9.9"/>
            
              <ellipse transform="matrix(0.9908 -0.1356 0.1356 0.9908 -46.1109 88.1854)" class="st17" cx="624.1" cy="382.5" rx="7.5" ry="9.9"/>
            <path class="st37" d="M529.9,452.7l96.9-63.4c1.9-2.9,2.2-5.7,1.8-8.1c-0.7-4.5-5.9-6.7-9.7-4.2l-91.2,59.7
              C527.7,436.7,532.7,443,529.9,452.7z"/>
            
              <ellipse transform="matrix(0.9908 -0.1356 0.1356 0.9908 -55.4333 75.5551)" class="st17" cx="526.7" cy="444.6" rx="7.5" ry="9.9"/>
            
              <ellipse transform="matrix(0.9908 -0.1356 0.1356 0.9908 -55.6764 75.1816)" class="st31" cx="523.9" cy="446.2" rx="7.5" ry="9.9"/>
          </g>
        </g>
      </g>
      <g id="ADCSAlt1">
        <g>
          <g>
            <path class="st27" d="M519.7,358.7l122.9,57c2.7,1.3,3,5,0.5,6.7l-121.3,80.1c-1.1,0.7-2.5,0.8-3.7,0.3l-122.9-57
              c-2.7-1.3-3-5-0.5-6.7L515.9,359C517,358.3,518.5,358.1,519.7,358.7z"/>
            <path class="st15" d="M519.6,356.3l122.9,57c2.7,1.3,3,5,0.5,6.7l-121.3,80.1c-1.1,0.7-2.5,0.8-3.7,0.3l-122.9-57
              c-2.7-1.3-3-5-0.5-6.7l121.3-80.1C517,355.9,518.4,355.8,519.6,356.3z"/>
          </g>
          <g>
            
              <ellipse transform="matrix(1 -7.580062e-03 7.580062e-03 1 -3.2556 3.0855)" class="st31" cx="405.4" cy="431" rx="7.5" ry="9.9"/>
            
              <ellipse transform="matrix(1 -7.580062e-03 7.580062e-03 1 -3.2645 3.1027)" class="st17" cx="407.7" cy="432.2" rx="7.5" ry="9.9"/>
            <path class="st37" d="M510.9,488.2l-105-48.9c-2.3-2.6-3-5.3-3-7.8c0.1-4.6,4.9-7.5,9-5.6l98.8,46.1
              C510.8,472,506.8,479,510.9,488.2z"/>
            
              <ellipse transform="matrix(1 -7.580062e-03 7.580062e-03 1 -3.6217 3.9017)" class="st17" cx="512.9" cy="479.7" rx="7.5" ry="9.9"/>
            
              <ellipse transform="matrix(1 -7.580062e-03 7.580062e-03 1 -3.6305 3.925)" class="st31" cx="516" cy="480.9" rx="7.5" ry="9.9"/>
          </g>
          <g>
            
              <ellipse transform="matrix(1 -7.580062e-03 7.580062e-03 1 -2.6956 3.8972)" class="st31" cx="512.8" cy="357.6" rx="7.5" ry="9.9"/>
            
              <ellipse transform="matrix(1 -7.580062e-03 7.580062e-03 1 -2.7045 3.9144)" class="st17" cx="515.1" cy="358.7" rx="7.5" ry="9.9"/>
            <path class="st37" d="M618.3,414.8l-105-48.9c-2.3-2.6-3-5.3-3-7.8c0.1-4.6,4.9-7.5,9-5.6l98.8,46.1
              C618.2,398.6,614.1,405.6,618.3,414.8z"/>
            
              <ellipse transform="matrix(1 -7.580062e-03 7.580062e-03 1 -3.0617 4.7135)" class="st17" cx="620.3" cy="406.3" rx="7.5" ry="9.9"/>
            
              <ellipse transform="matrix(1 -7.580062e-03 7.580062e-03 1 -3.0705 4.7367)" class="st31" cx="623.4" cy="407.4" rx="7.5" ry="9.9"/>
          </g>
        </g>
      </g>
      <g id="ADCS">
        <g id="ADCS_00000140016157613807511760000016838180372580742813_">
          <g>
            <g>
              <path class="st27" d="M522.2,372.5L645.8,431c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C519.6,372.1,521,372,522.2,372.5z"/>
              <path class="st15" d="M522.2,370.1l123.6,58.5c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3L395.9,457
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C519.6,369.7,521,369.6,522.2,370.1z"/>
              <g>
                <path class="st29" d="M623.8,384.5V433c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-49.4"/>
                <ellipse class="st30" cx="631" cy="384.5" rx="7.2" ry="3.6"/>
                <path class="st29" d="M513,456.4v48.5c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-49.4"/>
                <ellipse class="st30" cx="520.2" cy="456.4" rx="7.2" ry="3.6"/>
                <path class="st29" d="M513,331.6V380c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-49.4"/>
                <ellipse class="st30" cx="520.2" cy="331.6" rx="7.2" ry="3.6"/>
                <path class="st29" d="M399.4,403.5V452c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-49.4"/>
                <ellipse class="st30" cx="406.7" cy="403.5" rx="7.2" ry="3.6"/>
              </g>
            </g>
            <g>
              <path class="st27" d="M522.2,328.8l123.6,58.5c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C519.6,328.3,521,328.2,522.2,328.8z"/>
              <path class="st15" d="M522.2,326.4l123.6,58.5c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C519.6,325.9,521,325.8,522.2,326.4z"/>
            </g>
          </g>
          <g>
            <ellipse class="st31" cx="398.4" cy="432.5" rx="7.6" ry="10"/>
            <ellipse class="st17" cx="400.6" cy="433.8" rx="7.6" ry="10"/>
            <path class="st32" d="M504.4,491.1l-105.6-50.2c-2.3-2.6-3-5.4-3-7.9c0.1-4.6,5-7.5,9.1-5.5l99.4,47.2
              C504.4,474.7,500.3,481.8,504.4,491.1z"/>
            <ellipse class="st17" cx="506.5" cy="482.5" rx="7.6" ry="10"/>
            <ellipse class="st31" cx="509.6" cy="483.7" rx="7.6" ry="10"/>
          </g>
        </g>
      </g>
      <g id="Batteries-Wire_00000154427006462411962700000000411819614690398644_">
        <g id="Batteries-Wire">
          <g>
            <g>
              <path class="st0" d="M443.8,406.2l71.1,33.7c0.9,0.4,2,0.4,2.9-0.2l123.7-80.8c1.9-1.2,1.8-4.1-0.3-5.1l-8-4"/>
            </g>
            <path class="st0" d="M507.9,417.5V429c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-11"/>
            <path class="st0" d="M618.7,354.8v2.3c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-11.7"/>
            <path class="st0" d="M407.3,378.8c1-0.7,1.6-1.7,1.6-2.7v-4.8"/>
            <line class="st0" x1="394.4" y1="364.4" x2="394.4" y2="373.5"/>
          </g>
          <g>
            <g>
              <path class="st0" d="M444.1,425.4l71.1,33.7c0.7,0.3,1.6,0.3,2.2-0.2l125.1-81.7c1.5-1,1.3-3.1-0.2-3.9l-9.1-4.6"/>
            </g>
            <path class="st0" d="M507.9,436.6V448c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-11"/>
            <path class="st0" d="M618.7,373.9v2.3c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-11.7"/>
          </g>
          <line class="st0" x1="525.5" y1="425.2" x2="615.5" y2="365.9"/>
          <line class="st0" x1="502.2" y1="425.2" x2="414.1" y2="386.1"/>
        </g>
      </g>
      <g id="BatteriesAlt3">
        <g>
          <g>
            <g>
              <path class="st27" d="M519.4,315.8L643,374.3c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C516.8,315.3,518.2,315.2,519.4,315.8z"/>
              <path class="st15" d="M519.4,313.4L643,371.9c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C516.8,312.9,518.2,312.8,519.4,313.4z"/>
              <g>
                <path class="st29" d="M510.2,290.4v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
                <ellipse class="st30" cx="517.5" cy="290.4" rx="7.2" ry="3.6"/>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,345.7 516,323 413.9,387.7 413.9,387.7 413.6,393.7 462.2,416.4 564.3,351.6 						"/>
                  <polygon class="st14" points="564.6,345.7 462.5,409.4 413.9,387.7 516,323 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,369.3 566.7,346.6 464.7,411.4 464.7,411.4 464.4,417.3 512.9,440 615,375.3 						"/>
                  <polygon class="st14" points="615.3,369.3 513.2,433 464.7,411.4 566.7,346.6 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,335 516,312.3 413.9,377.1 413.9,377.1 413.6,383.1 462.2,405.8 564.3,341 						"/>
                  <polygon class="st14" points="564.6,335 462.5,398.7 413.9,377.1 516,312.3 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,358.7 566.7,336 464.7,400.8 464.7,400.7 464.4,406.7 512.9,429.4 615,364.6 						"/>
                  <polygon class="st14" points="615.3,358.7 513.2,422.4 464.7,400.7 566.7,336 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,325.4 516,302.7 413.9,367.5 413.9,367.5 413.6,373.5 462.2,396.2 564.3,331.4 						"/>
                  <polygon class="st14" points="564.6,325.4 462.5,389.1 413.9,367.5 516,302.7 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,349.1 566.7,326.4 464.7,391.1 464.7,391.1 464.4,397.1 512.9,419.8 615,355 						"/>
                  <polygon class="st14" points="615.3,349.1 513.2,412.8 464.7,391.1 566.7,326.4 						"/>
                </g>
              </g>
            </g>
            <path class="st29" d="M621,343.3v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="628.2" cy="343.3" rx="7.2" ry="3.6"/>
            <path class="st29" d="M510.2,415.2V448c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="517.5" cy="415.2" rx="7.2" ry="3.6"/>
            <path class="st29" d="M396.7,362.3v32.9c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="403.9" cy="362.3" rx="7.2" ry="3.6"/>
          </g>
          <g>
            <g>
              <path class="st27" d="M519.4,276.4L643,334.9c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C516.8,275.9,518.2,275.8,519.4,276.4z"/>
              <path class="st15" d="M519.4,274L643,332.5c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C516.8,273.5,518.2,273.4,519.4,274z"/>
              <g>
                <path class="st29" d="M510.2,251v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
                <ellipse class="st30" cx="517.5" cy="251" rx="7.2" ry="3.6"/>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,306.3 516,283.6 413.9,348.3 413.9,348.3 413.6,354.3 462.2,377 564.3,312.2 						"/>
                  <polygon class="st14" points="564.6,306.3 462.5,370 413.9,348.3 516,283.6 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,329.9 566.7,307.2 464.7,372 464.7,372 464.4,378 512.9,400.7 615,335.9 						"/>
                  <polygon class="st14" points="615.3,329.9 513.2,393.6 464.7,372 566.7,307.2 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,295.7 516,273 413.9,337.7 413.9,337.7 413.6,343.7 462.2,366.4 564.3,301.6 						"/>
                  <polygon class="st14" points="564.6,295.7 462.5,359.4 413.9,337.7 516,273 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,319.3 566.7,296.6 464.7,361.4 464.7,361.4 464.4,367.3 512.9,390 615,325.3 						"/>
                  <polygon class="st14" points="615.3,319.3 513.2,383 464.7,361.4 566.7,296.6 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,286.1 516,263.4 413.9,328.1 413.9,328.1 413.6,334.1 462.2,356.8 564.3,292 						"/>
                  <polygon class="st14" points="564.6,286.1 462.5,349.7 413.9,328.1 516,263.4 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,309.7 566.7,287 464.7,351.8 464.7,351.8 464.4,357.7 512.9,380.4 615,315.7 						"/>
                  <polygon class="st14" points="615.3,309.7 513.2,373.4 464.7,351.8 566.7,287 						"/>
                </g>
              </g>
            </g>
            <path class="st29" d="M621,303.9v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3V303"/>
            <ellipse class="st30" cx="628.2" cy="303.9" rx="7.2" ry="3.6"/>
            <path class="st29" d="M510.2,375.8v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="517.5" cy="375.8" rx="7.2" ry="3.6"/>
            <path class="st29" d="M396.7,323v32.9c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3V322"/>
            <ellipse class="st30" cx="403.9" cy="323" rx="7.2" ry="3.6"/>
          </g>
          <g>
            <g>
              <path class="st27" d="M519.4,238.2L643,296.7c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C516.8,237.8,518.2,237.7,519.4,238.2z"/>
              <path class="st15" d="M519.4,235.8L643,294.3c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
                c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C516.8,235.4,518.2,235.3,519.4,235.8z"/>
              <g>
                <path class="st29" d="M510.2,212.9v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
                <ellipse class="st30" cx="517.5" cy="212.9" rx="7.2" ry="3.6"/>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,268.1 516,245.4 413.9,310.2 413.9,310.2 413.6,316.2 462.2,338.9 564.3,274.1 						"/>
                  <polygon class="st14" points="564.6,268.1 462.5,331.8 413.9,310.2 516,245.4 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,291.8 566.7,269.1 464.7,333.8 464.7,333.8 464.4,339.8 512.9,362.5 615,297.7 						"/>
                  <polygon class="st14" points="615.3,291.8 513.2,355.5 464.7,333.8 566.7,269.1 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,257.5 516,234.8 413.9,299.6 413.9,299.6 413.6,305.6 462.2,328.3 564.3,263.5 						"/>
                  <polygon class="st14" points="564.6,257.5 462.5,321.2 413.9,299.6 516,234.8 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,281.2 566.7,258.5 464.7,323.2 464.7,323.2 464.4,329.2 512.9,351.9 615,287.1 						"/>
                  <polygon class="st14" points="615.3,281.2 513.2,344.8 464.7,323.2 566.7,258.5 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,247.9 516,225.2 413.9,290 413.9,290 413.6,295.9 462.2,318.7 564.3,253.9 						"/>
                  <polygon class="st14" points="564.6,247.9 462.5,311.6 413.9,290 516,225.2 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.3,271.6 566.7,248.9 464.7,313.6 464.7,313.6 464.4,319.6 512.9,342.3 615,277.5 						"/>
                  <polygon class="st14" points="615.3,271.6 513.2,335.2 464.7,313.6 566.7,248.9 						"/>
                </g>
              </g>
            </g>
            <path class="st29" d="M621,265.8v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="628.2" cy="265.8" rx="7.2" ry="3.6"/>
            <path class="st29" d="M510.2,337.6v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="517.5" cy="337.6" rx="7.2" ry="3.6"/>
            <path class="st29" d="M396.7,284.8v32.9c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="403.9" cy="284.8" rx="7.2" ry="3.6"/>
          </g>
        </g>
      </g>
      <g id="BatteriesAlt2">
        <g>
          <g>
            <g>
              <path class="st27" d="M519.4,313.6l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3L393,400.6
                c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C516.8,313.1,518.2,313,519.4,313.6z"/>
              <path class="st14" d="M519.4,311.2l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3L393,398.2
                c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C516.8,310.7,518.2,310.6,519.4,311.2z"/>
              <g>
                <path class="st29" d="M510.2,288.2v32.9c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-33.8"/>
                <ellipse class="st30" cx="517.5" cy="288.2" rx="7.2" ry="3.6"/>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,343.5 516,320.8 413.8,385.7 413.8,385.7 413.5,391.6 462.1,414.4 564.3,349.5 						"/>
                  <polygon class="st14" points="564.6,343.5 462.4,407.3 413.8,385.7 516,320.8 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.4,367.2 566.8,344.5 464.6,409.3 464.6,409.3 464.3,415.3 512.9,438 615.1,373.2 						"/>
                  <polygon class="st14" points="615.4,367.2 513.2,431 464.6,409.3 566.8,344.5 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,332.9 516,310.2 413.8,375 413.8,375 413.5,381 462.1,403.7 564.3,338.9 						"/>
                  <polygon class="st14" points="564.6,332.9 462.4,396.7 413.8,375 516,310.2 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.4,356.6 566.8,333.9 464.6,398.7 464.6,398.7 464.3,404.7 512.9,427.4 615.1,362.5 						
                    "/>
                  <polygon class="st14" points="615.4,356.6 513.2,420.3 464.6,398.7 566.8,333.9 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.6,323.3 516,300.6 413.8,365.4 413.8,365.4 413.5,371.4 462.1,394.1 564.3,329.3 						"/>
                  <polygon class="st14" points="564.6,323.3 462.4,387.1 413.8,365.4 516,300.6 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.4,347 566.8,324.2 464.6,389.1 464.6,389.1 464.3,395.1 512.9,417.8 615.1,352.9 						"/>
                  <polygon class="st14" points="615.4,347 513.2,410.7 464.6,389.1 566.8,324.2 						"/>
                </g>
              </g>
            </g>
            <path class="st29" d="M621.2,341.2v32.9c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="628.4" cy="341.2" rx="7.2" ry="3.6"/>
            <path class="st29" d="M510.2,413.1V446c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="517.5" cy="413.1" rx="7.2" ry="3.6"/>
            <path class="st29" d="M396.5,360.2v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
            <ellipse class="st30" cx="403.8" cy="360.2" rx="7.2" ry="3.6"/>
          </g>
          <path class="st27" d="M519.4,277.4L643.2,336c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3L393,364.4
            c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C516.8,277,518.2,276.9,519.4,277.4z"/>
          <path class="st14" d="M519.4,275l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3L393,362
            c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C516.8,274.6,518.2,274.5,519.4,275z"/>
          <polygon class="st39" points="628.4,389.4 534.4,448.5 534.4,411.3 628.4,352.1 		"/>
          <polygon class="st39" points="494.9,448.3 419.1,412.8 419.1,375.5 494.9,411 		"/>
          <line class="st34" x1="429.6" y1="350.4" x2="520.7" y2="291.3"/>
          <line class="st34" x1="444" y1="358.5" x2="535.1" y2="299.4"/>
          <line class="st34" x1="459.5" y1="366.7" x2="550.6" y2="307.6"/>
          <line class="st34" x1="473.9" y1="374.9" x2="565" y2="315.8"/>
          <line class="st34" x1="490.1" y1="383.2" x2="581.1" y2="324.1"/>
          <line class="st34" x1="505.6" y1="391.4" x2="596.6" y2="332.3"/>
          <line class="st34" x1="520" y1="399.6" x2="611" y2="340.5"/>
        </g>
      </g>
      <g id="BatteriesAlt1">
        <g>
          <g>
            <g>
              <path class="st27" d="M519.8,315.6l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
                c-2.7-1.3-3-5.1-0.5-6.7L516,315.9C517.1,315.2,518.6,315.1,519.8,315.6z"/>
              <path class="st14" d="M519.8,313.2l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3L393.6,400
                c-2.7-1.3-3-5.1-0.5-6.7L516,313.5C517.1,312.8,518.6,312.7,519.8,313.2z"/>
              <g>
                <path class="st29" d="M510.6,290.3v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.7"/>
                <ellipse class="st30" cx="517.8" cy="290.3" rx="7.2" ry="3.6"/>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.9,345.5 516.3,322.8 414.4,387.5 414.4,387.5 414.1,393.5 462.6,416.1 564.5,351.4 						
                    "/>
                  <polygon class="st14" points="564.9,345.5 462.9,409.1 414.4,387.5 516.3,322.8 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.5,369.1 567,346.4 465.1,411.1 465.1,411.1 464.8,417.1 513.3,439.7 615.2,375.1 						"/>
                  <polygon class="st14" points="615.5,369.1 513.6,432.7 465.1,411.1 567,346.4 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.9,334.9 516.3,312.2 414.4,376.9 414.4,376.9 414.1,382.9 462.6,405.5 564.5,340.8 						
                    "/>
                  <polygon class="st14" points="564.9,334.9 462.9,398.5 414.4,376.9 516.3,312.2 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.5,358.5 567,335.8 465.1,400.5 465.1,400.5 464.8,406.5 513.3,429.1 615.2,364.4 						"/>
                  <polygon class="st14" points="615.5,358.5 513.6,422.1 465.1,400.5 567,335.8 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="564.9,325.3 516.3,302.6 414.4,367.3 414.4,367.3 414.1,373.3 462.6,395.9 564.5,331.2 						
                    "/>
                  <polygon class="st14" points="564.9,325.3 462.9,388.9 414.4,367.3 516.3,302.6 						"/>
                </g>
                <g>
                  <polygon class="st38" points="615.5,348.9 567,326.2 465.1,390.9 465.1,390.9 464.8,396.9 513.3,419.5 615.2,354.9 						"/>
                  <polygon class="st14" points="615.5,348.9 513.6,412.5 465.1,390.9 567,326.2 						"/>
                </g>
              </g>
            </g>
            <path class="st29" d="M621.2,343.1v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.7"/>
            <ellipse class="st30" cx="628.4" cy="343.1" rx="7.2" ry="3.6"/>
            <path class="st29" d="M510.6,414.9v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3V414"/>
            <ellipse class="st30" cx="517.8" cy="414.9" rx="7.2" ry="3.6"/>
            <path class="st29" d="M397.2,362.1V395c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-33.7"/>
            <ellipse class="st30" cx="404.4" cy="362.1" rx="7.2" ry="3.6"/>
          </g>
          <g>
            <g>
              <path class="st27" d="M519,303.7l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
                c-2.7-1.3-3-5.1-0.5-6.7L515.3,304C516.4,303.2,517.8,303.2,519,303.7z"/>
              <path class="st15" d="M519,301.3l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
                c-2.7-1.3-3-5.1-0.5-6.7l122.8-79.8C516.4,300.8,517.8,300.8,519,301.3z"/>
              <g>
                <path class="st29" d="M620.5,331.2V364c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.7"/>
                <ellipse class="st30" cx="627.7" cy="331.2" rx="7.2" ry="3.6"/>
                <path class="st29" d="M509.8,403v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.7"/>
                <ellipse class="st30" cx="517.1" cy="403" rx="7.2" ry="3.6"/>
                <path class="st29" d="M509.8,278.4v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.7"/>
                <ellipse class="st30" cx="517.1" cy="278.4" rx="7.2" ry="3.6"/>
                <path class="st29" d="M396.4,350.2v32.8c0,2.4,3.2,4.3,7.2,4.3c4,0,7.2-1.9,7.2-4.3v-33.7"/>
                <ellipse class="st30" cx="403.6" cy="350.2" rx="7.2" ry="3.6"/>
              </g>
            </g>
            <g>
              <path class="st27" d="M519,273l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
                c-2.7-1.3-3-5.1-0.5-6.7l122.8-79.8C516.4,272.6,517.8,272.5,519,273z"/>
              <path class="st15" d="M519,270.6l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
                c-2.7-1.3-3-5.1-0.5-6.7l122.8-79.8C516.4,270.2,517.8,270.1,519,270.6z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st27" d="M518.4,260.4l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
                c-2.7-1.3-3-5.1-0.5-6.7l122.8-79.8C515.8,259.9,517.2,259.8,518.4,260.4z"/>
              <path class="st14" d="M518.4,258l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
                c-2.7-1.3-3-5.1-0.5-6.7l122.8-79.8C515.8,257.5,517.2,257.4,518.4,258z"/>
              <g>
                <path class="st29" d="M509.2,235.1v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.7"/>
                <ellipse class="st30" cx="516.5" cy="235.1" rx="7.2" ry="3.6"/>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="563.5,290.3 515,267.6 413.1,332.3 413,332.3 412.7,338.2 461.2,360.9 563.2,296.2 						"/>
                  <polygon class="st14" points="563.5,290.3 461.6,353.9 413,332.3 515,267.6 						"/>
                </g>
                <g>
                  <polygon class="st38" points="614.2,313.9 565.7,291.2 463.7,355.9 463.7,355.9 463.4,361.9 511.9,384.5 613.9,319.8 						
                    "/>
                  <polygon class="st14" points="614.2,313.9 512.2,377.5 463.7,355.9 565.7,291.2 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="563.5,279.7 515,257 413.1,321.7 413,321.7 412.7,327.6 461.2,350.3 563.2,285.6 						"/>
                  <polygon class="st14" points="563.5,279.7 461.6,343.3 413,321.7 515,257 						"/>
                </g>
                <g>
                  <polygon class="st38" points="614.2,303.3 565.7,280.6 463.7,345.3 463.7,345.3 463.4,351.3 511.9,373.9 613.9,309.2 						
                    "/>
                  <polygon class="st14" points="614.2,303.3 512.2,366.9 463.7,345.3 565.7,280.6 						"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st38" points="563.5,270.1 515,247.4 413.1,312.1 413,312.1 412.7,318 461.2,340.7 563.2,276 						"/>
                  <polygon class="st14" points="563.5,270.1 461.6,333.7 413,312.1 515,247.4 						"/>
                </g>
                <g>
                  <polygon class="st38" points="614.2,293.7 565.7,271 463.7,335.7 463.7,335.7 463.4,341.7 511.9,364.3 613.9,299.6 						"/>
                  <polygon class="st14" points="614.2,293.7 512.2,357.3 463.7,335.7 565.7,271 						"/>
                </g>
              </g>
            </g>
            <path class="st29" d="M619.9,287.9v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3V287"/>
            <ellipse class="st30" cx="627.1" cy="287.9" rx="7.2" ry="3.6"/>
            <path class="st29" d="M509.2,359.7v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.7"/>
            <ellipse class="st30" cx="516.5" cy="359.7" rx="7.2" ry="3.6"/>
            <path class="st29" d="M395.8,306.9v32.8c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3V306"/>
            <ellipse class="st30" cx="403" cy="306.9" rx="7.2" ry="3.6"/>
          </g>
          <path class="st27" d="M518.4,224.3l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
            c-2.7-1.3-3-5.1-0.5-6.7l122.8-79.8C515.8,223.9,517.2,223.8,518.4,224.3z"/>
          <path class="st14" d="M518.4,222l123.4,58.4c2.7,1.3,3,5.1,0.5,6.7l-122.8,79.8c-1.1,0.7-2.5,0.8-3.8,0.3l-123.4-58.4
            c-2.7-1.3-3-5.1-0.5-6.7l122.8-79.8C515.8,221.5,517.2,221.4,518.4,222z"/>
          <polygon class="st39" points="627.1,336 533.4,395 533.4,357.8 627.1,298.8 		"/>
          <polygon class="st39" points="494,394.7 418.3,359.3 418.3,322.1 494,357.5 		"/>
          <line class="st34" x1="428.8" y1="297.1" x2="519.6" y2="238.2"/>
          <line class="st34" x1="443.2" y1="305.2" x2="534" y2="246.3"/>
          <line class="st34" x1="458.7" y1="313.4" x2="549.5" y2="254.5"/>
          <line class="st34" x1="473" y1="321.5" x2="563.8" y2="262.6"/>
          <line class="st34" x1="489.1" y1="329.9" x2="579.9" y2="270.9"/>
          <line class="st34" x1="504.6" y1="338" x2="595.4" y2="279.1"/>
          <line class="st34" x1="519" y1="346.1" x2="609.8" y2="287.2"/>
        </g>
      </g>
      <g id="Batteries_00000012467795205697458520000001028656434886879145_">
        <g id="Batteries">
          <g>
            <path class="st27" d="M519.6,315.5L643.2,374c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
              c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C517,315.1,518.4,315,519.6,315.5z"/>
            <path class="st15" d="M519.6,313.1l123.6,58.5c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3L393.3,400
              c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C517,312.7,518.4,312.6,519.6,313.1z"/>
            <g>
              <path class="st29" d="M510.4,290.2V323c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
              <ellipse class="st30" cx="517.7" cy="290.2" rx="7.2" ry="3.6"/>
            </g>
            <g>
              <g>
                <polygon class="st38" points="564.8,345.4 516.2,322.7 414.1,387.5 414.1,387.5 413.8,393.5 462.4,416.2 564.5,351.4 					"/>
                <polygon class="st14" points="564.8,345.4 462.7,409.1 414.1,387.5 516.2,322.7 					"/>
              </g>
              <g>
                <polygon class="st38" points="615.5,369.1 566.9,346.4 464.9,411.2 464.9,411.2 464.5,417.1 513.1,439.8 615.2,375 					"/>
                <polygon class="st14" points="615.5,369.1 513.4,432.8 464.9,411.2 566.9,346.4 					"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st38" points="564.8,334.8 516.2,312.1 414.1,376.9 414.1,376.9 413.8,382.9 462.4,405.6 564.5,340.8 					"/>
                <polygon class="st14" points="564.8,334.8 462.7,398.5 414.1,376.9 516.2,312.1 					"/>
              </g>
              <g>
                <polygon class="st38" points="615.5,358.5 566.9,335.8 464.9,400.5 464.9,400.5 464.5,406.5 513.1,429.2 615.2,364.4 					"/>
                <polygon class="st14" points="615.5,358.5 513.4,422.2 464.9,400.5 566.9,335.8 					"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st38" points="564.8,325.2 516.2,302.5 414.1,367.3 414.1,367.3 413.8,373.3 462.4,396 564.5,331.2 					"/>
                <polygon class="st14" points="564.8,325.2 462.7,388.9 414.1,367.3 516.2,302.5 					"/>
              </g>
              <g>
                <polygon class="st38" points="615.5,348.9 566.9,326.2 464.9,390.9 464.9,390.9 464.5,396.9 513.1,419.6 615.2,354.8 					"/>
                <polygon class="st14" points="615.5,348.9 513.4,412.6 464.9,390.9 566.9,326.2 					"/>
              </g>
            </g>
          </g>
          <path class="st29" d="M621.2,343.1V376c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
          <ellipse class="st30" cx="628.5" cy="343.1" rx="7.2" ry="3.6"/>
          <path class="st29" d="M510.4,414.9v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3V414"/>
          <ellipse class="st30" cx="517.7" cy="414.9" rx="7.2" ry="3.6"/>
          <path class="st29" d="M396.8,362.1V395c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
          <ellipse class="st30" cx="404.1" cy="362.1" rx="7.2" ry="3.6"/>
        </g>
      </g>
      <g id="EPS-Wire_00000130626868077245116710000012501437988853928881_">
        <g id="EPS-Wire">
          <g>
            <path class="st0" d="M621.6,323.3"/>
            <path class="st0" d="M397.4,352.7l-9.8,6.4l130,61.5c1.1,0.5,2.4,0.4,3.5-0.2l122.6-80.1c2.3-1.5,2.1-4.9-0.3-6.1l-7.1-3.6"/>
          </g>
          <path class="st0" d="M511,385.5v24.4c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-22.1"/>
          <path class="st0" d="M621.8,323.3V338c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-24.1"/>
          <path class="st0" d="M397.4,345.4v11.7c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-4.8"/>
        </g>
      </g>
      <g id="EPSAlt2">
        <g>
          <g>
            <g>
              <path class="st27" d="M520.4,276.2l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
                c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.8,275.7,519.2,275.6,520.4,276.2z"/>
              <path class="st15" d="M520.4,273.7l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
                c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.8,273.3,519.2,273.2,520.4,273.7z"/>
              <g>
                <path class="st29" d="M622.2,303.7v32.9c0,2.4,3.2,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.8"/>
                <ellipse class="st30" cx="629.4" cy="303.7" rx="7.3" ry="3.6"/>
                <path class="st29" d="M511.2,375.7v32.9c0,2.4,3.2,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.8"/>
                <ellipse class="st30" cx="518.5" cy="375.7" rx="7.3" ry="3.6"/>
                <path class="st29" d="M511.2,250.7v32.9c0,2.4,3.2,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.8"/>
                <ellipse class="st30" cx="518.5" cy="250.7" rx="7.3" ry="3.6"/>
                <path class="st29" d="M397.5,322.8v32.9c0,2.4,3.2,4.3,7.3,4.3s7.3-1.9,7.3-4.3v-33.8"/>
                <ellipse class="st30" cx="404.7" cy="322.8" rx="7.3" ry="3.6"/>
              </g>
            </g>
            <g>
              <path class="st27" d="M520.4,245.4L644.2,304c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
                c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.8,244.9,519.2,244.8,520.4,245.4z"/>
              <path class="st15" d="M520.4,243l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3L393.9,330
                c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.8,242.5,519.2,242.4,520.4,243z"/>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path class="st27" d="M519.8,232.7l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
                  c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.2,232.2,518.6,232.1,519.8,232.7z"/>
                <path class="st14" d="M519.8,230.3l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
                  c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.2,229.8,518.6,229.7,519.8,230.3z"/>
                <g>
                  <path class="st29" d="M510.6,207.3v32.9c0,2.4,3.2,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.8"/>
                  <ellipse class="st30" cx="517.9" cy="207.3" rx="7.3" ry="3.6"/>
                </g>
                <g>
                  <g>
                    <polygon class="st38" points="565,262.7 516.4,239.9 414.2,304.8 414.1,304.8 413.8,310.8 462.5,333.5 564.7,268.6 							
                      "/>
                    <polygon class="st14" points="565,262.7 462.8,326.5 414.1,304.8 516.4,239.9 							"/>
                  </g>
                  <g>
                    <polygon class="st38" points="615.9,286.4 567.2,263.6 465,328.5 465,328.5 464.7,334.5 513.3,357.2 615.6,292.3 							"/>
                    <polygon class="st14" points="615.9,286.4 513.6,350.1 465,328.5 567.2,263.6 							"/>
                  </g>
                </g>
                <g>
                  <g>
                    <polygon class="st38" points="565,252 516.4,229.3 414.2,294.2 414.1,294.2 413.8,300.1 462.5,322.9 564.7,258 							"/>
                    <polygon class="st14" points="565,252 462.8,315.8 414.1,294.2 516.4,229.3 							"/>
                  </g>
                  <g>
                    <polygon class="st38" points="615.9,275.7 567.2,253 465,317.8 465,317.8 464.7,323.8 513.3,346.6 615.6,281.7 							"/>
                    <polygon class="st14" points="615.9,275.7 513.6,339.5 465,317.8 567.2,253 							"/>
                  </g>
                </g>
                <g>
                  <g>
                    <polygon class="st38" points="565,242.4 516.4,219.7 414.2,284.5 414.1,284.5 413.8,290.5 462.5,313.3 564.7,248.4 							
                      "/>
                    <polygon class="st14" points="565,242.4 462.8,306.2 414.1,284.5 516.4,219.7 							"/>
                  </g>
                  <g>
                    <polygon class="st38" points="615.9,266.1 567.2,243.3 465,308.2 465,308.2 464.7,314.2 513.3,336.9 615.6,272.1 							"/>
                    <polygon class="st14" points="615.9,266.1 513.6,329.9 465,308.2 567.2,243.3 							"/>
                  </g>
                </g>
              </g>
              <path class="st29" d="M621.6,260.3v32.9c0,2.4,3.2,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.8"/>
              <ellipse class="st30" cx="628.8" cy="260.3" rx="7.3" ry="3.6"/>
              <path class="st29" d="M510.6,332.3v32.9c0,2.4,3.2,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.8"/>
              <ellipse class="st30" cx="517.9" cy="332.3" rx="7.3" ry="3.6"/>
              <path class="st29" d="M396.9,279.4v32.9c0,2.4,3.2,4.3,7.3,4.3s7.3-1.9,7.3-4.3v-33.8"/>
              <ellipse class="st30" cx="404.1" cy="279.4" rx="7.3" ry="3.6"/>
            </g>
            <path class="st27" d="M519.8,196.5l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
              c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.2,196.1,518.6,196,519.8,196.5z"/>
            <path class="st14" d="M519.8,194.1l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
              c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.2,193.7,518.6,193.6,519.8,194.1z"/>
            <polygon class="st39" points="628.8,308.5 534.8,367.7 534.8,330.4 628.8,271.2 			"/>
            <polygon class="st39" points="495.3,367.4 419.4,331.9 419.4,294.6 495.3,330.1 			"/>
            <line class="st34" x1="430" y1="269.5" x2="521" y2="210.4"/>
            <line class="st34" x1="444.4" y1="277.7" x2="535.4" y2="218.5"/>
            <line class="st34" x1="459.9" y1="285.9" x2="551" y2="226.7"/>
            <line class="st34" x1="474.3" y1="294" x2="565.4" y2="234.9"/>
            <line class="st34" x1="490.5" y1="302.4" x2="581.5" y2="243.2"/>
            <line class="st34" x1="506" y1="310.6" x2="597.1" y2="251.4"/>
            <line class="st34" x1="520.4" y1="318.7" x2="611.5" y2="259.6"/>
          </g>
          <g>
            <path class="st27" d="M520.4,181.6l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
              c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.8,181.1,519.2,181,520.4,181.6z"/>
            <path class="st15" d="M520.4,179.2l123.8,58.6c2.7,1.3,3,5.1,0.5,6.7l-123.2,80c-1.1,0.7-2.5,0.8-3.8,0.3l-123.8-58.6
              c-2.7-1.3-3-5.1-0.5-6.7l123.2-80C517.8,178.7,519.2,178.6,520.4,179.2z"/>
          </g>
        </g>
      </g>
      <g id="EPSAlt1">
        <g>
          <path class="st27" d="M520,277l124,58.7c2.7,1.3,3,5.1,0.5,6.8l-123.4,80.1c-1.1,0.7-2.6,0.8-3.8,0.3l-124-58.7
            c-2.7-1.3-3-5.1-0.5-6.8l123.4-80.1C517.4,276.5,518.8,276.4,520,277z"/>
          <path class="st40" d="M520,274.6l124,58.7c2.7,1.3,3,5.1,0.5,6.8l-123.4,80.1c-1.1,0.7-2.6,0.8-3.8,0.3l-124-58.7
            c-2.7-1.3-3-5.1-0.5-6.8l123.4-80.1C517.4,274.1,518.8,274,520,274.6z"/>
          <g>
            <path class="st29" d="M621.9,304.6v33c0,2.4,3.3,4.3,7.3,4.3s7.3-1.9,7.3-4.3v-33.9"/>
            <ellipse class="st30" cx="629.2" cy="304.6" rx="7.3" ry="3.6"/>
            <path class="st29" d="M510.8,376.7v33c0,2.4,3.3,4.3,7.3,4.3s7.3-1.9,7.3-4.3v-33.9"/>
            <ellipse class="st30" cx="518.1" cy="376.7" rx="7.3" ry="3.6"/>
            <path class="st29" d="M510.8,251.6v33c0,2.4,3.3,4.3,7.3,4.3s7.3-1.9,7.3-4.3v-33.9"/>
            <ellipse class="st30" cx="518.1" cy="251.6" rx="7.3" ry="3.6"/>
            <path class="st29" d="M396.9,323.7v33c0,2.4,3.3,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.9"/>
            <ellipse class="st30" cx="404.1" cy="323.7" rx="7.3" ry="3.6"/>
          </g>
          <g>
            <path class="st27" d="M520,246.2l124,58.7c2.7,1.3,3,5.1,0.5,6.8l-123.4,80.1c-1.1,0.7-2.6,0.8-3.8,0.3l-124-58.7
              c-2.7-1.3-3-5.1-0.5-6.8l123.4-80.1C517.4,245.7,518.8,245.6,520,246.2z"/>
            <path class="st40" d="M520,243.8l124,58.7c2.7,1.3,3,5.1,0.5,6.8l-123.4,80.1c-1.1,0.7-2.6,0.8-3.8,0.3l-124-58.7
              c-2.7-1.3-3-5.1-0.5-6.8L516.3,244C517.4,243.3,518.8,243.2,520,243.8z"/>
          </g>
          <g>
            <path class="st29" d="M561.5,310.7v33c0,2.4,3.3,4.3,7.3,4.3s7.3-1.9,7.3-4.3v-33.9"/>
            <ellipse class="st30" cx="568.8" cy="310.7" rx="7.3" ry="3.6"/>
            <path class="st29" d="M510.8,340.2v33c0,2.4,3.3,4.3,7.3,4.3s7.3-1.9,7.3-4.3v-33.9"/>
            <ellipse class="st30" cx="518.1" cy="340.2" rx="7.3" ry="3.6"/>
            <path class="st29" d="M450.4,257.6v33c0,2.4,3.3,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.9"/>
            <ellipse class="st30" cx="457.6" cy="257.6" rx="7.3" ry="3.6"/>
            <path class="st29" d="M396.9,287.2v33c0,2.4,3.3,4.3,7.3,4.3c4,0,7.3-1.9,7.3-4.3v-33.9"/>
            <ellipse class="st30" cx="404.1" cy="287.2" rx="7.3" ry="3.6"/>
          </g>
          <g>
            <path class="st27" d="M457.8,251.2l124,58.7c2.7,1.3,3,5.1,0.5,6.8l-61.1,38.5c-1.1,0.7-2.6,0.8-3.8,0.3l-124-58.7
              c-2.7-1.3-3-5.1-0.5-6.8l61.1-38.5C455.1,250.7,456.5,250.6,457.8,251.2z"/>
            <path class="st15" d="M457.8,248.8l124,58.7c2.7,1.3,3,5.1,0.5,6.8l-61.1,38.5c-1.1,0.7-2.6,0.8-3.8,0.3l-124-58.7
              c-2.7-1.3-3-5.1-0.5-6.8l61.1-38.5C455.1,248.3,456.5,248.2,457.8,248.8z"/>
          </g>
        </g>
      </g>
      <g id="EPS_00000094619831344084582660000002956674696391884936_">
        <g id="EPS">
          <g>
            <path class="st27" d="M519.6,276.7l123.6,58.5c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
              c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C517,276.2,518.4,276.1,519.6,276.7z"/>
            <path class="st15" d="M519.6,274.3l123.6,58.5c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
              c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C517,273.8,518.4,273.7,519.6,274.3z"/>
            <g>
              <path class="st29" d="M621.2,304.2v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
              <ellipse class="st30" cx="628.5" cy="304.2" rx="7.2" ry="3.6"/>
              <path class="st29" d="M510.4,376.1V409c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
              <ellipse class="st30" cx="517.7" cy="376.1" rx="7.2" ry="3.6"/>
              <path class="st29" d="M510.4,251.3v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
              <ellipse class="st30" cx="517.7" cy="251.3" rx="7.2" ry="3.6"/>
              <path class="st29" d="M396.8,323.2v32.9c0,2.4,3.2,4.3,7.2,4.3s7.2-1.9,7.2-4.3v-33.8"/>
              <ellipse class="st30" cx="404.1" cy="323.2" rx="7.2" ry="3.6"/>
            </g>
          </g>
          <g>
            <path class="st27" d="M519.6,245.9l123.6,58.5c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
              c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C517,245.4,518.4,245.4,519.6,245.9z"/>
            <path class="st15" d="M519.6,243.5L643.2,302c2.7,1.3,3,5.1,0.5,6.7l-123,79.9c-1.1,0.7-2.5,0.8-3.8,0.3l-123.6-58.5
              c-2.7-1.3-3-5.1-0.5-6.7l123-79.9C517,243,518.4,243,519.6,243.5z"/>
          </g>
        </g>
      </g>
      <g id="Gyro-Wire_00000070814829936652989480000009587365278185271962_">
        <g id="Gyro-Wire">
          <g>
            <polyline class="st16" points="270,391.2 341.9,421.5 356.6,411.1 			"/>
          </g>
          <g>
            <polyline class="st16" points="406.2,335.2 339.3,306.5 270,349.5 342.9,380.3 354.2,372.5 			"/>
          </g>
          <line class="st16" x1="341.9" y1="421.5" x2="341.9" y2="384.3"/>
          <g>
            <path class="st16" d="M335.5,311.1c-5.5-2.9-14.4-2.4-19.8,1.1s-5.4,8.7,0.1,11.6s14.4,2.4,19.8-1.1S341,314,335.5,311.1"/>
          </g>
          <line class="st16" x1="325.6" y1="317.5" x2="325.6" y2="300.7"/>
          <g>
            <path class="st16" d="M326,354.3c-5.5-2.9-14.4-2.4-19.8,1.1s-5.4,8.7,0.1,11.6s14.4,2.4,19.8-1.1
              C331.5,362.5,331.5,357.3,326,354.3"/>
          </g>
          <line class="st16" x1="316.1" y1="360.7" x2="316.1" y2="343.9"/>
          <g>
            <g>
              <path class="st16" d="M400.5,338.7c-0.6-0.5-1.3-1-2.1-1.4"/>
              <path class="st16" d="M398.4,337.2c-5.5-2.9-14.4-2.4-19.8,1.1s-5.4,8.7,0.1,11.6c1.2,0.6,2.6,1.1,4,1.4"/>
            </g>
            <line class="st16" x1="388.5" y1="343.6" x2="388.5" y2="326.8"/>
          </g>
          <line class="st16" x1="332.1" y1="394.4" x2="332.1" y2="402.3"/>
          <line class="st16" x1="351.9" y1="409" x2="351.9" y2="414.4"/>
        </g>
      </g>
      <g id="Gyro_00000041288630882370783270000006244608335920405641_">
        <g id="Gyro">
          <g>
            <polygon class="st19" points="406.2,335.2 270.1,349 270.1,390.7 342.7,420.6 406.2,375.8 			"/>
          </g>
          <g>
            <polygon class="st41" points="338.1,306.4 270.1,350.1 342.7,380 406.2,335.2 			"/>
          </g>
          <g>
            <polygon class="st13" points="338.1,331.2 316.1,345.4 339.6,355.1 360.2,340.6 			"/>
          </g>
          <line class="st28" x1="342.7" y1="380" x2="342.7" y2="420.6"/>
          <g>
            <g>
              <path class="st42" d="M335.4,312.5c-5.4-3.1-14.2-3.1-19.6,0s-5.3,8.2,0.1,11.3c5.4,3.1,14.2,3.1,19.6,0
                C340.9,320.7,340.8,315.6,335.4,312.5"/>
            </g>
            <path class="st43" d="M335.4,317.3c-5.4-3.1-14.2-3.1-19.6,0c-1.6,0.9-2.7,2.1-3.4,3.2c0.7,1.2,1.8,2.3,3.5,3.3
              c5.4,3.1,14.2,3.1,19.6,0c1.6-0.9,2.7-2.1,3.4-3.3C338.2,319.4,337.1,318.3,335.4,317.3z"/>
          </g>
          <g>
            <g>
              <path class="st42" d="M326.9,355.1c-5.4-3.1-14.2-3.1-19.6,0s-5.3,8.2,0.1,11.3c5.4,3.1,14.2,3.1,19.6,0
                S332.3,358.2,326.9,355.1"/>
            </g>
            <path class="st43" d="M326.9,359.9c-5.4-3.1-14.2-3.1-19.6,0c-1.6,0.9-2.7,2.1-3.4,3.2c0.7,1.2,1.8,2.3,3.5,3.3
              c5.4,3.1,14.2,3.1,19.6,0c1.6-0.9,2.7-2.1,3.4-3.3C329.7,362,328.5,360.9,326.9,359.9z"/>
          </g>
          <g>
            <g>
              <path class="st42" d="M399,338.3c-5.4-3.1-14.2-3.1-19.6,0s-5.3,8.2,0.1,11.3c5.4,3.1,14.2,3.1,19.6,0
                C404.4,346.5,404.4,341.4,399,338.3"/>
            </g>
            <path class="st43" d="M399,343.1c-5.4-3.1-14.2-3.1-19.6,0c-1.6,0.9-2.7,2.1-3.4,3.2c0.7,1.2,1.8,2.3,3.5,3.3
              c5.4,3.1,14.2,3.1,19.6,0c1.6-0.9,2.7-2.1,3.4-3.3C401.8,345.2,400.6,344,399,343.1z"/>
          </g>
          <polyline class="st19" points="282.3,393.5 288.6,386.1 317.1,398.2 319,407.7 		"/>
        </g>
      </g>
      <g id="SunSensor-Wire_00000139984236990534166290000001565220080644609429_">
        <g id="SunSensor-Wire">
          <polygon class="st16" points="346.4,406.5 346.4,381.1 407,408.3 407,433.7 		"/>
          <line class="st16" x1="407" y1="408.3" x2="416.7" y2="400.3"/>
          <line class="st16" x1="346.1" y1="381.3" x2="358.2" y2="372.5"/>
          <line class="st16" x1="407" y1="433.7" x2="416.7" y2="425.8"/>
        </g>
      </g>
      <g id="SunSensor_00000082352712948052772430000001828244663957301410_">
        <g id="SunSensor">
          <polygon class="st38" points="346.4,381.1 356,371.5 416.7,398.6 416.7,424.1 407,433.7 		"/>
          <polygon class="st14" points="346.4,406.5 346.4,381.1 407,408.3 407,433.7 		"/>
          <polygon class="st12" points="352.9,404.1 352.9,387.8 360,391.2 360,407.5 		"/>
        </g>
      </g>
      <g id="SunSensor-Alt" style="visibility: hidden">
        <g id="SunSensor_00000161606091491025806350000007171801198521730440_">
          <polygon class="st38" points="346.4,381.1 356,371.5 416.7,398.6 416.7,424.1 407,433.7 		"/>
          <polygon class="st14" points="346.4,406.5 346.4,381.1 407,408.3 407,433.7 		"/>
          <polygon class="st44" points="352.9,404.1 352.9,387.8 360,391.2 360,407.5 		"/>
        </g>
      </g>
      <g id="Structure-Front-Wire_00000149351977660490236610000013830610807554904501_">
        <g id="Structure-Front-Wire">
          <polyline class="st5" points="664.6,74.8 257.5,323.2 408.3,392.8 408.3,385.6 		"/>
          <polyline class="st5" points="894.2,77.6 876.6,69.7 857.6,82 779.4,55.5 799.1,42.7 742.2,23.1 684.4,60.5 		"/>
          <g>
            <polygon class="st5" points="909,75.8 909.1,101.7 409.8,425.6 409.8,399.7 			"/>
          </g>
          <g>
            <polygon class="st5" points="409.8,425.6 395.1,417.7 395,391.8 409.8,399.7 			"/>
          </g>
          <polygon class="st5" points="893.2,68.6 395,391.8 409.8,399.7 909,75.8 		"/>
          <polygon class="st5" points="857.7,74.5 779.5,48.1 799.2,35.3 742.3,15.7 257.5,315.9 408.3,384.4 893.2,69.6 876.7,62.2 		"/>
          <polyline class="st5" points="408.3,392.9 894.2,77.6 894.2,70.4 		"/>
          <line class="st5" x1="876.7" y1="62.2" x2="876.7" y2="69.5"/>
          <line class="st5" x1="257.5" y1="315.9" x2="257.5" y2="323.2"/>
          <line class="st5" x1="779.5" y1="48.1" x2="779.5" y2="55.5"/>
          <line class="st5" x1="857.7" y1="74.5" x2="857.7" y2="81.7"/>
          <line class="st5" x1="799.2" y1="35.3" x2="799.2" y2="42.7"/>
          <line class="st5" x1="742.2" y1="23.1" x2="742.2" y2="15.7"/>
          <line class="st5" x1="507.7" y1="161" x2="507.7" y2="170.6"/>
          <line class="st5" x1="289.9" y1="338.2" x2="289.9" y2="374"/>
          <path class="st5" d="M470.3,333.4"/>
          <g>
            <g>
              <g>
                <polygon class="st5" points="881.7,228.4 881.7,144.8 907.7,127.7 907.7,105.9 431.3,415.6 431.3,588 907.7,271.3 907.7,211.2 
                            "/>
                <polygon class="st5" points="696.7,357.8 670.9,375 670.9,269.2 696.7,252 					"/>
              </g>
            </g>
            <polyline class="st5" points="433.5,604.1 433.5,567.6 918.4,252.8 918.4,289.3 			"/>
            <line class="st5" x1="926.3" y1="283.9" x2="918.5" y2="289.4"/>
            <polyline class="st5" points="919.4,263.2 926.3,258.2 926.3,283.9 			"/>
            <polyline class="st5" points="433.5,597.4 444.1,590.4 444.1,582.6 438.8,579.2 438.8,571.7 433.5,567.8 			"/>
            <line class="st5" x1="926.3" y1="258.2" x2="918.8" y2="254.7"/>
            <line class="st5" x1="918.4" y1="252.8" x2="907.7" y2="248.4"/>
            <line class="st5" x1="918.4" y1="289.3" x2="433.5" y2="604.1"/>
          </g>
        </g>
      </g>
      <g id="Structure-Front_00000040541454857624908970000007754790229677777584_">
        <g id="Structure-Front">
          <g>
            <polygon class="st6" points="295.4,345.1 295.4,362.7 295.4,508.7 304.2,529.9 280.9,521.2 278.6,340 			"/>
            <g>
              <g>
                <polygon class="st8" points="926.3,258 926.3,283.9 427.1,607.8 427,582 					"/>
              </g>
              <polygon class="st7" points="910.4,250.9 412.2,574.1 427,582 926.3,258 				"/>
              <g>
                <polygon class="st6" points="427.1,607.8 412.3,600 412.2,574.1 427,582 					"/>
              </g>
            </g>
            <g>
              <path class="st6" d="M437.9,544L889,250.3v-21.2l-20,17.7l-6.4-3.6l-1.3-105.8c0,0,27.2-23.1,27.8-19.5
                c0.7,3.6,3.2,11.6,3.2,11.6L873.1,144l2.7,85.1l27.6-5.3l-7.6,33.4l-458,304.4V544H437.9z"/>
              <polygon class="st6" points="401.5,407.2 416.8,429.4 416.8,561.1 409.1,577.4 432.6,588 437.9,419.3 903.4,108.3 903.4,91.9 
                        "/>
              <path class="st8" d="M906.8,125.2v-21.8L430.3,416.6v170.3l476.4-315.5v-62.7l-26,17.2v-83.6L906.8,125.2z M895.9,230.1v23.5
                L442.7,551.4V418.9l442.5-289.4v4.3l-16.3,10.3v102.6L895.9,230.1z"/>
            </g>
            <g>
              <g>
                <polygon class="st8" points="424,402.1 286.3,338.3 286.2,312.4 424,376.2 					"/>
              </g>
              <g>
                <polygon class="st7" points="424,376.2 286.2,312.4 300.1,303.9 437.9,367.7 					"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st8" points="560.8,314 423.1,250.2 423,224.3 560.8,288.1 					"/>
              </g>
              <g>
                <polygon class="st7" points="560.8,288.1 423,224.3 436.9,215.8 574.7,279.6 					"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st8" points="722.7,207.6 585,143.8 584.9,118 722.7,181.7 					"/>
              </g>
              <g>
                <polygon class="st7" points="722.7,181.7 584.9,118 598.8,109.4 736.6,173.2 					"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st8" points="883.3,106.8 733.5,51.4 733.4,25.5 883.2,80.9 					"/>
              </g>
              <g>
                <polygon class="st7" points="883.2,80.9 733.4,25.5 747.4,17 897.1,72.4 					"/>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon class="st8" points="908.1,74.3 908.2,100.1 408.9,424.1 408.8,398.2 						"/>
                </g>
                <polygon class="st7" points="892.2,67.1 394.1,390.3 408.8,398.2 908.1,74.3 					"/>
                <g>
                  <polygon class="st6" points="408.9,424.1 394.1,416.2 394.1,390.3 408.8,398.2 						"/>
                </g>
              </g>
              <circle class="st6" cx="442.7" cy="390.3" r="3.7"/>
              <circle class="st6" cx="590.9" cy="294.4" r="3.7"/>
              <circle class="st6" cx="744.2" cy="196.4" r="3.7"/>
              <circle class="st6" cx="895.9" cy="95.6" r="3.7"/>
            </g>
            <g>
              <circle class="st6" cx="442.7" cy="563.8" r="3.7"/>
              <circle class="st6" cx="590.9" cy="467.9" r="3.7"/>
              <circle class="st6" cx="744.2" cy="369.8" r="3.7"/>
              <circle class="st6" cx="895.9" cy="269" r="3.7"/>
            </g>
          </g>
        </g>
      </g>
      <g id="SolarArray-Near-Wire">
        <g>
          <polyline class="st0" points="580.5,665.6 580.5,672.4 711.6,726.6 1170.9,423.5 1170.9,414.3 		"/>
          <polygon class="st0" points="711.6,719.8 1170.9,416.7 1170.9,407.5 1039.8,351.5 580.5,654.6 580.5,665.6 		"/>
          <polygon class="st0" points="760.8,662.2 652.9,616 601.4,650 716.3,699.1 760.8,669.7 		"/>
          <polygon class="st0" points="820.2,623.3 712.3,577.1 660.8,611.1 775.7,660.2 820.2,630.8 		"/>
          <polygon class="st0" points="879.6,584.4 771.7,538.2 720.2,572.2 835.1,621.3 879.6,591.9 		"/>
          <polygon class="st0" points="939,545.4 831.1,499.3 779.6,533.3 894.5,582.4 939,553 		"/>
          <polygon class="st0" points="1022.8,489.8 914.9,443.7 863.4,477.7 978.3,526.8 1022.8,497.4 		"/>
          <polygon class="st0" points="1082.2,450.9 974.3,404.8 922.8,438.7 1037.7,487.9 1082.2,458.5 		"/>
          <polygon class="st0" points="1141.6,412 1033.7,365.8 982.2,399.8 1097.1,448.9 1141.6,419.5 		"/>
        </g>
        <g>
          <polyline class="st0" points="439.6,598.4 439.6,607.2 570.7,661.4 1029.9,358.3 1029.9,349.1 		"/>
          <polygon class="st0" points="570.7,654.6 1029.9,351.5 1029.9,342.3 898.9,286.3 439.6,589.4 439.6,600.5 		"/>
          <polygon class="st0" points="619.9,597 512,550.9 460.5,584.8 575.3,633.9 619.9,604.5 		"/>
          <polygon class="st0" points="679.3,558.1 571.4,511.9 519.9,545.9 634.8,595 679.3,565.6 		"/>
          <polygon class="st0" points="738.7,519.2 630.8,473 579.3,507 694.2,556.1 738.7,526.7 		"/>
          <polygon class="st0" points="798.1,480.2 690.2,434.1 638.7,468.1 753.6,517.2 798.1,487.8 		"/>
          <polygon class="st0" points="881.9,424.6 774,378.5 722.5,412.5 837.4,461.6 881.9,432.2 		"/>
          <polygon class="st0" points="941.3,385.7 833.4,339.6 781.9,373.6 896.8,422.7 941.3,393.3 		"/>
          <polygon class="st0" points="1000.7,346.8 892.8,300.6 841.3,334.6 956.2,383.7 1000.7,354.3 		"/>
        </g>
      </g>
      <g id="Solar-Near-Wire_00000161617882394504643630000012161311838408544690_">
        <g>
          <g>
            <path class="st0" d="M544.3,472.6v-83c-3.4-3.7-7.2-7.9-10-11.1L495,402.8V513l44.4-29.7L544.3,472.6z"/>
          </g>
          <g>
            <path class="st0" d="M602.5,434.4v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l44.4-29.7L602.5,434.4z"/>
          </g>
          <g>
            <path class="st0" d="M662.5,396.1v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l44.4-29.7L662.5,396.1z"/>
          </g>
          <g>
            <path class="st0" d="M786.9,313.2v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l44.4-29.7L786.9,313.2z"/>
          </g>
          <g>
            <path class="st0" d="M845.9,274.5v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l44.4-29.7L845.9,274.5z"/>
          </g>
        </g>
        <g>
          <polygon class="st0" points="747.5,140.6 639.5,94.5 588.1,128.5 702.9,177.6 747.5,148.2 		"/>
          <polygon class="st0" points="678.2,182.6 570.2,136.4 518.8,170.4 633.6,219.5 678.2,190.1 		"/>
          <polygon class="st0" points="619.8,224.8 511.8,178.6 460.4,212.6 575.2,261.7 619.8,232.3 		"/>
          <polygon class="st0" points="558.4,264.1 450.5,217.9 399,251.9 513.8,301 558.4,271.6 		"/>
          <polygon class="st0" points="497.8,308.3 389.8,262.1 338.4,296.1 453.2,345.2 497.8,315.8 		"/>
        </g>
      </g>
      <g id="SolarArray-Near">
        <g>
          <g>
            <g>
              <polygon class="st1" points="569.5,662.2 1028.8,359.1 1028.8,350 897.8,293.9 438.5,597.1 438.5,608.1 				"/>
              <polygon class="st2" points="569.5,655.4 1028.8,352.3 1028.8,343.2 897.8,287.1 438.5,590.3 438.5,601.3 				"/>
            </g>
            <polygon class="st3" points="618.8,597.8 510.8,551.7 459.4,585.7 574.2,634.8 618.8,605.4 			"/>
            <polygon class="st3" points="678.2,558.9 570.2,512.8 518.8,546.7 633.6,595.9 678.2,566.5 			"/>
            <polygon class="st3" points="737.6,520 629.6,473.8 578.2,507.8 693,556.9 737.6,527.5 			"/>
            <polygon class="st3" points="797,481.1 689.1,434.9 637.6,468.9 752.4,518 797,488.6 			"/>
            <polygon class="st3" points="880.8,425.5 772.9,379.3 721.4,413.3 836.2,462.4 880.8,433 			"/>
            <polygon class="st3" points="940.2,386.6 832.3,340.4 780.8,374.4 895.6,423.5 940.2,394.1 			"/>
            <polygon class="st3" points="999.6,347.6 891.7,301.5 840.2,335.5 955,384.6 999.6,355.2 			"/>
            <g>
              <line class="st4" x1="557.2" y1="634.8" x2="571.6" y2="625.3"/>
              <line class="st4" x1="461.1" y1="593.2" x2="475.6" y2="583.8"/>
            </g>
            <g>
              <line class="st4" x1="618.9" y1="594.2" x2="633.4" y2="584.8"/>
              <line class="st4" x1="522.9" y1="552.7" x2="537.4" y2="543.2"/>
            </g>
            <g>
              <line class="st4" x1="680" y1="554.3" x2="694.5" y2="544.9"/>
              <line class="st4" x1="584" y1="512.8" x2="598.5" y2="503.3"/>
            </g>
            <g>
              <line class="st4" x1="736.8" y1="515.4" x2="751.3" y2="505.9"/>
              <line class="st4" x1="640.8" y1="473.8" x2="655.3" y2="464.4"/>
            </g>
            <g>
              <line class="st4" x1="821.5" y1="460.4" x2="836" y2="451"/>
              <line class="st4" x1="725.5" y1="418.9" x2="740" y2="409.4"/>
            </g>
            <g>
              <line class="st4" x1="880.9" y1="421" x2="895.4" y2="411.5"/>
              <line class="st4" x1="784.9" y1="379.4" x2="799.4" y2="369.9"/>
            </g>
            <g>
              <line class="st4" x1="942.5" y1="382.2" x2="957" y2="372.7"/>
              <line class="st4" x1="846.5" y1="340.6" x2="861" y2="331.1"/>
            </g>
          </g>
          <g>
            <g>
              <polygon class="st1" points="712.2,726.5 1171.5,423.4 1171.5,414.2 1040.4,358.2 581.2,661.3 581.2,672.3 				"/>
              <polygon class="st2" points="712.2,719.7 1171.5,416.6 1171.5,407.4 1040.4,351.4 581.2,654.5 581.2,665.5 				"/>
            </g>
            <polygon class="st3" points="761.4,662.1 653.5,615.9 602,649.9 716.9,699 761.4,669.6 			"/>
            <polygon class="st3" points="820.8,623.2 712.9,577 661.4,611 776.3,660.1 820.8,630.7 			"/>
            <polygon class="st3" points="880.2,584.3 772.3,538.1 720.8,572.1 835.7,621.2 880.2,591.8 			"/>
            <polygon class="st3" points="939.6,545.3 831.7,499.2 780.2,533.2 895.1,582.3 939.6,552.9 			"/>
            <polygon class="st3" points="1023.4,489.7 915.5,443.6 864,477.6 978.9,526.7 1023.4,497.3 			"/>
            <polygon class="st3" points="1082.8,450.8 974.9,404.7 923.4,438.6 1038.3,487.8 1082.8,458.3 			"/>
            <polygon class="st3" points="1142.3,411.9 1034.3,365.7 982.9,399.7 1097.7,448.8 1142.3,419.4 			"/>
            <g>
              <line class="st4" x1="699.8" y1="699" x2="714.3" y2="689.6"/>
              <line class="st4" x1="603.8" y1="657.5" x2="618.3" y2="648"/>
            </g>
            <g>
              <line class="st4" x1="761.6" y1="658.5" x2="776.1" y2="649"/>
              <line class="st4" x1="665.6" y1="616.9" x2="680.1" y2="607.5"/>
            </g>
            <g>
              <line class="st4" x1="822.7" y1="618.6" x2="837.2" y2="609.1"/>
              <line class="st4" x1="726.7" y1="577" x2="741.2" y2="567.6"/>
            </g>
            <g>
              <line class="st4" x1="879.5" y1="579.6" x2="894" y2="570.2"/>
              <line class="st4" x1="783.5" y1="538.1" x2="798" y2="528.6"/>
            </g>
            <g>
              <line class="st4" x1="964.2" y1="524.7" x2="978.7" y2="515.2"/>
              <line class="st4" x1="868.2" y1="483.1" x2="882.7" y2="473.7"/>
            </g>
            <g>
              <line class="st4" x1="1023.6" y1="485.2" x2="1038.1" y2="475.7"/>
              <line class="st4" x1="927.6" y1="443.7" x2="942.1" y2="434.2"/>
            </g>
            <g>
              <line class="st4" x1="1085.1" y1="446.4" x2="1099.6" y2="436.9"/>
              <line class="st4" x1="989.1" y1="404.9" x2="1003.6" y2="395.4"/>
            </g>
          </g>
        </g>
      </g>
      <g id="SolarArray-Near-Alt">
        <g>
          <g>
            <g>
              <polygon class="st1" points="569.5,662.2 1028.8,359.1 1028.8,350 897.8,293.9 438.5,597.1 438.5,608.1 				"/>
              <polygon class="st2" points="569.5,655.4 1028.8,352.3 1028.8,343.2 897.8,287.1 438.5,590.3 438.5,601.3 				"/>
            </g>
            <polygon class="st3" points="618.8,597.8 510.8,551.7 459.4,585.7 574.2,634.8 618.8,605.4 			"/>
            <polygon class="st3" points="678.2,558.9 570.2,512.8 518.8,546.7 633.6,595.9 678.2,566.5 			"/>
            <polygon class="st3" points="737.6,520 629.6,473.8 578.2,507.8 693,556.9 737.6,527.5 			"/>
            <polygon class="st3" points="797,481.1 689.1,434.9 637.6,468.9 752.4,518 797,488.6 			"/>
            <polygon class="st3" points="880.8,425.5 772.9,379.3 721.4,413.3 836.2,462.4 880.8,433 			"/>
            <polygon class="st3" points="940.2,386.6 832.3,340.4 780.8,374.4 895.6,423.5 940.2,394.1 			"/>
            <polygon class="st3" points="999.6,347.6 891.7,301.5 840.2,335.5 955,384.6 999.6,355.2 			"/>
            <g>
              <line class="st4" x1="557.2" y1="634.8" x2="571.6" y2="625.3"/>
              <line class="st4" x1="461.1" y1="593.2" x2="475.6" y2="583.8"/>
            </g>
            <g>
              <line class="st4" x1="618.9" y1="594.2" x2="633.4" y2="584.8"/>
              <line class="st4" x1="522.9" y1="552.7" x2="537.4" y2="543.2"/>
            </g>
            <g>
              <line class="st4" x1="680" y1="554.3" x2="694.5" y2="544.9"/>
              <line class="st4" x1="584" y1="512.8" x2="598.5" y2="503.3"/>
            </g>
            <g>
              <line class="st4" x1="736.8" y1="515.4" x2="751.3" y2="505.9"/>
              <line class="st4" x1="640.8" y1="473.8" x2="655.3" y2="464.4"/>
            </g>
            <g>
              <line class="st4" x1="821.5" y1="460.4" x2="836" y2="451"/>
              <line class="st4" x1="725.5" y1="418.9" x2="740" y2="409.4"/>
            </g>
            <g>
              <line class="st4" x1="880.9" y1="421" x2="895.4" y2="411.5"/>
              <line class="st4" x1="784.9" y1="379.4" x2="799.4" y2="369.9"/>
            </g>
            <g>
              <line class="st4" x1="942.5" y1="382.2" x2="957" y2="372.7"/>
              <line class="st4" x1="846.5" y1="340.6" x2="861" y2="331.1"/>
            </g>
          </g>
        </g>
      </g>
      <g id="Solar-Near">
        <g>
          <g>
            <path class="st27" d="M842.7,273.4v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l2.4,2.4l42-32.1L842.7,273.4z"/>
          </g>
          <g>
            <path class="st45" d="M845.2,275.8v-83c-3.4-3.7-7.2-7.9-10-11.1L796,205.9v110.2l44.4-29.7L845.2,275.8z"/>
          </g>
        </g>
        <g>
          <g>
            <path class="st27" d="M784.1,309.4v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l2.4,2.4l42-32.1L784.1,309.4z"/>
          </g>
          <g>
            <path class="st45" d="M786.5,311.8v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l44.4-29.7L786.5,311.8z"/>
          </g>
        </g>
        <g>
          <g>
            <path class="st27" d="M659.6,394.3v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l2.4,2.4l42-32.1L659.6,394.3z"/>
          </g>
          <g>
            <path class="st45" d="M662,396.7v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2V437l44.4-29.7L662,396.7z"/>
          </g>
        </g>
        <g>
          <g>
            <path class="st27" d="M600.9,430.3v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l2.4,2.4l42-32.1L600.9,430.3z"/>
          </g>
          <g>
            <path class="st45" d="M603.3,432.7v-83c-3.4-3.7-7.2-7.9-10-11.1L554,362.9v110.2l44.4-29.7L603.3,432.7z"/>
          </g>
        </g>
        <g>
          <polygon class="st27" points="747,143.8 639.1,97.6 587.6,128 587.6,131.6 702.5,180.7 747,151.3 		"/>
          <polygon class="st45" points="747,140.2 639.1,94 587.6,128 702.5,177.1 747,147.7 		"/>
        </g>
        <g>
          <polygon class="st27" points="678.2,186.2 570.2,140 518.8,170.4 518.8,174 633.6,223.1 678.2,193.7 		"/>
          <polygon class="st45" points="678.2,182.6 570.2,136.4 518.8,170.4 633.6,219.5 678.2,190.1 		"/>
        </g>
        <g>
          <polygon class="st27" points="621.2,227.9 513.3,181.8 461.8,212.1 461.8,215.7 576.7,264.9 621.2,235.5 		"/>
          <polygon class="st45" points="621.2,224.3 513.3,178.2 461.8,212.1 576.7,261.3 621.2,231.9 		"/>
        </g>
        <g>
          <polygon class="st27" points="558.4,267.7 450.5,221.5 399,251.9 399,255.5 513.8,304.6 558.4,275.2 		"/>
          <polygon class="st45" points="558.4,264.1 450.5,217.9 399,251.9 513.8,301 558.4,271.6 		"/>
        </g>
        <g>
          <polygon class="st27" points="497.9,311.9 390,265.7 338.5,296.1 338.5,299.7 453.4,348.8 497.9,319.4 		"/>
          <polygon class="st45" points="497.9,308.3 390,262.1 338.5,296.1 453.4,345.2 497.9,315.8 		"/>
        </g>
        <g>
          <g>
            <path class="st27" d="M542.4,468.5v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l2.4,2.4l42-32.1L542.4,468.5z"/>
          </g>
          <g>
            <path class="st45" d="M544.8,471v-83c-3.4-3.7-7.2-7.9-10-11.1l-39.2,24.2v110.2l44.4-29.7L544.8,471z"/>
          </g>
        </g>
      </g>
      <g id="Solar-Near-Alt5up">
        <g>
          <polygon class="st27" points="747,143.8 639.1,97.6 587.6,128 587.6,131.6 702.5,180.7 747,151.3 		"/>
          <polygon class="st45" points="747,140.2 639.1,94 587.6,128 702.5,177.1 747,147.7 		"/>
        </g>
        <g>
          <polygon class="st27" points="678.2,186.2 570.2,140 518.8,170.4 518.8,174 633.6,223.1 678.2,193.7 		"/>
          <polygon class="st45" points="678.2,182.6 570.2,136.4 518.8,170.4 633.6,219.5 678.2,190.1 		"/>
        </g>
        <g>
          <polygon class="st27" points="621.2,227.9 513.3,181.8 461.8,212.1 461.8,215.7 576.7,264.9 621.2,235.5 		"/>
          <polygon class="st45" points="621.2,224.3 513.3,178.2 461.8,212.1 576.7,261.3 621.2,231.9 		"/>
        </g>
        <g>
          <polygon class="st27" points="558.4,267.7 450.5,221.5 399,251.9 399,255.5 513.8,304.6 558.4,275.2 		"/>
          <polygon class="st45" points="558.4,264.1 450.5,217.9 399,251.9 513.8,301 558.4,271.6 		"/>
        </g>
        <g>
          <polygon class="st27" points="497.9,311.9 390,265.7 338.5,296.1 338.5,299.7 453.4,348.8 497.9,319.4 		"/>
          <polygon class="st45" points="497.9,308.3 390,262.1 338.5,296.1 453.4,345.2 497.9,315.8 		"/>
        </g>
      </g>
      <g id="Solar-Near-AltSingle">
        <g>
          <polygon class="st27" points="621.2,227.9 513.3,181.8 461.8,212.1 461.8,215.7 576.7,264.9 621.2,235.5 		"/>
          <polygon class="st45" points="621.2,224.3 513.3,178.2 461.8,212.1 576.7,261.3 621.2,231.9 		"/>
        </g>
      </g>
      <g id="Imager-Wire_00000109745999800223606760000004242855977902354563_">
        <g id="Imager-Wire">
          <path class="st46" d="M291.7,592.2l-56.5-25.3c-7.1-3.2-11.7-10.2-11.7-18v-65.8c0-5.8,5.9-9.6,11.2-7.3l52.2,23.4
            c8.4,3.8,13.9,12.2,13.9,21.4v65.6C300.8,591,296,594.1,291.7,592.2z"/>
          <line class="st46" x1="228.6" y1="475.8" x2="303.6" y2="428.8"/>
          <polyline class="st46" points="242.7,466.9 242.7,325.6 432.1,410.5 432.1,603.9 342.4,563.8 		"/>
          <path class="st46" d="M357,375.3c0-10.5,10.8-17.5,20.4-13.2l76.1,34.1c5.2,2.3,8.6,7.5,8.6,13.2v91.8c0,10.5-10.8,17.5-20.4,13.2
            L432,510"/>
          <path class="st46" d="M303.6,428.8c1.9-0.8,4.2-0.9,6.4,0.1l52.2,23.4c8.4,3.8,13.9,12.2,13.9,21.4v65.6c0,2.2-1.1,4.1-2.6,5.2
            l0,0L298.8,591"/>
          <line class="st46" x1="285.1" y1="498.4" x2="359.7" y2="451.9"/>
          <line class="st46" x1="301.4" y1="525.9" x2="376" y2="479.4"/>
          <path class="st46" d="M286.8,516.7c-11-16.7-26.9-26.3-38.8-24.7c-8.7,9.4-6.8,29.8,5.3,48.1c11,16.7,26.9,26.3,38.8,24.7
            C300.8,555.3,298.9,535,286.8,516.7z"/>
          <path class="st46" d="M248.1,492l-14.8,10.6c-6.3,10.3-3.7,28.8,7.3,45.5c11.3,17.2,27.9,27,39.9,24.5l11.6-7.9"/>
          <polyline class="st46" points="283.1,441.6 283.1,420.9 262.2,370.6 262.2,360.7 272.7,366 297.5,401.2 297.5,432.6 		"/>
          <polyline class="st46" points="262.2,360.7 293.4,344.3 303.9,349.6 328.7,384.7 328.7,435.6 		"/>
          <line class="st46" x1="272.7" y1="366" x2="303.9" y2="349.6"/>
          <line class="st46" x1="297.5" y1="401.2" x2="328.7" y2="384.7"/>
          <polyline class="st46" points="376,473.7 376,435.6 414.2,412.4 427.6,412.4 418.4,424 392.4,466.9 392.4,545 422.3,599.5 
            422.3,611.8 409.5,605.8 365.8,549.2 		"/>
          <line class="st46" x1="427.6" y1="412.4" x2="460.1" y2="392.1"/>
          <line class="st46" x1="424.9" y1="446.6" x2="392.4" y2="466.9"/>
          <line class="st46" x1="392.4" y1="545" x2="424.9" y2="524.7"/>
          <line class="st46" x1="454.8" y1="579.2" x2="422.3" y2="599.5"/>
          <path class="st46" d="M422.3,611.8l32.5-20.3v-12.3l-29.9-54.5v-78.1l26-42.9l9.1-11.6h-13.4l-38.2,23.2l-16.8-3.6
            c-15.1-3.2-28.5-12.1-37.2-24.9l-37.7-55.4l-23.4,12.8"/>
          <path class="st46" d="M400,421l-14.4-3.1c-15.1-3.2-28.5-12.1-37.2-24.9l-38.9-57.6"/>
          <path class="st46" d="M441.7,514.4c1.4,2.9,27.1,60.5,27.1,60.5l-13.9,10.2"/>
          <line class="st46" x1="432.1" y1="514.4" x2="462.1" y2="579.2"/>
        </g>
      </g>
      <g id="ImagerAlt3">
        <g>
          <g>
            <path class="st17" d="M453.8,584.7l13.9-10.2c0,0-25.7-57.7-27.1-60.6l-8.7-24c0,0-28.1,1.8-27.2,3.7c0.9,1.8-3.7,47.6-3.7,47.6
              s17.6,37.1,20.2,37.8C423.9,579.4,453.8,584.7,453.8,584.7L453.8,584.7z"/>
            <line class="st47" x1="431.1" y1="513.9" x2="461.1" y2="578.8"/>
            <path class="st1" d="M360.6,372.2c0-10.5,10.8-17.6,20.5-13.3l76.2,34.1c5.2,2.3,8.6,7.5,8.6,13.3v91.9
              c0,10.5-10.8,17.6-20.5,13.3l-9.7-4.4L360.6,372.2z"/>
            <path class="st12" d="M355.8,374.6c0-10.5,10.8-17.6,20.5-13.3l76.2,34.1c5.2,2.3,8.6,7.5,8.6,13.3v91.9
              c0,10.5-10.8,17.6-20.5,13.3l-9.7-4.4L355.8,374.6z"/>
            <line class="st48" x1="426.5" y1="411.8" x2="459" y2="391.5"/>
            <path class="st49" d="M268.9,377.6l139.6,227.8l12.8,6l32.5-20.3v-12.3l-29.9-54.6v-78.1l26.1-43l9.2-11.6h-13.4l-38.2,23.2
              l-16.8-3.6c-15.2-3.2-28.5-12.1-37.2-24.9l-37.8-55.4l-24.7,13.1l-30,16.1L268.9,377.6L268.9,377.6z"/>
            <path class="st47" d="M398.9,420.5l-14.4-3.1c-15.2-3.2-28.5-12.1-37.2-24.9l-38.9-57.7"/>
            <line class="st47" x1="271.4" y1="365.4" x2="311.9" y2="344"/>
            <line class="st47" x1="296.3" y1="400.6" x2="327.6" y2="384.1"/>
            <line class="st47" x1="423.9" y1="446.1" x2="391.3" y2="466.4"/>
            <polyline class="st47" points="453.8,578.8 421.2,599.1 421.2,611.4 			"/>
            <polyline class="st47" points="459,391.5 442.8,397.9 404.7,425.3 			"/>
            <polyline class="st47" points="419.8,597.3 391.3,544.6 423.9,524.2 			"/>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <defs>
                      <polygon id="SVGID_00000034084456192258910390000004562516077117238195_" points="269.1,299.1 484.5,382.8 478.7,584.4 
                        417.1,613.4 242.4,531.9 236.3,323.6 								"/>
                    </defs>
                    <clipPath id="SVGID_00000108988411905645022920000005605715893080325038_">
                      <use  style="overflow:visible;"/>
                    </clipPath>
                    <g style="clip-path:url(#SVGID_00000108988411905645022920000005605715893080325038_);">
                      <polygon class="st6" points="295.4,345.1 295.4,362.7 295.4,508.7 304.2,529.9 280.9,521.2 278.6,340 								"/>
                      <g>
                        <g>
                          <polygon class="st8" points="926.3,258 926.3,283.9 427.1,607.8 427,582 										"/>
                        </g>
                        <polygon class="st7" points="910.4,250.9 412.2,574.1 427,582 926.3,258 									"/>
                        <g>
                          <polygon class="st6" points="427.1,607.8 412.3,600 412.2,574.1 427,582 										"/>
                        </g>
                      </g>
                      <g>
                        <path class="st6" d="M437.9,544L889,250.3v-21.2l-20,17.7l-6.4-3.6l-1.3-105.8c0,0,27.2-23.1,27.8-19.5
                          c0.7,3.6,3.2,11.6,3.2,11.6L873.1,144l2.7,85.1l27.6-5.3l-7.6,33.4l-458,304.4V544H437.9z"/>
                        <polygon class="st6" points="401.5,407.2 416.8,429.4 416.8,561.1 409.1,577.4 432.6,588 437.9,419.3 903.4,108.3 
                          903.4,91.9 									"/>
                        <path class="st8" d="M906.8,125.2v-21.8L430.3,416.6v170.3l476.4-315.5v-62.7l-26,17.2v-83.6L906.8,125.2z M895.9,230.1
                          v23.5L442.7,551.4V418.9l442.5-289.4v4.3l-16.3,10.3v102.6L895.9,230.1z"/>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="424,402.1 286.3,338.3 286.2,312.4 424,376.2 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="424,376.2 286.2,312.4 300.1,303.9 437.9,367.7 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="562.8,308.7 413,253.3 413,227.5 562.7,282.8 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="562.7,282.8 413,227.5 426.9,218.9 576.7,274.3 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="726.5,207.3 576.7,151.9 576.7,126 726.4,181.4 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="726.4,181.4 576.7,126 590.6,117.5 740.4,172.9 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="883.3,106.8 733.5,51.4 733.4,25.5 883.2,80.9 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="883.2,80.9 733.4,25.5 747.4,17 897.1,72.4 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="st8" points="908.1,74.3 908.2,100.1 408.9,424.1 408.8,398.2 											"/>
                          </g>
                          <polygon class="st7" points="892.2,67.1 394.1,390.3 408.8,398.2 908.1,74.3 										"/>
                          <g>
                            <polygon class="st6" points="408.9,424.1 394.1,416.2 394.1,390.3 408.8,398.2 											"/>
                          </g>
                        </g>
                        <circle class="st6" cx="442.7" cy="390.3" r="3.7"/>
                        <circle class="st6" cx="590.9" cy="294.4" r="3.7"/>
                        <circle class="st6" cx="744.2" cy="196.4" r="3.7"/>
                        <circle class="st6" cx="895.9" cy="95.6" r="3.7"/>
                      </g>
                      <g>
                        <circle class="st6" cx="442.7" cy="563.8" r="3.7"/>
                        <circle class="st6" cx="590.9" cy="467.9" r="3.7"/>
                        <circle class="st6" cx="744.2" cy="369.8" r="3.7"/>
                        <circle class="st6" cx="895.9" cy="269" r="3.7"/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <polyline class="st17" points="241.4,466.4 241.4,325 431.1,409.9 431.1,603.6 341.3,563.3 		"/>
          <g>
            <path class="st51" d="M275.3,433.4c1.9-0.8,4.2-0.9,6.4,0.1l52.3,23.4c8.4,3.8,13.9,12.2,13.9,21.4V544c0,2.2-1.1,4.1-2.6,5.2
              l0,0l-74.7,46.5l-7.1,1.2l-63.2-116.5L275.3,433.4"/>
            <path class="st17" d="M263.3,597l-56.5-25.3c-7.1-3.2-11.7-10.2-11.7-18v-65.8c0-5.8,6-9.6,11.2-7.3l52.3,23.4
              c8.4,3.8,13.9,12.2,13.9,21.4V591C272.4,595.7,267.6,598.9,263.3,597L263.3,597z"/>
            <line class="st47" x1="273.1" y1="530.6" x2="347.8" y2="484.1"/>
            <path class="st51" d="M219.7,496.7l-14.8,10.7c-6.3,10.3-3.7,28.8,7.3,45.6c11.3,17.2,27.9,27,39.9,24.5l11.6-7.9L219.7,496.7z"
              />
            <path class="st52" d="M258.5,521.4c-11-16.7-26.9-26.3-38.8-24.7c-8.7,9.5-6.8,29.9,5.3,48.2c11,16.7,26.9,26.3,38.8,24.7
              C272.5,560.1,270.5,539.7,258.5,521.4z"/>
            <path class="st47" d="M258.5,521.4c-11-16.7-26.9-26.3-38.8-24.7l-14.8,10.7c-6.3,10.3-3.7,28.8,7.3,45.6
              c11.3,17.2,27.9,27,39.9,24.5l11.6-7.9C272.5,560.1,270.5,539.7,258.5,521.4L258.5,521.4z"/>
            <path class="st28" d="M333.9,456.9l-52.3-23.4c-2.2-1-4.4-0.9-6.4-0.1l0,0l-75.1,47c-2.9,1.1-5,3.9-5,7.4v65.8
              c0,7.8,4.6,14.8,11.7,18l56.5,25.3c2.6,1.1,5.3,0.5,7.1-1.2l74.7-46.6l0,0c1.6-1.1,2.6-3,2.6-5.2v-65.7
              C347.7,469,342.3,460.6,333.9,456.9L333.9,456.9z"/>
          </g>
          <g>
            <path class="st53" d="M386.4,534.1V497c0-5.2-3.1-10-7.8-12.1L349,471.6c-1.2-0.6-2.5-0.5-3.6,0l-28.2,19.5l-42.5,26.6
              c-1.6,0.6-2.8,2.2-2.8,4.2V559c0,4.4,2.6,8.4,6.6,10.2l31.9,14.3c1.5,0.6,3,0.3,4-0.7l70.4-45.8L386.4,534.1L386.4,534.1z"/>
            <path class="st17" d="M310.4,583.5l-31.9-14.3c-4-1.8-6.6-5.8-6.6-10.2v-37.2c0-3.3,3.4-5.4,6.3-4.1l29.5,13.2
              c4.8,2.1,7.8,6.9,7.8,12.1v37.1C315.6,582.8,312.9,584.6,310.4,583.5L310.4,583.5z"/>
            <line class="st47" x1="323.9" y1="530.4" x2="378.3" y2="496.5"/>
            <path class="st51" d="M285.8,526.8l-8.4,6c-3.6,5.8-2.1,16.3,4.1,25.8c6.4,9.7,15.8,15.2,22.6,13.9l6.6-4.5L285.8,526.8z"/>
            <path class="st52" d="M307.7,540.8c-6.2-9.4-15.2-14.9-21.9-13.9c-4.9,5.3-3.8,16.9,3,27.2c6.2,9.4,15.2,14.9,21.9,13.9
              C315.6,562.6,314.5,551.1,307.7,540.8z"/>
            <path class="st47" d="M307.7,540.8c-6.2-9.4-15.2-14.9-21.9-13.9l-8.4,6c-3.6,5.8-2.1,16.3,4.1,25.8c6.4,9.7,15.8,15.2,22.6,13.9
              l6.6-4.5C315.7,562.6,314.5,551.1,307.7,540.8L307.7,540.8z"/>
          </g>
          <path class="st52" d="M403.9,440.7l-143-58.5c-3.8-1.6-6.3-5.3-6.3-9.5l0,0c0-7.3,7.4-12.2,14.1-9.5l143,58.5
            c3.8,1.6,6.3,5.3,6.3,9.5l0,0C418,438.5,410.6,443.4,403.9,440.7L403.9,440.7z"/>
        </g>
      </g>
      <g id="ImagerAlt2">
        <g>
          <g>
            <path class="st1" d="M453.8,584.6l13.9-10.2c0,0-25.7-57.7-27.1-60.6l-8.7-24c0,0-28.1,1.8-27.2,3.7c0.9,1.8-3.7,47.6-3.7,47.6
              s17.6,37.1,20.2,37.8C423.9,579.3,453.8,584.6,453.8,584.6L453.8,584.6z"/>
            <line class="st47" x1="431.1" y1="513.8" x2="461.1" y2="578.7"/>
            <path class="st1" d="M360.6,372.1c0-10.5,10.8-17.6,20.5-13.3l76.2,34.1c5.2,2.3,8.6,7.5,8.6,13.3v91.9
              c0,10.5-10.8,17.6-20.5,13.3l-9.7-4.4L360.6,372.1z"/>
            <path class="st12" d="M355.8,374.5c0-10.5,10.8-17.6,20.5-13.3l76.2,34.1c5.2,2.3,8.6,7.5,8.6,13.3v91.9
              c0,10.5-10.8,17.6-20.5,13.3l-9.7-4.4L355.8,374.5z"/>
            <line class="st48" x1="426.5" y1="411.7" x2="459" y2="391.4"/>
            <path class="st49" d="M268.9,377.5l139.6,227.8l12.8,6l32.5-20.3v-12.3l-29.9-54.6V446l26.1-43l9.2-11.6h-13.4l-38.2,23.2
              l-16.8-3.6c-15.2-3.2-28.5-12.1-37.2-24.9l-37.8-55.4l-24.7,13.1l-30,16.1L268.9,377.5L268.9,377.5z"/>
            <path class="st47" d="M398.9,420.4l-14.4-3.1c-15.2-3.2-28.5-12.1-37.2-24.9l-38.9-57.7"/>
            <line class="st47" x1="271.4" y1="365.3" x2="311.9" y2="343.9"/>
            <line class="st47" x1="296.3" y1="400.5" x2="327.5" y2="384"/>
            <line class="st47" x1="423.8" y1="446" x2="391.3" y2="466.3"/>
            <polyline class="st47" points="453.8,578.7 421.2,599 421.2,611.3 			"/>
            <polyline class="st47" points="459,391.4 442.8,397.8 404.7,425.2 			"/>
            <polyline class="st47" points="419.8,597.2 391.3,544.5 423.8,524.1 			"/>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <defs>
                      <polygon id="SVGID_00000119811494143517507950000007188282694724456109_" points="269.1,299.1 484.5,382.8 478.7,584.4 
                        417.1,613.4 242.4,531.9 236.3,323.6 								"/>
                    </defs>
                    <clipPath id="SVGID_00000070820881523609080260000013797119475005603746_">
                      <use  style="overflow:visible;"/>
                    </clipPath>
                    <g style="clip-path:url(#SVGID_00000070820881523609080260000013797119475005603746_);">
                      <polygon class="st6" points="295.4,345.1 295.4,362.7 295.4,508.7 304.2,529.9 280.9,521.2 278.6,340 								"/>
                      <g>
                        <g>
                          <polygon class="st8" points="926.3,258 926.3,283.9 427.1,607.8 427,582 										"/>
                        </g>
                        <polygon class="st7" points="910.4,250.9 412.2,574.1 427,582 926.3,258 									"/>
                        <g>
                          <polygon class="st6" points="427.1,607.8 412.3,600 412.2,574.1 427,582 										"/>
                        </g>
                      </g>
                      <g>
                        <path class="st6" d="M437.9,544L889,250.3v-21.2l-20,17.7l-6.4-3.6l-1.3-105.8c0,0,27.2-23.1,27.8-19.5
                          c0.7,3.6,3.2,11.6,3.2,11.6L873.1,144l2.7,85.1l27.6-5.3l-7.6,33.4l-458,304.4V544H437.9z"/>
                        <polygon class="st6" points="401.5,407.2 416.8,429.4 416.8,561.1 409.1,577.4 432.6,588 437.9,419.3 903.4,108.3 
                          903.4,91.9 									"/>
                        <path class="st8" d="M906.8,125.2v-21.8L430.3,416.6v170.3l476.4-315.5v-62.7l-26,17.2v-83.6L906.8,125.2z M895.9,230.1
                          v23.5L442.7,551.4V418.9l442.5-289.4v4.3l-16.3,10.3v102.6L895.9,230.1z"/>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="424,402.1 286.3,338.3 286.2,312.4 424,376.2 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="424,376.2 286.2,312.4 300.1,303.9 437.9,367.7 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="562.8,308.7 413,253.3 413,227.5 562.7,282.8 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="562.7,282.8 413,227.5 426.9,218.9 576.7,274.3 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="726.5,207.3 576.7,151.9 576.7,126 726.4,181.4 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="726.4,181.4 576.7,126 590.6,117.5 740.4,172.9 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="883.3,106.8 733.5,51.4 733.4,25.5 883.2,80.9 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="883.2,80.9 733.4,25.5 747.4,17 897.1,72.4 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="st8" points="908.1,74.3 908.2,100.1 408.9,424.1 408.8,398.2 											"/>
                          </g>
                          <polygon class="st7" points="892.2,67.1 394.1,390.3 408.8,398.2 908.1,74.3 										"/>
                          <g>
                            <polygon class="st6" points="408.9,424.1 394.1,416.2 394.1,390.3 408.8,398.2 											"/>
                          </g>
                        </g>
                        <circle class="st6" cx="442.7" cy="390.3" r="3.7"/>
                        <circle class="st6" cx="590.9" cy="294.4" r="3.7"/>
                        <circle class="st6" cx="744.2" cy="196.4" r="3.7"/>
                        <circle class="st6" cx="895.9" cy="95.6" r="3.7"/>
                      </g>
                      <g>
                        <circle class="st6" cx="442.7" cy="563.8" r="3.7"/>
                        <circle class="st6" cx="590.9" cy="467.9" r="3.7"/>
                        <circle class="st6" cx="744.2" cy="369.8" r="3.7"/>
                        <circle class="st6" cx="895.9" cy="269" r="3.7"/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <polyline class="st17" points="241.4,466.3 241.4,324.8 431.1,409.8 431.1,603.4 341.3,563.2 		"/>
          <g>
            <path class="st1" d="M420.3,523c0.2-53.8-37.7-119.3-84.5-146.3c-30.2-17.4-56.7-14.6-71.8,4l-27.7,23.9l14.5,24
              c1.4,53.1,38.6,116.4,84.4,142.9c1,0.6,2,1.1,3,1.7l21.6,35.7l39.4-33.8C412.3,565.3,420.2,547.5,420.3,523z"/>
            <g>
              <path class="st17" d="M300.8,407.7c46.8,27,84.6,92.5,84.5,146.3c-0.2,53.8-38.2,75.5-85,48.5c-46.8-27-84.6-92.5-84.5-146.3
                C216,402.4,254,380.7,300.8,407.7"/>
              <path class="st55" d="M300.8,425.3c38.4,22.1,69.3,75.8,69.2,119.9c-0.1,44.1-31.3,61.8-69.6,39.7
                C262,562.7,231,509.1,231.1,465C231.2,420.9,262.4,403.2,300.8,425.3"/>
              <path class="st1" d="M258.2,417.5c-4,7.8-6.2,17.6-6.3,29.3c-0.1,44.1,30.9,97.7,69.2,119.9c16,9.3,30.8,11.5,42.6,7.8
                c-11,21.3-35.2,26.6-63.4,10.3C262,562.7,231,509,231.1,465C231.2,439.3,241.8,422.6,258.2,417.5L258.2,417.5z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="ImagerAlt1">
        <g>
          <g>
            <path class="st17" d="M453.8,584.7l13.9-10.2c0,0-25.7-57.7-27.1-60.6l-8.7-24c0,0-28.1,1.8-27.2,3.7S401,541,401,541
              s17.6,37.1,20.2,37.8C423.9,579.4,453.8,584.7,453.8,584.7L453.8,584.7z"/>
            <line class="st47" x1="431.1" y1="513.9" x2="461.1" y2="578.8"/>
            <path class="st1" d="M360.6,372.2c0-10.5,10.8-17.6,20.5-13.3l76.2,34.1c5.2,2.3,8.6,7.5,8.6,13.3v91.9
              c0,10.5-10.8,17.6-20.5,13.3l-9.7-4.4L360.6,372.2z"/>
            <path class="st12" d="M355.8,374.6c0-10.5,10.8-17.6,20.5-13.3l76.2,34.1c5.2,2.3,8.6,7.5,8.6,13.3v91.9
              c0,10.5-10.8,17.6-20.5,13.3l-9.7-4.4L355.8,374.6z"/>
            <line class="st48" x1="426.5" y1="411.8" x2="459" y2="391.5"/>
            <path class="st49" d="M268.9,377.6l139.6,227.8l12.8,6l32.5-20.3v-12.3l-29.9-54.6v-78.1l26.1-43l9.2-11.6h-13.4l-38.2,23.2
              l-16.8-3.6c-15.2-3.2-28.5-12.1-37.2-24.9l-37.8-55.4l-24.7,13.1l-30,16.1L268.9,377.6L268.9,377.6z"/>
            <path class="st47" d="M398.9,420.5l-14.4-3.1c-15.2-3.2-28.5-12.1-37.2-24.9l-38.9-57.7"/>
            <line class="st47" x1="271.4" y1="365.4" x2="311.9" y2="344"/>
            <line class="st47" x1="296.3" y1="400.6" x2="327.6" y2="384.1"/>
            <line class="st47" x1="423.8" y1="446.1" x2="391.3" y2="466.4"/>
            <polyline class="st47" points="453.8,578.8 421.2,599.1 421.2,611.4 			"/>
            <polyline class="st47" points="459,391.5 442.8,397.9 404.7,425.3 			"/>
            <polyline class="st47" points="419.8,597.3 391.3,544.6 423.8,524.2 			"/>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <defs>
                      <polygon id="SVGID_00000155858128150376128410000001035675680122495415_" points="269.1,299.1 484.5,382.8 478.7,584.4 
                        417.1,613.4 242.4,531.9 236.3,323.6 								"/>
                    </defs>
                    <clipPath id="SVGID_00000080198982987489014310000018379987261346060446_">
                      <use  style="overflow:visible;"/>
                    </clipPath>
                    <g style="clip-path:url(#SVGID_00000080198982987489014310000018379987261346060446_);">
                      <polygon class="st6" points="295.4,345.1 295.4,362.7 295.4,508.7 304.2,529.9 280.9,521.2 278.6,340 								"/>
                      <g>
                        <g>
                          <polygon class="st8" points="926.3,258 926.3,283.9 427.1,607.8 427,582 										"/>
                        </g>
                        <polygon class="st7" points="910.4,250.9 412.2,574.1 427,582 926.3,258 									"/>
                        <g>
                          <polygon class="st6" points="427.1,607.8 412.3,600 412.2,574.1 427,582 										"/>
                        </g>
                      </g>
                      <g>
                        <path class="st6" d="M437.9,544L889,250.3v-21.2l-20,17.7l-6.4-3.6l-1.3-105.8c0,0,27.2-23.1,27.8-19.5
                          c0.7,3.6,3.2,11.6,3.2,11.6L873.1,144l2.7,85.1l27.6-5.3l-7.6,33.4l-458,304.4V544H437.9z"/>
                        <polygon class="st6" points="401.5,407.2 416.8,429.4 416.8,561.1 409.1,577.4 432.6,588 437.9,419.3 903.4,108.3 
                          903.4,91.9 									"/>
                        <path class="st8" d="M906.8,125.2v-21.8L430.3,416.6v170.3l476.4-315.5v-62.7l-26,17.2v-83.6L906.8,125.2z M895.9,230.1
                          v23.5L442.7,551.4V418.9l442.5-289.4v4.3l-16.3,10.3v102.6L895.9,230.1z"/>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="424,402.1 286.3,338.3 286.2,312.4 424,376.2 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="424,376.2 286.2,312.4 300.1,303.9 437.9,367.7 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="562.8,308.7 413,253.3 413,227.5 562.7,282.8 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="562.7,282.8 413,227.5 426.9,218.9 576.7,274.3 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="726.5,207.3 576.7,151.9 576.7,126 726.4,181.4 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="726.4,181.4 576.7,126 590.6,117.5 740.4,172.9 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="883.3,106.8 733.5,51.4 733.4,25.5 883.2,80.9 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="883.2,80.9 733.4,25.5 747.4,17 897.1,72.4 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="st8" points="908.1,74.3 908.2,100.1 408.9,424.1 408.8,398.2 											"/>
                          </g>
                          <polygon class="st7" points="892.2,67.1 394.1,390.3 408.8,398.2 908.1,74.3 										"/>
                          <g>
                            <polygon class="st6" points="408.9,424.1 394.1,416.2 394.1,390.3 408.8,398.2 											"/>
                          </g>
                        </g>
                        <circle class="st6" cx="442.7" cy="390.3" r="3.7"/>
                        <circle class="st6" cx="590.9" cy="294.4" r="3.7"/>
                        <circle class="st6" cx="744.2" cy="196.4" r="3.7"/>
                        <circle class="st6" cx="895.9" cy="95.6" r="3.7"/>
                      </g>
                      <g>
                        <circle class="st6" cx="442.7" cy="563.8" r="3.7"/>
                        <circle class="st6" cx="590.9" cy="467.9" r="3.7"/>
                        <circle class="st6" cx="744.2" cy="369.8" r="3.7"/>
                        <circle class="st6" cx="895.9" cy="269" r="3.7"/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <polyline class="st17" points="241.4,466.4 241.4,325 431.1,409.9 431.1,603.6 341.3,563.3 		"/>
          <path class="st51" d="M302.4,428.2c1.9-0.8,4.2-0.9,6.4,0.1l52.3,23.4c8.4,3.8,13.9,12.2,13.9,21.4v65.7c0,2.2-1.1,4.1-2.6,5.2
            l0,0l-74.7,46.5l-7.1,1.2l-63.2-116.5L302.4,428.2"/>
          <path class="st17" d="M290.4,591.8l-56.5-25.3c-7.1-3.2-11.7-10.2-11.7-18v-65.8c0-5.8,6-9.6,11.2-7.3l52.3,23.4
            c8.4,3.8,13.9,12.2,13.9,21.4v65.7C299.6,590.5,294.7,593.7,290.4,591.8L290.4,591.8z"/>
          <line class="st47" x1="283.9" y1="497.9" x2="358.6" y2="451.4"/>
          <line class="st47" x1="300.2" y1="525.4" x2="374.9" y2="478.9"/>
          <path class="st51" d="M246.8,491.5L232,502.2c-6.3,10.3-3.7,28.8,7.3,45.6c11.3,17.2,27.9,27,39.9,24.5l11.6-7.9L246.8,491.5z"/>
          <path class="st24" d="M285.6,516.2c-11-16.7-26.9-26.3-38.8-24.7c-8.7,9.5-6.8,29.9,5.3,48.2c11,16.7,26.9,26.3,38.8,24.7
            C299.6,554.9,297.7,534.5,285.6,516.2z"/>
          <path class="st47" d="M285.6,516.2c-11-16.7-26.9-26.3-38.8-24.7L232,502.2c-6.3,10.3-3.7,28.8,7.3,45.6
            c11.3,17.2,27.9,27,39.9,24.5l11.6-7.9C299.6,554.9,297.7,534.5,285.6,516.2L285.6,516.2z"/>
          <path class="st28" d="M361,451.7l-52.3-23.4c-2.2-1-4.4-0.9-6.4-0.1l0,0l-75.1,47c-2.9,1.1-5,3.9-5,7.4v65.8
            c0,7.8,4.6,14.8,11.7,18l56.5,25.3c2.6,1.1,5.3,0.5,7.1-1.2l74.7-46.6l0,0c1.6-1.1,2.6-3,2.6-5.2v-65.7
            C374.9,463.9,369.5,455.5,361,451.7L361,451.7z"/>
          <path class="st24" d="M403.5,436.6l-141.7-61.8c-3.8-1.7-6.2-5.5-6.1-9.6l0,0c0.2-7.3,7.6-12,14.3-9.1l141.7,61.8
            c3.8,1.7,6.2,5.5,6.1,9.6l0,0C417.7,434.7,410.2,439.5,403.5,436.6z"/>
        </g>
      </g>
      <g id="Imager_00000052093405847725007740000002508984569410938023_">
        <g id="Imager">
          <g>
            <path class="st17" d="M453.8,584.4l13.9-10.2c0,0-25.7-57.7-27.1-60.5l-8.7-24c0,0-28.1,1.8-27.2,3.7c0.9,1.8-3.7,47.5-3.7,47.5
              s17.5,37.1,20.2,37.7C424,579.2,453.8,584.4,453.8,584.4z"/>
            <line class="st57" x1="431.1" y1="513.7" x2="461.1" y2="578.6"/>
            <path class="st1" d="M360.8,372.2c0-10.5,10.8-17.5,20.4-13.2l76.1,34.1c5.2,2.3,8.6,7.5,8.6,13.2V498
              c0,10.5-10.8,17.5-20.4,13.2l-9.7-4.3L360.8,372.2z"/>
            <path class="st12" d="M356,374.6c0-10.5,10.8-17.5,20.4-13.2l76.1,34.1c5.2,2.3,8.6,7.5,8.6,13.2v91.8
              c0,10.5-10.8,17.5-20.4,13.2l-9.7-4.3L356,374.6z"/>
            <line class="st46" x1="426.6" y1="411.8" x2="459.1" y2="391.5"/>
            <path class="st49" d="M269.1,377.6l139.4,227.6l12.8,6l32.5-20.3v-12.3l-29.9-54.5V446l26-42.9l9.1-11.6h-13.4l-38.2,23.2
              l-16.8-3.6c-15.1-3.2-28.5-12.1-37.2-24.9l-37.7-55.4L291,343.9L261,360L269.1,377.6z"/>
            <path class="st57" d="M399,420.4l-14.4-3.1c-15.1-3.2-28.5-12.1-37.2-24.9l-38.9-57.6"/>
            <line class="st57" x1="271.7" y1="365.4" x2="312.1" y2="344"/>
            <line class="st57" x1="296.5" y1="400.5" x2="327.7" y2="384.1"/>
            <line class="st57" x1="423.9" y1="446" x2="391.4" y2="466.3"/>
            <polyline class="st57" points="453.8,578.6 421.3,598.9 421.3,611.2 			"/>
            <polyline class="st57" points="459.1,391.5 442.8,397.8 404.8,425.3 			"/>
            <polyline class="st57" points="419.8,597 391.4,544.4 423.9,524.1 			"/>
          </g>
          <path class="st57" d="M285.8,516c-11-16.7-26.9-26.3-38.8-24.7L232.3,502c-6.3,10.3-3.7,28.8,7.3,45.5
            c11.3,17.2,27.9,27,39.9,24.5l11.6-7.9C299.8,554.7,297.9,534.3,285.8,516z"/>
          <path class="st28" d="M361.2,451.6L309,428.2c-2.2-1-4.4-0.9-6.4-0.1l0,0L227.5,475c-2.9,1.1-5,3.9-5,7.4v65.8
            c0,7.8,4.6,14.8,11.7,18l56.5,25.3c2.6,1.1,5.3,0.5,7.1-1.2l74.6-46.5l0,0c1.6-1.2,2.6-3,2.6-5.2V473
            C375,463.8,369.6,455.4,361.2,451.6z"/>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <defs>
                      <polygon id="SVGID_00000152262932777047188600000006484057582035831168_" points="269.1,299.1 484.5,382.8 478.7,584.4 
                        417.1,613.4 242.4,531.9 236.3,323.6 								"/>
                    </defs>
                    <clipPath id="SVGID_00000042738343332902968620000015734739911775975069_">
                      <use  style="overflow:visible;"/>
                    </clipPath>
                    <g style="clip-path:url(#SVGID_00000042738343332902968620000015734739911775975069_);">
                      <polygon class="st6" points="295.4,345.1 295.4,362.7 295.4,508.7 304.2,529.9 280.9,521.2 278.6,340 								"/>
                      <g>
                        <g>
                          <polygon class="st8" points="926.3,258 926.3,283.9 427.1,607.8 427,582 										"/>
                        </g>
                        <polygon class="st7" points="910.4,250.9 412.2,574.1 427,582 926.3,258 									"/>
                        <g>
                          <polygon class="st6" points="427.1,607.8 412.3,600 412.2,574.1 427,582 										"/>
                        </g>
                      </g>
                      <g>
                        <path class="st6" d="M437.9,544L889,250.3v-21.2l-20,17.7l-6.4-3.6l-1.3-105.8c0,0,27.2-23.1,27.8-19.5
                          c0.7,3.6,3.2,11.6,3.2,11.6L873.1,144l2.7,85.1l27.6-5.3l-7.6,33.4l-458,304.4V544H437.9z"/>
                        <polygon class="st6" points="401.5,407.2 416.8,429.4 416.8,561.1 409.1,577.4 432.6,588 437.9,419.3 903.4,108.3 
                          903.4,91.9 									"/>
                        <path class="st8" d="M906.8,125.2v-21.8L430.3,416.6v170.3l476.4-315.5v-62.7l-26,17.2v-83.6L906.8,125.2z M895.9,230.1
                          v23.5L442.7,551.4V418.9l442.5-289.4v4.3l-16.3,10.3v102.6L895.9,230.1z"/>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="424,402.1 286.3,338.3 286.2,312.4 424,376.2 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="424,376.2 286.2,312.4 300.1,303.9 437.9,367.7 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="562.8,308.7 413,253.3 413,227.5 562.7,282.8 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="562.7,282.8 413,227.5 426.9,218.9 576.7,274.3 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="726.5,207.3 576.7,151.9 576.7,126 726.4,181.4 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="726.4,181.4 576.7,126 590.6,117.5 740.4,172.9 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="st8" points="883.3,106.8 733.5,51.4 733.4,25.5 883.2,80.9 										"/>
                        </g>
                        <g>
                          <polygon class="st7" points="883.2,80.9 733.4,25.5 747.4,17 897.1,72.4 										"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="st8" points="908.1,74.3 908.2,100.1 408.9,424.1 408.8,398.2 											"/>
                          </g>
                          <polygon class="st7" points="892.2,67.1 394.1,390.3 408.8,398.2 908.1,74.3 										"/>
                          <g>
                            <polygon class="st6" points="408.9,424.1 394.1,416.2 394.1,390.3 408.8,398.2 											"/>
                          </g>
                        </g>
                        <circle class="st6" cx="442.7" cy="390.3" r="3.7"/>
                        <circle class="st6" cx="590.9" cy="294.4" r="3.7"/>
                        <circle class="st6" cx="744.2" cy="196.4" r="3.7"/>
                        <circle class="st6" cx="895.9" cy="95.6" r="3.7"/>
                      </g>
                      <g>
                        <circle class="st6" cx="442.7" cy="563.8" r="3.7"/>
                        <circle class="st6" cx="590.9" cy="467.9" r="3.7"/>
                        <circle class="st6" cx="744.2" cy="369.8" r="3.7"/>
                        <circle class="st6" cx="895.9" cy="269" r="3.7"/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <polyline class="st17" points="241.7,466.3 241.7,325 431.1,409.8 431.1,603.3 341.4,563.1 		"/>
          <g>
            <path class="st51" d="M302.6,428.2c1.9-0.8,4.2-0.9,6.4,0.1l52.2,23.4c8.4,3.8,13.9,12.2,13.9,21.4v65.6c0,2.2-1.1,4.1-2.6,5.2
              l0,0l-74.6,46.5l-7.1,1.2l-63.1-116.4L302.6,428.2"/>
            <line class="st57" x1="284.1" y1="497.8" x2="358.7" y2="451.3"/>
            <line class="st57" x1="300.4" y1="525.3" x2="375" y2="478.7"/>
            <path class="st17" d="M290.7,591.5l-56.5-25.3c-7.1-3.2-11.7-10.2-11.7-18v-65.8c0-5.8,5.9-9.6,11.2-7.3l52.2,23.4
              c8.4,3.8,13.9,12.2,13.9,21.4v65.6C299.8,590.3,295,593.5,290.7,591.5z"/>
            <path class="st51" d="M247.1,491.4L232.3,502c-6.3,10.3-3.7,28.8,7.3,45.5c11.3,17.2,27.9,27,39.9,24.5l11.6-7.9L247.1,491.4z"/>
            <path class="st24" d="M285.8,516c-11-16.7-26.9-26.3-38.8-24.7c-8.7,9.4-6.8,29.8,5.3,48.1c11,16.7,26.9,26.3,38.8,24.7
              C299.8,554.7,297.9,534.3,285.8,516z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
