import { FunctionalComponent, h, JSX } from 'preact';
import { Section } from './section';
import { SectionName } from '../section-context';
import { usePagination } from '../hooks/usePagination';
import { Img } from "./img"
import {useCallback} from 'preact/hooks';
import useSound from '../hooks/useSound';
import { config } from "../config"
import { Close } from "./close"

interface IProps {
  close: (...params: any) => void,
}

const imgs = [
  "book-1.png",
  "book-2.png",
  "book-3.png",
  "book-4.png",
  "book-5.png",
]

export const BooksSection: FunctionalComponent<IProps> = ({ close }) => {
  const [ playButtonSound ] = useSound(`${config.m2SoundFolder}click_to_the_next_book.ogg`, { volume: 0.8 })
  const nothing = useCallback((evt: JSX.TargetedMouseEvent<HTMLElement>) => {
    evt.stopPropagation();
  }, [])
  const { idx, back, next } = usePagination(5)
  const moveWithSound = (cb) => (e) => {
    playButtonSound();
    cb(e)
  }
  return (
      <Section name={SectionName.book} close={close} title="Just some light reading. I enjoy the classics." subtitle="">
        <div class="specs-gallery" onClick={nothing}>
          <Img src={`${imgs[idx]}`} class="main-image" />
          <div class="specs-gallery__nav">
            <button onClick={moveWithSound(back)} class="btn-prev"></button>
            <button onClick={moveWithSound(next)} class="btn-next"></button>
          </div>
        </div>
        <Close close={close} />
      </Section>
  )
}
