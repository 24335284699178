import { useCallback, useEffect, useState } from 'preact/hooks';

const once = (fn: (asset: string) => void) => {
  let ran = false;
  return () => {
    if (!ran) {
      ran = true;
      fn.call(window)
    }
  }
}

export const useLoadAssets = (assets: any[], assetLoaded: (...args:any) => void, onLoad: (...args: any) => void) => {
  const totalAssets = assets.length;
  const [ loadedAssets, setLoadedAssets ] = useState(0)

  const loadAssets = useCallback(() => {
    // Start loading all the assets
    assets.forEach((asset) => {
      loadAsset(asset, once(() => {
        setLoadedAssets((n) => n+1)
      }));
    });
  }, [assets]) 

  useEffect(() => {
    assetLoaded(loadedAssets)
    if (loadedAssets == totalAssets) {
      onLoad();
    }
  }, [loadedAssets])

  const loadAsset = useCallback((uri: string, callback: () => void) => {
    // preload if asset is audio
    if (uri.toLowerCase().match("ogg|mp3") !== null) {
      const audio = new Audio(uri)
      audio.addEventListener("canplaythrough", callback, false);
      audio.addEventListener("error", callback, false);
    } else
    // preload if asset is image
    if (uri.toLowerCase().match("jpg|jpeg|gif|png|webp|svg") !== null) {
      const image = new Image();
      image.src = uri;
      image.onload = callback;
      image.onerror = callback;
    } else

    // preload if asset is video
    if (uri.toLowerCase().match("mp4|webm|ogv") !== null) {
      const video = document.createElement('video');
      const source = document.createElement('source'); 
      source.src = uri;
      video.setAttribute("preload", "auto");
      video.appendChild(source);
      video.addEventListener('canplaythrough', callback, false);
      video.addEventListener('error', callback, false);
    } else if (uri.toLowerCase().match("json")) {
      const link = document.createElement("link");
      link.rel = "preload"
      link.as = "fetch"
      link.href = uri
      link.onload = callback;
      link.onerror = callback;
      document.head.appendChild(link)
    }

    setTimeout(callback, 10000)
  }, [assets]) 

  return {
    loadAssets
  }
}
