export const config = {
  imgFolder: "/assets/img/",
  soundFolder: "/assets/sound/",
  m1VideoFolder: "/assets/m1/video/",
  m1ImgFolder: "/assets/m1/img/",
  m1SoundFolder: "/assets/m1/sound/",
  m2VideoFolder: "/assets/m2/video/",
  m2SoundFolder: "/assets/m2/sound/",
  m2ImgFolder: "/assets/m2/img/",
  m3VideoFolder: "/assets/m3/video/",
  m3SoundFolder: "/assets/m3/sound/",
  m3ImgFolder: "/assets/m3/img/",
  m4VideoFolder: "/assets/m4/video/",
  m4SoundFolder: "/assets/m4/sound/",
  m4ImgFolder: "/assets/m4/img/",
  getMissionImgFolder: (mission: number) => {
    switch(mission) {
      case 1: 
        return config.m1ImgFolder
      case 2:
        return config.m2ImgFolder
      case 3:
        return config.m3ImgFolder
      case 4:
        return config.m4ImgFolder
      default:
        return config.imgFolder
    }
  }, 
  getMissionSoundFolder: (mission: number) => {
    switch(mission) {
      case 1: 
        return config.m1SoundFolder
      case 2:
        return config.m2SoundFolder
      case 3:
        return config.m3SoundFolder
      case 4:
        return config.m4SoundFolder
      default:
        return config.soundFolder
    }
  },

  // config options for A/B or other tests
  disableTabletPassword: true,
  disableTabletPower: true,
  disableScreenPassword: true,
  terminal: {

    defaultPrompt:'me@HaS3:~$',
    commandPrompt:'$',
    totalTests:5,
    welcomeMsg:`
Welcome.
Run ‘howto’ to learn how to use this interface.
Run ‘help’ to see all options.
Run ‘input_design’ when you are ready to start testing your satellite.`,

    uploadCompleteMsg:`
Running program COMMISSION SAT
spacecraft is currently in SAFE MODE
run a command from the list below
attitude_sensing
attitude_adjust_rough
stabilize
estimate_parameters
unload
set_imager
test_imager`,

    completeMsg:`entering MISSION MODE
commissioning complete
ready for full mission performance`,

    howtoMsg:`
> input_design
Start here. Input your satellite design into the digital twin, one component at a time. Don't worry if you didn't finish Mission 2 or can't remember the exact details of your design. Consider this a fresh start.
> commission_sat
This is step two. After a satellite has been ejected from the launch vehicle into space, the satellite owner begins to "commission" their spacecraft and bring it into operation. Simulating this in a digital twin is a great way to solve a lot of problems before you strap anything to a rocket. 
> revise_design
If your satellite fails a command during the commissioning process, visit here to replace a component with a different option that accomplishes the same function. (for example, replace a nonfunctional imaging unit with the other option from the components list). Then try again!`,
  commission_sat: `spacecraft in SAFE MODE
choose a command from the list below`,
  attitude_sensing: [ `command sent
communications established
run communications check
antenna responsive
radio responsive
GPS antenna responsive
entering STABILIZATION MODE
engaging ADCS
ADCS wake
run sensor check
sensor01 responsive
sensor02 responsive
sensor03 responsive
ADCS engaged
✓ The test was successful
You can continue testing attitude_adjust_rough`, `command sent
communications established
run communications check
antenna responsive
radio responsive
GPS ANTENNA NONRESPONSIVE
x The test has failed
please run "revise_design" to replace failed component then try again` ],
  attitude_adjust_rough: [`command received
entering INITIAL MODE
begin rough attitude adjustment
magnetorquer reading .65rad/s
adust to .1rad/s
applying adjustment...
magnetorquer reading .1rad/s
✓ The test was successful
You can continue testing stabilize`, `command received
entering INITIAL MODE
begin rough attitude adjustment
magnetorquer reading .65rad/s
adust to .1rad/s
applying adjustment...
magnetorquer reading .65rad/s
MAGNETORQUER NONFUNCTIONAL
x The test has failed
please run "revise_design" to replace failed component then try again`],
  stabilize: [`command received
engage star trackers
engage gyro
engage reaction wheels
fetch star tracker reading
current position -z=3.066 -y=44.22 -x=30.085
begin stabilization
set position -z to target=earth center- set position -y to target=1.002- set position -x to target=90.001
fetch star tracker reading
position confirmed
✓ The test was successful
You can continue testing estimate_parameters`, `command received
engage star trackers
engage gyro
engage reaction wheels
set star tracker reading
STAR TRACKERS INCOMPATIBLE
x The test has failed
please run "revise_design" to replace failed component then try again`],
  estimate_parameters: [`command received
entering COARSE CONTROL MODE
calculating variables
temperature
battery
vibration
adjusting for variables
✓ The test was successful
You can continue testing unload`, `command received
entering COARSE CONTROL MODE
calculating variables
temperature
battery
vibration
MOTHERBOARD NONRESPONSIVE
x The test has failed
please run "revise_design" to replace failed component then try again`],
  unload: `command received
decreasing reaction wheel velocity...
please wait...
reaction wheels at stable velocity
✓ The test was successful
You can continue testing set_imager`,
  set_imager: [`command received
entering FINE CONTROL MODE
engaging imaging unit
setting focus
setting brightness
taking test picture
adjusting clarity...
please wait...
taking test picture
adjusting ADCS to reduce blur
imager at standard setting
✓ The test was successful
You can continue testing test_imager`, `command received
entering FINE CONTROL MODE
engaging imaging unit
setting focus
setting brightness
brightness adjustment nonfunctional
IMAGER UNUSABLE
x The test has failed
please run "revise_design" to replace failed component then try again`],
  test_imager: `taking test picture
review picture quality
acceptable type Y unacceptable type N`

  }
}
