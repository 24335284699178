import { FunctionalComponent, h, JSX } from 'preact';
import { config } from "../config"

interface IProps {
  close: (...params: any) => void,
}

export const Close: FunctionalComponent<IProps> = ({ close }) => {
  return (
    <div class="experience__goback">
      <a>
        <img class="img__linkable__close" src={`${config.imgFolder}goback.svg`} onClick={close}/>
      </a>
    </div>
  )
}
