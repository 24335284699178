import { FunctionalComponent, h } from 'preact';
import { config } from "../config"
import Terminal from 'react-console-emulator'
import {getContextPos, SectionContext, SectionName} from '../section-context';
import {useCallback, useContext, useEffect, useRef} from 'preact/hooks';
import { Close } from "./close"
const motd = require("../assets/motd.json");

interface IProps {
  close: (...args: any) => void
}

function logo() {
  return `.------..------..------..------.\n
|H.--. ||A.--. ||C.--. ||K.--. |\n
| :/\\: || (\\/) || :/\\: || :/\\: |\n
| (__) || :\\/: || :\\/: || :\\/: |\n
| '--'H|| '--'A|| '--'C|| '--'K|\n
\`------'\`------'\`------'\`------'\n
.------.\n
|A.--. |\n
| (\\/) |\n
| :\\/: |\n
| '--'A|\n
\`------'
.------..------..------.\n
|S.--. ||A.--. ||T.--. |\n
| :/\\: || (\\/) || :/\\: |\n
| :\\/: || :\\/: || (__) |\n
| '--'S|| '--'A|| '--'T|\n
\`------'\`------'\`------'`
}


function rot13(str: string) {
  const input = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const output = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
  const index = (x:string) => input.indexOf(x);
  const translate = (x: string) => index(x) > -1 ? output[index(x)] : x;
  return str.split('').map(translate).join('');
}

const terminalCommands = {
  rot13: {
    description: "The Caesar cipher",
    fn: (...args: any[]) => {
      if (args.length === 0 || args[0].includes("help")) {
        return "Usage: rot13 <phrase>";
      }
      return rot13(args.join(" "))
    }
  },
  motd: {
    description: "Message of the day",
    usage: "motd",
    fn: () => {
      const today = new Date();
      const dayOfYear = Math.floor((today.getTime() - new Date(today.getFullYear(), 0, 0).getTime()) / (1000 * 60 * 60 * 24));
      const { msg, a } = motd[dayOfYear % motd.length]
      return `> ${msg}\n--${a}`
    }
  },
  logo: {
    fn: logo
  }
}

export const TerminalSection: FunctionalComponent<IProps> = ({ close }) => {
  const { contentHeight, activeSection } = useContext(SectionContext);
  const getPos = useCallback((x: number) => getContextPos(x, contentHeight), [contentHeight]);
  const terminalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (activeSection === SectionName.terminal) {
      terminalRef.current.getElementsByTagName("input")[0].focus()
    }
  }, [activeSection])

  return (
    <div style={`background-image: url('${config.m1ImgFolder}terminal-background.jpg'); margin-top: -185px`} class="experience__section--back-background">
      <div class="browser green" style={{width: getPos(65), top: getPos(20), left: "-28px"}}>
        <div class="browser__title">
          <button class="browser__close" onClick={close}></button>
          <button class="browser__max"></button>
          <button class="browser__min" onClick={close}></button>
        </div>
        <div class="browser__content" id="browser-console" ref={terminalRef}>
          <Terminal
            commands={terminalCommands}
            welcomeMessage={'\nType "help" to see available commands.'}
            promptLabel={'me@HaS3:~$'}
            style={{
              backgroundColor: "transparent",
              minHeight: "200px",
              maxHeight: "450px",
              overflow: "auto"
            }}
          />
        </div>
      </div>
      <Close close={close}/>
    </div>
  )
}
