import { FunctionalComponent, h } from 'preact';
import { Section } from './section';
import { Clickable } from './clickable';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useLoadAssets } from '../hooks/useAssets'
import { SectionContext, SectionName } from "../section-context"
import { Img } from "./img"
import { BluetoothRadioSection } from "./bluetoothRadioSection"
import { TDPrinterSection } from "./tdPrinterSection"
import { BooksSection } from "./booksSection"
import { TabletSection } from "./tabletSection"
import { ShoppingSatSection } from "./shoppingSatSection"
import { SafeSection } from "./safeSection"
import { AISection } from "./aiSection"
import { config } from "../config"
import ReactGA from 'react-ga4'
const m2Assets = require('../assets/m2.json')
const m2smAssets = require('../assets/m2_sm.json')

interface IProps {
  goToSection: (...params: any) => (...params: any) => void,
  setAudioPaused: (...params: any) => void,
  goToM1: (...params: any) => void,
  complete: (...params: any) => void,
}

export const M2: FunctionalComponent<IProps> = ({ setAudioPaused, complete, goToSection, goToM1 }) => {
  const ref = useRef(null)
  const { contentHeight, contentWidth } = useContext(SectionContext)
  const [ allAssetsLoaded, setAllAssetsLoaded ] = useState(false)
  const [ tabletVideoViewed, setTabletVideoViewed ] = useState(false)
  const [ totalLoadedAssets, setTotalLoadedAssets ] = useState(0)
  const assetLoaded = useCallback((assets: number) => {
    setTotalLoadedAssets(assets)
  }, [])
  const cbAllAssetsLoaded = useCallback(() => {
    setAllAssetsLoaded(true)
    setTimeout(() => {
      ref.current.parentNode.scrollLeft = ref.current.parentNode.scrollWidth
    }, 1)
  }, [])

  const { loadAssets } = useLoadAssets(contentWidth > 1200 ? m2Assets : m2smAssets, assetLoaded, cbAllAssetsLoaded)
  const loadingPercentage = useMemo(() => {
    return Math.round(totalLoadedAssets / m2Assets.length * 100)
  }, [totalLoadedAssets])
  const [ firstOpen, setFirstOpen ] = useState(true)
  const unpauseAudio = useCallback(() => setAudioPaused(false), []);
  const pauseAudio = useCallback(() => {
    setAudioPaused(true)
  }, []);


  useEffect(() => {
    if (firstOpen) {
      loadAssets()
      setFirstOpen(false)
    } else {
      goToSection(SectionName.empty, true, true)()
    }
  }, [firstOpen]) // goToSection and loadAssets are explicitely excluded

  const getPos = useCallback((num: number | "auto") => {
    if (num !== "auto") {
      return `${num / 100 * contentHeight}px`;
    }

    return num
  }, [contentHeight])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/mission-2" });
  }, [])

  if (!allAssetsLoaded) {
    return (
      <div class="loading-overlay">
        <div class="loading-bar" style={{width: `${loadingPercentage*5}px`}} />
        {loadingPercentage}%
      </div>
    )
  } else if (allAssetsLoaded) {
    return (
      <>
        <div id="please-turn-phone">
          <h1>Please rotate your phone to access White Rabbit's workshop</h1>
        </div>
        <Img class="experience__background" src="base-background.jpg" draggable style={{ height: `${contentHeight}px` }} ref={ref} />
        <Img src="tablet-alert.png" class="tablet-alert" style={{ left: getPos(472), top: getPos(70), width: getPos(15) }}/>
        <Clickable x={493.4} y={59.3} width={127.9} action={goToSection(tabletVideoViewed ? SectionName.shoppingSat : SectionName.checkTabletVideoNote)}  sound="click_on_the_desk" shine={tabletVideoViewed}>
          <Img src="bench-hover.svg" />
        </Clickable>
        <Clickable x={589} y={42} width={16} action={goToSection(SectionName.tdprinter)} sound="click_on_the_3d_printer" volume={1}>
          <Img src="3dprinter-hover.svg" />
        </Clickable>
        <Clickable x={565} y={36} width={21} action={goToSection(SectionName.aitab)} sound="click_on_the_AI_tablet">
          <Img src="aitab-hover.svg" />
        </Clickable>
        <Clickable x={536.75} y={55.25} width={12.2} action={goToSection(SectionName.bluetooth)} sound="click_on_the_radio" >
          <Img src="bluetooth-hover.svg" />
        </Clickable>
        <Clickable x={597} y={59} width={18} action={goToSection(SectionName.flatsat)} sound="click_on_the_flatsat">
          <Img src="flatsat-hover.svg"/>
        </Clickable>
        <Clickable x={586} y={88} width={3.5} action={goToSection(SectionName.keys)} sound="click_on_the_keys">
          <Img src="keys-hover.svg" />
        </Clickable>
        <Clickable x={265} y={42} width={28.5} action={goToSection(SectionName.book)} sound="click_on_the_books">
          <Img src="literature-hover.svg" />
        </Clickable>
        <Clickable x={73} y={31} width={52.5} action={goToM1} sound="click_back_to_mission_1">
          <Img src="m1-hover.svg" />
        </Clickable>
        {/*
        <Clickable x={457} y={39} width={11} action={goToSection(SectionName.rubiks)} sound="rubik_s_game">
          <Img src="rubiks-hover.svg" />
        </Clickable>
        */}
        <Clickable x={340.25} y={36} width={34.5} action={goToSection(SectionName.safe)} sound="click_safe_section">
          <Img src="safe-hover.svg" />
        </Clickable>
        <Clickable x={437.5} y={65.5} width={72.5} action={goToSection(SectionName.tablet)} sound="tablet-click">
          <Img src="tablet-hover.svg" />
        </Clickable>
        { !config.disableTabletPassword && (
            <Clickable x={492} y={88.5} width={12.5} action={goToSection(SectionName.password)} sound="click_on_the_secret_note">
              <Img src="password-hover.svg" />
            </Clickable>
          )
        }
        <Section name={SectionName.keys} close={goToSection(SectionName.empty)} title="My motorcycle keys! I wondered where those were at." subtitle="">
          <Img src="keys.png" close={goToSection(SectionName.empty)} class="main-image" style="margin-top: 100px"/>
        </Section>
        <AISection close={goToSection(SectionName.empty, false, true)} />
        <TDPrinterSection close={goToSection(SectionName.empty)}>
        </TDPrinterSection>
        <BluetoothRadioSection close={goToSection(SectionName.empty)} />
        <Section name={SectionName.flatsat} close={goToSection(SectionName.empty)} title="You found my Flatsat model from last year! Here's some info about it" subtitle="">
          <Img src="flatsat.png" class="main-image" close={goToSection(SectionName.empty)} style={{ maxHeight: "70vh", width: "fit-content" }} download={"/assets/m2/obj/flatsat.pdf"}/>
        </Section>
        <Section name={SectionName.password} close={goToSection(SectionName.empty)} title="" subtitle="">
          <Img src="password-note.png" class="main-image" />
        </Section>
        <TabletSection close={goToSection(SectionName.empty, false, true)} playVideo={() => { pauseAudio(); setTabletVideoViewed(true) }} stopVideo={unpauseAudio} />
        <ShoppingSatSection close={goToSection(SectionName.empty)} complete={complete} />
        <SafeSection close={goToSection(SectionName.empty)} />
        <Section name={SectionName.checkTabletVideoNote} close={goToSection(SectionName.empty)}>
          <Img src="block-table-view.png" />
        </Section>
        <BooksSection close={goToSection(SectionName.empty)} />
      </>
    )
  }
}
