import { FunctionalComponent, h, JSX } from 'preact';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';
import { Img } from './img'

interface IProps {
  src: string
  playVideo: (...params: any) => void
  stopVideo: (...params: any) => void
  videoEnded?: (...params: any) => void
  controls?: boolean
  playing?: boolean
}


export const Video: FunctionalComponent<IProps> = ({ src, playVideo, stopVideo, videoEnded = () => {}, controls, playing }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [moveProgressBar, setMoveProgressBar] = useState<boolean>(true)
  const [progress, setProgress] = useState<number>(0)
  const [buffering, setBuffering] = useState(false);

  const toggleVideoState = useCallback((evt: any) => {
    const video = videoRef.current;
    if (video.paused) {
      playVideo()
    } else {
      stopVideo();
      evt.preventDefault();
      evt.stopPropagation();
    }
  }, [playVideo, stopVideo]);

  useEffect(() => {
    if (!playing && !videoRef.current.paused) {
      videoRef.current.pause()
    } else if (playing && videoRef.current.paused) {
      videoRef.current.play()
    }
    return () => {
      if (playing) {
        videoRef.current.pause()
        stopVideo();
      }
    }
  }, [playing])

  const skipForward = useCallback(() => {
    const video = videoRef.current;
    const seekToTime = video.currentTime + 10;
    if (seekToTime > video.duration) 
      return;
    video.currentTime = seekToTime;
  }, [])
  const skipBack = useCallback(() => {
    const video = videoRef.current;
    const seekToTime = video.currentTime - 10;
    if (seekToTime > video.duration) 
      return;
    video.currentTime = seekToTime;
  }, [])

  const updateTime = useCallback(() => {
    if (moveProgressBar) {
      setBuffering(false)
      const video = videoRef.current;
      if (video) {
        const currentPos = video.currentTime;
        const maxduration = video.duration;
        const percentage = 100 * currentPos / maxduration;
        setProgress(percentage);
      }
    }
  }, [moveProgressBar])

  const setVideoProgress = useCallback((val: string) => {
    const video = videoRef.current;
    const percentage = parseInt(val, 10);
    setProgress(percentage);
    video.currentTime = video.duration * percentage / 100;
    setBuffering(true)
  }, [moveProgressBar]);

  const setSoundVolume = useCallback((val: string) => {
    const video = videoRef.current;
    video.volume = parseInt(val, 10) / 100; //Get video duration
  }, []);

  const mute = useCallback(() => {
    const video = videoRef.current;
    video.muted = !video.muted;
  }, []);

  const play = useCallback(() => {
    playVideo()
  }, [playVideo])

  const pause = useCallback(() => {
    stopVideo()
  }, [stopVideo])

  const startProgress = useCallback(() => {
    setMoveProgressBar(true);
  }, []);

  const stopProgress = useCallback(() => {
    setMoveProgressBar(false);
  }, []);

  return (
    <>
      <video ref={videoRef} onClick={toggleVideoState} onTimeUpdate={updateTime} onEnded={(e) => { videoEnded(e); stopVideo(e); }}>
        <source src={src}
                type="video/mp4"
                autoPlay />
        Sorry, your browser doesn't support embedded videos.
      </video>
      { buffering && 
        <div class="video__buffering">
          <Img src="loader.svg" mission={0} />
        </div>
      }
      { controls && (
        <>
          <div class="rabbit"></div>
          <div class="browser__content__vc">
            <input class="vc__progress" type="range" min="0" max="100" value={progress} onChange={(e: any) => setVideoProgress(e.target.value)} onMouseDown={stopProgress} onMouseUp={startProgress}/>
            <div class="vc__bottom">
              <div class="vc__col">
                <button class="vc__backward" onClick={skipBack}></button>
                <button class="vc__play" onClick={play}></button>
                <button class="vc__pause" onClick={pause}></button>
                <button class="vc__forward" onClick={skipForward}></button>
              </div>
              <div class="vc__col">
                <div class="vc__sound">
                  <button class="vc__mute" onClick={mute}></button>
                  <input class="vc__volume" type="range" min="0" max="100" defaultValue="100" onChange={(e: any) => setSoundVolume(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

