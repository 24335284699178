import { h } from 'preact';
import { useCallback, useContext, useEffect, useState } from 'preact/hooks';
import useFade from "../hooks/useFade";
import { useSound } from "../hooks/useSound"
import { config } from "../config"
import {getContextPos, SectionContext, SectionName} from '../section-context';
import { Video } from './video'

interface IProps {
  close: (...params: any) => void,
  authenticated: boolean,
  playVideo: (...params: any) => void,
  stopVideo: (...params: any) => void,
}

export const LoginSection = ({ close, playVideo: _playVideo, stopVideo: _stopVideo, authenticated }: IProps) => {
  const [playWrongPassword] = useSound(`${config.m1SoundFolder}wrong-password.ogg`, { volume: 0.2 });
  const [playPrinter] = useSound(`${config.m1SoundFolder}printer.ogg`, { volume: 0.2 });

  const [ videoPlaying, setVideoPlaying ] = useState<boolean>(false)
  const { activeSection, contentHeight } = useContext(SectionContext)
  const [loginInfo, setLoginInfo] = useState<string>("")
  const [wrongPassword, setWrongPassword] = useState<boolean>(false)
  const [locked, setLocked, lockFadeProps] = useFade(!authenticated)
  const [postLocked, setPostLocked, postlockFadeProps] = useFade(!config.disableScreenPassword)
  const [postVideo, setPostVideo, postvideoFadeProps] = useFade(false)
  const [toast, setToast, toastProps] = useFade(false)

  const playVideo = useCallback(() => {
    _playVideo()
    setVideoPlaying(true)
  }, [_playVideo])

  const stopVideo = useCallback(() => {
    _stopVideo()
    setVideoPlaying(false)
  }, [_stopVideo])

  useEffect(() => {
    if (activeSection !== SectionName.login) {
      setVideoPlaying(false)
    }
  }, [activeSection])

  const login = useCallback((evt: any) => {
    evt.preventDefault()
    if (loginInfo === "learnspacefaster") {
      playPrinter()
      setLocked(false);
    } else {
      playWrongPassword();
      setWrongPassword(true);
      setTimeout(() => {
        setWrongPassword(false);
      }, 1000);
    }
  }, [loginInfo, setLocked])

  useEffect(() => {
    setPostLocked(locked);
  }, [locked])

  useEffect(() => {
    if (!postLocked) {
      playVideo();
      setVideoPlaying(true)
    }
  }, [postLocked, playVideo])

  const savePassword = useCallback(() => {
    if (navigator) {
      navigator.clipboard.writeText("yrneafcnprsnfgre").then(function () {
        setToast(true);
        setTimeout(() => setToast(false), 2000)
      }, function (err) {
        console.log(err);
      })
    }
  }, [])

  const style = {
    left: getContextPos(103, contentHeight),
    top: getContextPos(12, contentHeight)
  }

  const videoEnded = useCallback(() => {
    setPostVideo(true)
  }, [stopVideo])

  return (
    <>
      { toast && <div class="login-toast" {...toastProps}>Encoded password copied to your clipboard</div>}
    <div id="login-section" class="browser">
      <div class="password-post-it__container">
          <div class="password-post-it" style={style} onClick={savePassword}></div>
        </div>
        <div class="browser__title">
          <button class="browser__close" onClick={close}></button>
          <button class="browser__max"></button>
          <button class="browser__min" onClick={close}></button>
        </div>

        <div class="browser__content browser__content--video">
          { postLocked &&
          <div class={`login-overlay`} {...postlockFadeProps}>
              <h1>New</h1>
              <h1>Video Message</h1>
              <h3>Subject: Mission 1 Briefing</h3>
            </div>
          }
          { postVideo &&
            <div class={`login-overlay`} {...postvideoFadeProps} onClick={() => { setPostVideo(false); setVideoPlaying(true) }}>
              <h1>CONCLUDE</h1>
              <h1>VIDEO MESSAGE</h1>
            </div>
          }
          <Video
            src="https://player.vimeo.com/progressive_redirect/playback/709196562/rendition/1080p/file.mp4?loc=external&signature=3f5ecd1364ba0ba4af1ad820b1b2670f43a925a27e21508a1b72f7464eb01e1e"
            playVideo={playVideo}
            stopVideo={stopVideo}
            videoEnded={videoEnded}
            controls
            playing={videoPlaying}
            />
          { locked && 
            <div class="browser login-window" {...lockFadeProps}>
              <div class="browser__title">
                <button class="browser__close" onClick={close}></button>
                <button class="browser__max"></button>
                <button class="browser__min" onClick={close}></button>
              </div>
              <div class="browser__content dark-form wpforms-container-full">
                <div class="wpforms-form">
                  <h1>GUEST LOGIN</h1>
                  <form onSubmit={login}>
                    <div class="wpforms-field wpforms-field-password">
                      <input id="password-login" type="password" class="wpforms-field-large wpforms-field-required" placeholder="Password" required value={loginInfo} onInput={(e: any) => setLoginInfo(e.target.value)} autocomplete="new-password" />
                    </div>
                    <div class="wpforms-submit-container">
                      { !wrongPassword && <button type="submit" class="wpforms-submit" data-alt-text="Sending..." data-submit-text="SUBMIT" aria-live="assertive">SUBMIT</button> }
                      { wrongPassword && <button disabled class="wpforms-submit btn-wrong-password" data-alt-text="Sending..." data-submit-text="SUBMIT" aria-live="assertive">INCORRECT PASSWORD</button> }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
