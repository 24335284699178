import { h } from 'preact';
import {useCallback, useContext, useEffect, useRef} from 'preact/hooks';
import {SectionContext, SectionName} from '../section-context';
import { useSound } from "../hooks/useSound"
import { config } from "../config"

interface IProps {
  x: number,
  y: number,
  volume?: number,
  sound: string,
  shine?: boolean,
  opacityClass?: string,
  width?: number | "auto",
  height?: number | "auto",
  disabled?: boolean,
  action: (...params: any) => void,
  children: any
}

export const Clickable = ({ x, y, disabled = false, volume = 0.7, width = "auto", height = "auto", action, sound, children, shine = false, opacityClass = "" }: IProps) => {
  const ref = useRef(null)
  let play: any;
  const { contentHeight, activeSection, mission } = useContext(SectionContext);
  const soundFolder = config.getMissionSoundFolder(mission)
  if (sound) {
    [play] = useSound(`${soundFolder}${sound}.ogg`, { volume });
  }
  const getPos = useCallback((num: number | "auto") => {
    if (num !== "auto") {
      return `${num / 100 * contentHeight}px`;
    }

    return num
  }, [contentHeight])

  const click = useCallback((evt: any) => {
    if (sound) {
      play();
    }
    action(evt);
  }, [play, action, sound, volume])

  useEffect(() => {
    if ((window.screen.width < 1000 || shine) && activeSection === SectionName.empty) {
      const interv = setInterval(() => {
        ref.current.style.opacity = 0.55
        ref.current.style.transition = "opacity 0.5s ease-out";
        setTimeout(() => ref.current?.style ? ref.current.style.opacity = null : null, 1500)
      }, 4500)
      return () => clearInterval(interv)
    }
  }, [shine, activeSection])

  return (
    <div class={`experience__section__clickable ${opacityClass}`} style={{ left: getPos(x), top: getPos(y), width: getPos(width), height: getPos(height), opacity: opacityClass ? 1 : 0.15 }} onClick={!disabled ? click : () => {}} ref={ref}>
      {children}
    </div>
  )
}
