import { createContext } from "preact";

export enum SectionName {
  // common
  blocked = "blocked-overlay",
	instructions = "instructions-overlay",
	empty = "empty",

  // m1
	bike = "bike",
	documents = "documents",
	printer = "printer",
	printerDisabled = "printer-disabled",
	recipe = "recipe",
	terminal = "terminal",
	login = "login",
	radio = "radio",
	warning = "warning",
	laptopVideo = "laptop-video",

	// m2
  checkTabletVideoNote = "check-tablet-video-note",
  tdprinter = "3dprinter",
  aitab = "aitab",
  bench = "bench",
  book = "book",
  bluetooth = "bluetooth",
  flatsat = "flatsat",
  keys = "keys",
  password = "password",
  rubiks = "rubiks",
  safe = "safe",
  specsheet = "specsheet",
  shoppingSat = "shopping-sat",
  tablet = "tablet",

  // m3
  m3Video = "m3-video",
  commandScreen = "command-screen",

  // m4
  m4Video = "m4-video",
  station1 = "station-1",
  station2 = "station-2",
  station3 = "station-3",
  launch = "launch",
  end = "end"
};

interface ISectionContext {
  activeSection: SectionName,
  scrollLeft: number,
  contentHeight: number,
  contentWidth: number,
  completedFaseOne: boolean,
  completedFaseTwo: boolean,
  completedFaseThree: boolean,
  completedFaseFour: boolean,
  audioPaused: boolean,
  mission: number,
  isLandscape: boolean
}

export const SectionContext = createContext<ISectionContext>({
  activeSection: SectionName.empty,
  scrollLeft: 0,
  contentHeight: 0,
  contentWidth: 0,
  completedFaseOne: false,
  completedFaseTwo: false,
  completedFaseThree: false,
  completedFaseFour: false,
  audioPaused: false,
  mission: 0,
  isLandscape: true
});

export const getContextPos = (num: number | "auto", winSize: number) => {
  if (num !== "auto") {
    return `${num / 100 * winSize}px`;
  }
  return num
}
