import { h, JSX } from 'preact';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { Section } from './section';
import { Img } from "./img"
import { SectionContext, SectionName } from "../section-context"
import { config } from "../config"
import { useDraggable } from '../hooks/useDraggableScroll';
import { M1 } from "./m1"
import { M2 } from "./m2"
import { M3 } from "./m3"
import { M4 } from "./m4"
import ReactGA from 'react-ga4'

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if ((document as any).webkitExitFullscreen) {
    (document as any).webkitExitFullscreen()
  }
}

let localStorage: any = { getItem: () => null, setItem: () => null };
try {
  if (typeof window !== 'undefined') {
    localStorage = window.localStorage;
  }
} catch (e) {
  console.log("cant use localstorage")
}

const App = () => {
  const clickSound  = useRef(typeof window !== "undefined" ? new Audio(`${config.m1SoundFolder}exit.ogg`) : null);
  const playCloseSection = () => {
    clickSound.current.volume = 0.3
    clickSound.current.play()
  }
  const ref = useRef(null);
  const blockedVidRef = useRef<HTMLVideoElement>(null);
  const [activeSection, setActiveSection] = useState<SectionName>(SectionName.blocked);
  const [completedFaseOne, setCompletedFaseOne] = useState<boolean>(JSON.parse(localStorage.getItem('completedFaseOne')) || false);
  const [completedFaseTwo, setCompletedFaseTwo] = useState<boolean>(JSON.parse(localStorage.getItem('completedFaseTwo')) || false);
  const [completedFaseThree, setCompletedFaseThree] = useState<boolean>(JSON.parse(localStorage.getItem('completedFaseThree')) || false);
  const [completedFaseFour, setCompletedFaseFour] = useState<boolean>(JSON.parse(localStorage.getItem('completedFaseFour')) || false);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [contentWidth, setContentWidth] = useState<number>(0);
  const { events: { onMouseDown } } = useDraggable(ref);
  const [ activeMission, setActiveMission ] = useState(0);
  const [ audioPaused, setAudioPaused ] = useState(true);
  const [ isLandscape, setIsLandscape ] = useState(false)
  const [ isIphone, setIsIphone ] = useState(false)

  const completeFaseOne = useCallback(() => {
    localStorage?.setItem('completedFaseOne', JSON.stringify(true))
    setCompletedFaseOne(true)
  }, [])

  const completeFaseTwo = useCallback(() => {
    localStorage?.setItem('completedFaseTwo', JSON.stringify(true))
    setCompletedFaseTwo(true)
  }, [])

  const completeFaseThree = useCallback(() => {
    localStorage?.setItem('completedFaseThree', JSON.stringify(true))
    setCompletedFaseThree(true)
  }, [])

  const completeFaseFour = useCallback(() => {
    localStorage?.setItem('completedFaseFour', JSON.stringify(true))
    setCompletedFaseFour(true)
  }, [])

  const safeOnMouseDown = useCallback((evt: JSX.TargetedMouseEvent<HTMLElement>) => {
    if (activeSection === SectionName.empty) {
      onMouseDown(evt);
    }
  }, [onMouseDown])

  const setSectionLeft = useCallback(() => {
    setScrollLeft(ref.current.scrollLeft);
  }, [])

  useEffect(() => {
    const el = document.body
    const _isIphone = !!location.href.match(/ios$/) || (!el.requestFullscreen && !(el as any).webkitRequestFullScreen)
    setIsIphone(!!location.href.match(/ios$/))
    setIsLandscape(window.screen.height > window.screen.width)
    ReactGA.initialize(location.hostname === 'mission.hackasat.com' ? 'G-T1491B42RE' : 'G-HC50LPM2CE');
    const resize = () => {
      setContentHeight(_isIphone ? window.screen.width : window.screen.height)
      setContentWidth(_isIphone ? window.screen.height: window.screen.width)
    }

    const blockExperience = () => {
      if (!window.document.fullscreenElement && !(window.document as any).webkitFullscreenElement) {
        if (blockedVidRef.current && !blockedVidRef.current.paused) {
          blockedVidRef.current.pause();
        }
        setAudioPaused(true)
        setActiveSection(SectionName.blocked);
        ReactGA.send({ hitType: "pageview", page: "/blocked" });
        setActiveMission(0);
      }
    }

    setContentHeight(_isIphone ? window.screen.width : window.screen.height)
    setContentWidth(_isIphone ? window.screen.height : window.screen.width)
    window.addEventListener("resize", resize);

    if (document.body.requestFullscreen) {
      window.addEventListener('fullscreenchange', blockExperience);
    } else if ((document.body as any).webkitRequestFullScreen) {
      window.addEventListener('webkitfullscreenchange', blockExperience);
    } else {
      blockedVidRef.current.addEventListener('webkitendfullscreen', blockExperience);
    }

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener('fullscreenchange', blockExperience);
      window.removeEventListener('webkitfullscreenchange', blockExperience);
      blockedVidRef.current.removeEventListener('webkitendfullscreen', blockExperience);
    }
  }, []);

  const goToSection = useCallback((section: SectionName, maximize: boolean = false, override = false) => (evt?: any) => {
    const classes = evt?.target?.classList
    if (override ||
        evt.target instanceof Image || 
        classes?.contains("experience__section") ||
        classes?.contains("experience__section__clickable") ||
        classes?.contains("experience__section--back-background") ||
        classes?.contains("black-bg")) {
      setActiveSection(section)
      ReactGA.send({ hitType: "pageview", page: `/${section}` });
      if (section === SectionName.empty) {
        playCloseSection()
      }
    }
    const el = ref?.current;
    if (!isIphone && maximize && el) {
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen((Element as any).ALLOW_KEYBOARD_INPUT);
      } else if (window !== top) {
        // this will probably be the path for iOS
        window.open(`${window.location.href}?ios`)
      }
      setAudioPaused(false);
    }
  }, [playCloseSection, activeMission, isIphone])

  const pickExperience = useCallback((experience: number) => {
    setActiveMission(experience)
  }, [goToSection])

  const doc = typeof window !== "undefined" ? window.document : {}

  const close = useCallback(() => {
    exitFullscreen()
    pickExperience(0)
  }, []);

  const missionProps = useMemo(() => ({ 
    setAudioPaused,
    goToSection,
    close
  }), [setAudioPaused, goToSection, close])

  return (
    <SectionContext.Provider value={{ activeSection, scrollLeft, contentHeight, contentWidth, completedFaseOne, completedFaseTwo, completedFaseThree, completedFaseFour, audioPaused, mission: activeMission, isLandscape }}>
      <div id="app" class={`app-iframed ${isIphone ? "app-iphone" : ""}`}>
        <div class="browser-container" style="width: 100%">
          <div class="h-exp browser">
            <Section name={SectionName.blocked} close={() => {}} nopos>
              <Img mission={0} src={contentWidth < 1000 ? "mobile-header.svg": "blocked-hover.svg"} style={{
                zIndex: -1,
                margin: "auto",
                left: 0,
                right: 0,
                maxWidth: contentWidth < 1000 ? "100%" : "75%",
                maxHeight: "100%"
              }} />
              <div class="experience__missions">
                <button onClick={() => pickExperience(1)}>1</button>
                <button onClick={() => pickExperience(2)}>2</button>
                <button onClick={() => pickExperience(3)}>3</button>
                <button onClick={() => pickExperience(4)}>4</button>
              </div>
            </Section>
            <div class="browser__title">
              <span style="text-overflow: ellipsis; max-width: 65%; display: inline-block;">hack-a-sat 3 /// learn. space. faster.</span>
              <button class="browser__close"></button>
              <button class="browser__max"></button>
              <button class="browser__min"></button>
            </div>
            <div class={`browser__content mission-${activeMission}`} ref={ref} onScroll={setSectionLeft} onMouseDown={safeOnMouseDown} style={{ height: (doc as any)?.webkitFullscreenElement ? "100%" : ""}}>
              <div class="my-trophies">
                { completedFaseOne && activeSection !== SectionName.blocked && (
                  <div class="fase-one-trophy-icon">
                    <Img src="m1-trophy-sm.png" mission={0}/>
                  </div>
                )}
                { completedFaseTwo && activeSection !== SectionName.blocked && (
                  <div class="fase-two-trophy-icon">
                    <Img src="m2-trophy-sm.png" mission={0}/>
                  </div>
                )}
                { completedFaseThree && activeSection !== SectionName.blocked && (
                  <div class="fase-three-trophy-icon">
                    <Img src="m3-trophy-sm.png" mission={0}/>
                  </div>
                )}
                { completedFaseFour && activeSection !== SectionName.blocked && (
                  <div class="fase-four-trophy-icon">
                    <Img src="m4-trophy-sm.png" mission={0}/>
                  </div>
                )}
              </div>
              { activeMission === 0 && contentWidth > 1000 && (
                <>
                  <Img src="blocked-bg.jpg" style={{ height: "100%", aspectRatio: "2.9905" }}/>
                </>
              )
              }
              {
                activeMission === 1 && (<M1 complete={completeFaseOne} {...missionProps} goToM2={() => setActiveMission(2)} />)
              }
              {
                activeMission === 2 && (<M2 complete={completeFaseTwo} {...missionProps} goToM1={() => setActiveMission(1)} />)
              }
              {
                activeMission === 3 && (<M3 complete={completeFaseThree} {...missionProps} />)
              }
              {
                activeMission === 4 && (<M4 complete={completeFaseFour} {...missionProps} />)
              }
            </div>
          </div>
        </div>
      </div>
    </SectionContext.Provider>
  )

}

export default App;
