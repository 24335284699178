import { FunctionalComponent, h } from 'preact';
import { Section } from './section';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useLoadAssets } from '../hooks/useAssets'
import { SectionContext, SectionName } from "../section-context"
import { Img } from "./img"
import { config } from "../config"
import ReactGA from 'react-ga4'
const m4Assets = require('../assets/m4.json')
const m4smAssets = require('../assets/m4_sm.json')
import { Player } from '@lottiefiles/react-lottie-player';
import { Video } from "./video"
import { useSound } from "../hooks/useSound"
import { Clickable } from './clickable'
import { StationOne } from './stationOne'
import {ITestComponent} from '../hooks/useTestComponents';
import {StationTwo} from './stationTwo';
import {StationThree} from './stationThree';

interface IProps {
  goToSection: (...params: any) => (...params: any) => void,
  setAudioPaused: (...params: any) => void,
  complete: (...params: any) => void,
}

export const M4: FunctionalComponent<IProps> = ({ setAudioPaused, complete, goToSection }) => {
  const ref = useRef(null)
  const { activeSection, contentWidth, contentHeight, audioPaused } = useContext(SectionContext)
  const [ mute, setMute ] = useState(false)
  const [ volume, setVolume ] = useState<number>(50);
  const [ videoPlaying, setVideoPlaying ] = useState(true)
  const [ finalVideoPlaying, setFinalVideoPlaying ] = useState(true)
  const [ allAssetsLoaded, setAllAssetsLoaded ] = useState(false)
  const [ totalLoadedAssets, setTotalLoadedAssets ] = useState(0)
  const [ clickSound ] = useSound(config.m4SoundFolder + 'click.ogg', { volume: 0.5 })
  const assetLoaded = useCallback((assets: number) => {
    setTotalLoadedAssets(assets)
  }, [])
  const bgAudioRef  = useRef(typeof window !== "undefined" ? new Audio(`${config.m4SoundFolder}background_music.ogg`) : null);
  const [ finalVideoPlayed, setFinalVideoPlayed ] = useState(false)

  if (bgAudioRef) {
    bgAudioRef.current.autoplay = true
  }

  useEffect(() => {
    bgAudioRef.current.volume = volume / 100 / 2;
  }, [volume]) 

  useEffect(() => {
    bgAudioRef.current.muted = mute
  }, [mute]) 

  useEffect(() => {
    bgAudioRef.current.loop = true
    if (audioPaused && !bgAudioRef.current.paused) {
      bgAudioRef.current.pause()
    } else {
      setTimeout(() => {
        bgAudioRef.current.play()
      }, 100)
    }
  }, [audioPaused])

  const cbAllAssetsLoaded = useCallback(() => {
    setAllAssetsLoaded(true)
    setTimeout(() => {
      ref.current.parentNode.scrollLeft = ref.current.parentNode.scrollWidth
    }, 1)
  }, [])

  const TPlayer = (Player as any);
  const { loadAssets } = useLoadAssets(contentWidth > 1200 ? m4Assets : m4smAssets, assetLoaded, cbAllAssetsLoaded)
  const loadingPercentage = useMemo(() => {
    return Math.round(totalLoadedAssets / m4Assets.length * 100)
  }, [totalLoadedAssets])

  const unpauseBgAudio = useCallback(() => { setAudioPaused(false) }, [setAudioPaused]);
  const pauseBgAudio = useCallback(() => { setAudioPaused(true) }, [setAudioPaused]);

  useEffect(() => {
    loadAssets()
    goToSection(SectionName.empty, true, true)()
  }, []) // goToSection and loadAssets are explicitely excluded

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/mission-4" });
    return () => {
      if (bgAudioRef.current && !bgAudioRef.current.paused) {
        bgAudioRef.current.pause()
      }
    }
  }, [])

  const [ chosenComponents, setChosenComponents ] = useState<ITestComponent[]>([])

  const finalVideoEnded = useCallback(() => {
    ReactGA.event("m4_final_video__completed");
    ReactGA.event({
      category: "m4_final_video",
      action: "completed",
    });
    setFinalVideoPlayed(true)
  }, [goToSection, ReactGA])

  const videoEnded = useCallback(() => {
    ReactGA.event("m4_start_video__completed");
    ReactGA.event({
      category: "m4_start_video",
      action: "completed",
    });
    goToSection(SectionName.station1, false, true)()
  }, [goToSection, ReactGA])

  const showVideo = useCallback(() => { 
    ReactGA.event("m4_video__open");
    ReactGA.event({
      category: "m4_video",
      action: "open",
    });
    clickSound();
    pauseBgAudio();
    goToSection(SectionName.m4Video, false, true)() 
  }, [goToSection, pauseBgAudio, clickSound])

  useEffect(() => {
    if (activeSection === SectionName.m4Video) {
      setVideoPlaying(true)
    }
    if (activeSection === SectionName.end) {
      setFinalVideoPlaying(true)
      pauseBgAudio()
    }
  }, [activeSection])

  const finishStationThree = useCallback(() => {
    complete(),
    goToSection(SectionName.end, false, true)()
  }, [complete, goToSection])

  const review = useCallback(() => {
    goToSection(SectionName.station1, false, true)()
    setChosenComponents([])
  }, [goToSection])

  if (!allAssetsLoaded) {
    return (
      <div class="loading-overlay">
        <div class="loading-bar" style={{width: `${loadingPercentage*5}px`}} />
        {loadingPercentage}%
      </div>
    )
  } else if (allAssetsLoaded) {
    return (
      <div class="m4-container" ref={ref}>
        <div id="please-turn-phone">
          <h1>Please rotate your phone to access White Rabbit's workshop</h1>
        </div>
        <Clickable disabled={!chosenComponents[0]} x={13.5} y={73} width={50} action={goToSection(SectionName.station2, false, true)}  sound="click" opacityClass="m4-clickable" shine={false}>
          <Img src="station2.png" style={{maxWidth: "unset", maxHeight: "unset", position: "absolute", opacity: 1}}/>
          <Img src="station2sign.svg" class="m4-sign" style={{ left: "25%", top: "-22%", width: "45%"}} />
          <Img src="station2hover.svg" style={{maxWidth: "unset", maxHeight: "unset"}}/>
        </Clickable>
        <Clickable disabled={!chosenComponents[1]} x={103} y={43.5} width={68} action={goToSection(SectionName.station3, false, true)}  sound="click" opacityClass="m4-clickable" shine={false}>
          <Img src="station3.png" style={{maxWidth: "unset", maxHeight: "unset", position: "absolute", opacity: 1}}/>
          <Img src="station3sign.svg" class="m4-sign" style={{ left: "55%", top: "26%", width: "27%"}} />
          <Img src="station3hover.svg" style={{maxWidth: "unset", maxHeight: "unset"}}/>
        </Clickable>
        <Clickable x={61} y={70.5} width={60} action={showVideo}  sound="click" opacityClass="m4-clickable m4-clickable--radius" shine={false}>
          <Img src="station1.png" style={{maxWidth: "unset", maxHeight: "unset", position: "absolute", opacity: 1, width: "184.5%", left: "-40%", top: "-21%"}}/>
          <Img src="station1sign.svg" class="m4-sign" style={{ left: "25.5%", top: "8%", width: "50%"}} />
          <Img src="station1hover.svg" style={{maxWidth: "unset", maxHeight: "unset"}}/>
        </Clickable>
        <Section name={SectionName.m4Video} transition="fade-in">
          <div class="browser m4-main-browser">
            <div class="browser__title">
              <button class="browser__close" onClick={goToSection(SectionName.station1, false, true)}></button>
              <button class="browser__max"></button>
              <button class="browser__min" onClick={goToSection(SectionName.station1, false, true)}></button>
            </div>

            <div class="browser__content browser__content--video">
              <Video 
                src="https://player.vimeo.com/progressive_redirect/playback/755718247/rendition/1080p/file.mp4?loc=external&signature=5686bfc1f0f7e6f4eb1d564f4e9c22098c68bfce3fb7a0ec544d1647197aa536"
                playVideo={() => { pauseBgAudio(); setVideoPlaying(true) }}
                stopVideo={() => { unpauseBgAudio(); setVideoPlaying(false) }}
                controls
                playing={videoPlaying}
                videoEnded={videoEnded}
                />
            </div>
          </div>
        </Section>
        <StationOne close={goToSection(SectionName.empty, false, true)} testingComponents={chosenComponents} setTestingComponents={setChosenComponents} finish={goToSection(SectionName.station2, false, true)}/>
        <StationTwo close={goToSection(SectionName.empty, false, true)} testingComponents={chosenComponents} finish={goToSection(SectionName.station3, false, true)} review={review}/>
        <StationThree close={goToSection(SectionName.empty, false, true)} testingComponents={chosenComponents} finish={finishStationThree} review={review}/>
        <Section name={SectionName.end} transition="fade-in">
          { !finalVideoPlayed ? (
          <Video 
            src="https://player.vimeo.com/progressive_redirect/playback/757676141/rendition/720p/file.mp4?loc=external&signature=b19ea1e4c6ea61f8509963b484e42a66c7531258c1a4d016a9cd50f2caaf7214"
            playVideo={() => { pauseBgAudio(); setFinalVideoPlaying(true) }}
            stopVideo={() => { unpauseBgAudio(); setFinalVideoPlaying(false) }}
            controls={false}
            playing={finalVideoPlaying}
            videoEnded={finalVideoEnded}
            />
          ) : (
            <>
              <h1>BUILD-A-SAT PROGRAM COMPLETE.</h1>
              <p>Visit the Workshop Assets page and grab some cool stuff to brag about your satellite awesomeness!</p>
              <Img src="Trophy4Graphic-Large.png" />
              <Img onClick={() => window.open("https://hackasat.com/assets")} src="workshopassetsbutton.svg" />
            </>
          )} 
        </Section>
      </div>
    )
  }
}

