import { FunctionalComponent, h, JSX } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { useSound } from "../hooks/useSound"
import { config } from "../config"
import { Img } from "./img"
import {usePagination} from '../hooks/usePagination';

interface IProps {
  close: (...params: any) => void,
}

const galleryTotal = 3;

export const DocumentsSection: FunctionalComponent<IProps> = ({ close }) => {
  const { idx: galleryIdx, back, next } = usePagination(galleryTotal, 0, `${config.m1SoundFolder}page-flip.ogg`)

  const changePage = useCallback((evt: JSX.TargetedMouseEvent<HTMLImageElement>) => {
    const target = (evt.target as HTMLImageElement);
    if (target.nodeName === "IMG" && target.src.includes("documents")) {
      evt.preventDefault()
      evt.stopPropagation()
      const rect = evt.currentTarget.getBoundingClientRect();
      if ((evt.clientX - rect.left) / rect.width > 0.5) {
        next();
      } else {
        back();
      }
    }
  }, [next, back])


  return (
    <div class="specs-gallery" onClick={changePage}>
      { galleryIdx === 0 && <Img src="documents-1.png" close={close} />}
      { galleryIdx === 1 && <Img src="documents-2.png" close={close} />}
      { galleryIdx === 2 && <Img src="documents-3.png" close={close} />}
    </div>
  )
};
