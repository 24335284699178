import { FunctionalComponent, h, JSX } from 'preact';
import { useCallback, useContext, useEffect, useState } from 'preact/hooks';
import { SectionContext, SectionName } from "../section-context"
import { Section } from './section';
import { useTestComponents, ITestComponentCategory, ITestComponent } from '../hooks/useTestComponents'
import { Img } from "./img"
import useSound from '../hooks/useSound';
import {config} from '../config';

interface IProps {
  testingComponents: any[],
  close: (...params: any) => void,
  finish: (...params: any) => void,
  setTestingComponents: (...params: any) => void
}

export const StationOne: FunctionalComponent<IProps> = ({ close, testingComponents, setTestingComponents, finish }) => {
  const [ currentComponent, setCurrentComponent ] = useState<ITestComponent | null>(null)
  const { testComponents } = useTestComponents()
  const [ currentCategory, setCurrentCategory ] = useState<ITestComponentCategory>("component")
  const [ continueSound ] = useSound(config.m4SoundFolder + 'continue_to_s2.ogg', { volume: 0.5 })
  const { activeSection } = useContext(SectionContext)

  const selectComponent = useCallback(() => {
    testingComponents[currentCategory === "test" ? 1 : 0] = currentComponent
    setTestingComponents([ ... testingComponents ])
    if (currentCategory === "test" as ITestComponentCategory) {
      continueSound()
      finish()
    } else {
      setCurrentCategory("test")
      setCurrentComponent(null)
    }
  }, [currentComponent, currentCategory, testingComponents, setTestingComponents])

  const goBack = useCallback(() => {
    setCurrentComponent(null)
    if (currentCategory === "test") {
      setCurrentCategory("component")
    } else {
      close()
    }
  }, [currentCategory])

  useEffect(() => {
    setCurrentComponent(null)
    setCurrentCategory("component")
  }, [activeSection])

  return (
    <Section name={SectionName.station1} transition="fade-in">
      <Img src="backbutton.svg" onClick={goBack} class="mission-4__btn-back"/>
      { currentComponent === null ? (
        <div class="station-one__welcome-screen">
          <h1>CHOOSE YOUR LAUNCH VEHICLE<br />AND VIBRATION DAMPENER SYSTEMS</h1>
          {/*<h3>You have {initialCredits} credits and {initialHours} hours of worktime to spend.</h3> */}
          <p>
            A more expensive launch vehicle means a smoother ride for your satellite, but you can also take measures to make
    your satellite more shock-resistant on a lower-cost launch vehicle. Don’t blow your whole budget here!
          </p>
        </div>
      ) : (
        <div class="station-one__component-detail">
          <div class="col-8">
            <h1>{ currentComponent.name }</h1>
            <p>{ currentComponent.description}</p>
            <button onClick={() => selectComponent()}><Img src="station-1/btn-select.svg" /></button>
          </div>
          <div class="col-4">
            { currentComponent && <Img src={`station-1/${currentComponent.image}.svg`} />}
            { currentCategory === "test" && <Img src="station-1/station1-verify-satgraphic.svg" />}
          </div>
        </div>
      )}
      <div class="station-one__buttons">
        { testComponents.filter(c => c.category === currentCategory ).map((c, idx) => (
          <button key={c.name} onClick={() => setCurrentComponent(c)} class={`${currentComponent === c?"button--active":""}`}>
            { c.category === "component" ? (
              <>
                <div class="col-9 col-sm-12">
                  <h4>{c.name}</h4>
                  <p>
                    install: {c.line1}
                  </p>
                  <p>
                    launch vehicle: {c.line2}
                  </p>
                  <p>
                    {/*cost: {c.cost} CREDITS, {c.hours}HRS*/}
                  </p>
                </div>
                <div class="col-3 hide-sm">
                  <Img src={`station-1/${c.thumb}.svg`} />
                </div>
              </>
            ) : (
              <>
                <div class="col-3">
                  <h1 style={{textAlign: "center"}}>LVL <br/>{idx+1}</h1>
                </div>
                <div class="col-9">
                  <h4>{c.name}</h4>
                  <p>
                    scope: {c.line1}
                  </p>
                  <p>
                    {/*cost: {c.cost} CREDITS, {c.hours}HRS*/}
                  </p>
                </div>
              </>
            )}
          </button>
        ))}
      </div>
    </Section>
  )
}

