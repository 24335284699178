import { FunctionalComponent, h, JSX } from 'preact';
import { useCallback, useState, useEffect, useMemo, useContext, useRef } from 'preact/hooks';
import { Section } from './section';
import { SectionContext, SectionName } from "../section-context"
import { config } from "../config"
import { Img } from "./img"
import { Clickable } from './clickable';
import { LoginSection } from './loginSection';
import { PrintSection } from './printSection';
import { DocumentsSection } from './documentsSection'
import { RadioSection } from './radioSection'
import { TerminalSection } from './terminalSection'
import { LaptopVideoSection } from './laptopVideoSection'
import { Close } from "./close"
import { useLoadAssets } from '../hooks/useAssets'
import ReactGA from 'react-ga4';
const m1Assets = require('../assets/m1.json')
const m1smAssets = require('../assets/m1_sm.json')

interface IProps {
  goToSection: (...params: any) => (...params: any) => void,
  setAudioPaused: (...params: any) => void,
  goToM2: (...params: any) => void, 
  complete: (...params: any) => void,
}

const galleryTotal = 3;

export const M1: FunctionalComponent<IProps> = ({ 
  complete,
  setAudioPaused,
  goToSection,
  goToM2
}) => {
  const ref = useRef(null)
  const { audioPaused, contentHeight, contentWidth } = useContext(SectionContext)
  const [ allAssetsLoaded, setAllAssetsLoaded ] = useState(false)
  const [ firstOpen, setFirstOpen ] = useState(true)
  const [ totalLoadedAssets, setTotalLoadedAssets ] = useState(0)
  const assetLoaded = useCallback((assets: number) => {
    setTotalLoadedAssets(assets)
  }, [])
  const loadingPercentage = useMemo(() => {
    return Math.round(totalLoadedAssets / m1Assets.length * 100)
  }, [totalLoadedAssets])
  const [ authenticated, setAuthenticated ] = useState(config.disableScreenPassword)
  const [ printerShine, setPrinterShine ] = useState(false)
  const [ loginShine, setLoginShine ] = useState(true)

  const unpauseAudio = useCallback(() => setAudioPaused(false), []);
  const pauseAudio = useCallback(() => {
    setAudioPaused(true)
  }, []);

  const cbAllAssetsLoaded = useCallback(() => {
    setAllAssetsLoaded(true)
    setTimeout(() => {
      ref.current.parentNode.scrollLeft = 500;
    }, 1)
  }, [])

  const { loadAssets } = useLoadAssets(contentWidth > 1200 ? m1Assets : m1smAssets, assetLoaded, cbAllAssetsLoaded)

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/mission-1" });
  }, [])

  useEffect(() => {
    if (firstOpen) {
      loadAssets()
      setFirstOpen(false)
    } else {
      goToSection(SectionName.empty, true, true)()
    }
  }, [firstOpen]) // goToSection and loadAssets are explicitely excluded

  const validLogin = useCallback(() => { 
    setAuthenticated(true)
    setPrinterShine(true)
    setLoginShine(false)
    pauseAudio()
  }, [pauseAudio])

  const printerClickAction = useCallback((evt: JSX.TargetedEvent) => {
    if (printerShine) {
      goToSection(SectionName.printer)(evt)
    } else {
      goToSection(SectionName.printerDisabled)(evt)
    }
  }, [goToSection, printerShine])


  if (!firstOpen && !allAssetsLoaded) {
    return (
      <div class="loading-overlay">
        <div class="loading-bar" style={{width: `${loadingPercentage*5}px`}} />
        {loadingPercentage}%
      </div>
    )
  } else if (allAssetsLoaded) {
    return (
      <>
        <div id="please-turn-phone">
          <h1>Please rotate your phone to access White Rabbit's workshop</h1>
        </div>
        <Img class="experience__background" src="base-background.jpg" draggable style={{ height: `${contentHeight}px` }} ref={ref}/>
        <Clickable x={105} y={48.4} width={31.2} action={goToSection(SectionName.laptopVideo)} sound="radio">
          <Img src="laptop-hover.svg" />
        </Clickable>
        <Clickable x={163.3} y={9.5} width={16.25} action={goToSection(SectionName.radio)} sound="radio">
          <Img src="radio-hover.svg" />
        </Clickable>
        <Clickable x={151.5} y={19} width={15.2} action={goToSection(SectionName.bike)} sound="bike">
          <Img src="bike-hover.svg" />
        </Clickable>
        <Clickable x={171.5} y={52} width={14.5} action={goToSection(SectionName.documents)} sound="folder">
          <Img src="files-hover.svg" />
        </Clickable>
        <Clickable x={202} y={51} width={29} action={printerClickAction} sound="printer" shine={printerShine}>
          <Img src="printer-hover.svg" />
        </Clickable>
        <Clickable x={176.25} y={66} width={24.5} action={goToSection(SectionName.recipe)} sound="ramen">
          <Img src="ramen-hover.svg" />
        </Clickable>
        <Clickable x={71} y={34} width={34} action={goToSection(SectionName.terminal)} sound="click-enter">
          <Img src="terminal-hover.svg" />
        </Clickable>
        <Clickable x={130.25} y={37} width={44} height={26} action={goToSection(SectionName.login)} shine={loginShine} sound="click-enter">
          <Img src="login-hover.svg" />
        </Clickable>
        <Clickable x={312.5} y={18} width={99} action={goToM2} sound="click-enter">
          <Img src="m2-hover.svg" />
        </Clickable>


        {/* Sections! */}
        <Section name={SectionName.instructions} close={goToSection(SectionName.empty, false, true)}>
          <Img src="white-rabbit-note.svg" />
        </Section>
        <Section name={SectionName.bike} close={goToSection(SectionName.empty)} title="Vrooooom" subtitle="It's my motorcycle from Hack-A-Sat 1. Good times...">
          <Close close={goToSection(SectionName.empty)}/>
          <Img src="bike.png" />
        </Section>

        <Section name={SectionName.login} close={goToSection(SectionName.empty)} title="" subtitle="" transition="fade-in">
          <div class="experience__section--back-background experience__section--transition-fade-in" style={`width: 100vw; height: 100vh; background-image: url('${config.m1ImgFolder}/login-workstation.jpg');`}>
            <LoginSection close={goToSection(SectionName.empty, false, true)} playVideo={validLogin} stopVideo={unpauseAudio} authenticated={authenticated}/>
          </div>
        </Section>
        
        <Section name={SectionName.documents} close={goToSection(SectionName.empty)} title="Desk Files" subtitle="Just some behind-the-scenes info for the event. Definitely not anything interesting if you look closer...">
          <DocumentsSection close={goToSection(SectionName.empty, false, true)} />
        </Section>

        <Section name={SectionName.printerDisabled} close={goToSection(SectionName.empty)}>
          <Close close={goToSection(SectionName.empty)}/>
          <div class="flex-center-content">
            <div class="experience__section__title">
              There's nothing here... yet<br/>
              The queue light is blinking, maybe the computer has more info?
            </div>
          </div>
        </Section>

        <Section name={SectionName.printer} close={goToSection(SectionName.empty)}>
          <PrintSection close={goToSection(SectionName.empty, false, true)} completedFaseOne={complete}/>
        </Section>

        <Section name={SectionName.terminal} close={goToSection(SectionName.empty)} title="A Terminal" subtitle="It sure FEELS like being a badass super-hacker..." transition="fade-in">
          <TerminalSection close={goToSection(SectionName.empty, false, true)} />
        </Section>

        <Section name={SectionName.recipe} close={goToSection(SectionName.empty)} title="Ramen Recipe" subtitle="It’s an old Rabbit family secret recipe. Dare you to try it!">
          <Img src="ramen.png" close={goToSection(SectionName.empty)} class="main-image" />
        </Section>

        <Section name={SectionName.radio} close={goToSection(SectionName.empty)} title="" subtitle="">
          <RadioSection paused={audioPaused} />
        </Section>
        <Section name={SectionName.laptopVideo} close={goToSection(SectionName.empty)} title="" subtitle="">
          <LaptopVideoSection close={goToSection(SectionName.empty, false, true)} playVideo={pauseAudio} stopVideo={unpauseAudio} />
        </Section>
      </>
    )
  }
}
