import { FunctionalComponent, h, JSX } from 'preact';
import { Section } from './section';
import { SectionName } from '../section-context';
import { Img } from "./img"
import {useCallback, useContext, useEffect, useState} from 'preact/hooks';
import {SectionContext} from '../section-context';
import { Close } from "./close"
import useSound from '../hooks/useSound';
import { config } from "../config"
import { Video } from "./video"
import ReactGA from "react-ga4"

interface IProps {
  close: (...params: any) => void
  playVideo: (...params: any) => void
  stopVideo: (...params: any) => void
}

const numbers = [
  { num: 1, letters: "" },
  { num: 2, letters: "abc" },
  { num: 3, letters: "def" },
  { num: 4, letters: "ghi" },
  { num: 5, letters: "jkl" },
  { num: 6, letters: "mno" },
  { num: 7, letters: "pqrs" },
  { num: 8, letters: "tuv" },
  { num: 9, letters: "wxyz" },
  { num: 0, letters: "+" }
]

const PinPoint = ({ idx, pin }) => (
  <div class={`section__tablet__screen__pin-point section__tablet__screen__pin-point--${pin[idx]?'on':'off'}`}>
  </div>
)

const KeyPadBtn = ({ number, action }) => (
  <div class="section__tablet__screen__keypad__btn" onClick={() => action(number.num)}>
    <div class="section__tablet__screen__keypad__btn__number">
    {number.num}
    </div>
    <div class="section__tablet__screen__keypad__btn__letters">
      {number.letters}
    </div>
  </div>
)

const disablePassword = config.disableTabletPassword

export const TabletSection: FunctionalComponent<IProps> = ({ close, playVideo: _playVideo, stopVideo: _stopVideo }) => {
  const [ playTabletCharging ] = useSound(`${config.m2SoundFolder}tablet_charging.ogg`)
  const [ playTabletOn ] = useSound(`${config.m2SoundFolder}turn_lamp_on.ogg`, { volume: 0.5 })
  const [ playSwitchOn ] = useSound(`${config.m2SoundFolder}switch-on.ogg`, { volume: 0.5 })
  const [ playTabletUnlocked ] = useSound(`${config.m2SoundFolder}tablet_unlocked.ogg`, { volume: 0.5 })
  const [ playTabletWrongPass ] = useSound(`${config.m2SoundFolder}tablet_wrong_password.ogg`, { volume: 0.5 })
  const { activeSection } = useContext(SectionContext);
  const [ powered, setPowered ] = useState<boolean>(config.disableTabletPower)
  const [ charging, setCharging ] = useState<boolean>(config.disableTabletPower)
  const [ authenticated, setAuthenticated ] = useState<boolean>(disablePassword)
  const [ pin, setPin ] = useState<string>("")
  const [ showingHint, setShowingHint ] = useState<boolean>(false)
  const [ videoPlaying, setVideoPlaying ] = useState<boolean>(false)

  const powerOn  = useCallback((evt: JSX.TargetedEvent) => {
    evt.stopPropagation();
    ReactGA.event("m2_tablet_section__power_on")
    ReactGA.event({
      category: "m2_tablet_section",
      action: "power_on",
    });
    if (!charging) {
      playSwitchOn()
      playTabletCharging()
      setCharging(true)
      setTimeout(() => {
        setPowered(true)
        playTabletOn()
        if (disablePassword) {
          playVideo()
        }
      }, 5000)
    }
  }, [charging, powered, playSwitchOn, playTabletCharging])

  const addPin = useCallback((num: number) => {
    if (pin.length < 4) {
      setPin(pin + ("" + num))
    }
  }, [pin])

  const playVideo = useCallback(() => {
    _playVideo()
    setVideoPlaying(true)
  }, [_playVideo])

  const stopVideo = useCallback(() => {
    _stopVideo()
    setVideoPlaying(false)
  }, [_stopVideo])

  useEffect(() => {
    if (pin.length === 4) {
      if (pin === "1764") {
        ReactGA.event("m2_tablet_section__right_password")
        ReactGA.event({
          category: "m2_tablet_section",
          action: "right_password",
        });
        if (!authenticated) {
          playTabletUnlocked()
          setAuthenticated(true)
          playVideo()
          setVideoPlaying(true)
        }
      } else {
        ReactGA.event("m2_tablet_section__wrong_password")
        ReactGA.event({
          category: "m2_tablet_section",
          action: "wrong_password",
        });
        playTabletWrongPass()
        setPin("")
      }
    }
  }, [pin, authenticated, playVideo, playTabletWrongPass, playTabletUnlocked])

  useEffect(() => {
    if (activeSection !== SectionName.tablet) {
      stopVideo()
    } else {
      playVideo()
    }
  }, [activeSection, stopVideo])

  const showHint = useCallback(() => {
    setShowingHint(true);
  }, [])

  const hideHint = useCallback(() => {
    setShowingHint(true);
    const to = setTimeout(() => setShowingHint(false), 1500);
    return () => clearTimeout(to)
  }, [])

  return (
    <Section name={SectionName.tablet} title="" subtitle="" transition="fade-in">
      <div class={`section__tablet section__tablet--power-${powered?'on':'off'}`}>
        <Close close={close} />
        <Img src={`tablet-power-${charging?'on':'off'}.png`} class="section__tablet__switch" style={{ marginTop: charging ? "-22px" : 0 }} />
        { !charging && (
          <Img src={`tablet-power-${charging?'on':'off'}-hover.svg`} class="section__tablet__switch experience__section__clickable" onClick={powerOn}/>
        )}
        <div class="section__tablet__screen">
          <div class="section__tablet__screen__on">
            { authenticated ? (
              <div class="browser">
                <div class="browser__title">
                  <button class="browser__close" onClick={close}></button>
                  <button class="browser__max"></button>
                  <button class="browser__min" onClick={close}></button>
                </div>

                <div class="browser__content browser__content--video">
                  <Video 
                    src="https://player.vimeo.com/progressive_redirect/playback/720798528/rendition/1080p/file.mp4?loc=external&signature=541aeee991c23ef9dfc367ce30ae2c2d78ee3890c6126cd25c1fceb8501d8915"
                    playVideo={playVideo}
                    stopVideo={stopVideo}
                    controls
                    playing={videoPlaying}
                    />
                </div>
              </div>
            ) : (
              <>
                <h1> Enter password </h1>
                <div class="section__tablet__screen__pin-point-container">
                  <PinPoint idx={0} pin={pin} />
                  <PinPoint idx={1} pin={pin} />
                  <PinPoint idx={2} pin={pin} />
                  <PinPoint idx={3} pin={pin} />
                </div>
                <div class="section__tablet__screen__keypad">
                  <KeyPadBtn number={numbers[0]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[1]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[2]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[3]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[4]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[5]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[6]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[7]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[8]} action={(num: number) => addPin(num)}/>
                  <KeyPadBtn number={numbers[9]} action={(num: number) => addPin(num)}/>
                </div>
                <div class="section__tablet__screen__password-hint" onClick={() => { showHint(); hideHint() }} onMouseEnter={showHint} onMouseLeave={hideHint}>Forgot password?</div>
                { showingHint && (
                  <div class="section__tablet__screen__password-hint__hint">
                    password hint<br />in tool drawer
                  </div>
                )}
              </>
            )}
          </div>
          <div class={`section__tablet__screen__off section__tablet__screen__off--${charging?'charging':'empty'}`}>
            <Img src="batteryoutline.svg" class="section__tablet__screen__battery"/>
            { !charging && <Img src="exclamationmark.svg" class="section__tablet__screen__battery-empty" /> }
            { charging && <Img src="lightningbolt.svg" class="section__tablet__screen__battery-charging" /> }
          </div>
        </div>
      </div>
    </Section>
  )
}
