import { FunctionalComponent, h } from 'preact';
import { useCallback, useMemo, useEffect, useRef, useState, useContext } from 'preact/hooks';
import { useSound } from "../hooks/useSound"
import { config } from "../config"
import { SectionContext, SectionName } from "../section-context"
import { Section } from './section';
import { Img } from './img';
import { SatelliteUpload } from './satelliteUpload';
import { SatelliteGIF } from './satelliteGIF';
import Terminal from 'react-console-emulator'
import {ComponentCategory, findCategoryByKey, IChosenComponentsByCategory, useSatComponents} from '../hooks/useSatComponents';
import ReactGA from 'react-ga4'

const commandPrinter = (output: string[], terminal: Terminal, finish: () => void = () => {}, random = 1000) => {
  let idx = 0
  const cb = () => {
    terminal.current.pushToStdout(output[idx++])
    if (idx < output.length) {
      setTimeout(cb, Math.random() * random)
    } else {
      finish()
    }
    try {
      setTimeout(() => {
        terminal.current.terminalRoot.current.firstChild.scrollTop = terminal.current.terminalRoot.current.firstChild.scrollHeight
        terminal.current.terminalRoot.current.scrollTop = terminal.current.terminalRoot.current.scrollHeight
        terminal.current.focusTerminal()
      }, 1)
    } catch (e) {
    }
  }
  setTimeout(cb, Math.random() * random)
}

const t = config.terminal

const commandList = [ "attitude_sensing", "attitude_adjust_rough", "stabilize", "estimate_parameters", "unload", "set_imager", "test_imager" ]
interface IProps {
  close: (...params: any) => void,
  chosenComponents: IChosenComponentsByCategory[],
  setChosenComponents: (...params: any) => void,
  goToSection: (...params: any) => any,
  setVolume: (...params: any) => any,
  volume: number,
  setMute: (...params: any) => any,
  mute: boolean,
  complete: (...params: any) => any,
}

export interface IErrorMap {
  [key: string]: Array<boolean | null>;
}

export const M3CommandSection: FunctionalComponent<IProps> = ({ close, setChosenComponents, chosenComponents, goToSection, volume, setVolume, mute, setMute, complete }) => {
  const terminal = useRef(null)
  const [ lastCommand, setLastCommand] = useState<string>("")
  const [ lastTestStatus, setLastTestStatus] = useState<boolean>()
  const [ terminalState, setTerminalState] = useState<"uploading" | "testing" | "completed" | "help" | null>(null)
  const [ prompt, setPrompt ] = useState(t.defaultPrompt)
  const [ lockTerminal, setLockTerminal ] = useState(false)
  const [ lockTerminalLine, setLockTerminalLine ] = useState(false)
  const [ showForm, setShowForm ] = useState(false);
  const { categories } = useSatComponents()
  const defaultErrors = {}
  categories.forEach((cat) => defaultErrors[cat.key] = cat.items.map(() => null))
  const [ checked, setChecked ] = useState<IErrorMap>(defaultErrors)
  const [ testSuccessSound ] = useSound(`${config.m3SoundFolder}test_success.ogg`, { volume: 0.5 })
  const [ testFailSound ] = useSound(`${config.m3SoundFolder}test_fail.ogg`, { volume: 0.5 })
  const [ testImagerCount, setTestImagerCount ] = useState<number>(1)
  const { contentWidth } = useContext(SectionContext)
  const isPhone = contentWidth < 1000
  const [ askAgain, setAskAgain ] = useState(true)

  const completeAndTrack = useCallback(() => {
    ReactGA.event("m3_cmd_section__completed");
    ReactGA.event({
      category: "m3_cmd_section",
      action: "completed",
    });
    complete()
  }, [complete])

  const trackDesktopCmd = useCallback(() => {
    if (terminal.current.terminalInput.current.value) {
      ReactGA.event(`m3_cmd_section__terminal__${terminal.current.terminalInput.current.value}`);
      ReactGA.event({
        category: "m3_cmd_section__terminal",
        action: "desktop_cmd",
        label: terminal.current.terminalInput.current.value,
      });
    }
  }, [ReactGA])

  const testCommand = useCallback((cmd: string, description: string, fn: (...params: any) => boolean | string) => {
    return {
      description,
      fn: async() => {
        const lastCmd = terminal.current.terminalInput.current.value
        trackDesktopCmd()
        const result = fn()
        if (typeof result === "string") {
          terminal.current.pushToStdout(result)
          return null
        }
        const output = t[lastCmd][result ? 0 : 1]
        setLockTerminal(true)
        const wait2s = new Promise(resolve => {
          commandPrinter(output.split("\n"), terminal, () => {
            resolve('resolved')
            setLockTerminal(false)
            if (!result) {
              testFailSound()
            }
            terminal.current.focusTerminal()
          }, 500);
        });
        setTerminalState("testing")
        setLastCommand(cmd)
        await wait2s
      }
    }
  }, [ testFailSound ])
  const testComponent = useCallback((categoryKey: ComponentCategory, groupIdx: number) => {
    let result: boolean;
    const lastCmd = terminal.current.terminalInput.current.value;
    const check = (expectedValue: string = "") => {
      const testResult = expectedValue === "" || findCategoryByKey(categoryKey, chosenComponents).picks[groupIdx].filename !== expectedValue
      const _checked = { ...checked }
      _checked[categoryKey][groupIdx] = testResult
      setLastTestStatus(testResult)
      result = testResult
      setChecked(_checked)
    }

    const checks = {
      "Command": {
        1: [ "Command-202", checked["NavAttitude"][4], "stabilize" ]
      },
      "EarthObserv": {
        0: [ "EarthObserv-103", checked["PowerMgmt"][2], "estimate_parameters" ]
      },
      "NavAttitude": {
        0: ["NavAttitude-102", checked["RadioTelemetry"][2], "attitude_sensing" ],
        4: [ "NavAttitude-502", checked["NavAttitude"][0], "attitude_adjust_rough" ]
      },
      "RadioTelemetry": {
        2: [ "RadioTelemetry-301", true, "" ]
      },
      "Structure": {},
      "PowerMgmt": {},
    }

    const test = checks[categoryKey][groupIdx]
    if (test) {
      if (!test[1]) {
        return `"${test[2]}" must be successfully tested before "${lastCmd}"`
      } else {
        check(test[0])
      }
    } else {
      check()
    }

    return result;
  }, [chosenComponents, checked])
  const terminalCommands = useMemo(() => ({
    input_design: {
      description: "Input your design into the digital twin to be tested.",
      fn: () => {
        trackDesktopCmd()
        setPrompt(t.commandPrompt)
        setLockTerminal(true)
        setTerminalState("uploading")
        setChosenComponents(chosenComponents.slice(0).map(c => {
          return {
            ...c,
            picks: c.picks.map(() => null)
          }
        }))
        return "Please pick all the parts that you have chosen for your satellite"
      }
    },
    commission_sat: {
      description: "Run the digital twin simulating the process of activating a satellite.",
      fn: () => {
        trackDesktopCmd()
        const allUploaded = chosenComponents.every(c => c.picks.every(c => c));
        if (!allUploaded) {
          terminal.current.pushToStdout("You need to input your design first")
        } else {
          setTerminalState("testing")
          terminal.current.clearStdout()
          commandPrinter(t.uploadCompleteMsg.split("\n"), terminal, () => setLockTerminal(false), 100)
        }
        
      }
    },
    attitude_sensing: testCommand("attitudesensing", "Establish communications with the satellite.", () => testComponent("RadioTelemetry", 2)),
    attitude_adjust_rough: testCommand("attitudeadjustrough", "Use the ADCS to stop the satellite's tumbling and set an approximate position.",() => testComponent("NavAttitude", 0)),
    stabilize: testCommand("stabilize", "Stabilize the satellite's position within its orbit along all three axes.", () => testComponent("NavAttitude", 4)),
    estimate_parameters: testCommand("estimateparameters", "Calculate the satellite's parameters of operation for optimal imaging", () => testComponent("Command", 1)),
    unload: {
      description: "Unload excess energy from within the satellite to keep its position under control.",
      fn: () => {
        trackDesktopCmd()
        if (!checked["Command"][1]) {
          terminal.current.pushToStdout(`"estimate_parameters" must be successfully tested before "unload"`)
          return
        }
        const output = t.unload.split("\n")
        setLastCommand("unload")
        setLastTestStatus(true)
        commandPrinter(output, terminal, () => testComponent("PowerMgmt", 2))
      }
    },
    set_imager: testCommand("setimager", "Adjust the imager settings to take the best images possible.", () => testComponent("EarthObserv", 0)),
    test_imager: {
      description: "Continue to adjust imager settings",
      fn: () => {
        trackDesktopCmd()
        if (!checked["EarthObserv"][0]) {
          terminal.current.pushToStdout(`"set_imager" must be successfully tested before "test_imager"`)
          return
        }
        const output = t.test_imager.split("\n")
        setLastCommand("testimager")
        commandPrinter(output, terminal, () => testComponent("PowerMgmt", 0), 1500)
      }
    },
    N: {
      description: "Answer to the test_imager command: \"No\"",
      fn: () => {
        trackDesktopCmd()
        if (!checked["PowerMgmt"][0]) {
          terminal.current.pushToStdout(`This command must be run after "test_imager"`)
          return
        }
        setAskAgain(true)
        const output = "adjusting ADCS to reduce blur- taking test picture- review picture quality- acceptable type Y unacceptable type N".split("- ")
        commandPrinter(output, terminal, () => {}, 1500)
        if (testImagerCount < 3) {
          setTestImagerCount(testImagerCount + 1)
        }
      }
    },
    Y: {
      description: "Answer to the test_imager command: \"Yes\"",
      fn: () => {
        trackDesktopCmd()
        if (!checked["PowerMgmt"][0]) {
          terminal.current.pushToStdout(`This command must be run after "test_imager"`)
          return 
        } else if (testImagerCount < 3 && askAgain) {
          setAskAgain(false)
          terminal.current.pushToStdout("Are you sure?")
          return 
        } else if (testImagerCount < 3) {
          terminal.current.pushToStdout("If you say so!")
        }
        const output = "entering MISSION MODE- commissioning complete- ready for full mission performance".split("- ")
        commandPrinter(output, terminal, testSuccessSound)
        testComponent("PowerMgmt", 1)
        setTerminalState("completed")
        setLockTerminal(true)
      }
    },
    revise_design: {
      description: "Revise your design to replace a failed component",
      fn: () => {
        if (lastCommand) {
          terminal.current.pushToStdout("Pick a new component")
        }
        setPrompt(t.commandPrompt)
        setLockTerminal(true)
        setTerminalState("uploading")
        setLastCommand("")
      }
    },
    howto: {
      description: "Instructions for how to use the digital twin.",
      fn: () => {
        terminal.current.pushToStdout(t.howtoMsg)
      }
    }
  }), [chosenComponents, testComponent, lastCommand, testImagerCount, checked, askAgain, testSuccessSound])

  useEffect(() => {
    const allUploaded = chosenComponents.every(c => c.picks.every(c => c));
    if (allUploaded) {
      setTerminalState("testing")
      terminal.current.clearStdout()
      commandPrinter(t.uploadCompleteMsg.split("\n"), terminal, () => setLockTerminal(false), 200)
    }
  }, [chosenComponents])

  const phoneTerminal = useCallback((cmd: string) => {
    ReactGA.event(`m3_cmd_section__terminal__mobile_cmd`);
    ReactGA.event({
      category: "m3_cmd_section__terminal",
      action: "mobile_cmd",
      label: cmd,
    });
    terminal.current.terminalInput.current.value = cmd
    terminal.current.clearStdout()
    setTimeout(() => {
      terminalCommands[cmd].fn()
    }, 1)
  }, [terminalCommands])

  const [ currentCommand, setCurrentCommand] = useState(commandList[0])
  const prevCommand = useCallback(() => {
    const idx = commandList.indexOf(currentCommand) - 1
    setCurrentCommand(commandList[idx >= 0 ? idx : commandList.length - 1])
  }, [currentCommand])

  const nextCommand = useCallback(() => {
    const idx = (commandList.indexOf(currentCommand) + 1) % commandList.length
    setCurrentCommand(commandList[idx])
  }, [currentCommand])

  const PhoneMenu = useMemo(() => {
    if (!isPhone) {
      return null
    } else {
      switch(terminalState) {
        case null:
        return (
          <div class="m3__command__phone__menu m3__command__phone__menu--full">
            <span onClick={() => { phoneTerminal("howto"); setTerminalState("help") }}>help</span>
            <span onClick={() => phoneTerminal("input_design")}>input design</span>
            <span onClick={() => phoneTerminal("commission_sat")}>commission sat</span>
            <span onClick={() => phoneTerminal("revise_design")}>revise design</span>
          </div>
        )
        case "help":
        return (
          <div class="m3__command__phone__menu m3__command__phone__menu--short">
            <div class="m3__command__phone__menu__title">
              <button onClick={() => setTerminalState(null)}><Img src="previous-arrow.svg" /></button>help
            </div>
          </div>
        )
        case "uploading":
        return (
          <div class="m3__command__phone__menu m3__command__phone__menu--short">
            <div class="m3__command__phone__menu__title">
              <button onClick={() => setTerminalState(null)}><Img src="previous-arrow.svg" /></button>input design
            </div>
          </div>
        )
        case "testing":
        return (
          <div class="m3__command__phone__menu m3__command__phone__menu--testing">
            <div class="m3__command__phone__menu__title">
              <button onClick={() => setTerminalState(null)}><Img src="previous-arrow.svg" /></button>commission sat
            </div>
            <div class="m3__command__phone__menu__command-picker">
              <button onClick={() => prevCommand()}><Img src="previous-arrow.svg" /></button>
              {currentCommand}
              <button onClick={() => nextCommand()}><Img src="next-arrow.svg" /></button>
            </div>
            <button class="command-cta" onClick={() => phoneTerminal(currentCommand)}>run command</button>
            { lastCommand === "testimager" && checked["PowerMgmt"][0] === true && (
              <div class="command__test-imager__options">
                <button class="command-cta" onClick={() => phoneTerminal("Y")}>Y</button>
                <button class="command-cta" onClick={() => phoneTerminal("N")}>N</button>
              </div>
            )}
          </div>
        )
      }
    }
  }, [terminalState, isPhone, phoneTerminal, nextCommand, prevCommand, currentCommand])

  return (
    <Section name={SectionName.commandScreen} title="" subtitle="" transition="fade-in">
      { !isPhone && 
      <div class="m3__background__controls">
        <Img src={`mute${mute?"_on":""}.svg`} class="sound__controls__mute" onClick={() => setMute(!mute)}/>
        <div class="radio__sound">
          <input class="radio__volume" type="range" min="0" max="100" value={volume} onChange={(e: any) => setVolume(e.target.value)}/>
        </div>
        <Img src="video-icon.svg" class="m3__video__button" onClick={goToSection(SectionName.m3Video)}/>
      </div>
      }
      <div class="browser">
        <div class="browser__title">
          DIGITAL TWIN: MOONLIGHTER_COMMISSIONING_SIMULATION
          <button class="browser__close" onClick={close}></button>
          <button class="browser__max"></button>
          <button class="browser__min" onClick={close}></button>
        </div>

        { !showForm ? (
          <div class="browser__content browser__content--split">
            <div class="browser__content__terminal-panel">
              {PhoneMenu}
              <Terminal
                commands={terminalCommands}
                readOnly={lockTerminal || isPhone}
                locked={lockTerminalLine}
                welcomeMessage={t.welcomeMsg}
                promptLabel={prompt}
                style={{
                  textAlign: "left",
                  backgroundColor: "rgba(0,0,0,0.75)",
                  overflow: "auto",
                  borderRadius: "0",
                  flex: 1,
                  minHeight: isPhone ? "100px" : "300px",
                  display: isPhone && terminalState === null ? "none" : "block",
                  paddingBottom: "8px"
                }}
                contentStyle={{ fontSize: isPhone ? "14px" : "16px", padding: isPhone ? "8px" : "20px", height: "auto" }}
                promptLabelStyle={{ paddingTop: "2px" }}
                messageStyle={{ lineHeight: 'auto' }}
                ref={terminal}
              />
            </div>
            <div>
              { terminalState === null && (
                <div class="satellite__simulation__gif">
                  <Img src={`satellite_gifs/StartScreen.gif`} />
                </div>
              )}
              { terminalState === "testing" && (lastCommand ? (
                <div class="satellite__simulation__gif">
                  <SatelliteGIF lastTest={lastCommand} lastTestStatus={lastTestStatus} testImagerCount={testImagerCount}/>
                </div>
              ) : (
                <div class="commission-sat-gif">
                  <Img src={`satellite_gifs/commissionsat-satonly.gif`} />
                  <Img src={`satellite_gifs/commissionsat-textonly.gif`} />
                </div>
              ))}
              { terminalState === "uploading" && (
                <SatelliteUpload checked={checked} setChecked={setChecked} chosenComponents={chosenComponents} setChosenComponents={setChosenComponents} terminal={terminal}/>
              )}
              { terminalState === "completed" && (
                <div class="satellite__simulation__complete">
                  <h1>SIMULATION COMPLETE</h1>
                  <h3>Your design should perform optimally in orbit. Well done!</h3>

                  <Img src="trophy-large.png" />
                  <button onClick={() => {setShowForm(true); completeAndTrack()}}>Continue</button>
                </div>
              )}
            </div>
          </div>
      ) : (
        <div class="browser__content browser__content--joined">
          <iframe
            id={location.hostname === 'mission.hackasat.com' ? "JotFormIFrame-222485871240153" : "JotFormIFrame-222485871240153"}
            title="Mission 3 - Digital Twin V3"
            allowTransparency
            allowFullScreen
            allow="geolocation; microphone; camera"
            src={location.hostname === 'mission.hackasat.com' ? "https://hipaa.jotform.com/222485871240153" : "https://hipaa.jotform.com/222485871240153"}
            frameBorder="0"
            scrolling="no"
          >
          </iframe>
        </div>
      )}
      </div>
    </Section>
  )
}

