import { FunctionalComponent, h, JSX } from 'preact';
import { Section } from './section';
import { SectionName } from "../section-context"
import { Close } from "./close"

interface IProps {
  close: (...params: any) => void,
}

export const AISection: FunctionalComponent<IProps> = ({ close }) => {
  return (
    <Section name={SectionName.aitab} title="" subtitle="" transition="fade-in">
      <div class="browser">
        <div class="browser__title">
          WHITE RABBIT AI - BETA
          <button class="browser__close" onClick={close}></button>
          <button class="browser__max"></button>
          <button class="browser__min" onClick={close}></button>
        </div>

        <div class="browser__content browser__content--video">
          <iframe src="https://u0v1a9tshmy.typeform.com/c/EQLhJiSU" />
        </div>
      </div>
      <Close close={close} />
    </Section>
  )
}
