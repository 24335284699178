import { FunctionalComponent, h } from 'preact';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { SectionContext, SectionName } from "../section-context"
import { Section } from './section';
import { Img } from "./img"
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import {config} from '../config';

enum Animations {
  LOAD= 0,
  SEAL= 1,
  TEST_THERMAL= 2,
  TEST_SOLAR= 3,
  TEST_BAKEOUT= 4,
  TEST_ECLIPSE= 5,
}

const grades = {
  "CORE SYSTEM CHECK": {
    title: "YOUR DESIGN FAILED",
    text: `<strong>Core System Verification</strong> passed the tests. However, your
battery overheated and shorted out in direct sunlight due to a
faulty solar sensor! Should have checked that. Let’s try again!`
  },
  "FULL SYSTEM CHECK": {
    title: "YOUR DESIGN FAILED",
    text: `<strong>Full System Verification</strong> passed, but your sealants
deteriorated rapidly in a vacuum, causing particles to
condense on your components.<br/>
That would be bad news in orbit. Let’s try again!`
  },
  "EXHAUSTIVE SYSTEM CHECK": {
    title: "CLEARED FOR LAUNCH!",
    text: `That <strong>Exhaustive Systems Verification</strong> is definitely the way to
go. All of your components held up to the extremes of
vacuum and tempereature. You can launch with confidence!`,
    passed: true
  },
}

const animations = [
  { 
    table: "insertsat-chamber",
  },
  { 
    table: "shutdoor-chamber",
  },
  { 
    table: "thermalcyclingtest-chamber",
    screen: "thermalcyclingtest-screen",
  },
  { 
    table: "solarsimtest-chamber",
    screen: "solarsimtest-screen",
  },
  { 
    table: "tvacbakeouttest-chamber",
    screen: "tvacbakeouttest-screen",
  },
  { 
    table: "eclipsetest-chamber",
    screen: "eclipsetest-screen",
  },
]

const screens = {
  initial: {
    title: "THERMAL AND OUTGAS TESTING",
    content: `<p>
The space simulation chamber is designed to test hardware
in a total vacuum, simulating a space environment.
This system can simulate the effect of direct sunlight as well
as the freezing cold of space.
        </p>
        <p>
It also has the sensors necessary to perform an outgassing
test, measuring the microscopic particles that evaporate from
materials in a vacuum environment.
        </p>`

  },
  solar: {
    title: "SOLAR SIMULATOR TEST",
    content: `<p>Using ultra-bright lamps and a reflector array, this test will
show you how your spacecraft responds to direct sunlight in
space- especially your solar charging systems and batteries.</p>`
  },
  thermal: {
    title: "THERMAL VACUUM CYCLING (TVAC) TEST",
    content: `<p>This test cycles through extreme warm and cold
temperatures, stressing the spacecraft and simulating the
range of thermal states it will experience in space with no
atmosphere to insulate it.</p>`
  },
  bakeout: {
    title: "TVAC BAKEOUT TEST",
    content: `<p>
    This test keeps your spacecraft at a high temperature for about 24 hours.
    </p>
    <p>
      Being at high temperature in a vacuum will cause your
      components to outgas, or emit any particles that may cause
      problems if they condense on your systems in orbit.
    </p>
    <p>
      Better to get them out of the way now!
    </p>`
  },
  eclipse: {
    title: "ECLIPSE TEST",
    content: `<p>
    It's very dark and cold in space. Your spacecraft needs to be able to hold up in prolonged periods of eclipse.
    </p>
    <p>
      This test will show you how your temperature regulation systems behave and whether your satellite will stay warm enough to operate.
    </p>`
  }
}
const steps = {
  'STEP 1': {
    screen: screens.initial,
    content: `Click or tap the satellite to load it into the space simulation chamber.`,
    next: 'STEP 2',
    animation: Animations.LOAD,
  },
  'STEP 2': {
    screen: screens.initial,
    content: `Click or tap the door to seal the chamber and pump out the atmosphere, creating a high-vacuum environment environment for testing.`,
    animation: Animations.SEAL,
  },
  'SELECT TEST TO BEGIN': {
    screen: screens.initial,
    animation: Animations.TEST_THERMAL,
    options: [{
      name: 'test1',
      nextAnimationIdx: Animations.TEST_THERMAL,
    },{
      name: 'test2',
      nextAnimationIdx: Animations.TEST_SOLAR,
    },{
      name: 'test3',
      nextAnimationIdx: Animations.TEST_BAKEOUT,
    },{
      name: 'test4',
      nextAnimationIdx: Animations.TEST_ECLIPSE,
    }]
  },
  'COMPLETE': {
  },
  'TESTING COMPLETE': {
    animation: Animations.TEST_THERMAL,
  }
}

const screensForAnimation = {
  [Animations.TEST_THERMAL]: screens.thermal,
  [Animations.TEST_ECLIPSE]: screens.eclipse,
  [Animations.TEST_BAKEOUT]: screens.bakeout,
  [Animations.TEST_SOLAR]: screens.solar,
}


interface IProps {
  testingComponents: any[],
  close: (...params: any) => void,
  review: (...params: any) => void,
  finish: (...params: any) => void,
}

export const StationThree: FunctionalComponent<IProps> = ({ close, testingComponents, review, finish }) => {
  const { activeSection } = useContext(SectionContext)
  const [ isPlayingAnimation, setIsPlayingAnimation ] = useState(false)
  const [ isClosed, setIsClosed ] = useState(false)
  const tableLottieRef = useRef(null)
  const screenLottieRef = useRef(null)
  const TPlayer = (Player as any);
  const [ currentAnimation, setCurrentAnimation ] = useState(0)
  const [ currentStep, setCurrentStep ] = useState('STEP 1')
  const animationOptions = useMemo(() => {
    return ([{
        src: `/assets/m4/obj/station-3/${animations[currentAnimation].table}.json`,
        loop: false,
        autoplay: false,
      }, animations[currentAnimation].screen ? {
        src: `/assets/m4/obj/station-3/${animations[currentAnimation].screen}.json`,
        loop: false,
        autoplay: false,
      } : null])
  }, [currentAnimation])

  const goBack = useCallback(() => {
    close()
  }, [])

  const [ testSuccess, setTestSuccess ] = useState(false)

  const [ pendingTests, setPendingTests ] = useState([Animations.TEST_THERMAL, Animations.TEST_BAKEOUT, Animations.TEST_ECLIPSE, Animations.TEST_SOLAR])

  const onCompleteAnimation = useCallback((event: PlayerEvent) => { 
    if (event === "complete") {
      let step: string;
      switch (currentAnimation){
        case  Animations.LOAD:
          step = "STEP 2"
          break;
        case  Animations.SEAL:
          setIsClosed(true)
          step = "SELECT TEST TO BEGIN"
          break;
        case  Animations.TEST_THERMAL:
        case  Animations.TEST_BAKEOUT:
        case  Animations.TEST_ECLIPSE:
        case  Animations.TEST_SOLAR:
          if (pendingTests.length > 1) {
            const newPendingTests = pendingTests.filter(p => p !== currentAnimation)
            setPendingTests([ ... newPendingTests ])
            setCurrentAnimation(newPendingTests[0])
            step = "COMPLETE"
          } else {
            setTestSuccess(!!grades[testingComponents[1].name].passed)
            step = "TESTING COMPLETE"
          }
          break;
        default:
          step = "TESTING COMPLETE"
          break;
      }
      setCurrentStep(step)
      if (step !== "COMPLETE" && steps[step].animation || steps[step].animation === 0) {
        setCurrentAnimation(steps[step].animation)
      }
      setIsPlayingAnimation(false)
    }
  }, [currentStep, currentAnimation, pendingTests])

  const playAnimation = useCallback(() => {
    const a = new Audio(config.m4SoundFolder + "station-3/" + animations[currentAnimation].table.replace("-chamber", "") + ".ogg")
    a.volume = 0.5
    a.play()
    tableLottieRef.current.play() 
    screenLottieRef.current?.play() 
    setIsPlayingAnimation(true)
  }, [currentAnimation])

  const chooseOption = useCallback(((opt) => {
    setCurrentAnimation(opt.nextAnimationIdx)
    setIsPlayingAnimation(true)
    setCurrentStep("COMPLETE")
    setTimeout(() => {
      playAnimation()}, 100)
  }), [playAnimation])

  const getGrade = useCallback(() => {
    // TODO: this should not have a null state
    return testingComponents[1] ? grades[testingComponents[1].name] : { title: "", text: ""}
  }, [testingComponents])


  useEffect(() => {
    setCurrentAnimation(0)
    setCurrentStep('STEP 1')
    setIsClosed(false)
  }, [activeSection])

  return (
    <Section name={SectionName.station3} transition="fade-in">
      <div class="station-three__infobox">
        { currentStep === 'COMPLETE' && (
          <>
            <h1>{screensForAnimation[currentAnimation].title}</h1>
            <div dangerouslySetInnerHTML={{ __html: screensForAnimation[currentAnimation].content }}></div>
          </>
        )}
        { currentStep === 'TESTING COMPLETE' && (
          <>
            <h1>TEST RESULTS</h1>
            <h1 class="text-white">{getGrade().title}</h1>
            <p dangerouslySetInnerHTML={{ __html: getGrade().text }}></p>
          </>
        )}
        { currentStep !== 'TESTING COMPLETE' && currentStep !== 'COMPLETE' && (
          <>
            <h1>{steps[currentStep].screen.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: steps[currentStep].screen.content }}></div>
          </>
        )}
      </div>
      <div class="station-three__animations">
        <Img src="station-3/TVACbody-static.png" class="station-three__machine"/>
        { currentAnimation === Animations.SEAL && !isPlayingAnimation && (
          <div class="experience__section__clickable station-three__door-hover" onClick={playAnimation}>
            <Img src="station-3/shutdoorhover.svg" />
          </div>
        )}
        <TPlayer {...animationOptions[0]} ref={tableLottieRef} onEvent={onCompleteAnimation}>
          { isClosed && <Img src="station-3/chamberdoor-closedstatic.png" class="station-three__door"/>}
        </TPlayer>
        { (currentAnimation === Animations.LOAD || currentAnimation === Animations.SEAL) && 
          <div onClick={playAnimation} style={{ width: "80%", position: "absolute", left: "10%", height: "80%", top: "20%", zIndex: 2 }}>
          </div>
        }
      </div>
      <div class="station-three__console">
        <div class={`station-three__console__text ${isPlayingAnimation && animationOptions[1] ? '' : 'hide'}`}>
          <TPlayer {...animationOptions[1]} ref={screenLottieRef} style={{ height: "90%" }}>
          </TPlayer>
        </div>
        <div class={`station-three__console__text ${isPlayingAnimation && animationOptions[1] ? 'hide' : ''}`}>
          <h1>{currentStep}</h1>
          { currentStep === 'COMPLETE' && (
            <button onClick={() => playAnimation()}><Img src="station-3/next-test-btn.svg" /></button>
          )}
          { currentStep === 'TESTING COMPLETE' && (
            <>
              { testSuccess ? (
                <button onClick={finish}><Img src="station-3/launch-btn.svg "/></button>
              ) : (
                <button onClick={review}><Img src="station-3/revise-btn.svg "/></button>
              )}
            </>
          )}
          { currentStep !== 'TESTING COMPLETE' && currentStep !== 'COMPLETE' && (
            <div>
              {steps[currentStep].content && (
              <p>
                {steps[currentStep].content}
              </p>)}
              { steps[currentStep].options &&  steps[currentStep].options.map((opt) => (
                <button key={opt.text} onClick={() => chooseOption(opt)} className="image-btn">
                  <Img src={`station-3/${opt.name}-btn.svg`} />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <Img src="backbutton.svg" onClick={goBack} class="mission-4__btn-back" />
    </Section>
  )
}



