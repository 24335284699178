import { FunctionalComponent, h } from 'preact';
import { Section } from './section';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useLoadAssets } from '../hooks/useAssets'
import { SectionContext, SectionName } from "../section-context"
import { Img } from "./img"
import { config } from "../config"
import ReactGA from 'react-ga4'
const m3Assets = require('../assets/m3.json')
const m3smAssets = require('../assets/m3_sm.json')
import { Player } from '@lottiefiles/react-lottie-player';
import { Video } from "./video"
import {M3CommandSection} from './m3CommandSection';
import {IChosenComponentsByCategory, useSatComponents} from '../hooks/useSatComponents';
import { useSound } from "../hooks/useSound"
import { Close } from "./close"

interface IProps {
  goToSection: (...params: any) => (...params: any) => void,
  setAudioPaused: (...params: any) => void,
  complete: (...params: any) => void,
}

const lottieRabbitOptions = {
  src: "/assets/m3/obj/lottie-rabbit.json",
  loop: true,
  autoplay: true,
};


export const M3: FunctionalComponent<IProps> = ({ setAudioPaused, complete, goToSection }) => {
  const ref = useRef(null)
  const { activeSection, contentWidth, contentHeight, audioPaused } = useContext(SectionContext)
  const [ mute, setMute ] = useState(false)
  const [ volume, setVolume ] = useState<number>(50);
  const [ videoPlaying, setVideoPlaying ] = useState(true)
  const [ allAssetsLoaded, setAllAssetsLoaded ] = useState(false)
  const [ totalLoadedAssets, setTotalLoadedAssets ] = useState(0)
  const [ laptopClickSound ] = useSound(config.m3SoundFolder + 'laptop_click.ogg', { volume: 0.5 })
  const assetLoaded = useCallback((assets: number) => {
    setTotalLoadedAssets(assets)
  }, [])
  const bgAudioRef  = useRef(typeof window !== "undefined" ? new Audio(`${config.m3SoundFolder}background_music.ogg`) : null);

  if (bgAudioRef) {
    bgAudioRef.current.autoplay = true
  }

  useEffect(() => {
    bgAudioRef.current.volume = volume / 100 / 2;
  }, [volume]) 

  useEffect(() => {
    bgAudioRef.current.muted = mute
  }, [mute]) 

  useEffect(() => {
    bgAudioRef.current.loop = true
    if (audioPaused && !bgAudioRef.current.paused) {
      bgAudioRef.current.pause()
    } else {
      setTimeout(() => {
        bgAudioRef.current.play()
      }, 100)
    }
  }, [audioPaused])

  const cbAllAssetsLoaded = useCallback(() => {
    setAllAssetsLoaded(true)
    setTimeout(() => {
      ref.current.parentNode.scrollLeft = ref.current.parentNode.scrollWidth
    }, 1)
  }, [])

  const TPlayer = (Player as any);
  const { loadAssets } = useLoadAssets(contentWidth > 1200 ? m3Assets : m3smAssets, assetLoaded, cbAllAssetsLoaded)
  const loadingPercentage = useMemo(() => {
    return Math.round(totalLoadedAssets / m3Assets.length * 100)
  }, [totalLoadedAssets])

  const unpauseAudio = useCallback(() => { setAudioPaused(false) }, [setAudioPaused]);
  const pauseAudio = useCallback(() => { setAudioPaused(true) }, [setAudioPaused]);

  useEffect(() => {
    loadAssets()
    goToSection(SectionName.empty, true, true)()
  }, []) // goToSection and loadAssets are explicitely excluded

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/mission-3" });
    return () => {
      if (bgAudioRef.current && !bgAudioRef.current.paused) {
        bgAudioRef.current.pause()
      }
    }
  }, [])

  /*
  const getPos = useCallback((num: number | "auto", useWidth: boolean = false) => {
    if (num !== "auto") {
      return `${num / 100 * (useWidth ? contentWidth : contentHeight)}px`;
    }

    return num
  }, [contentHeight])
   */
  const { categories } = useSatComponents()

  const [ chosenComponents, setChosenComponents ] = useState<IChosenComponentsByCategory[]>(categories.map((category) => ({
    key: category.key,
    picks: category.items.map(() => null)
  })))

  const videoEnded = useCallback(() => {
    ReactGA.event("m3_video__completed");
    ReactGA.event({
      category: "m3_video",
      action: "completed",
    });
    goToSection(SectionName.commandScreen, false, true)()
  }, [goToSection, ReactGA])

  const showVideo = useCallback(() => { 
    ReactGA.event("m3_video__open");
    ReactGA.event({
      category: "m3_video",
      action: "open",
    });
    laptopClickSound();
    pauseAudio();
    goToSection(SectionName.m3Video, false, true)() 
  }, [goToSection, pauseAudio, laptopClickSound])

  useEffect(() => {
    if (activeSection === SectionName.m3Video) {
      setVideoPlaying(true)
    }
  }, [activeSection])

  const clickableStyle = useMemo(() => {
    const res = contentWidth > 1000 ? {
      // top: contentWidth/contentHeight > 1.76 ? 780*contentHeight/contentWidth : 0.415 * contentHeight
    } : {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto"
    }
    return res
  }, [ contentWidth, contentHeight ])

  if (!allAssetsLoaded) {
    return (
      <div class="loading-overlay">
        <div class="loading-bar" style={{width: `${loadingPercentage*5}px`}} />
        {loadingPercentage}%
      </div>
    )
  } else if (allAssetsLoaded) {
    return (
      <div class="m3-container" ref={ref}>
        <div id="please-turn-phone">
          <h1>Please rotate your phone to access White Rabbit's workshop</h1>
        </div>
        {/*
        <Img src="smoke.gif" class="blended-gif m3__coffee-gif" style={{
          top: contentWidth/contentHeight > 1.76 ? 750*contentHeight/contentWidth : 0.395 * contentHeight,
          right: contentWidth/contentHeight > 1.76 ? 150*contentHeight/contentWidth : 0.12 * contentHeight
        }} />
          */}
        { contentWidth > 1000 && (
        <Img src="lights.gif"  class="blended-gif m3__lights-gif"  style={{
          top: contentWidth/contentHeight > 1.76 ? 450*contentHeight/contentWidth : 200000 / contentHeight,
          right: contentWidth/contentHeight > 1.76 ? 300*contentHeight/contentWidth : 50000 / contentHeight
        }} />
        )}
        <Section name={SectionName.m3Video} transition="fade-in">
          { contentWidth > 1000 && <Close close={goToSection(SectionName.empty, false, true)}/>}
          <div class="browser m3-main-browser">
            <div class="browser__title">
              <button class="browser__close" onClick={goToSection(SectionName.commandScreen, false, true)}></button>
              <button class="browser__max"></button>
              <button class="browser__min" onClick={goToSection(SectionName.commandScreen, false, true)}></button>
            </div>

            <div class="browser__content browser__content--video">
              <Video 
                src="https://player.vimeo.com/progressive_redirect/playback/743233656/rendition/720p/file.mp4?loc=external&signature=b4af15551d8e3979efbd75eff5a082d8ec55f01371606365ea12dd73450e341a"
                playVideo={() => { pauseAudio(); setVideoPlaying(true) }}
                stopVideo={() => { unpauseAudio(); setVideoPlaying(false) }}
                controls
                playing={videoPlaying}
                videoEnded={videoEnded}
                />
            </div>
          </div>
        </Section>
        <M3CommandSection close={goToSection(SectionName.empty, false, true)} chosenComponents={chosenComponents} setChosenComponents={setChosenComponents} goToSection={goToSection} volume={volume} setVolume={setVolume} mute={mute} setMute={setMute} complete={complete}/>
        <Section name={SectionName.empty} transition="fade-in">
          <div class="m3-laptop" onClick={showVideo} style={clickableStyle}>
            <div class="experience__section__clickable">
              <Img src="laptophover.svg" style={{maxWidth: "unset", maxHeight: "unset"}}/>
            </div>
            <TPlayer {...lottieRabbitOptions}>
            </TPlayer>
          </div>
        </Section>
      </div>
    )
  }
}

