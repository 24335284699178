import { FunctionalComponent, h, JSX } from 'preact';
import { config } from "../config"

interface IProps {
  action: (...params: any) => void,
}

export const ShoppingIcon: FunctionalComponent<IProps> = ({ action }) => {
  return (
    <div class="experience__shopping">
      <a>
        <img class="img__linkable__shopping" src={`${config.m2ImgFolder}shopping-basket-icon.svg`} onClick={action}/>
      </a>
    </div>
  )
}

