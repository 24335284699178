import {FunctionalComponent} from "preact"
import { Section } from './section';
import { SectionName } from "../section-context"
import { Close } from "./close"
import { Player } from '@lottiefiles/react-lottie-player';
import { useCallback, useRef, useState } from "preact/hooks";
import useSound from '../hooks/useSound';
import {config} from "../config";
import {Clickable} from "./clickable";
import {Img} from "./img";
import {usePagination} from "../hooks/usePagination";

interface IProps {
  close: (...params: any) => void,
}

const options = {
  src: "/assets/m2/obj/safe-dial.json",
  loop: false,
  autoplay: false,
};

const newsTotal = 2

export const SafeSection: FunctionalComponent<IProps> = ({ close }) => {
  const TPlayer = (Player as any);
  const lottieRef = useRef(null)
  const [ showSafeNews, setShowSafeNews ] = useState<boolean>(false)
  const [ playSafe ] = useSound(`${config.m2SoundFolder}click_safe.ogg`, { volume: 0.5 })
  const [ playSafeNews ] = useSound(`${config.m2SoundFolder}click_safe_section.ogg`, { volume: 0.5 })

  const play = useCallback((e) => {
    playSafe();
    lottieRef.current.play();
  }, [playSafe])

  const openSafeNews = useCallback(() => {
    setShowSafeNews(true)
    playSafeNews()
  }, [playSafeNews])

  const { idx, back, next } = usePagination(newsTotal)

  return (
    <Section name={SectionName.safe} title="" subtitle="" transition="fade-in">
      <div>
        <Img src="safe-news-hover.svg" class="experience__section__safe__news-hover experience__section__clickable" onClick={openSafeNews}/>
        <div class="experience__section__safe__animation" onClick={play}>
          <TPlayer {...options} ref={lottieRef}>
          </TPlayer>
        </div>
        <Img src="dial-hover.svg" class="experience__section__safe__ring-hover experience__section__clickable" onClick={play} />
        <Close close={close} />
        { showSafeNews && (
          <div class="specs-gallery" style="z-index: 2">
          <Img src={`safe-news-${idx+1}.png`} class="main-image" style="z-index: 2"/>
          <div class="specs-gallery__nav">
            <button onClick={back} class="btn-prev"></button>
            <button onClick={next} class="btn-next"></button>
          </div>
          <Close close={() => setShowSafeNews(false)} />
        </div>
        )}
      </div>
    </Section>
  )
}
