import { FunctionalComponent, h, JSX } from 'preact';
import { Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useSound } from "../hooks/useSound"
import { config } from "../config"
import { Img } from "./img"
import { useSatComponents, ComponentCategory, IChosenComponentsByCategory, IComponentsCategory, IComponent, findCategoryByKey } from "../hooks/useSatComponents"
import ReactGA from "react-ga4"
import { IErrorMap } from './m3CommandSection';
import Terminal from 'react-console-emulator'

interface IProps {
  checked: IErrorMap,
  setChecked: (...params: any) => void,
  chosenComponents: IChosenComponentsByCategory[],
  setChosenComponents: (...params: any) => void,
  terminal: Ref<Terminal>
}

export const SatelliteUpload: FunctionalComponent<IProps> = ({ checked, setChecked, chosenComponents, setChosenComponents, terminal }) => {
  const [ selectComponentSound ] = useSound(`${config.m3SoundFolder}select_component.ogg`, { volume: 0.5 })

  const [ currentCategory, setCurrentCategory ] = useState<ComponentCategory>("Structure" as ComponentCategory)
  const [ currentGroup, setCurrentGroup ] = useState<number>(0)
  const { categories } = useSatComponents()

  const selectComponent = useCallback((component: IComponent ) => {
    selectComponentSound()
    const categoryData = findCategoryByKey(currentCategory, chosenComponents)
    const picks = categoryData.picks.slice()
    terminal.current.pushToStdout(`${currentGroup + 1} of ${picks.length}`)
    setChecked((_checked: IErrorMap) => {
      _checked[currentCategory][currentGroup] = null
      return _checked 
    })
    ReactGA.event("m3_satdesign_pick_component");
    ReactGA.event({
      category: "m3_shopping_section",
      action: "pick_component",
      label: component.name
    });
    // playComponentChosen();
    picks[currentGroup] = component;
    setChosenComponents([
      ...chosenComponents.filter(i => i.key !== currentCategory),
      {
        key: currentCategory,
        picks: picks
      }
    ]);

    next()
    setTimeout(() => {
      terminal.current.terminalRoot.current.firstChild.scrollTop = terminal.current.terminalRoot.current.firstChild.scrollHeight
      terminal.current.terminalRoot.current.scrollTop = terminal.current.terminalRoot.current.scrollHeight
      terminal.current.focusTerminal()
    }, 10)
  }, [chosenComponents, currentCategory, currentGroup, selectComponentSound])
  
  const currentGroupList = useMemo(() => {
    return findCategoryByKey(currentCategory, categories).items[currentGroup]
  }, [currentCategory, currentGroup])

  const currentPick = useMemo(() => {
    return findCategoryByKey(currentCategory, chosenComponents).picks[currentGroup]
  }, [currentCategory, currentGroup, chosenComponents])

  const back = useCallback(() => {
    const nextGroup = currentGroup - 1;
    const categoryData = findCategoryByKey(currentCategory, categories);
    const nextCategory = categories.indexOf(categoryData) - 1;
    if (nextGroup >= 0) {
      setCurrentGroup(nextGroup)
    } else if (nextCategory >= 0) {
      terminal.current.pushToStdout(`processing "${categories[nextCategory].name}"`)
      setCurrentCategory(categories[nextCategory].key)
      setCurrentGroup(categories[nextCategory].items.length - 1)
    } else {
      const newCat = categories[categories.length-1]
      terminal.current.pushToStdout(`processing "${newCat.name}"`)
      setCurrentCategory(newCat.key)
      setCurrentGroup(newCat.items.length - 1)
    }
  }, [categories, currentCategory, currentGroup])

  const next = useCallback(() => {
    const nextGroup = currentGroup + 1;
    const categoryData = findCategoryByKey(currentCategory, categories);
    const nextCategory = categories.indexOf(categoryData) + 1;
    if (categoryData.items.length === nextGroup) {
      if (categories.length !== nextCategory) {
        setCurrentCategory(categories[nextCategory].key)
        terminal.current.pushToStdout(`processing "${categories[nextCategory].name}"`)
        setCurrentGroup(0)
      } else {
        terminal.current.pushToStdout(`processing "${categories[0].name}"`)
        setCurrentCategory(categories[0].key)
        setCurrentGroup(0)
      }
    } else {
      setCurrentGroup(nextGroup)
    }
  }, [categories, currentCategory, currentGroup])

  useEffect(() => {
    const allEmpty = chosenComponents.every(c => c.picks.every(c => c === null));
    if (allEmpty) {
      setCurrentCategory("Structure")
      setCurrentGroup(0)
    }
  }, [chosenComponents])

  useEffect(() => {
    Object.keys(checked).forEach((category) => {
      checked[category].forEach((s, idx) => {
        if (s === false) {
          setCurrentCategory(category as ComponentCategory)
          setCurrentGroup(idx)
        }
      })
    })
  }, [checked])

  useEffect(() => {
    terminal.current.pushToStdout(`processing "${categories[0].name}"`)
  }, [])

  return (
    <div class="satellite__upload">
      <div class="satellite__upload__component-list">
        <h1>{currentCategory} - {currentGroupList[0].group}</h1>
        { currentGroupList.filter(component => component.valid).map((component) => (
          <div key={component.name} class={`satellite__upload__component ${currentPick === component?"satellite__upload__component--"+(checked[currentCategory][currentGroup] === false?"error":"selected"):""}`}>
            <div class="satellite__upload__component__image">
              <Img key={component.filename} src={`${currentCategory}/${component.filename}.svg`} mission={2} onClick={() => selectComponent(component)} />
            </div>
            <div class="satellite__upload__component__description">
              <p>{component.name}</p>
              <p>{component.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div class="satellite__upload__arrows">
        <div onClick={back}>
          <div class="btn-prev" />
          BACK
        </div>
        <div onClick={next}>
          NEXT
          <div class="btn-next" />
        </div>
      </div>
    </div>
  )
}

